import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import { RESPONSE_DISMISS_MSG, DELETE_RESPONSE_TITLE } from './response.meta';
import { GridState } from 'src/app/shared/components/ey-grid/models/grid-state.model';
import { TableData } from 'src/app/shared/components/ey-grid/models/table-data.model';
import {ResponseInfo} from '../../../dashboard/my-responses/response-info.model';
import {Subject} from 'rxjs/internal/Subject';
import {
  CollaborationResponseStatuses,
  ResponseStatuses,
} from '../../../dashboard/my-responses-card/response-card.model';
import {UNKNOWN_RESPONSE_STATUS} from '../../../projects/responses/responses.const';
import {InfoDialogTypes} from 'src/app/shared/components/ey-info-dialog/info-dialog-types.enum';
import { InfoDialogOptions } from 'src/app/shared/components/ey-info-dialog/ey-info-dialog.model';
import {DRAFT_RESPONSE_STATUS} from '../../../projects/project.const';
import {IRespondent} from '../../../projects/get-web-link/get-web-link.model';

export const DOC_NAME = 'Document_%TS%.';
export const DRAFT = '[DRAFT]';

@Injectable({
  providedIn: 'root'
})

export class ResponseService {
  isResponseSubmitted = new Subject<boolean>();
  private baseUrl = environment.baseUrl;
  constructor(private httpClient: HttpClient) { }

  deleteResponse(projectId: string, id: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.baseUrl}/project/${projectId}/delete-response/${id}`);
  }

  duplicateResponse(responseId: string, respondentId: string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.baseUrl}/project/clone-response/${responseId}/${respondentId}`, null);
  }

  responseCloneData(responseId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/project/get-response-cloning-details/${responseId}`);
  }

  getRespondentCandidatesForNonCollaborationProject(projectId: string): Observable<any> {
    return this.httpClient.get<IRespondent[]>(`${environment.baseUrl}/project/${projectId}/respondents-for-response-duplication-lookup`);
  }

  getRespondentCandidatesForNonCollaborationProjectOnSearch(projectId: string, lookupPhrase: string): Observable<IRespondent[]> {
    return this.httpClient.get<IRespondent[]>(`${environment.baseUrl}/project/${projectId}/respondents-for-response-duplication-lookup/${lookupPhrase}`);
  }

  deleteTemplate(projectId: string, id: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.baseUrl}/project-file/${projectId}/${id}`);
  }

  downloadResponse(id: string): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/file/${id}`, {observe: 'response', responseType: 'blob' });
  }

  retryDownloadResponse(id: string): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/project-bulk-export/reschedule-generation/${id}`,
      {observe: 'response', responseType: 'blob' });
  }

  isResponseInDraftState(projectId: string): Observable<{ isCollaborationMode: boolean, draftResponseAvailable: boolean }> {
    return this.httpClient.get<{ isCollaborationMode: boolean, draftResponseAvailable: boolean }>(`${this.baseUrl}/responseflow/show-drafts-popup/${projectId}`);
  }

  getUserDraftResponses(projectId: string, state: GridState): Observable<TableData<ResponseInfo>> {
    return this.httpClient.post<TableData<ResponseInfo>>(`${this.baseUrl}/responseflow/drafts/${projectId}`, {
      pageSize: state.pageSize.toString(),
      sortBy: state.sortBy.toString(),
      sortDirection: state.sortDirection.toString(),
      pageNumber: state.pageNumber.toString(),
      search: state.search.toString(),
      activeFilters: state.activeFilters,
    });
  }

  // TODO(A.T) TASK[4491899]: Segregate all API calls to a common api.service

  getResponseDeleteDialogOptions(): InfoDialogOptions {
    return {
      dialogType: InfoDialogTypes.delete,
      msg: RESPONSE_DISMISS_MSG,
      title: DELETE_RESPONSE_TITLE,
    };
  }

  checkIfResponseIsInDraftState(responseStatus: number): boolean {
    return responseStatus === DRAFT_RESPONSE_STATUS;
  }

  getResponseStatus(statusId: number, isCollaborationLocked: boolean = null): any {
    const status = ResponseStatuses.find((s) => s.id === statusId);
    if (isCollaborationLocked !== null && status.labelText !== 'Completed') {
      const collaborationModeStatus = CollaborationResponseStatuses.find((s) => s.isLocked === isCollaborationLocked);
      return collaborationModeStatus !== undefined ? collaborationModeStatus : UNKNOWN_RESPONSE_STATUS;
    }
    return status !== undefined ? status : UNKNOWN_RESPONSE_STATUS;
  }
}
