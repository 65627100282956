
export enum EnrollmentStatus {
    Unknown,
    Success,
    FailedDueToMissingDetails,
    FailedDueToUnknownOrigin,
    FailedDueToExternalUserOrigin,
    FailedDueToNotAllowedDomain,
    FailedDueToUserIsBlocked,
    FailedDueToSessionTermination,
}
