import { createReducer, on } from '@ngrx/store';
import {
  moveShape,
  deleteShapes,
  clearShapes,
  loadState,
  updateShape,
  moveShapes,
  renameTool,
  addSimpleShape,
  addDecisionShape,
  addConnectionToDecisionTool,
  changeConnectionLabel,
  addYesNoShape,
  deleteDependentConnections
} from './actions';
import { createUndoableReducer } from '../utils/reducer.utils';
import { IUndoableState, UndoableState } from '../domain/undoable-state';
import { ConnectionShape, DecisionShape, RectangularShape, Shape, shapeIsConnection, shapeIsRectangular, YesNoShape } from './shape';
import { CONNECTION } from '../constants';
import { HEIGHT, WIDTH } from '../tools/rectangular-tool';
import { ConnectorPosition } from '../tools/connector';

const updateShapeReducer = (state: Shape[], action: any) => {
    const shape = action.shape;
    if (!state.find(s => s.id === shape.id)) {
        return state;
    }
    return [...state.filter(s => s.id !== shape.id), shape];
};

const updateShapesReducer = (state: Shape[], action: any) => {
    const shapesThatExistInState = action.shapes.filter(s => state.some(stateShape => stateShape.id === s.id));

    return [...state.filter(shape => !shapesThatExistInState.some(s => s.id === shape.id)), ...shapesThatExistInState];
};

const addShapeToCanvasReducer = (state: Shape[], newShape: DecisionShape): Shape[] => {
        return [
            ...state,
            newShape,
            ...generateConnections(newShape)
        ];
};

function generateConnections(shape: DecisionShape): ConnectionShape[] {
  const currentConnectionShapes: ConnectionShape[] = [];
  const { x, y, connectionCommonProps } = getCoordinatesAndCommonProps(shape);
  shape.connectionMeta.forEach((connections, index) => {
    currentConnectionShapes.push({
      ...connectionCommonProps,
      id: connections.connectionId,
      label: connections.label,
      visual: {
        ...connectionCommonProps.visual,
        targetX: x + 120,
        targetY: y + ((index + 1) * 40),
      }
    });
  });
  return currentConnectionShapes;
}

function getCoordinatesAndCommonProps(shape: RectangularShape): { x: number, y: number, connectionCommonProps: any} {
    const x = shape.visual.x + WIDTH / 2;
    const y = shape.visual.y + HEIGHT;

    const connectionCommonProps = {
        class: CONNECTION,
        sourceId: shape.id,
        targetId: null,
        visual: {
            sourceX: x,
            sourceY: y,
            sourceReceptorId: ConnectorPosition.Bottom.toString(),
            targetReceptorId: null
        }
    };

    return { x, y, connectionCommonProps};
}

function getPredefinedConnection(shape: RectangularShape, id: string, label: string, offsetX: number, offsetY: number): Shape {
    const { x, y, connectionCommonProps } = getCoordinatesAndCommonProps(shape);

    return {
        ...connectionCommonProps,
        id,
        label,
        visual: {
            ...connectionCommonProps.visual,
            targetX: x + offsetX,
            targetY: y + offsetY,
        }
    };
}

const addYesNoShapeToCanvasReducer = (state: Shape[], shape: YesNoShape): Shape[] => {
    return [
        ...state,
        shape,
        getPredefinedConnection(shape, shape.connectionMeta.yes.connectionId, shape.connectionMeta.yes.label, 120, 40),
        getPredefinedConnection(shape, shape.connectionMeta.no.connectionId, shape.connectionMeta.no.label, 120, 80),
    ];
};

const init: Shape[] = [];
const shapeReducers = createReducer(
    init,
    on(addSimpleShape, (state, action) => [...state, action.shape]),
    on(addDecisionShape, (state, action) => addShapeToCanvasReducer(state, action.shape)),
    on(addYesNoShape, (state, action) => addYesNoShapeToCanvasReducer(state, action.shape as YesNoShape)),
    on(addConnectionToDecisionTool, (state, action) => {
        const decisionTool = state.find(t => t.id === action.decisionToolId) as DecisionShape;

        const connectionsStartingFromDecisionTool =
            state.filter(t => shapeIsConnection(t) && t.sourceId === action.decisionToolId) as ConnectionShape[];
        const notConnectedConnections = connectionsStartingFromDecisionTool.filter(c => c.targetId === null);

        const { x, y, connectionCommonProps } = getCoordinatesAndCommonProps(decisionTool);
        const maxY = notConnectedConnections.reduce((p, n) => n.visual.targetY > p ? n.visual.targetY : p, 0) || y;

        const newConnection = {
            ...connectionCommonProps,
            id: action.connectionId,
            label: action.label,
            visual: {
                ...connectionCommonProps.visual,
                targetX: x + 120,
                targetY: maxY + 40
            }
        };

        return [...state, newConnection];
    }),
    on(moveShape, updateShapeReducer),
    on(moveShapes, updateShapesReducer),
    on(deleteShapes, (state, action) => [...state.filter(s => !action.ids.includes(s.id))]),
    on(deleteDependentConnections, (state, action) => [...state.filter(s => !action.ids.includes(s.id))]),
    on(clearShapes, () => []),
    on(loadState, (_, action) => action.shapes),
    on(updateShape, updateShapeReducer),
    on(renameTool, (s, a) => {
        const tool = s.find(t => t.id === a.id);
        const newShape = {
            ...tool,
            name: a.name
        };

        return [...s.filter(t => t.id !== tool.id), newShape];
    }),
    on(changeConnectionLabel, (s, a) => {
        const connection = s.find(c => c.id === a.connectionId);

        const newConnection = {
            ...connection,
            label: a.newLabel
        };

        return [...s.filter(t => t.id !== connection.id), newConnection];
    }),
);

const initState = new UndoableState<Array<Shape>>([], [], []);

export const undoableShapeReducers = createUndoableReducer(initState, shapeReducers);
