<app-ey-app-spinner [showDraftsPopup]="showDraftsPopup"></app-ey-app-spinner>
<div  role="dialog"  aria-modal="true" class="modal-header modal__header d-flex flex-column w-100">
    <div class="d-flex p-20">
      <div class="flex-grow-1">
        <ng-content select="[header]"></ng-content>
      </div>
      <div class="p-l-2">
        <button type="button" class="close modal__close" tabindex="-1" aria-label="Close" (click)="close(modalResult.dismiss)">
        <i class="emp-icon icon-ico_close"></i>
        </button>
      </div>
    </div>
    <div class="modal-sub-header" *ngIf="showSubHeader">
      <hr />
    </div>
    <div class="modal-sub-header p-3" *ngIf="showSubHeader">
      <ng-content select="[sub-header]"></ng-content>
    </div>
  </div>
  <div>
    <div *ngIf="showBanner" class="modal-body modal__body imgBackground"  id="modalBanner" tabindex="-1">
      <ng-content select="[banner]"></ng-content>
    </div>
    <div [class.decrease-modal-body-padding-right]="decreaseModalBodyPaddingRight" [class.scroll-bar-custom]="showDarkScroll" [class.modal-body-autoheight]="setHeightAuto" [class.gray-body-bkg]="enableGrayBodyBackground" [class.remove-padding-bottom]="showInfoPanelInBody" class="modal-body modal__body"  id="modalBody">
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
  <div class="modal-footer modal__footer">
    <ng-content select="[footer]"></ng-content>
  </div>

