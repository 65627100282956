<nav class="navigation-bar-primary navigation-bar-primary--stack pl-0" id="usage-navigation-bar-primary">
    <button class="btn-close"><i class="material-icons">close</i></button>
    <ul class="pl-20">
        <li>
            <button aria-controls="primary1" aria-expanded="false" [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="navigation-bar-primary__nav-link"  data-target="#primary1" data-toggle="collapse" id="dropdownMenuLink3a" role="button">Dashboard</button>
        </li>
        <li *ngIf="!user.isExternal">
            <button aria-controls="primary2" aria-expanded="false" [routerLink]="['/projects']" routerLinkActive="active" class="navigation-bar-primary__nav-link" data-target="#primary2" data-toggle="collapse" id="dropdownMenuLink3b" role="button">Projects</button>
        </li>
        <li *ngIf="!user.isExternal">
            <button class="navigation-bar-primary__nav-link" [routerLink]="['/designer']" routerLinkActive="active">Designer</button>
        </li>
        <li *ngIf="!user.isExternal && roleHasReadAdminSetupPermission">
            <button aria-controls="primary3" aria-expanded="false" [routerLink]="['/admin']" routerLinkActive="active" class="navigation-bar-primary__nav-link" data-target="#primary3" data-toggle="collapse" id="dropdownMenuLink3d" role="button">Admin</button>
        </li>
        <div class="navigation-bar-utility__environment-container pr-3">
            <app-environment [isMobileBreakpoint]="isMobileBreakpoint"></app-environment>
        </div>
    </ul>
</nav>
