export enum InfoDialogTypes {
  info,
  delete,
  dismiss,
  yesNo,
  warning
}

// enables us to put icons on the modal component. Keep on adding new icons when required. Juts add enums and change icon css for ex --> icon-ico_download to --> icon-ico_warning and so on.
export enum ButtonIconTypes {
  none,
  download = 'icon-ico_download emp-icon mr-2 icon-position'
}
