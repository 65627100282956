export enum PropertyType {
    Text = 1,
    RichText,
    Name,
    HideOption,
    FormOptions,
    MailOptions,
    LetterOptions,
    UploadOptions,
    Scores,
    YnScores,
    ListAnswersLimit,
    TableToolOptions,
    EnableDecision,
    Conditions
}

export enum PropertyPlacement {
    Subheader = 1,
    PropertiesTab,
    Hint,
    Body
}
