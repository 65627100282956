import { Injectable } from '@angular/core';

export interface FileExtension {
  icon: string;
  extension: string[];
}

export enum ExtensionTypes {
    anyFile = 1,
    imageFile,
    empFile,
    docAutomationFile,
    videoFile,
    bulkUploadFile
}

export const FileTypes = {
  pdf: {icon: 'icon-ico_PDF', extension: ['PDF']} ,
  powerPoint : {icon: 'icon-ico_PPoint', extension: ['PPTX', 'PPT']},
  excel : {icon: 'icon-ico_Excel', extension: ['XLS', 'XLSX']},
  word : {icon: 'icon-ico_Word', extension: ['DOC', 'DOCX']},
  txt : {icon: 'icon-ico_file', extension: ['TXT', 'ODT', 'ODP', 'ODS', 'CSV', 'PPS',
                                            'PPSX', 'XML', 'EPUB', 'JSON', 'CSS', 'PAGES',
                                            'HTML', 'NUMBERS', 'KEY', 'HTM', 'XHTMLT',
                                            'RTF', 'MDB', 'MSG', 'EML', 'EMLX', 'GSHEET']},
  emp : {icon: 'icon-ico_file', extension: ['EMP', 'EMPX']},
  img : {icon: 'icon-ico_file', extension: ['JPG', 'JPEG', 'PNG', 'TIFF', 'BMP', 'PSD', 'SVG', 'TIF', 'RAW']},
  video : {icon: 'icon-ico_file', extension: ['MP4', 'WEBM']},
  archive : {icon: 'icon-ico_file', extension: ['ZIP', '7ZIP', 'RAR', 'ZIPX', '7Z']},
  alteryx : {icon: 'icon-ico_file', extension: ['YXMD', 'YXWZ']},
  excel_addons : {icon: 'icon-ico_file', extension: ['XLSM', 'XLSB']}
};

export const FileTypesImage = {
  img : {icon: 'icon-ico_file', extension: ['JPG', 'JPEG', 'PNG']}
};

@Injectable({
  providedIn: 'root'
})
export class EyFileUploadService {
  videoFileExt: FileExtension[] = [FileTypes.video];
  anyfileExt: FileExtension[] = [FileTypes.pdf, FileTypes.excel, FileTypes.word, FileTypes.txt,
                              FileTypes.powerPoint, FileTypes.emp, FileTypes.video, FileTypes.archive,
                              FileTypes.alteryx, FileTypes.img];
  imagefileExt: FileExtension[] = [FileTypes.img];
  empFileExt: FileExtension[] = [FileTypes.emp];
  docAutomationFileExt: FileExtension[] = [FileTypes.word, FileTypes.excel, FileTypes.powerPoint, FileTypes.excel_addons];
  bulkUploadFileExt: FileExtension[] = [FileTypes.excel];


  getFileExtension(file: string, type: ExtensionTypes): FileExtension {
    const fileExtArray = file.split('.');
    const fileExt =  fileExtArray[fileExtArray.length - 1].toUpperCase();

    switch (type) {
      case ExtensionTypes.anyFile: {
        return this.anyfileExt.find(fe => fe.extension.find(ext => ext === fileExt) !== undefined);
      }
      case ExtensionTypes.imageFile: {
        return this.imagefileExt.find(fe => fe.extension.find(ext => ext === fileExt) !== undefined);
      }
      case ExtensionTypes.empFile: {
        return this.empFileExt.find(fe => fe.extension.find(ext => ext === fileExt) !== undefined);
      }
      case ExtensionTypes.docAutomationFile: {
        return this.docAutomationFileExt.find(fe => fe.extension.find(ext => ext === fileExt) !== undefined);
      }
      case ExtensionTypes.videoFile: {
        return this.videoFileExt.find(fe => fe.extension.find(ext => ext === fileExt) !== undefined);
      }
      case ExtensionTypes.bulkUploadFile: {
        return this.bulkUploadFileExt.find(fe => fe.extension.find(ext => ext === fileExt) !== undefined);
      }
      default: {
        return this.anyfileExt.find(fe => fe.extension.find(ext => ext === fileExt) !== undefined);
      }
    }
  }
}
