import {LabelComponent} from './label/label.component';
import {ListComponent} from './list/list.component';
import {MapFieldComponent} from './map-field/map-field.component';
import {TextInputComponent} from './text-input/text-input.component';
import {NumberInputComponent} from './number-input/number-input.component';
import {DropdownComponent} from './dropdown/dropdown.component';
import {DateComponent} from './date/date.component';
import {FormulaComponent} from './formula/formula.component';
import {NumberComponent} from './number/number.component';

export enum TableBuilderControlTypes {
  label,
list,
mapField,
textInput,
numberInput,
dropdown,
date,
formula,
  number
}

export const TABLE_BUILDER_CONTROLS_EDIT = new Map<TableBuilderControlTypes, any>([
  [TableBuilderControlTypes.label, LabelComponent],
  [TableBuilderControlTypes.list, ListComponent],
  [TableBuilderControlTypes.mapField, MapFieldComponent],
  [TableBuilderControlTypes.textInput, TextInputComponent],
  [TableBuilderControlTypes.numberInput, NumberInputComponent],
  [TableBuilderControlTypes.dropdown, DropdownComponent],
  [TableBuilderControlTypes.date, DateComponent],
  [TableBuilderControlTypes.formula, FormulaComponent],
  [TableBuilderControlTypes.number, NumberComponent],
]);


export const TABLE_BUILDER_EDITOR_COMPONENTS = [
  LabelComponent,
  ListComponent,
  MapFieldComponent,
  TextInputComponent,
  NumberInputComponent,
  DropdownComponent,
  DateComponent,
  FormulaComponent,
  NumberComponent
];
