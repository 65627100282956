<app-fluid-footer-template class="d-flex flex-fill width" [hintPresent]="page?.moduleFlowPage?.hint" [selectedViewType]="selectedViewType">
  <ng-container appPageContent>
    <div *ngIf="isHidden" class="d-flex align-items-center automation-warning p-10 mt-10 mb-10">
      <app-hide-from-end-users [text]="'This page will be hidden from end-users.'"></app-hide-from-end-users>
    </div>
    <span class="mb-20 d-block" *ngIf="isRequiresCtr > 0"> <span class="required eyfontlight mr-1">*</span><span class="required-text">Required fields</span> </span>
    <ng-container *ngFor="let item of this.formParts; let i = index">
      <div hieroglyphicInputSupport class="d-flex flex-fill p-0 m-0 mb-20" *ngIf="getPropertyVisibility(item)">
        <form [formGroup]="form" class="d-flex flex-fill">
        <span [ngSwitch]="item.type" class="d-flex flex-fill">
          <span *ngSwitchCase="controlType.description" class="d-block w-100">
            <ey-froala-content-wrapper [editorContent]="item.content"></ey-froala-content-wrapper>
          </span>
          <span *ngSwitchCase="controlType.textInput" class="d-flex flex-fill flex-column">
            <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
            <ey-input
              [maxlength]="item.length"
              class="input-height"
              [formControl]="form.controls[i]"
              [meta]="item.meta"
              extra="none"
              (blur)="onInputBlur()"
              (ngModelChange)="onInputValueChange()">
            </ey-input>
          </span>
          <span *ngSwitchCase="controlType.numberInput" class="d-flex flex-fill flex-column">
            <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
            <ey-input
              [formatOnBlur]="item"
              #formatOnBlur="formatOnBlur"
              type="text"
              class="input-height"
              [maxlength]="15"
              [formControl]="form.controls[i]"
              [meta]="item.meta"
              extra="none"
              (blur)="onInputBlur()"
              (ngModelChange)="onInputValueChange()">
            </ey-input>
          </span>
          <span *ngSwitchCase="controlType.multilineInput" class="d-flex flex-fill flex-column">
            <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
            <ey-textarea
              [toolTipHolderOption]="toolTipHolderOptionHidden"
              [maxlength]="item.length"
              class="input-height"
              [formControl]="form.controls[i]"
              [meta]="item.meta"
              extra="none"
              (blur)="onInputBlur()"
              (ngModelChange)="onInputValueChange()">
            </ey-textarea>
          </span>
          <span *ngSwitchCase="controlType.date" class="d-flex flex-fill flex-column">
            <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
            <ey-date-picker
              placeholder="dd/mm/yyyy"
              class="input-height"
              [showToolTipHolder]="false"
              [formControl]="form.controls[i]"
              [meta]="item.meta"
              extra="none"
              (ngModelChange)="onInputChange()">
            </ey-date-picker>
          </span>
          <span *ngSwitchCase="controlType.dropDown" class="d-flex flex-fill flex-column">
            <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
            <ey-drop-down
              [positioningDropdownsWithRespectToBrowserWindow]="true"
              [values]="item.options"
              [labelName]="'text'"
              [noInfo]="true"
              class="input-height"
              [formControl]="form.controls[i]"
              [meta]="item.meta"
              (ngModelChange)="onInputChange()">
            </ey-drop-down>
          </span>
          <span *ngSwitchCase="controlType.radioButton" class="d-flex flex-fill flex-column">
            <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
            <ey-radio-group-plain
              class="input-height"
              [values]="item.options"
              [formControl]="form.controls[i]"
              [meta]="item.meta"
              extra="none"
              (ngModelChange)="onInputChange()">
            </ey-radio-group-plain>
          </span>
          <span *ngSwitchCase="controlType.checkbox" class="d-flex flex-fill flex-column">
            <label class="checkbox checkbox-margin mb-0">
              <input
                class="checkbox__input"
                [class]="{'error': form.controls[i].touched && form.controls[i].hasError('required')}"
                type="checkbox"
                [formControl]="form.controls[i]"
                (ngModelChange)="onInputChange()"
              />
              <span class="checkbox__checkmark checkbox-background"></span>
              <p class="checkbox-question mb-0 mr-1" [innerText]="item.title"></p>
              <span *ngIf="item.isRequired" class="required eyfontlight">*</span>
              <p [innerHtml]="item.hint" class="eyfontlight checkbox-hint mb-20"></p>
              <label *ngIf="form.controls[i].touched && form.controls[i].hasError('required')" class="textinput_checkbox__errmsg mt-10">
                <i class="err_icon icon-ico_error_toast"></i>
                <span class="">{{item.meta.errorMsg}}</span>
              </label>
            </label>
          </span>
          <span *ngSwitchCase="controlType.checkboxGroup" class="d-flex flex-fill flex-column">
            <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
             <ey-checkbox-group-plain
                class="input-height"
                [values]="item.options"
                [formControl]="form.controls[i]"
                [meta]="item.meta"
                extra="none"
                [restrictedCombinations]="item.restrictedGroups"
                (ngModelChange)="onInputChange()">
              </ey-checkbox-group-plain>
          </span>
          <span *ngSwitchCase="controlType.eyEmailLookup" class="d-flex flex-fill flex-column">
            <ng-container *ngTemplateOutlet="fieldDes; context: { item: item }"></ng-container>
            <ey-multiselect-search
              [placeholder]="emailLookUpPlaceHolder"
              [limit]="100" class="d-block mb-40"
              [showSpinner]="item?.showSpinnerEP"
              [meta]="item.meta"
              [values]="item?.emailDictionary"
              (searchTextChanged)="onEmailSearch($event, item)"
              labelName2="email"
              [formControl]="form.controls[i]"
              (ngModelChange)="onInputChange()">
            </ey-multiselect-search>
          </span>
          <span *ngSwitchCase="controlType.divider" class="d-flex flex-fill flex-column">
            <hr class="divider-color" [ngStyle]="{ height: getHrStyle(item.style) }" />
          </span>
          <span *ngSwitchDefault class="p-10">{{ item.type }} -Work In Progress !</span>
        </span>
      </form>
      </div>
    </ng-container>
  </ng-container>
  <ng-container appPageFooter>
    <div>
      <div class="d-flex">
        <ey-button class="ml-auto mr-20" [btnClass]="btnClass.btnPrev" text="Prev" (click)="onPrev()"></ey-button>
        <ey-button [btnClass]="btnClass.btnNext" text="Next" (click)="onNext()"></ey-button>
      </div>
      <div *ngIf="lastTechReviewDate && !isMobileBreakpoint">
        <span class="reviewDate spacing">Last Technical Review: {{lastTechReviewDate}}</span>
      </div>
    </div>
  </ng-container>
</app-fluid-footer-template>

<ng-template #fieldDes let-item="item">
  <p class="question mb-0 mr-1">{{item.title}} <span *ngIf="item.isRequired" class="required eyfontlight ">*</span></p>
  <p class="eyfontlight hint mb-20 ">{{item.hint}}</p>
</ng-template>
