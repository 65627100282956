import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FlowType, ModuleFlowService } from 'src/app/designer/preview/module-flow.service';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { ButtonClass } from 'src/app/shared/components/ey-button/ey-button.component';
import { PropertyInput } from '../../property-input.model';
import { SaveNotificationServiceEndUserForm } from '../../save-notification-service-end-user-form.service';
import { BasePageContent } from '../base-page-content';
import {ScrollService} from 'src/app/designer/designer-services/scroll.service';

interface Option {
  description: string;
  targetId: string;
  optionId: string;
}

@Component({
  selector: 'app-decision-page',
  templateUrl: './decision-page.component.html',
  styleUrls: ['./decision-page.component.scss']
})
export class DecisionPageComponent extends BasePageContent implements OnInit, AfterViewInit {
  btnClass = ButtonClass;
  @HostBinding('class.d-flex') flex = true;
  @HostBinding('class.flex-fill') flexFill = true;

  selectedOption: Option = null;
  hasError = false;

  constructor(flowService: ModuleFlowService,
              private saveNotification: SaveNotificationServiceEndUserForm,
              protected spinnerService: EyAppSpinnerService,
              private scrollService: ScrollService) {
    super(flowService, spinnerService);
  }

  ngOnInit(): void {
    this.flowService.responseHeaderMobileViewChange.pipe(takeUntil(this.destroy$)).subscribe(mobileViewChange => {
      this.isMobileBreakpoint = mobileViewChange;
    });

    // TODO SO: refactor this quick fix to a normal operation
    if (this.flowType === FlowType.response && this.saveNotification.saveCurrentProperties$.observers.length === 0) {
      this.saveNotification.saveCurrentProperties$
        .pipe(
          switchMap(() => this.flowService.saveCurrent(this.page.moduleFlowPage.id, this.property)),
          takeUntil(this.destroy$)
        ).subscribe(response => {
          this.flowService.transformCurrentPageData(response);
          this.page = response;
          this.initPage();
        });
    }

    this.initPage();
  }

  initPage(): void {
    const selectedId = this.page.moduleFlowPage.properties[0].value;
    this.selectedOption = selectedId
      ? this.options.find(o => o.optionId === selectedId) || null
      : null;
  }

  ngAfterViewInit(): void {
    if (this.scrollService.shouldScroll()) {
      window.scroll(0, 0);
      this.scrollService.setHasScrolled(true);
    }
  }

  onValueChange(): void {
    this.saveNotification.dispatchNotSaved();
    if (this.flowType === FlowType.response && this.isAutoSave) {
      this.saveNotification.saveCurrentProperties$.next();
    }
  }

  get options(): Option[] {
    return this.page?.moduleFlowPage.properties[0].options || [];
  }

  get propertyName(): string {
    return this.page?.moduleFlowPage.properties[0].name;
  }

  get isAutomated(): boolean {
    return this.page?.moduleFlowPage.properties[0].isAutomated;
  }

  get property(): Array<PropertyInput> {
    return this.selectedOption
      ? [{ name: this.propertyName, value: this.selectedOption.optionId }]
      : [];
  }

  isSelected(option: Option): boolean {
    return option === this.selectedOption;
  }

  onOptionClick(option: Option): void {
    this.selectedOption = option;
    this.hasError = false;

    this.onValueChange();
  }

  onPrev(): void {
    const loadingResult$ = this.spinnerService.withLoadingIndicator(this.flowService.doPrev(this.property, this.flowType), this.destroy$);
    loadingResult$.subscribe();
  }

  onNext(): void {
    if (!this.selectedOption) {
      this.hasError = true;
      return;
    }

    const loadingResult$ = this.spinnerService.withLoadingIndicator(this.flowService.doNext(this.property, this.flowType), this.destroy$);
    loadingResult$.subscribe();
  }
}
