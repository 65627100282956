import {Component, Input} from '@angular/core';
import { EyAppSpinnerService } from './ey-app-spinner.service';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-ey-app-spinner',
  templateUrl: './ey-app-spinner.component.html',
  styleUrls: ['./ey-app-spinner.component.scss']
})
export class EyAppSpinnerComponent {
  showSpinner$: Observable<boolean>;
  @Input() showDraftsPopup = false;
  constructor(private spinnerService: EyAppSpinnerService) {
      this.showSpinner$ = this.spinnerService.showSpinner$.pipe(delay(1));
  }
}
