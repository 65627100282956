<div class="d-flex">
  <div class="textinput-group flex-grow-1">
    <div class="d-flex flex-row w-100">
      <div class="textarea-group w-100" [ngClass]="{error: showError}">
        <label class="textarea-group__label"  [ngClass]="{asterisk: meta?.required}"  [for]="'textarea-group-default' + ctrl_id">{{meta.title}}</label>
        <textarea [ngClass]="{error: showError}" [attr.aria-invalid]="showError" [attr.aria-label]="showError ? meta?.errorMsg: meta?.title + (meta?.info ? meta?.info : '' )" [attr.aria-describedby]="'textarea-group-default_err' + ctrl_id"  (blur)="onBlur()" [maxLength]="maxLength" [(ngModel)]="selectedValue"  class="textarea-group__textarea focusable pt-0"  [id]="'textarea-group-default' + ctrl_id"></textarea>
        <label class="textarea-group__errmsg" [id]="'textarea-group-default_err' + ctrl_id" [class]="{mini: mini}" *ngIf="showError">{{meta.errorMsg}}</label>
      </div>
    </div>
  </div>
  <div class="tooltip--holder" *ngIf="toolTipHolderOption !== toolTipHolderOptions.hidden">
    <ey-input-tooltip [toolTipContent]="meta.info" ></ey-input-tooltip>
  </div>
</div>
