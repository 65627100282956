import {addMinutes, format} from 'date-fns';

export function getCurrentBrowserDateFormat(): string {
  return getDateFormatPattern('yyyy', 'mm', 'dd');
}


export function getDateFormatPattern(year: string, month: string, day: string): string {
  const localizableDate = new Date(2013, 11, 31);
  let str = localizableDate.toLocaleDateString();
  str = str.replace('31', day);
  str = str.replace('12', month);
  str = str.replace('2013', year);

  return str;
}

export function getUTC_TS(d: Date): string {
  return format(addMinutes(d, d.getTimezoneOffset()), 'ddMMyyyy_HHmmss');
}
