export enum ModalResult {
  dismiss,
  submit
}

export const ModalVerticalSizeWindowClass = {
  contentHeavy:  'modal-heavy',
  contentHeavyDoubleHeader: 'modal-heavy-double-header',
  auto: 'modal-info',
  tall: 'modal-tall',
  default: ''
}

export enum ModalWidth {
  default = '',
  Small = 'sm',
  Large = 'lg',
  MonstrouslyBig = 'xl',
  fullscreen = 'fullscreen'
}
