import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ScrollService {
  private hasScrolled = false;

  constructor() { }

  setHasScrolled(value: boolean): void {
    this.hasScrolled = value;
  }

  shouldScroll(): boolean {
    return !this.hasScrolled;
  }
}
