import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EyDropDownComponent } from './ey-drop-down/ey-drop-down.component';
import { EyInputComponent } from './ey-input/ey-input.component';
import { EyMultiSelectComponent } from './ey-multi-select/ey-multi-select.component';
import { EyTextareaComponent } from './ey-textarea/ey-textarea.component';
import { EyToggleComponent } from './ey-toggle/ey-toggle.component';
import { EyInputTooltipComponent } from './ey-input-tooltip/ey-input-tooltip.component';
import { EyFormProgressBarComponent } from './ey-form-progress-bar/ey-form-progress-bar.component';
import { EyBaseFormControlComponent } from './ey-base-form-control/ey-base-form-control';
import { EyMultiselectSearchComponent } from './ey-multiselect-search/ey-multiselect-search.component';
import { ProgressBarService } from './ey-form-progress-bar/progress-bar.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EyGridComponent } from './ey-grid/ey-grid.component';
import { EyFiltersComponent } from './ey-grid/ey-filters/ey-filters.component';
import { EyColumnSelectionComponent } from './ey-grid/ey-column-selection/ey-column-selection.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EyTabContentDirective, EyTabDirective, EyTabTitleDirective } from './ey-tabset/ey-tab.directive';
import { EyTabsetConfig } from './ey-tabset/ey-tabset.config';
import { EyTabset } from './ey-tabset/ey-tabset.component';
import { EyRowExpandComponent } from './ey-grid/ey-row-expand/ey-row-expand.component';
import { EyGridMenuComponent } from './ey-grid/ey-grid-menu/ey-grid-menu.component';
import { EyGridSortComponent } from './ey-grid/ey-grid-sort/ey-grid-sort.component';
import { EyPaginationComponent } from './ey-grid/ey-pagination/ey-pagination.component';
import { EyModalTemplateComponent } from './ey-modal-template/ey-modal-template.component';
import { EyTemplateDirective } from './helpers/ey-template.directive';
import { EyPillComponent } from './ey-pill/ey-pill.component';
import { EyTextExpanderComponent } from './ey-text-expander/ey-text-expander.component';
import { EyFormToggleComponent } from './ey-form-toggle/ey-form-toggle.component';
import { EyToggleTemplateComponent } from './ey-toggle-template/ey-toggle-template.component';
import { EyBreadcrumbComponent } from './ey-breadcrumb/ey-breadcrumb.component';
import { RouterModule } from '@angular/router';
import { EyFormBaseComponent } from './ey-form-base/ey-base-form-control';
import { EyInfoDialogComponent } from './ey-info-dialog/ey-info-dialog.component';
import { EyInfoDialogService } from './ey-info-dialog/ey-info-dialog.service';
import { EyCopyToClipboardComponent } from './ey-copy-to-clipboard/ey-copy-to-clipboard.component';
import { EyToastComponent } from './ey-toast/ey-toast.component';
import { EyRadioGroupComponent } from './ey-radio-group/ey-radio-group.component';
import { EyAppSpinnerComponent } from './ey-app-spinner/ey-app-spinner.component';
import { EyFileUploadComponent } from './ey-file-upload/ey-file-upload.component';
import { EyInputPlaceholderComponent } from './ey-input-placeholder/ey-input-placeholder.component';
import { ToolMenuItemComponent } from '../../designer/workflow-designer/tool-menu-item/tool-menu-item.component';
import { EyButtonComponent } from './ey-button/ey-button.component';
import { EyDatePickerComponent } from './ey-date-picker/ey-date-picker.component';
import { FormBuilderNameComponent } from '../../designer/builders/form-builder/controls/shared/name/form-builder-name.component';
import { EyRangeSelectorComponent } from './ey-range-selector/ey-range-selector.component';
import { EyTagEditorComponent } from './ey-tag-editor/ey-tag-editor.component';
import { EyDataListComponent } from './ey-data-list/ey-data-list.component';
import { EyRadioGroupPlainComponent } from './ey-radio-group-plain/ey-radio-group-plain.component';
import { EyDropDownSmallComponent } from './ey-drop-down-small/ey-drop-down-small.component';
import { EyFroalaContentWrapperComponent } from './ey-froala-content-wrapper/ey-froala-content-wrapper.component';
import { EyCheckboxGroupPlainComponent } from './ey-checkbox-group-plain/ey-checkbox-group-plain.component';
import { EyTagEditorDropDownComponent } from './ey-tag-editor-drop-down/ey-tag-editor-drop-down.component';
import { EyConditionComponent } from './ey-condition/ey-condition.component';
import { EyConditionGroupComponent } from './ey-condition-group/ey-condition-group.component';
import { DashboardService } from '../../dashboard/dashboard.service';
import { EyFilterPillsComponent } from './ey-filter-pills/ey-filter-pills.component';
import { SummaryRowComponent } from './ey-mail-preview/summary-row/summary-row.component';
import { MailExpanderComponent } from './ey-mail-preview/mail-expander/mail-expander.component';
import { AttachmentsWrapperComponent } from './ey-mail-preview/attachments-wrapper/attachments-wrapper.component';
import { EyMailSummaryComponent } from './ey-mail-preview/mail-summary/mail-summary.component';
import { EyTextExpanderComponentAuto } from './ey-text-expander-auto/ey-text-expander-auto.component';
import { GetHtmlContentForFroalaContentWrapperPipe } from './pipes/get-html-content-for-froala-content-wrapper.pipe';
import { EyDropdownLightWeightComponent } from './ey-dropdown-light-weight/ey-dropdown-light-weight.component';
import { MouseEventsDirective } from '../directives/mouse-events.directive';
import { DynamicControlDirective } from '../directives/dynamic-control.directive';
import { HieroglyphicInputSupportDirective } from '../directives/hieroglyphic-input-support.directive';

export { EyTabsetConfig } from './ey-tabset/ey-tabset.config';

export const CUSTOM_COMPONENTS = [
  EyDropDownComponent,
  EyInputComponent,
  EyMultiSelectComponent,
  EyTextareaComponent,
  EyToggleComponent,
  EyInputTooltipComponent,
  EyFormProgressBarComponent,
  EyBaseFormControlComponent,
  EyMultiselectSearchComponent,
  EyGridComponent,
  EyFiltersComponent,
  EyColumnSelectionComponent,
  EyTabset,
  EyTabDirective,
  EyTabTitleDirective,
  EyTabContentDirective,
  EyRowExpandComponent,
  EyGridMenuComponent,
  EyGridSortComponent,
  EyPaginationComponent,
  EyModalTemplateComponent,
  EyTemplateDirective,
  EyPillComponent,
  EyTextExpanderComponent,
  EyFormToggleComponent,
  EyToggleTemplateComponent,
  EyBreadcrumbComponent,
  EyFormBaseComponent,
  EyInfoDialogComponent,
  EyCopyToClipboardComponent,
  EyToastComponent,
  EyRadioGroupComponent,
  EyAppSpinnerComponent,
  EyFileUploadComponent,
  EyInputPlaceholderComponent,
  ToolMenuItemComponent,
  DynamicControlDirective,
  MouseEventsDirective,
  HieroglyphicInputSupportDirective,
  EyButtonComponent,
  EyDatePickerComponent,
  FormBuilderNameComponent,
  EyRangeSelectorComponent,
  EyTagEditorComponent,
  EyDataListComponent,
  EyRadioGroupPlainComponent,
  EyDropDownSmallComponent,
  EyFroalaContentWrapperComponent,
  EyCheckboxGroupPlainComponent,
  EyTagEditorDropDownComponent,
  EyConditionComponent,
  EyConditionGroupComponent,
  EyFilterPillsComponent,
  SummaryRowComponent,
  MailExpanderComponent,
  AttachmentsWrapperComponent,
  EyMailSummaryComponent,
  EyTextExpanderComponentAuto,
  GetHtmlContentForFroalaContentWrapperPipe,
  EyDropdownLightWeightComponent
];

export const CUSTOM_COMPONENTS_SRV = [
  ProgressBarService, EyTabsetConfig, EyInfoDialogService
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        RouterModule
    ],
  declarations: [ ...CUSTOM_COMPONENTS],
    exports: [...CUSTOM_COMPONENTS],
  providers: [
    DashboardService,
    ...CUSTOM_COMPONENTS_SRV
  ]
})
export class ComponentsModule { }
