<ey-modal-template [showSubHeader]="false">
  <div header>
      <span class="d-flex">
        <span class="emp-icon icon-ico_waring_toast"></span>
        <h2 class="modal__title ml-2">Submitting Collaboration Response</h2>
      </span>
  </div>
  <div body>
    <p class="m-0">After you submit this response other collaborators won't be able to make changes. Make sure the response is complete before submitting it.</p>
  </div>
  <div footer class="d-flex w-100">
      <button class="btn mr-20 btn--primary btn-border-color" type="button" (click)="onSubmit()">Submit</button>
      <button class="btn btn--secondary ml-0" type="button" (click)="onCancel()">Cancel</button>
  </div>
</ey-modal-template>
