import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

export function nameIsUniqueValidatorFactory(names$: Observable<string[]>): AsyncValidatorFn {
    return control => {
        return names$.pipe(
            take(1),
            map(names => {
                return control.dirty && names.includes(control.value) // if control is initialized with some name, it is already in names collection
                    ? { nameNotUnique: true }
                    : null;
            })
        );
    };
}
