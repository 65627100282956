import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'ey-text-expander',
  templateUrl: './ey-text-expander.component.html',
  styleUrls: ['./ey-text-expander.component.scss']
})
export class EyTextExpanderComponent implements OnInit {
  @Input() charLimit = 30;
  @Input() text: string;
  @Input() splitText = true;
  @Input() class: string;
  private fullTextOnHover: string;

  constructor() { }

  ngOnInit(): void {
    this.fullTextOnHover = this.text;
  }
}
