<ey-modal-template>
  <div header>
    <h2 class="modal__title">Session Timeout</h2>
  </div>
  <div body>
    <div>
      <h6 class="fonts-col__heading-6--bold">Your Session Expires in {{secondsLeft}} sec</h6>
      <p>You have been inactive for a while. For your security, we will automatically log you out.</p>
    </div>
  </div>
  <div footer class="d-flex w-100">
    <button class="btn mr-20 btn--primary btn-border-color d-flex justify-content-center" type="button" (click)="getMoreTime()">Get More Time</button>
    <button class="btn btn--secondary ml-0" type="button" (click)="logout()">Log Out</button>
  </div>
</ey-modal-template>

