import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
export const FILE_UPLOAD_ENDPOINT = 'file';

export interface FileUploadResult {
  fileId: string;
  tempId: string;
  fileName?: string;
  visible?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

  createFormData(file: File, fileId: string): FormData {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('tempId', fileId);
    return formData;
  }

  postFormData(formData: FormData): Observable<FileUploadResult> {
    return this.http.post<FileUploadResult>(`${environment.baseUrl}/${FILE_UPLOAD_ENDPOINT}`, formData);
  }

  removeFile(id: string): Observable<any> {
    return this.http.delete(`${environment.baseUrl}/${FILE_UPLOAD_ENDPOINT}/${id}`);
  }
}
