import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterOption } from '../ey-filters/ey-filters.component';

export const PAGE_SIZE_FILTER_OPTIONS = [
  { text: 'Show 10', value: '10' },
  { text: 'Show 20', value: '20' },
  { text: 'Show 50', value: '50' }
];

@Component({
  selector: 'ey-pagination',
  templateUrl: './ey-pagination.component.html',
  styleUrls: ['./ey-pagination.component.scss'],
})
export class EyPaginationComponent {
  @Input() lightPaginationPanel = false;
  @Input() currentPage: number;
  @Input() totalPages: number;
  @Output() paginationEvent = new EventEmitter<number>();
  @Output() paginationPageSizeEvent = new EventEmitter<string>();

  get rightDisabled(): boolean {
    return +this.currentPage === +this.totalPages;
  }
  get leftDisabled(): boolean {
    return +this.currentPage === +1;
  }

  public pageSizes: FilterOption[] = PAGE_SIZE_FILTER_OPTIONS;
  currentPageSize = {...this.pageSizes[0]};

  start(): void {
    this.currentPage = 1;
    this.paginationEvent.emit(this.currentPage);
  }

  previous(): void {
    if (this.currentPage > 1) {
      --this.currentPage;
      this.paginationEvent.emit(this.currentPage);
    }
  }

  next(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.paginationEvent.emit(this.currentPage);
    }
  }

  end(): void {
    this.currentPage = this.totalPages;
    this.paginationEvent.emit(this.currentPage);
  }

  onFilterItemClick(value: any): void {
    this.currentPageSize.value = value.value;
    this.paginationPageSizeEvent.emit(this.currentPageSize.value);
  }


  setPageNumber(pageNumber: string): void {
    if (+pageNumber > 0 ) {
      if (+pageNumber > this.totalPages + 1) {
        this.paginationEvent.emit(+this.totalPages);
        this.currentPage = +this.totalPages;
      } else {
        this.paginationEvent.emit(+pageNumber);
      }
    }
  }

  /*
  setPageNumber(event: KeyboardEvent): void {
    if (!this.isValidEntry(event.key)) {
      event.preventDefault();
    }

    if (this.isEnterPressed(event.key)) {
      this.handleEnterKeyPressed();
    }
  }

  isValidEntry(key: string): boolean {
    return this.isEnterPressed(key) || this.isPositiveNumberGreaterThanZero(key) || this.isBackSpacePressed(key);
  }

  handleEnterKeyPressed(): void {
    this.currentPage = !this.currentPage ? 1 : this.currentPage;

    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }

    this.paginationEvent.emit(this.currentPage);
  }

  isEnterPressed(key: string): boolean {
    return key === 'Enter';
  }

  isBackSpacePressed(key: string): boolean {
    return key === 'Backspace';
  }

   */

  isPositiveNumberGreaterThanZero(key: string): boolean {
    const keyNumber: number = +key;

    return (keyNumber > 0 && keyNumber <= 9) || (keyNumber === 0 && this.currentPage === 0);
  }
}
