import {Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, ViewChild} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

function getTagHtmlfn(tag: TagEditorElement): string {

  let pillColour = 'pill-light-gray';
  switch (+tag.tagDescriber) {
    case TagDescriber.externalMail:
      pillColour = 'pill--black';
      break;
    case TagDescriber.unknownText:
      pillColour = 'pill--failed';
      break;
  }

  return TAG_HTML.replace(PART_ID_TOKEN, tag.id).replace(TAG_NAME_TOKEN, tag.content).replace(PILL_COLOR, pillColour).replace(TAG_DESCRIBER, tag?.tagDescriber?.toString());
}

export enum TagDescriber {
  internalMail,
  externalMail,
  unknownText,
}

export enum TagEditorPartType {
  tag,
  text,
}

export interface TagEditorElement {
  type: TagEditorPartType;
  content: any;
  id: string;
  tagDescriber?: TagDescriber;
}

export const EMPTY_TEXT_PART = {
  content: '',
  type: TagEditorPartType.text,
  id: '',
};

export const EMPTY_TAG_PART = {
  content: { name: 'test' },
  type: TagEditorPartType.tag,
  id: '',
};
export const PART_ID_TOKEN = '#PartId';
export const TAG_NAME_TOKEN = '#tagName';
export const PILL_COLOR = '#PillColor';
export const TAG_DESCRIBER = '#TAG_DESCRIBER';
const CHECK_SPAN = 'span>';


export const TAG_HTML = `<pill class="pill m-0 mr-10 pill-pill-non-std ${PILL_COLOR} pill-content-from-data-text-attr" style="display: inline-block;" data-tag-describer="${TAG_DESCRIBER}" data-partid="${PART_ID_TOKEN}"  data-text="${TAG_NAME_TOKEN}" contenteditable="false"></pill>`;

export const REMOVE_ONLY_ALLOWED_KEYS = [
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
  'Enter'
];

@Component({
  selector: 'app-ey-tag-editor-drop-down',
  templateUrl: './ey-tag-editor-drop-down.component.html',
  styleUrls: ['./ey-tag-editor-drop-down.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyTagEditorDropDownComponent),
      multi: true,
    },
  ],
})
export class EyTagEditorDropDownComponent extends EyBaseFormControlComponent implements ControlValueAccessor {
  @Output() blur = new EventEmitter<void>();
  @Output() addTagClick = new EventEmitter<void>();
  @Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() valueAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() enterPressed: EventEmitter<any> = new EventEmitter<any>();
  @Output() limitReached: EventEmitter<any> = new EventEmitter<any>();
  @Input() showAttachmentInfoMessage = false;
  @Input() Tags: any[];
  @Input() readonly = false;
  @Input() updateOnBlur = false;
  @Input() placeholder = '';
  @Input() showTagInsert = true;
  @Input() values: any[] = []; // mk to do rename to dictionary;
  @Input() labelName = 'name';
  @Input() labelName2 = '';
  @Input() toolTip = '';
  @Input() charLimit: number = null;
  @Input() removeOnly: boolean;
  @Input() tagLimit: number = null;
  @ViewChild('spanInput') spanInput;
  @ViewChild('spanTemplate') spanTemplate;
  @HostListener('document:click', ['$event'])
  clickOut(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.values = [];
    }
  }
  htmlContent: SafeHtml;
  writeValContent: any = '';
  _selectedValue: TagEditorElement[] = [];
  caretPos = 0;
  lastTouchedElementIndex = 0;
  getTagHtml = getTagHtmlfn;

  isFormula(): boolean {
    try {
      return this.spanInput.nativeElement.innerHTML[0] === '=';
    } catch (ex)
    {
      return false;
    }
  }

  getTextPart(cnt: string): TagEditorElement {
    return { ...EMPTY_TEXT_PART, content: cnt, id: Date.now().toString() };
  }
  getTagPart(cnt: any): TagEditorElement {
    return { ...EMPTY_TAG_PART, content: cnt, id: cnt.id };
  }

  constructor(private controlContainer: ControlContainer, private sanitizer: DomSanitizer, private eRef: ElementRef) {
    super(controlContainer);
  }

  emitUpdate(): void {
    this.getArrayFromContent();
    this.isFocus = false;
    this.onTouched(this.selectedValue);
  }

  onFormulaChange(): void {
    if (!this.updateOnBlur) {
      this.emitUpdate();
    }
  }

   onKeyPress(kpe: KeyboardEvent): void {
    if (this.removeOnly) {
      if (kpe && REMOVE_ONLY_ALLOWED_KEYS.findIndex(k => k === kpe.code) !== -1) {
        this.emitUpdate();
        this.stChanged(this.getTexts());
      } else {
        kpe.stopPropagation();
        kpe.preventDefault();
      }
    }

    if (kpe && kpe.code === 'Enter') {
      this.emitUpdate();
      kpe.stopPropagation();
      kpe.preventDefault();
      this.enterPressed.emit(this.getTexts());
      this.values = [];
    }
    this.stChanged(this.getTexts());
  }

  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  set selectedValue(val: TagEditorElement[]) {
    this._selectedValue = val;
    this.onChange(val);
    this.onTouched(val);
  }

  get selectedValue(): TagEditorElement[] {
    return this._selectedValue;
  }

  getArrayFromContent(): void {
    this.htmlContent = this.spanInput.nativeElement.innerHTML;

    const textElement = this.getTexts();
    let retVal: TagEditorElement[] = [];

    let ctr = 0;
    for (const item of this.spanInput.nativeElement.getElementsByTagName('pill')) {
      const tagId = item.getAttribute('data-partid');
      const tagDescriberVal = item.getAttribute('data-tag-describer');
      const tagName = item.getAttribute('data-text');;
      retVal.push(this.getTextPart(textElement[ctr]));
      retVal.push(this.getTagPart({ id: tagId, name: tagName, tagDescriber: tagDescriberVal }));
      ctr++;
    }
    retVal = [...retVal, ...textElement.slice(ctr).map((t) => this.getTextPart(t))];

    this.selectedValue = retVal;
  }

  isOverCharLimit(): boolean {
    if (this.charLimit == null) {
      return false;
    }

    try {
      return this.getTexts().join('').length > this.charLimit;
    } catch (e) {
      return false;
    }
  }

  getTexts(): string[] {
    const regex = new RegExp('<pill(.*?)</pill>', 'g');

    return this.spanInput.nativeElement.innerHTML.replace(regex, '&#x2800').split('&#x2800');
  }

  getTagEditorContent(tec: TagEditorElement[], stripText = false): string {
    let retVal = '';
    if (tec  != null) {
      tec.forEach((t) => {
        retVal += t.type === TagEditorPartType.text ?
          (!stripText ? t.content : '')
          : this.getTagHtml({id: t.content.id, content: t.content.name, type: t.type, tagDescriber: t.content.tagDescriber });
      });
    }

    if (retVal == null || retVal.length < 1) {
      retVal = ''; // '='
    }
    return retVal;
  }

  placeCaretAtEnd(el): void {
    el.focus();
    if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  writeValue(initValue: any): void {
    // this is to stop circular value updates
    if (document.activeElement === this.spanInput?.nativeElement) {
      return;
    }
    this.writeValContent = this.sanitizer.bypassSecurityTrustHtml(this.getTagEditorContent(initValue));
    this._selectedValue = initValue || [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /* code below sets the caret position */
  onFocusOut(): void {
    try {
      const target = document.createTextNode('\u0001');
      document.getSelection().getRangeAt(0).insertNode(target);
      this.caretPos = this.spanInput.nativeElement.innerHTML.indexOf('\u0001');
      target.parentNode.removeChild(target);
    } catch (ex) {
      console.log('could not set the caret position');
    }
    this.emitUpdate();
  }

  onAddTagClick(): void {
    this.addTagClick.emit();
  }
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const text = event.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
    this.enterPressed.emit(this.getTexts());
  }
  addTag(tag = this.getTagPart({ name: 'test' }), removePlainText = false): void {
    // always insert in 0
    if (this.tagLimit !== null) {
      if (this.spanInput.nativeElement.getElementsByTagName('pill').length >= this.tagLimit) {
        this.limitReached.emit();
        return;
      }
    }

    const innerHtml = this.spanInput.nativeElement.innerHTML;
    this.caretPos = this.spanInput.nativeElement.innerHTML.length; // always drop new element at the end ignoring current caret position
    let finalHtml: string = innerHtml.slice(0, this.caretPos) + this.getTagHtml(tag) + innerHtml.slice(this.caretPos);
    if (removePlainText) {
      const regex = new RegExp('<pill(.*?)</pill>', 'g');
      const replaceSequence = finalHtml.replace(regex, '&#x2800').split('&#x2800');
      replaceSequence.forEach((t) => {
        finalHtml = finalHtml.replace(t, '');
      });
    }

    this.spanInput.nativeElement.innerHTML = finalHtml;
    this.placeCaretAtEnd(this.spanInput.nativeElement);
    this.emitUpdate();
    this.searchTextChanged.emit('');
    this.values = [];
  }

  stChanged(val: string[]): void {
    // NOTE: Patch to get values from html string. Might be removed later.
    if (val[0].includes(CHECK_SPAN)) {
      const htmlString = val[0];
      const htmlObject = document.createElement('div');
      htmlObject.innerHTML = htmlString;
      this.searchTextChanged.emit(htmlObject.innerText);
    } else {
      this.searchTextChanged.emit(val.find(t => t.length > 0));
    }
  }

  addTagFromDropDown(ddItem: any): void {
      this.addTag({content: ddItem.email, type: TagEditorPartType.tag, id: ddItem.id,  tagDescriber: ddItem.isExternal ? TagDescriber.externalMail : TagDescriber.internalMail}, true);
      this.values = [];
  }


}
