import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';
import {ButtonIconTypes, InfoDialogTypes} from './info-dialog-types.enum';
import { EyInfoDialogComponent } from './ey-info-dialog.component';
import { ModalResult, ModalVerticalSizeWindowClass, ModalWidth } from '../ey-modal-template/ey-modal-result.enum';
import { IDialogButtonText } from './ey-info-dialog.model';

export const DEF_DISMISS_TEXT = 'Dismiss';
export const DEF_CANCEL_TEXT = 'Cancel';
export const DEF_DELETE_TEXT = 'Delete';
export const DEF_ACCEPT_TEXT = 'Ok';
export const DEF_YES_TEXT = 'Yes';
export const DEF_NO_TEXT = 'No';
export const ACCEPT_WARNING_TEXT = 'Accept';
export const REJECT_WARNING_TEXT = 'Reject';

export const DEF_TEXTS: IDialogButtonText = {
  dismissText: DEF_DISMISS_TEXT,
  cancelText: DEF_CANCEL_TEXT,
  deleteText: DEF_DELETE_TEXT,
  acceptText: DEF_ACCEPT_TEXT,
  yesText: DEF_YES_TEXT,
  noText: DEF_NO_TEXT,
  acceptWarningText: ACCEPT_WARNING_TEXT,
  rejectWarningText: REJECT_WARNING_TEXT,
};

@Injectable({
  providedIn: 'root'
})
export class EyInfoDialogService {
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    size: ModalWidth.default,
    windowClass: ModalVerticalSizeWindowClass.auto
  };

  constructor(private modalService: NgbModal) { }

  openInfoDialog(htmlBody: string, title: string, infoDialogType: InfoDialogTypes = InfoDialogTypes.info,
                 btnDialogText: IDialogButtonText = DEF_TEXTS, btnIcons: ButtonIconTypes = ButtonIconTypes.none): Observable<ModalResult> {
    const modalRef = this.modalService.open(EyInfoDialogComponent, this.modalOptions);
    modalRef.componentInstance.htmlBody = htmlBody;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.infoDialogType = infoDialogType;
    modalRef.componentInstance.texts = btnDialogText;
    modalRef.componentInstance.btnIcons = btnIcons;
    return from(modalRef.result);
  }

}
