<div class="userprofile-group" [class.show]="showUserDropdown">
  <a role="button"  class="userprofile-group__help fonts-col__subtitle-2 d-flex" href="{{internalSiteHelp}}" target="_blank" *ngIf="showInternalSiteHelp && !user.isExternal"><i class="emp-icon icon-ico_help text-decoration-none pr-2"></i><span *ngIf="!isMobileBreakpoint">Help</span></a>
  <div class="userprofile__dropdown">
    <a  role="button" aria-label="toggle user profile" [attr.aria-expanded]="userDropdownExpanded" aria-haspopup="true" class="fonts-col__subtitle-2 text-decoration-none d-flex" data-toggle="dropdown" href id="dropdownMenuLink" role="button" (click)="onUsernameClick($event); $event.preventDefault()"><i class="emp-icon icon-ico_user pr-2"></i>
      <span *ngIf="!mobileViewChange && !isMobileBreakpoint">{{user?.name}}</span>
      <span *ngIf="!showUserDropdown" > <i class="emp-icon icon-ico_triangle_down"></i></span>
      <span  *ngIf="showUserDropdown" > <i class="emp-icon icon-ico_triangle_up"></i></span>
    </a>
    <div aria-labelledby="dropdownMenuLink" class="dropdown-menu" [class.show]="showUserDropdown">
      <label  class="caption">Welcome</label><div class="dropdown-item " name="user">{{user?.name}}</div><hr>
      <label  class="caption">Email address</label><div class="dropdown-item " name="email">{{user?.email}}</div><hr>
      <label  class="caption">System roles</label><div class="dropdown-item " name="systemRoles">{{roles}}</div><hr>
      <a class="dropdown-item p-0" href role="button" (click)="onLogout(); $event.preventDefault();">
        <i class="emp-icon icon-ico_logout "></i>
        <span class="logout pl-2">Logout</span>
      </a>
    </div>
  </div>
</div>
