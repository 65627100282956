import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {ViewTypes} from '../../page.model';
export const BLANK_TARGET = '_blank';
@Component({
  selector: 'app-fluid-footer-template',
  templateUrl: './fluid-footer-template.component.html',
  styleUrls: ['./fluid-footer-template.component.scss']
})
export class FluidFooterTemplateComponent {
  constructor() { }
  @ViewChild('templateContainer') templateContainerRef: ElementRef;
  @Input() pageFooterVisible = true;
  @Input() hintPresent = false;
  @Input() selectedViewType: ViewTypes = ViewTypes.desktop;
  viewTypes = ViewTypes;
}
