import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResponseLinkService {

  openSubmitResponseTab(projectId: string, draftId: string = ''): void {
    window.open(this.getResponseLink(projectId, draftId), '_blank');
  }

  getResponseLink(projectId: string, draftId: string = ''): string {
    return `/design-module/${projectId}/submit-response/${draftId}`;
  }

  openViewSummaryWindow(projectId: string, responseId: string): void {
    window.open(`/design-module/${projectId}/view-response-summary/${responseId}`, '_blank');
  }
}
