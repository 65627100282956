import { Component, OnInit } from '@angular/core';
import {SummarySectionBaseComponent} from '../summary-section-base.component';

export interface IOption {
  description: string;
  selected: boolean;
}

@Component({
  selector: 'app-decision-summary',
  templateUrl: './decision-summary.component.html',
  styleUrls: ['./decision-summary.component.scss']
})
export class DecisionSummaryComponent extends SummarySectionBaseComponent implements OnInit {
  options: IOption[];
  body: string;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.body = this.page?.moduleFlowPage.body;
    this.options = this.page.moduleFlowPage.properties[0].options.map((o) => {
      return {
      description: o.description,
      selected: o.optionId === this.page.moduleFlowPage.properties[0].value
      };
    });
  }

  get isAutomated(): boolean {
    return this.page?.moduleFlowPage.properties[0].isAutomated;
  }
}
