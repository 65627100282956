import { ChangeDetectorRef, Component, DoCheck, ElementRef, HostListener, Input, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { TagEditorPartType } from 'src/app/shared/components/ey-tag-editor/ey-tag-editor.component';

@Component({
  selector: 'ey-mail-expander',
  templateUrl: './mail-expander.component.html',
  styleUrls: ['./mail-expander.component.scss']
})
export class MailExpanderComponent implements OnInit, DoCheck {
  @Input() mailTags: Array<{ id: string, tagContent: any, textContent: string, type: TagEditorPartType }>;

  @ViewChild('emailContainer') container: ElementRef = null;
  @ViewChildren('emailEl') emailEls: QueryList<ElementRef> = null;

  isCollapsed = true;

  private overflowingEmails = new Set<string>();

  constructor(private changeDetector: ChangeDetectorRef) {}

  @HostListener('window:resize')
  onResize(): void {
    this.recalculateOverflownElements();
  }

  ngDoCheck(): void {
    this.recalculateOverflownElements();
  }

  private recalculateOverflownElements(): void {
    this.overflowingEmails.clear();

    this.changeDetector.detectChanges();

    this.emailEls.forEach(el => {
      if (this.isOverflow(el.nativeElement)) {
        this.overflowingEmails.add(el.nativeElement.innerText);
      }
    });
  }

  ngOnInit(): void {
    this.changeDetector.detectChanges();
  }

  get count(): number {
    return this.overflowingEmails.size;
  }

  get emails(): Array<string> {
    return this.mailTags
      .filter(t => t.type === TagEditorPartType.tag)
      .map(t => t.tagContent?.name);
  }

  get filteredEmails(): Array<string> {
    return this.isCollapsed
      ? this.emails.filter(e => !this.overflowingEmails.has(e))
      : this.emails;
  }

  get height(): string | null {
    return this.isCollapsed ? null : 'none';
  }

  onExpand(): void {
    this.isCollapsed = false;
  }

  isOverflow(element: HTMLElement): boolean {
    if (!this.container?.nativeElement) {
      return false;
    }

    return element.offsetTop >= this.container.nativeElement.offsetHeight;
  }
}
