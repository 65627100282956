import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  ReadResponsesFiles,
  ResponseCard,
  TemplateFileTypes,
} from './response-card.model';
import {ActionTypes, RESPONSE_ACTIONS} from '../../projects/responses/responses-action.dictionary';
import {DropDownParentViewTypes} from '../../shared/components/ey-drop-down/ey-drop-down.enum';
import {
  DRAFT_RESPONSE_STATUS,
  ProjectStatus,
  ProjectStatuses,
  UNKNOWN_PROJECT_STATUS
} from '../../projects/project.const';
import {ResponseStatus} from '../../projects/responses/responses.const';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {IAction} from '../../projects/grid-actions/action.model';
import {ResponsesPermissionsService} from '../../projects/responses/responses-permissions.service';
import {UserPermissionsService} from '../../core/user-permissions.service';
import { IActionEventArgs } from 'src/app/projects/grid-actions/grid-actions.component';
import {DEF_TEXTS, EyInfoDialogService} from 'src/app/shared/components/ey-info-dialog/ey-info-dialog.service';
import { ResponseService } from 'src/app/designer/preview/submit-response/response.service';
import {
  ModalResult,
  ModalVerticalSizeWindowClass,
  ModalWidth
} from 'src/app/shared/components/ey-modal-template/ey-modal-result.enum';
import { Response } from 'src/app/projects/responses/response.model';
import { ResponseLinkService } from 'src/app/core/services/response-link.service';
import {UserOtherPermissions} from '../../core/user-permissions.model';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {CollaborationModeResponsesService} from '../../core/services/collaboration-mode-responses.service';
import {EyToastService} from '../../shared/components/ey-toast/ey-toast.service';
import {ToastTypes} from '../../shared/components/ey-toast/toast-types.enum';
import {ListOfRespondentsModalComponent} from '../list-of-respondents-modal/list-of-respondents-modal.component';
import {ReviewResponseService} from '../../projects/manage-project/review-responses-modal/review-response.service';
import {ButtonIconTypes, InfoDialogTypes} from '../../shared/components/ey-info-dialog/info-dialog-types.enum';
import {DownloadGeneratedDocumentsModalWindowInputDataInputData} from '../../cross-feature-module/download-generated-documents-modal/download-generated-documents-modal-window-input-data.modal';
import {DownloadGeneratedDocumentsService} from '../../cross-feature-module/download-generated-documents-modal/download-generated-documents.service';

export const RESP_LOCKED_MSG = { text: 'Response locked', type: ToastTypes.success };
export const RESP_UNLOCKED_MSG = { text: 'Response unlocked', type: ToastTypes.success };
export const RESP_LOCKED_BY_ANOTHER_USER_MSG = { text: 'Response locked by another user', type: ToastTypes.warning };
export const RESP_LOCK_ERROR_MSG = { text: 'Error occured during the locking opeation', type: ToastTypes.error };
export const RESP_UNLOCK_ERROR_MSG = { text: 'Error occured during the unlocking opeation', type: ToastTypes.error };
export const DUPLICATE_RESPONSE_MSG = { header: 'Duplicate Response', content: 'You are creating a duplicate response. New response created will be in Draft state, all data will be copied from original. You will be able to edit the new response before submitting.'};

@Component({
  selector: 'app-my-responses-card',
  templateUrl: './my-responses-card.component.html',
  styleUrls: ['./my-responses-card.component.scss']
})

export class MyResponsesCardComponent implements OnInit, OnDestroy {
  @Input() response: ResponseCard = null;
  @Output() responseDeleted = new EventEmitter<boolean>();
  @Output() refreshResponseCards = new EventEmitter<boolean>();
  @Input() otherPermissions: UserOtherPermissions;
  destroy$ = new Subject<boolean>();
  actions = RESPONSE_ACTIONS;
  parentViewFlag: DropDownParentViewTypes = DropDownParentViewTypes.grid;
  responseStatus: any;
  projectStatus: number;
  userPermissions: string[];
  templateFileTypesEnum = TemplateFileTypes;

  constructor(
    private userPermissionsService: UserPermissionsService,
    private responsesPermissionsService: ResponsesPermissionsService,
    private dialogService: EyInfoDialogService,
    private modalService: NgbModal,
    private responseService: ResponseService,
    private responseLinkService: ResponseLinkService,
    private collaborationModeResponseService: CollaborationModeResponsesService,
    private toastsService: EyToastService,
    private reviewResponseService: ReviewResponseService,
    private downloadGeneratedDocumentsService: DownloadGeneratedDocumentsService
  ) {}

  ngOnInit(): void {
    this.userPermissionsService
      .getRawPermissions()
      .pipe(takeUntil(this.destroy$))
      .subscribe((up) => {
        this.userPermissions = up;
      });
    this.responseStatus = this.responseService.getResponseStatus(this.response.responseStatus, this.response.isCollaborationLocked);
  }

  showDocumentsDownloadSection(): boolean {
    if (this.otherPermissions.ReadAllResponsesFiles !== null || this.response.userPermissions.includes(ReadResponsesFiles)) {
      return !this.checkIfResponseIsDraftBulkUpload() && this.checkConditionsToDisplayDocuments();
    }
    return false;
  }

  checkIfResponseIsDraftBulkUpload(): boolean {
    return this.response.uploadId && this.response.isCollaborationLocked === null && this.response.responseStatus == DRAFT_RESPONSE_STATUS;
  }

  checkConditionsToDisplayDocuments(): boolean {
    const uploadId = this.response.uploadId;
    if (uploadId) {
      return !this.checkIfDocumentsNotAvailable();
    } else {
      return true;
    }
  }

  checkIfDocumentsNotAvailable(): boolean {
    return (this.response.wordCount === 0 && this.response.excelCount === 0 && this.response.powerPointCount === 0);
  }

  getProjectStatus(statusId: number): any {
    const status = ProjectStatuses.find((s) => s.id === statusId);
    return status !== undefined ? status : UNKNOWN_PROJECT_STATUS;
  }

  onActionSelect(aea: IActionEventArgs, response: Response): void {
    switch (aea.actionType) {
      case ActionTypes.Delete:
        this.onDeleteResponseCard(response);
        break;
      case ActionTypes.ResumeDraft:
        this.onResumeDraft(response);
        break;
      case ActionTypes.ViewSummary:
        this.responseLinkService.openViewSummaryWindow(response.projectId, response.id);
        break;
      case ActionTypes.Lock:
        this.onCollaborationModeResponseLock(response.projectId, response.id);
        break;
      case ActionTypes.Unlock:
        this.onCollaborationModeResponseUnlock(response.projectId, response.id);
        break;
      case ActionTypes.ListOfRespondents:
        this.onCollaborationModeListOfRespondents(response.projectId, response.id);
        break;
      case ActionTypes.ReviewDraft:
        this.reviewResponseService.openReviewDraftResponseTab(response.projectId, response.id);
        break;
      case ActionTypes.Duplicate:
        this.onDuplicateResponse(response.id, response.createdBy);
        break;
      default:
        alert('WIP');
    }
  }

  onCollaborationModeResponseLock(projectId: string, responseId: string): void {
    this.collaborationModeResponseService.onCollaborationModeResponseLock(projectId, responseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(responseLockInfo => {
        if (responseLockInfo) {
          if (!responseLockInfo.isLockedByAnotherUser) {
            this.toastsService.add(RESP_LOCKED_MSG);
            this.refreshResponseCards.emit(true);
          } else {
            this.toastsService.add(RESP_LOCKED_BY_ANOTHER_USER_MSG);
          }
        } else {
          this.toastsService.add(RESP_LOCK_ERROR_MSG);
        }
      });
  }

  onCollaborationModeResponseUnlock(projectId: string, responseId: string): void {
    this.collaborationModeResponseService.onCollaborationModeResponseUnlock(projectId, responseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(responseLockInfo => {
        if (responseLockInfo) {
          this.toastsService.add(RESP_UNLOCKED_MSG);
          this.refreshResponseCards.emit(true);
        } else {
          this.toastsService.add(RESP_UNLOCK_ERROR_MSG);
        }
      });
  }

  onCollaborationModeListOfRespondents(projectId: string, responseId: string): void {
    const listOfRespondentsModal: NgbModalOptions = {
      backdrop: 'static',
      size: ModalWidth.Large,
      windowClass: ModalVerticalSizeWindowClass.contentHeavyDoubleHeader,
    };
    const modalRef = this.modalService.open(ListOfRespondentsModalComponent, listOfRespondentsModal);
    modalRef.componentInstance.projectId = projectId;
    modalRef.componentInstance.responseId = responseId;
    modalRef.result.then((result) => {
      return;
    });
  }

  onDuplicateResponse(responseId: string, respondentId: string): void {
    this.dialogService
      .openInfoDialog( DUPLICATE_RESPONSE_MSG.content, DUPLICATE_RESPONSE_MSG.header,
        InfoDialogTypes.warning, {...DEF_TEXTS, acceptWarningText: 'Duplicate', rejectWarningText: 'Cancel'}, ButtonIconTypes.none)
      .subscribe((result: ModalResult) => {
        if (result === ModalResult.submit) {
          this.generateDuplicateResponse(responseId, respondentId);
        }
      });
  }

  generateDuplicateResponse(responseId: string, respondentId: string): void {
    this.responseService.duplicateResponse(responseId, respondentId).subscribe(duplicate => {
      if (duplicate) {
        this.refreshResponseCards.emit(true);
      }
    });
  }

  onResumeDraft(response: Response): void {
    this.responseLinkService.openSubmitResponseTab(response.projectId, response.id);
  }

  onDeleteResponseCard(responseData: Response): void {
    const infoDialogOptions = this.responseService.getResponseDeleteDialogOptions();

    this.dialogService
      .openInfoDialog(infoDialogOptions.msg, infoDialogOptions.title, infoDialogOptions.dialogType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result === ModalResult.submit) {
          this.responseService.deleteResponse(responseData.projectId, responseData.id)
          .subscribe(res => {
            this.responseDeleted.emit(true);
          });
        }
      });

  }

  onActionClick(actionType: string): void {
    window.alert('WIP');
  }

  getResponseActionNavElements(data: ResponseCard): IAction[] {
    const permissionList = data.userPermissions;
    permissionList.concat(this.userPermissions);
    const projectStatus: ProjectStatus = this.getProjectStatus(data.projectStatus).label as ProjectStatus;
    const responseStatus: ResponseStatus = this.responseService.getResponseStatus(data.responseStatus).label as ResponseStatus;
    const collaborationModeResponseData: {isCollaborationLocked: boolean, isCurrentUserCanUnlock: boolean} = {
      isCollaborationLocked: data.isCollaborationLocked,
      isCurrentUserCanUnlock: data.isCurrentUserCanUnlock
    };
    return this.responsesPermissionsService.getResponsesActionNavElementsMyDashboard(projectStatus, responseStatus, permissionList, collaborationModeResponseData,
                                                                                     data.isRespondent, data.isDuplicatable);
  }

  openDocumentDownloadModal(templateCount: number, projectId: string, responseId: string, respondentName: string, templateType: number, responseDate: string, responseStatus: number): void {
    if (templateCount > 1) {
      const modalWindowInputData: DownloadGeneratedDocumentsModalWindowInputDataInputData = {
        responseId,
        isResponseInDraftState: this.responseService.checkIfResponseIsInDraftState(responseStatus) ? true : false,
        projectId,
        templateType,
        fileData: null,
        downloadDocumentsBasedOnTemplateType: true
      };
      this.downloadGeneratedDocumentsService.openDownloadGeneratedDocumentsModal(modalWindowInputData);
    } else {
      this.downloadGeneratedDocumentsService.checkResponseStatusAndDownloadTemplate(projectId, responseId, respondentName, templateType, responseDate, responseStatus, this.destroy$);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

}
