export interface FlowValidationError {
  error: number;
  errorTitle: string;
  errorText: string;
}

export interface Page {
    errors: any;
    moduleFlowPage: any;
    sessionId: string;
    flowValidationError?: FlowValidationError;
    progressBarPercentage?: any;
}

export enum ViewTypes {
  desktop,
  tablet,
  mobile
}
