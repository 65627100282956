<app-sticky-footer-template class="d-flex flex-fill" [selectedViewType]="selectedViewType" [removePaddingTopOnReviewDateDisplay]="lastTechReviewDate && !isMobileBreakpoint">
    <ng-container appPageContent>
        <ey-froala-content-wrapper [editorContent]="body"></ey-froala-content-wrapper>
    </ng-container>
    <ng-container appPageFooter>
      <div *ngIf="lastTechReviewDate && !isMobileBreakpoint">
        <span class="reviewDate">Last Technical Review: {{lastTechReviewDate}}</span>
      </div>
      <div class="d-flex justify-content-center">
          <ey-button [btnClass]="btnClass.primary" text="Start" (click)="onStart(collaborationMode)"></ey-button>
      </div>
    </ng-container>
</app-sticky-footer-template>
