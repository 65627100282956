/*
TODO(MK): this commponent will be removed keeping it temporary as there is some useful code to manage carret possition
 */

import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {EyBaseFormControlComponent} from '../ey-base-form-control/ey-base-form-control';
import {PillType} from '../ey-pill/ey-pill.component';

export enum TagEditorPartType {
  tag,
  text
}

export interface TagEditorElement {
  type: TagEditorPartType;
  content: any;
  id: string;
}

export const EMPTY_TEXT_PART = {
  content: '',
  type: TagEditorPartType.text
};

export const EMPTY_TAG_PART = {
  content: {name: 'test'},
  type: TagEditorPartType.tag,
  id: '',
};

@Component({
  selector: 'app-ey-tag-editor',
  templateUrl: './ey-tag-editor.component.html',
  styleUrls: ['./ey-tag-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyTagEditorComponent),
      multi: true,
    }
  ]
})
export class EyTagEditorComponent extends EyBaseFormControlComponent implements ControlValueAccessor {
  @Input() pillType = PillType.lightGray;
  @Output() blur = new EventEmitter<void>();
  tagEditorPartType = TagEditorPartType;
  _selectedValue: TagEditorElement[] = [];
  caretPos = 0;
  lastTouchedElementIndex = 0;
  @ViewChildren('spanInput') spanInput: QueryList<any>;

  getTextPart(cnt: string): TagEditorElement {
    return {...EMPTY_TEXT_PART, content: cnt, id:  Date.now().toString()};
  }
  getTagPart(cnt: any): TagEditorElement  {
    return {...EMPTY_TAG_PART, content: cnt, id:  Date.now().toString()};
  }

  constructor(private controlContainer: ControlContainer) {
    super(controlContainer);
  }

  onBlur(): void {
    this.isFocus = false;
    this.onTouched(this.selectedValue);
    this.blur.emit();
  }
  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  set selectedValue(val: TagEditorElement[]) {
    this._selectedValue = val;
    this.onChange(val);
    this.onTouched(val);
  }

  get selectedValue(): TagEditorElement[] {
    if (this._selectedValue == null || this._selectedValue.length === 0) {
      this._selectedValue = [this.getTextPart('=')];
    }
    if (this._selectedValue[this._selectedValue.length - 1].type === TagEditorPartType.tag) {
      this._selectedValue.push(this.getTextPart(''));
    }
    return this._selectedValue;
  }

  writeValue(initValue: any): void {
    this._selectedValue = initValue || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onFocusOut(oField,  ctrlIndex: number): void {
      this.caretPos =  this.getCaretPosition();
      this.lastTouchedElementIndex = ctrlIndex;
  }
  onKeyPress(oField,  ctrlIndex: number, event: KeyboardEvent): void {

    if (event.code === 'Delete') {
      if ( (this.getCaretPosition() === oField.innerText.length) && this.selectedValue[ctrlIndex + 1].type === TagEditorPartType.tag) {
        this.removeTag(ctrlIndex + 1);
      }
    }

    if (event.code === 'Backspace') {
      if ( (this.getCaretPosition() === 0) && this.selectedValue[ctrlIndex - 1].type === TagEditorPartType.tag) {
        this.removeTag(ctrlIndex - 1);
      }
    }

    try {
    if (event.code === 'ArrowLeft') {
      if ((this.getCaretPosition() === 0) && this.selectedValue[ctrlIndex - 1].type === TagEditorPartType.tag) {
          const el = this.spanInput.find((t) => t.nativeElement.getAttribute('partid') === this.selectedValue[ctrlIndex - 2].id).nativeElement;
          this.setFocus(el);
      }
    }
    } catch (e) {
      //
    }

    try {
      if (event.code === 'ArrowRight') {
          if ((this.getCaretPosition() === this.selectedValue[ctrlIndex].content.length) && this.selectedValue[ctrlIndex + 1].type === TagEditorPartType.tag) {
          const el = this.spanInput.find((t) => t.nativeElement.getAttribute('partid') === this.selectedValue[ctrlIndex + 2].id).nativeElement;
          this.setFocus(el, true);
        }
      }
    } catch (e) {
      // do nothing
    }


  }

  setFocus(el: any, atStart: boolean = false): void {
    setTimeout(() => {
      el.focus();
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(atStart);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }, 0);
  }

  removeTag(ctrlIndex: number): void {
    this.selectedValue.splice(ctrlIndex, 1);
    this.mergeTextParts(ctrlIndex);
    this.setFormulaFocus();
  }
  mergeTextParts(ctrlIndex: number): void {
    if (this.selectedValue[ctrlIndex]?.type === TagEditorPartType.text
      && this.selectedValue[ctrlIndex - 1]?.type === TagEditorPartType.text) {
      this.selectedValue[ctrlIndex - 1] = {
        type: TagEditorPartType.text,
        content:  this.selectedValue[ctrlIndex - 1].content + this.selectedValue[ctrlIndex].content,
        id: Date.now().toString()
      };
      this.selectedValue.splice(ctrlIndex, 1);
    }

  }

  getCaretPosition(): number {
    const sel = document.getSelection();
    return sel.focusOffset;
  }

  addTag(tag = this.getTagPart({name: 'test'})): void {

    // add after
    if (this.caretPos === this.selectedValue[this.lastTouchedElementIndex].content.length)
    {
        this.selectedValue.splice(this.lastTouchedElementIndex + 1, 0, tag);
        return;
    }

    // add before
    if (this.caretPos === 0)
    {
      this.selectedValue.splice(this.lastTouchedElementIndex === 0 ? 0 : this.lastTouchedElementIndex - 1, 0, tag);
      return;
    }

    // split array insert between

    const cnt =  this.selectedValue[this.lastTouchedElementIndex].content;
    const newTextPart = this.getTextPart(cnt.slice(this.caretPos, cnt.length));
    this.selectedValue[this.lastTouchedElementIndex].content = cnt.slice(0, this.caretPos);
    this.selectedValue.splice(this.lastTouchedElementIndex + 1, 0, tag, newTextPart);
  }

  setFormulaFocus(): void {
    // const ctrlIndex = this.selectedValue.filter(t => t.type === TagEditorPartType.text).length;
    if (this.spanInput && this.spanInput.last.nativeElement ) {
      this.spanInput.last.nativeElement.focus();
    }
  }

 /*
     setTimeout(function() {
        div.focus();
  }, 0);
  */
}
