export const environment = {
  production: true,
  baseUrl: '/api',
  supportEmail: 'empowerment.support@au.ey.com',
  internalSiteHelp: 'https://sites.ey.com/sites/Empowerment',
  // set to -1 for unlimited history
  undoHistoryCount: 25,
  showErrorDialog: false
};

