import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IProjectManager, Project, ProjectAPI } from './project.model';
import { GridState } from '../shared/components/ey-grid/models/grid-state.model';
import { TableData } from '../shared/components/ey-grid/models/table-data.model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CloseReopenOptions, ProjectStatus, ProjectType, PROJECT_MSGS, TargetAudience} from './project.const';
import { ITeamMember } from './add-team-member/team-member.model';
import { ProjectInfo } from './manage-project/project-info.model';
import { IProjectWorkflowSettings, IRespondent } from './get-web-link/get-web-link.model';
import { InfoDialogTypes } from '../shared/components/ey-info-dialog/info-dialog-types.enum';
import { InfoDialogOptions } from '../shared/components/ey-info-dialog/ey-info-dialog.model';
import { EditProjectProjectAPI, IEditProject, ModuleVersionProjectEdit } from './edit-project-modal/edit-project.model';
import { Response } from './responses/response.model';
import { IResponseFieldMapping } from './bulk-download-responses-modal/bulk-download-responses.model';
import {map, take} from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import {IDocAutomation} from './manage-project/document-automation/document-automation.model';
import {
  DELETE_DOCUMENT_TITLE,
  DOCUMENT_AUTOMATION_DISMISS_MSG
} from './manage-project/document-automation/document-automation.meta';
import { IUploadFiles } from './manage-project/upload-templates-modal/upload-template.modal';
import {CollaborationResponseStatuses, ResponseStatuses} from '../dashboard/my-responses-card/response-card.model';
import {UNKNOWN_RESPONSE_STATUS} from './responses/responses.const';
import { ProjectInfoForResponseDTO } from './project-info-for-response.model';

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {

    projectTypeChange: BehaviorSubject<number> = new BehaviorSubject(null);
    constructor(private http: HttpClient, private datePipe: DatePipe) {}

    createProject(project: Project): Observable<string> {
        const requestBody = this.mapToApiRequest(project);
        return this.http.post<string>(`${environment.baseUrl}/project`, requestBody);
    }

    editProject(project: IEditProject, id: string): Observable<string> {
      const requestBody = this.mapToEditProjectApiRequest(project);
      return this.http.put<string>(`${environment.baseUrl}/project/${id}`, requestBody);
    }

    mapToApiRequest(p: Project): ProjectAPI {
      return {
        name: p.name,
        description: p.description,
        projectCode: p.projectCode,
        moduleId: p.module.id,
        moduleVersionId: p.moduleVersion.id,
        targetAudience: p.targetAudience,
        // TODO LL: Seems all BE evaluates targetAudience to determine if isClientAccessible, so probably Projects.isClientAccessible field is not necessary
        isClientAccessible: (typeof (p.targetAudience) === 'number') ? p.targetAudience === TargetAudience.external : false,
        isGoLive: (typeof (p.projectType) === 'number') ? p.projectType === ProjectType.live : false,
        dataOriginCountryId: p.dataOriginCountry.id,
        areaId: p.area.id,
        countryId: p.country.id,
        serviceLineId: p.serviceLine.id,
        subServiceLineId: p.subServiceLine.id,
        marketSegmentId: p.marketSegment?.id,
        purposeIds: p.projectPurpose ? p.projectPurpose.map( t => t.id) : [],
        taxTypeIds: p.taxTypes ? p.taxTypes.map(t => t.id) : [],
        clientMultiplicity: p.client.id,
        channelType: p.channelType.id,
        engagementCode: p.engagementCode,
        EngagementPartner: p.engagementPartners ? p.engagementPartners[0].name : '',
        clientName: p.clientName,
        projectManagerIds: p.projectManagers.map( pm => pm.id),
        uploadedFiles: p.uploadedFiles,
        countryAcknowledge : p.countryAcknowledge,
      };
    }

    mapToEditProjectApiRequest(p: IEditProject): EditProjectProjectAPI {
      return {
        name: p.name,
        description: p.description,
        moduleVersionId: p.moduleVersion?.id,
        areaId: p.area.id,
        countryId: p.country.id,
        serviceLineId: p.serviceLine.id,
        subServiceLineId: p.subServiceLine.id,
        marketSegmentId: p.marketSegment?.id,
        purposeIds: p.projectPurpose ? p.projectPurpose.map( t => t.id) : [],
        taxTypeIds: p.taxTypes ? p.taxTypes.map(t => t.id) : [],
        clientMultiplicity: p.client.id,
        channelType: p.channelType.id,
        engagementCode: p.engagementCode,
        engagementPartner: p.engagementPartners ? p.engagementPartners[0].name : '',
        clientName: p.clientName,
        projectManagerIds: p.projectManagers.map( pm => pm.id),
      };
    }

    getProjects(state: GridState): Observable<TableData<Project>> {
      return this.http.post<TableData<Project>>(`${environment.baseUrl}/project/my-projects`, {
          pageSize: state.pageSize.toString(),
          sortBy: state.sortBy.toString(),
          sortDirection: state.sortDirection.toString(),
          pageNumber: state.pageNumber.toString(),
          search: state.search.toString(),
          activeFilters: state.activeFilters,
        });
    }

    getAllProjects(state: GridState): Observable<TableData<Project>> {
      return this.http.post<TableData<Project>>(`${environment.baseUrl}/project/all-projects`, {
          pageSize: state.pageSize.toString(),
          sortBy: state.sortBy.toString(),
          sortDirection:  state.sortDirection.toString(),
          pageNumber: state.pageNumber.toString(),
          search: state.search.toString(),
          activeFilters: state.activeFilters
      });
    }

    isProjectNameUnique(projectName: string): Observable<boolean> {
      return this.http.get<boolean>(`${environment.baseUrl}/project/is-project-name-unique`, {
        params: {
          projectName: encodeURIComponent(projectName),
        },
      });
    }

    getAutoGeneratedCode(): Observable<string> {
      return this.http.get<string>(`${environment.baseUrl}/project/code`);
    }

    getProjectManagers(lookupPhrase: string): Observable<IProjectManager[]> {
      return this.http.get<IProjectManager[]>(`${environment.baseUrl}/project/managers-lookup/${lookupPhrase}`);
    }

    getCurrentTeamMembers(projectId: string): Observable<ITeamMember[]> {
      return this.http.get<ITeamMember[]>(`${environment.baseUrl}/project/team-members/${projectId}`);
    }

    getTeamMemberCandidates(projectId: string, lookupPhrase: string): Observable<ITeamMember[]> {
      return this.http.get<ITeamMember[]>(`${environment.baseUrl}/project/${projectId}/team-members-lookup/${lookupPhrase}`);
    }

    updateTeamMembers(projectId: string, teamMembers: ITeamMember[]): Observable<boolean> {
      return this.http.patch<boolean>(`${environment.baseUrl}/project/${projectId}/team-members-update`, teamMembers);
    }

    getTeamMembersList(projectId: string, gridState: GridState): Observable<TableData<ITeamMember>> {
      return this.http.get<TableData<ITeamMember>>(`${environment.baseUrl}/project/team-members-list/${projectId}`, {
        params: {
          pageSize: gridState.pageSize.toString(),
          sortBy: gridState.sortBy.toString(),
          sortDirection: gridState.sortDirection.toString(),
          pageNumber: gridState.pageNumber.toString(),
        },
      });
    }

    getProjectName(id: string): Observable<string> {
      return this.http.get<string>(`${environment.baseUrl}/project/project-name/${id}`);
    }

    getProjectInfo(id: string): Observable<ProjectInfo> {
      return this.http.get<ProjectInfo>(`${environment.baseUrl}/project/project-info/${id}`);
    }

    deleteTeamMember(projectId: string, userId: string): Observable<boolean> {
      return this.http.delete<boolean>(`${environment.baseUrl}/project/${projectId}/delete-team-member/${userId}`);
    }

    getRespondentCandidates(projectId: string, lookupPhrase: string): Observable<IRespondent[]> {
      return this.http.get<IRespondent[]>(`${environment.baseUrl}/project/${projectId}/respondents-lookup/${lookupPhrase}`);
    }

    getProjectWorkflowSettings(projectId: string): Observable<IProjectWorkflowSettings> {
      return this.http.get<IProjectWorkflowSettings>(`${environment.baseUrl}/project/workflow-settings/${projectId}`);
    }
    
    getProjectInfoForResponse(id: string): Observable<ProjectInfoForResponseDTO> {
      return this.http.get<ProjectInfoForResponseDTO>(`${environment.baseUrl}/project/project-info-for-response/${id}`);
    }

    getPrepopulatedRespondents(projectId: string): Observable<IRespondent[]> {
      return this.http.get<IRespondent[]>(`${environment.baseUrl}/project/prepopulated-respondents/${projectId}`);
    }

    createProjectWorkflowSettings(projectWorkflowSettings: IProjectWorkflowSettings): Observable<string> {
      return this.http.post<string>(`${environment.baseUrl}/project/workflow-settings`, projectWorkflowSettings);
    }

    updateProjectWorkflowSettings(projectWorkflowSettings: IProjectWorkflowSettings): Observable<boolean> {
      return this.http.patch<boolean>(`${environment.baseUrl}/project/workflow-settings`, projectWorkflowSettings);
    }

    getProjectStatus(projectId: string): Observable<number> {
      return this.http.get<number>(`${environment.baseUrl}/project/${projectId}/status`);
    }

    updateProjectStatus(projectId: string, projectStatus: number): Observable<boolean> {
      return this.http.patch<boolean>(`${environment.baseUrl}/project/${projectId}/update-status`, projectStatus);
    }

    getProjectBulkDownloadResponsesVersionMappings(projectId: string): Observable<IResponseFieldMapping> {
      return this.http.get<IResponseFieldMapping>(`${environment.baseUrl}/project-mapping-fields/version-mappings-distinct/${projectId}`);
    }

    postProjectBulkDownloadResponsesVersionMappings(projectId: string, mapFields: any): Observable<any> {
      return this.http.post(`${environment.baseUrl}/project-bulk-export/create/${projectId}`, {
        mapFields
      }, {observe: 'response', responseType: 'blob' });
    }

    deleteProjectDialogSelection(projectStatus: ProjectStatus): InfoDialogOptions {
      let retVal = {
        dialogType: InfoDialogTypes.delete,
        msg: PROJECT_MSGS.DELETE_CONFIRMATION_MSG,
        title: 'Delete Project',
      };
      switch (projectStatus) {
        case ProjectStatus.Test:
        case ProjectStatus.TestInactive:
        case ProjectStatus.Live:
        case ProjectStatus.LiveInactive:
          retVal = this.getDismissDialogOptions();
          retVal.msg = PROJECT_MSGS.DELETE_DISMISS_MSG_IN_USE;
          retVal.title = 'Delete Project'
          break;
        case ProjectStatus.TestClosed:
        case ProjectStatus.TestExpired:
        case ProjectStatus.LiveClosed:
        case ProjectStatus.LiveExpired:
        case ProjectStatus.LiveApprovalRequired:
        case ProjectStatus.LiveInApproval:
          break;
      }

      return retVal;
    }

    private getDismissDialogOptions(): InfoDialogOptions {
      return {
        dialogType: InfoDialogTypes.dismiss,
        msg: '',
        title: '',
      };
    }

    deleteProject(projectId: string): Observable<boolean> {
      return this.http.delete<boolean>(`${environment.baseUrl}/project/${projectId}`);
    }

    closeReopenProjectDialogSelection(projectStatus: ProjectStatus): any {
      const retValOptions:any [] = [
        {
          closeReopenOption: CloseReopenOptions.Close,
          dialogType: InfoDialogTypes.yesNo,
          msg: PROJECT_MSGS.CLOSE_MSG,
          title: 'Close Project',
          btnText: 'Close',
        },
        {
          closeReopenOption: CloseReopenOptions.Reopen,
          dialogType: InfoDialogTypes.yesNo,
          msg: PROJECT_MSGS.REOPEN_MSG,
          title: 'Reopen Project',
          btnText: 'Reopen',
        }
      ];

      let retVal = retValOptions.find(o => o.closeReopenOption === CloseReopenOptions.Close);

      switch (projectStatus) {
        case ProjectStatus.Test:
        case ProjectStatus.TestInactive:
        case ProjectStatus.Live:
        case ProjectStatus.LiveInactive:
          break;
        case ProjectStatus.TestClosed:
        case ProjectStatus.LiveClosed:
          retVal = retValOptions.find(o => o.closeReopenOption === CloseReopenOptions.Reopen)
          break;
      }

      return retVal;
    }

    closeProject(projectId: string, projectStatus: number): Observable<boolean> {
      return this.http.patch<boolean>(`${environment.baseUrl}/project/${projectId}/close-project`, projectStatus);
    }

    reopenProject(projectId: string): Observable<boolean> {
      return this.http.patch<boolean>(`${environment.baseUrl}/project/${projectId}/reopen-project`, {});
    }

    getResponsesTableData(gridState: GridState, projectId: string): Observable<TableData<Response>> {
      return this.http.get<TableData<Response>>(`${environment.baseUrl}/project/${projectId}/responses`, {
        params: {
          pageSize: gridState.pageSize.toString(),
          sortBy: gridState.sortBy.toString(),
          sortDirection: gridState.sortDirection.toString(),
          pageNumber: gridState.pageNumber.toString(),
        },
      }).pipe(map(d => {
        d.data.forEach(res => {
          res.statusPills = this.getResponsePillStatus(res.responseStatus, res.isCollaborationLocked);
        });
        return d;
      }));
    }

    getProjectResponsesTotalCount(projectId: string): Observable<number> {
      return this.http.get<number>(`${environment.baseUrl}/project/${projectId}/responses-total-count`);
    }

    getResponsePillStatus(statusId: number, isCollaborationLocked: boolean = null): any {
      const status = ResponseStatuses.find((s) => s.id === statusId);
      if (isCollaborationLocked !== null && status.labelText !== 'Completed') {
        const collaborationModeStatus = CollaborationResponseStatuses.find((s) => s.isLocked === isCollaborationLocked);
        return collaborationModeStatus !== undefined ? collaborationModeStatus : UNKNOWN_RESPONSE_STATUS;
      }
      return status !== undefined ? status : UNKNOWN_RESPONSE_STATUS;
    }

    downloadGeneratedDocumentAndSaveAs(id: string, fileId: string): void {
      this.downloadGeneratedDocument(id, fileId).pipe(take(1)).subscribe((wordDocResponse: HttpResponse<Blob>) => {
        const headers: HttpHeaders = wordDocResponse.headers;

        const contentDisposition = headers.get('content-disposition');

        let fileName = 'default.docx';
        if (contentDisposition) {
          fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].replace(/"/g, '').trim();
          const currentDate = this.datePipe.transform(new Date(), 'ddMMyyyy');
          fileName = fileName.replace('ddmmyyyy', currentDate);
        }

        saveAs(wordDocResponse.body, fileName);
      },
      err => {   console.log(err); }
      );
    }

    getDocAutomationListData(gridState: GridState, projectId: string): Observable<TableData<IDocAutomation[]>> {
      return this.http.post<TableData<IDocAutomation[]>>(`${environment.baseUrl}/project-file/${projectId}/list`,
        gridState);
    }

    getDocAutomationPlainList(projectId: string): Observable<IDocAutomation[]> {
      return this.http.get<IDocAutomation[]>(`${environment.baseUrl}/project-file/${projectId}/plain-list`);
    }

    updateDocAutomationList(projectId: string, filesToUpload: IUploadFiles[]): Observable<boolean> {
      return this.http.post<boolean>(`${environment.baseUrl}/project-file/${projectId}/update-list`, filesToUpload );
    }

    updateDocument(id: string, fileData: IDocAutomation): Observable<boolean> {
      return this.http.post<boolean>(`${environment.baseUrl}/project-file/update/${id}`, fileData);
    }

    getAutomationDocumentDeleteDialogOptions(): InfoDialogOptions {
      return {
        dialogType: InfoDialogTypes.delete,
        msg: DOCUMENT_AUTOMATION_DISMISS_MSG,
        title: DELETE_DOCUMENT_TITLE,
      };
    }

    private downloadGeneratedDocument(id: string, fileId: string): Observable<any> {
      return this.http.get(`${environment.baseUrl}/documentPipeline/${id}/${fileId}`, { observe: 'response', responseType: 'blob' });
    }

    getModuleVersionsForProjectEdit(projectId: string, moduleId: string): Observable<ModuleVersionProjectEdit> {
      return this.http.get<ModuleVersionProjectEdit>(`${environment.baseUrl}/project/${moduleId}/module-versions-for-project-edit/${projectId}`);
    }
}
