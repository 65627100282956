import { Component, OnInit } from '@angular/core';
import { SummarySectionBaseComponent } from '../summary-section-base.component';
import { DIVIDER_STYLE_HELPER } from '../../../../../builders/form-builder/controls/divider/divider-editor/divider-editor.component';
import { FormSummaryService } from './form-summary.service';
import { IFormPart } from './form-summary.model';
import * as _ from 'lodash';
import { FormBuilderControlTypes } from '../../../../../builders/form-builder/form-builder-control.types';

@Component({
  selector: 'app-form-summary',
  templateUrl: './form-summary.component.html',
  styleUrls: ['./form-summary.component.scss'],
})
export class FormSummaryComponent extends SummarySectionBaseComponent implements OnInit {
  formParts: IFormPart[];
  controlType = FormBuilderControlTypes;
  constructor(private formSummaryService: FormSummaryService) {
    super();
  }

  ngOnInit(): void {
    this.formParts = this.formSummaryService.mapFormPartsToView(_.cloneDeep(this.page.moduleFlowPage.properties));
  }

  getHrStyle(type: string): any {
    return DIVIDER_STYLE_HELPER[type];
  }

  get isHidden(): boolean {
    return this.page.moduleFlowPage?.isHidden;
  }

  getPropertyVisibility(property: any): boolean {
    if (property.isCriteriaSet)
    {
      return property.isCriteriaSatisfied;
    }
    return true;
  }
}
