<label *ngIf="isRequired" class="eyfontlight d-flex"><span class="warningRequired">*</span>Upload is required</label>
<div draggable="true" [class.errorBorder]="hasError" ngClass="{{ dragAreaClass }} p-10" [class.upload_box]="!increaseFixedWidth" [class.change-fixed-width]="increaseFixedWidth" [attr.title]="files?.length === maxFiles ? 'You can upload only ' + maxFiles + ' file(s).' : null">
  <div class="row">
    <div class="col-md-12 text-center">
      <i class="material-icons" [ngClass]="isDisabled ? 'gray' : 'black'"> cloud_upload </i>
      <p class="info-text" [ngClass]="isDisabled ? 'gray' : 'black'">
        {{dragAndDropTitle}} <a href [class.disabled]="isDisabled" (click)="file.click(); $event.preventDefault()">browse</a>
      </p>
      <p class="info-text-light mt-10" [ngClass]="isDisabled ? 'gray' : 'black'">{{dragAndDropSubTitle}}
        <a *ngIf="subHeaderLink" class="textDecoration" href [class.disabled]="isDisabled" (click)="onSeeAllowedTyped(); $event.preventDefault()">see full list of allowed types</a></p>

      <ng-content></ng-content>
      <input [accept]="acceptedFormats" type="file" id="uploader" #file [multiple]="maxFiles > 1" (change)="onFileChange($event)" class="file-input" />
    </div>
  </div>
</div>

<div *ngIf="hasError" class="mt-10 d-flex">
  <i class="emp-icon icon-ico_error_toast"></i>
  <label class="pl-10 fonts-col__sub-nav-default-state flex-grow-1 bg-error">{{requiredErrMsg}}</label>
</div>
<div class="mt-30 d-flex" *ngIf="uploadInProgress">
  <i class="emp-icon icon-ico_error_toast"></i>
  <label class="pl-10 fonts-col__sub-nav-default-state flex-grow-1 bg-error">Upload in progress. To proceed please wait until upload is completed or cancel uploading of files.</label>
</div>


<h4 *ngIf="errors?.length > 0" class="fonts-col__heading-4 mb-0 pb-0 mt-20">Upload Errors</h4>
<div class="row" [class.customWidth]="!increaseFixedWidth" [class.change-fixed-width-for-rows]="increaseFixedWidth" *ngIf="errors.length > 0">
  <div class="col-md-12">
    <ul class="list-group list-group-flush file-upload-list">
      <li *ngFor="let err of errors" class="error mt-3 d-flex">
        <div class="flex-grow-1 pl-10" *ngIf="err.file.name.length > 0" title="{{ err.file.name }}">
          <i class="emp-icon icon-ico_file"></i>
          <span class="file--name ml-10">{{ err.file.name }}</span>
        </div>
        <div class="bg-error alignCenter" *ngIf="err.status.length > 0" title="{{ err.errorMsg }}">
          {{ err.errorMsg }}
        </div>
        <div class="overflow-inherit">
          <a class="upload-file-action-button pl-10 pr-10" (click)="delete(err)"><i class="emp-icon icon-ico_close"></i></a>
        </div>
      </li>
    </ul>
  </div>
</div>

<div class="row" [class.customWidth]="!increaseFixedWidth" [class.change-fixed-width-for-rows]="increaseFixedWidth" *ngIf="validFiles.length > 0">

  <div class="col-md-12">
    <ul class="list-group list-group-flush file-upload-list ">
      <li  class="mt-10 d-flex fu-list-header">
        <div class="flex-grow-1">
          <h4 class="fonts-col__heading-4 mb-0 pb-0 mt-20">{{filesSubTitle}}</h4>
          <span *ngIf="warningOnVideoUpload" class="d-flex mt-3">
            <span class="emp-icon icon-ico_waring_toast mr-2"></span>
            <p class="info-text">{{warningOnVideoUpload}}</p>
          </span>
        </div>
        <div class="min-width-12 d-flex flex-row custom-spacing" *ngIf="showVisibilityColumn"  >

          <h4 class="fonts-col__heading-4 mb-0 pb-0 mt-20">Visibility</h4>
          <i title="Hide this Document from Respondents" class="icon-ico_tooltip emp-icon toggle--info ml-10"></i>
        </div>
        <div class="pr-10 pl-10 align-right" *ngIf="showVisibilityColumn">
          <h4 class="fonts-col__heading-4 mb-0 pb-0 mt-20">Actions</h4>
        </div>
      </li>
    </ul>
  </div>

  <div class="col-md-12">
    <ul class="list-group list-group-flush file-upload-list">
      <li *ngFor="let val of validFiles" class="mt-10 d-flex p-10">
        <div class="flex-grow-1 pl-10" *ngIf="val.file.name.length > 0" title="{{ val.file.name }}">
          <i class="emp-icon" [class]="val.fileExtension.icon"> </i> <span class="file--name ml-10">{{ val.file.name }}</span>
        </div>
        <div class="min-width-12" *ngIf="showVisibilityColumn">
          <form #form="ngForm">
           <ey-toggle class="visibility--toggle" [ngModel]="getToggleValues(val)" [ngModelOptions]="{standalone: true}" (ngModelChange)="onVisibilityToggle(val.fileId, $event)"></ey-toggle>
          </form>
        </div>
        <div class="min-width-12 align-right" *ngIf="val.status === fileUploadStatuses.inProgress">
          <div class="spinner__icon spinner__icon--medium"></div>
        </div>
        <div class="pr-0 pl-0 col-1 align-right">
          <a *ngIf="val.status === fileUploadStatuses.inProgress" class="upload-file-action-button" (click)="deleteAndEmit(val)"><i class="emp-icon icon-ico_close"></i></a>
          <a *ngIf="val.status !== fileUploadStatuses.inProgress" class="upload-file-action-button" (click)="deleteAndEmit(val)"><i class="emp-icon icon-ico_delete"></i></a>
        </div>
      </li>
    </ul>
  </div>
</div>

