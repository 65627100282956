<app-fluid-footer-template [pageFooterVisible]="false" class="d-flex flex-fill">
  <ng-container appPageContent>
      <div class="align-content">
            <div>
              <i class="icon-ico_unlock emp-icon mr-1 locked-icon"></i>
              <h4>{{validationError.errorTitle}}</h4>
              <span class="error-description">{{validationError.errorText}}</span>
            </div>
      </div>
    </ng-container>
</app-fluid-footer-template>

