import { Component, OnInit } from '@angular/core';
import { SummarySectionBaseComponent } from '../summary-section-base.component';
import { ButtonClass } from '../../../../../../shared/components/ey-button/ey-button.component';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { ModuleFlowService } from 'src/app/designer/preview/module-flow.service';

export interface IFile {
  fileId: string;
  name: string;
}

@Component({
  selector: 'app-upload-summary',
  templateUrl: './upload-summary.component.html',
  styleUrls: ['./upload-summary.component.scss']
})
export class UploadSummaryComponent  extends SummarySectionBaseComponent implements OnInit {
  files: IFile[];
  body: string;
  constructor(
    private spinnerService: EyAppSpinnerService,
    private flowService: ModuleFlowService) {
    super();
  }
  btnClass = ButtonClass;

  ngOnInit(): void {
    this.body = this.page?.moduleFlowPage.body;
    this.files = this.page.moduleFlowPage.properties[0].items.map(f => {
      return {
        fileId: f.fileId,
        name: f.name
      };
    });
  }

  download(file: IFile): void {
    this.spinnerService.withLoadingIndicator(this.flowService.downloadFileFromSummaryUploadPage(this.page.sessionId, file.fileId), this.destroy$)
    .subscribe(response => {
      importedSaveAs(response.body, file.name);
    });

  }
}
