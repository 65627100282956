import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {ROUTING_PATHS} from '../../app-routing.module';
import {
  ModalResult,
  ModalVerticalSizeWindowClass,
  ModalWidth
} from '../../shared/components/ey-modal-template/ey-modal-result.enum';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {AutoLogoutPopupComponent} from '../components/auto-logout-popup/auto-logout-popup.component';
import { MsalTokenHelper } from '../authentication/msal-token-helper';
export const LS_LAST_ACTION_KEY = 'lastAction';
export const LS_USER_ID_KEY = 'user_id';

export const AUTO_LOGOUT_TIME_SPAN = 30 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class AutoLogoutService {
  isLogin = false;
  dialogActive = false;
  constructor(
    private tokenHelper: MsalTokenHelper,
    private router: Router,
    private ngZone: NgZone,
    private modalService: NgbModal) {

    if (this.isUserLoggedIn()) {
      this.isLogin = true;
    }
    this.lastAction(Date.now());
    this.check();
    this.initListener();
    this.initInterval();
  }

  getLastAction(): string {
    return localStorage.getItem(LS_LAST_ACTION_KEY);
  }

  lastAction(value): void {
    localStorage.setItem(LS_LAST_ACTION_KEY, JSON.stringify(value));
  }

  initListener(): void {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }

  initInterval(): void {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, 1000);
    });
  }

  reset(): void {
    this.lastAction(Date.now());
  }

  check(): void {
    const now = Date.now();
    const lastActionTime = this.getLastAction();
    if (lastActionTime) {
      const timeLeft = parseInt(lastActionTime) + AUTO_LOGOUT_TIME_SPAN;
      const diff = timeLeft - now;
      const isTimeout = diff < 0;
      this.ngZone.run(() => {
        if (isTimeout && this.isUserLoggedIn() && !this.dialogActive) {
            this.showDialog();
        }
      });
    }
  }

  showDialog(): void {
    this.dialogActive = true;
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: ModalWidth.default,
      windowClass: ModalVerticalSizeWindowClass.auto,
    };

    const modalRef = this.modalService.open(AutoLogoutPopupComponent, modalOptions);
    modalRef.result.then((result) => {
      this.dialogActive = false;
      if (result === ModalResult.submit) {
        sessionStorage.clear();
        localStorage.removeItem(LS_USER_ID_KEY);
        localStorage.removeItem(LS_LAST_ACTION_KEY);
        this.router.navigate([ROUTING_PATHS.logout], {queryParams: {auto: true}});
      } else {

        this.reset();
        return;
      }
    }, () => { this.reset();});
  }

  isUserLoggedIn(): string {
    return this.tokenHelper.getAccessToken();
  }
}
