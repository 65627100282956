import { Pipe, PipeTransform } from '@angular/core';
import {IAction} from '../../modules/grid-actions/action.model';
import {NavPlacement} from '../../modules/module.const';

@Pipe({
  name: 'gridActionPrimary',
  pure: true
})
export class GridActionPrimaryPipe implements PipeTransform {

  transform(actions: IAction[]): IAction[] {
    return actions.filter((a) => a.navPlacement ===  NavPlacement.primary);
  }

}
