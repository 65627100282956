<fieldset
  class="rbcontainer"
  [class.rbcontainer-active]="selectedValue == null || selectedValue == ''"
  [class.d-flex]="horizontal"
  role="radiogroup"
  aria-labelledby="pls select"
>
  <label
    class="rbcontainer__wrapper w-100 mb-0"
    [class]="{ active: this.selectedValue === val?.value, horizontal_wrapper: horizontal, vertical_wrapper: !horizontal }"
    *ngFor="let val of values; index as i"
    [for]="'radio_' + ctrl_id + '_' + i"
    [attr.title]="val.tooltip"
  >
    <label class="rbcontainer__label mb-0">
      <label class="fonts-col__heading-4 focusable" [for]="'radio_' + ctrl_id + '_' + i">{{ val.label }}</label>
      <input
        [attr.aria-label]="val.label"
        [tabindex]="i == 0 ? 0 : -1"
        [value]="val.value"
        [id]="'radio_' + ctrl_id + '_' + i"
        [name]="ctrl_id"
        type="radio"
        [(ngModel)]="selectedValue"
        (ngModelChange)="onSelectionChange($event)" />
      <span class="rbcontainer__checkmark" [class]="{ 'radio--error': showError }"></span
    ></label>
    <p class="ml-30 mt-0 mb-0" *ngFor="let des of val.description">{{ des }}</p>
  </label>
</fieldset>
<label *ngIf="showError" class="mt-10 textinput__errmsg">
  <i class="err_icon icon-ico_error_toast"></i>
  <span class="">{{ meta.errorMsg }}</span>
</label>
