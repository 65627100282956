export interface IUndoableState<T> {
    readonly past: Array<T>;
    readonly present: T;
    readonly future: Array<T>;
    readonly canUndo: boolean;
    readonly canRedo: boolean;
}

export class UndoableState<T> implements IUndoableState<T> {
    constructor(
            public readonly past: T[],
            public readonly present: T,
            public readonly future: T[]) {
        }
    get canUndo(): boolean {
        return this.past.length > 0;
    }

    get canRedo(): boolean {
        return this.future.length > 0;
    }
}
