import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Toast } from './ey-toast.component';

let toastId = 0;

@Injectable({
  providedIn: 'root'
})
export class EyToastService {
  private toastSbs: Subject<Toast> = new Subject<Toast>();
  public toast$ = this.toastSbs.asObservable();
  constructor() {
  }

  add(toast: Toast): string {
    toast.id = (toastId++).toString();
    this.toastSbs.next(toast);
    return toast.id;
  }

}
