<app-fluid-footer-template class="d-flex flex-fill">
    <ng-container appPageContent>
        <ey-froala-content-wrapper class="d-block mb-10" [editorContent]="html"></ey-froala-content-wrapper>
        <div class="mt-10 mb-20 d-flex">
            <ng-container *ngTemplateOutlet="option; context: { $implicit: 'Yes', option: yes}"></ng-container>
            <ng-container *ngTemplateOutlet="option; context: { $implicit: 'No', option: no}"></ng-container>
        </div>
        <div class="error-message mb-20" *ngIf="hasError">
          <label class="textinput__errmsg position"><i class="err_icon icon-ico_error_toast"></i>Select option to proceed.</label>
        </div>
    </ng-container>
    <ng-container appPageFooter>
        <div>
          <div class="d-flex">
            <ey-button class="ml-auto mr-20" [btnClass]="btnClass.btnPrev" text="Prev" (click)="onPrev()"></ey-button>
            <ey-button [btnClass]="btnClass.btnNext" text="Next" (click)="onNext()"></ey-button>
          </div>
          <div *ngIf="lastTechReviewDate && !isMobileBreakpoint">
            <span class="reviewDate spacing">Last Technical Review: {{lastTechReviewDate}}</span>
          </div>
        </div>
    </ng-container>
</app-fluid-footer-template>
<ng-template #option let-name let-option="option">
    <div
        class="option flex-fill d-flex align-items-center justify-content-center p-20 mb-10 mr-10"
        [class]="{ 'is-selected': isSelected(option), error: hasError }"
        (click)="onOptionClick(option)">
        <div class="option-name eyfontlight">{{name}}</div>
        <i *ngIf="!isSelected(option)" class="icon-ico_check emp-icon positioned-icon" ></i>
        <i *ngIf="isSelected(option)" class="icon-ico_success_toast success-icon positioned-icon" ></i>
    </div>
</ng-template>

