
<app-ey-app-spinner [showDraftsPopup]="showDraftsPopup"></app-ey-app-spinner>
<div appMouseEvents class="holder overflow-x-auto" [ngClass]="(currentPage?.moduleFlowPage?.isFooterFixed | conditionalClassContentViewer: selectedViewType: 'overflow-x-inherit': 'overflow-x-clip')"
     [class]="{mobile: (selectedViewType === viewTypes.mobile && flowType !== 'previewFlow'), tablet: (selectedViewType === viewTypes.tablet && flowType !== 'previewFlow'),
'mobile-preview': (selectedViewType === viewTypes.mobile && flowType === 'previewFlow'), 'tablet-preview': (selectedViewType === viewTypes.tablet && flowType === 'previewFlow')}">
  <div [ngClass]="(currentPage?.moduleFlowPage?.isFooterFixed | conditionalClassContentViewer: selectedViewType: 'overflow-x-inherit': 'overflow-x-clip')" [class]="{hint: currentPage?.moduleFlowPage?.hint}" class="d-flex flex-fill overflow-x-auto">
    <div class="content-wrapper flex-fill tabset-view" [class.hidden]="!showTabset" [class.displayFlex]="showTabset" [class.flex-important]= "selectedViewType === viewTypes.mobile && showTabset && flowType === 'previewFlow'">
      <div class="flex-fill d-flex" >
        <ey-tabset class="flex-fill" tabsetStyle="tetrary-reverse" [destroyOnHide]="false">
          <ey-tab title="Step">
            <ng-template eyTabContent>
              <ng-content *ngTemplateOutlet="content"></ng-content>
            </ng-template>
          </ey-tab>
          <ey-tab [class.hidden]="!currentPage?.moduleFlowPage?.hint" [class.displayFlex]="currentPage?.moduleFlowPage?.hint">
            <ng-template eyTabTitle>
              <i class="icon-ico_info_toast emp-icon" style="font-size: 1.2rem !important; vertical-align: middle;"></i>
              Hints
            </ng-template>
            <ng-template eyTabContent>
              <ng-content *ngTemplateOutlet="hint"></ng-content>
            </ng-template>
          </ey-tab>
        </ey-tabset>
      </div>
    </div>
    <div class="content-wrapper flex-fill regular-view" [class.hidden]="showTabset" [class.displayFlex]="!showTabset">
      <div class="content-container d-flex flex-fill">
        <ng-content *ngTemplateOutlet="content"></ng-content>
      </div>
      <div class="hint--container border-left" [class.hidden]="!currentPage?.moduleFlowPage?.hint" [class.displayBlock]="currentPage?.moduleFlowPage?.hint">
        <div class="border-bottom pv-10 ph-20 d-flex">
          <i class="icon-ico_info_toast emp-icon"></i>
          <h4 class="fonts-col__heading-4 flex-grow-1 mt-auto mb-auto ml-10">
            Hints
          </h4>
        </div>
        <div class="pv-10 ph-20">
          <ng-content *ngTemplateOutlet="hint"></ng-content>
        </div>
      </div>
    </div>
    <ng-template #content>
      <ng-container [collaborationMode]="collaborationMode" appDynamicContent [isMobileBreakPoint]="isMobileBreakpoint" [disableSubmitOnReview]="flowType === 'ReviewFlow' || flowType === 'ReviewDraftFlow'" [autoSaveResponse]="autoSaveResponse" [lastTechReviewDate]="lastTechReviewDate" [flowType]="flowType" [selectedViewType]="selectedViewType"></ng-container>
    </ng-template>
    <ng-template #hint>
      <app-hint class="d-block w-100" [class.p-20]="selectedViewType === viewTypes.mobile || isMobileBreakpoint" [hintHtml]="currentPage?.moduleFlowPage?.hint" [selectedViewType]="selectedViewType"></app-hint>
    </ng-template>
  </div>
</div>
