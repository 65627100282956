import { Injectable } from '@angular/core';
import {DownloadGeneratedDocumentsModalWindowInputDataInputData} from './download-generated-documents-modal-window-input-data.modal';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {
  ModalResult,
  ModalVerticalSizeWindowClass,
  ModalWidth
} from '../../shared/components/ey-modal-template/ey-modal-result.enum';
import {DownloadGeneratedDocumentsModalComponent} from './download-generated-documents-modal.component';
import {DEF_TEXTS, EyInfoDialogService} from '../../shared/components/ey-info-dialog/ey-info-dialog.service';
import {Subject} from 'rxjs/internal/Subject';
import {
  DOWNLOAD_GENERATED_TEMPLATES_TITLE,
  DRAFT_RESPONSE_TEMPLATE_DOWNLOAD_WARNING
} from '../../dashboard/my-responses/my-responses.component';
import {ButtonIconTypes, InfoDialogTypes} from '../../shared/components/ey-info-dialog/info-dialog-types.enum';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ERROR_DOWNLOADING_DOCUMENT_TITLE} from '../../designer/preview/submit-response/response.meta';
import {DRAFT_RESPONSE_STATUS} from '../../projects/project.const';
import {saveAs as importedSaveAs} from 'file-saver';
import {DRAFT} from '../../designer/preview/submit-response/response.service';
import {EyAppSpinnerService} from '../../shared/components/ey-app-spinner/ey-app-spinner.service';
import {TemplateFileModel} from './template-file.modal';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadGeneratedDocumentsService {
  private baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient, private modalService: NgbModal, private dialogService: EyInfoDialogService, private spinnerService: EyAppSpinnerService) { }

  // TODO(A.T) TASK[4491899]: Segregate all API calls to a common api.service

  getAllDownloadGeneratedDocuments(projectId: string): Observable<TemplateFileModel[]> {
    return this.httpClient.get<TemplateFileModel[]>(`${this.baseUrl}/project-file/${projectId}/all-templates`);
  }

  downloadResponseTemplates(responseId: string, templateType: number): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/documentpipeline/${responseId}/${templateType}`,
      {observe: 'response', responseType: 'blob' });
  }

  downloadMultipleTemplatesResponsePopup(projectId: string, templateType: number): Observable<TemplateFileModel[]> {
    return this.httpClient.get<TemplateFileModel[]>(`${this.baseUrl}/project-file/${projectId}/by-template/${templateType}`);
  }

  downloadMultipleResponseTemplates(responseId: string, templateType: number, docAutomationfileId: string): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/documentpipeline/${responseId}/${templateType}/${docAutomationfileId}`,
      {observe: 'response', responseType: 'blob' });
  }

  openDownloadGeneratedDocumentsModal(modalWindowInputData: DownloadGeneratedDocumentsModalWindowInputDataInputData): void {
    const downloadGeneratedDocumentsModal: NgbModalOptions = {
      backdrop: 'static',
      size: ModalWidth.default,
      windowClass: ModalVerticalSizeWindowClass.auto,
    };
    const modalRef = this.modalService.open(DownloadGeneratedDocumentsModalComponent, downloadGeneratedDocumentsModal);
    modalRef.componentInstance.downloadDocumentsBasedOnTemplateType = modalWindowInputData?.downloadDocumentsBasedOnTemplateType;
    modalRef.componentInstance.fileData = modalWindowInputData?.fileData;
    modalRef.componentInstance.responseId = modalWindowInputData?.responseId;
    modalRef.componentInstance.isResponseInDraftState = modalWindowInputData?.isResponseInDraftState;
    modalRef.componentInstance.projectId = modalWindowInputData?.projectId;
    modalRef.componentInstance.templateType = modalWindowInputData?.templateType;
    modalRef.result.then((result) => {
      if (result === ModalResult.dismiss) {
        return;
      }
    }, () => {});
  }

  showWarningForDraftResponseTemplateDownload(projectId: string, responseId: string, respondentName: string, templateType: number,
                                              responseDate: string, responseStatus: number, destroy: Subject<any>): void {
    this.dialogService
      .openInfoDialog( DRAFT_RESPONSE_TEMPLATE_DOWNLOAD_WARNING, DOWNLOAD_GENERATED_TEMPLATES_TITLE,
        InfoDialogTypes.warning, {...DEF_TEXTS, acceptWarningText: 'Acknowledge and Download', rejectWarningText: 'Cancel'}, ButtonIconTypes.download)
      .subscribe((result: ModalResult) => {
        if (result === ModalResult.submit) {
          this.downloadGeneratedTemplate(projectId, responseId, respondentName, templateType, responseDate, responseStatus, destroy);
        }
      });
  }

  downloadGeneratedTemplate(projectId: string, responseId: string, respondentName: string, templateType: number, responseDate: string, responseStatus: number, destroy: Subject<any>): void {
    const downloadFile$ = forkJoin([
      this.downloadMultipleTemplatesResponsePopup(projectId, templateType),
      this.downloadResponseTemplates(responseId, templateType)
        .pipe(
          catchError((err) => {
            const errorMessage = (err.status === 404 ? 'File not found' : 'Unknown') +
              ' error occured while downloading the document:<br>';
            return of({ isError: true, errorMessage });
          }))
    ]);
    this.spinnerService.withLoadingIndicator(downloadFile$ , destroy)
      .subscribe(([templateInfo, templateData]) => {
        if (templateData.isError) {
          const errorMessage = templateData.errorMessage + templateInfo[0].name;
          this.dialogService.openInfoDialog(errorMessage, ERROR_DOWNLOADING_DOCUMENT_TITLE, InfoDialogTypes.dismiss);
        } else {
          const d = isNaN(Date.parse(responseDate)) ? new Date() : new Date(responseDate);
          const dString: string = d.getDate().toString().padStart(2, '0') + (d.getMonth() + 1).toString().padStart(2, '0') + d.getFullYear();
          let fileName = templateInfo[0].name + '_' + respondentName + '_%TS%.' + templateInfo[0].extension;
          if (responseStatus === DRAFT_RESPONSE_STATUS) {
            fileName = DRAFT + fileName;
          }
          importedSaveAs(templateData.body, fileName.replace('%TS%', dString));
        }
      });
  }

  downloadSingleTemplate(response: any, fileData: TemplateFileModel, isResponseInDraftState: boolean = false): void {
    const dString = this.getDateString(fileData.dateCreated);
    const currentDateString = dString.split('/').join('');
    let fileName = fileData.name + '_' + fileData.createdBy + '_%TS%.' + fileData.extension;
    if (isResponseInDraftState) {
      fileName = DRAFT + fileName;
    }
    importedSaveAs(response.body, fileName.replace('%TS%', currentDateString));
  }

  getDateString(responseDate: string): string {
    const d = isNaN(Date.parse(responseDate)) ? new Date() : new Date(responseDate);
    const dString: string = d.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return dString;
  }

  checkResponseStatusAndDownloadTemplate(projectId: string, responseId: string, respondentName: string, templateType: number,
                                         responseDate: string, responseStatus: number, destroy: Subject<any>): void {
    (responseStatus === DRAFT_RESPONSE_STATUS) ?
      this.showWarningForDraftResponseTemplateDownload(projectId, responseId, respondentName, templateType, responseDate, responseStatus, destroy) :
      this.downloadGeneratedTemplate(projectId, responseId, respondentName, templateType, responseDate, responseStatus, destroy);
  }

  downloadTemplatesBasedOnIndex(response: any, index: number, filesToDownload: any, isResponseInDraftState: boolean = false): void {
    const fileToDownload = filesToDownload[index];
    const dString = this.getDateString(fileToDownload.dateCreated);
    const currentDateString = dString.split('/').join('');
    const fileName = fileToDownload.fileName.split('.');
    fileName.splice(1, 0, '_' + fileToDownload.createdBy + '_%TS%.' + filesToDownload[index].fileExtension);
    if (isResponseInDraftState) {
      fileName.unshift(DRAFT);
    }
    importedSaveAs(response.body, fileName.join('').replace('%TS%', currentDateString));
    return;
  }
}
