import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ey-attachments-wrapper',
  templateUrl: './attachments-wrapper.component.html',
  styleUrls: ['./attachments-wrapper.component.scss']
})
export class AttachmentsWrapperComponent implements OnInit {
  @Input() attachments: Array<string> = [];

  constructor() { }

  ngOnInit(): void {
  }
}
