<form autocomplete="noAutocomplete" [formGroup]="completeProfileForm" id="completeProfileForm">
  <ey-modal-template>
    <div header>
      <h2 class="modal__title" i18n>Create Your Profile</h2>
    </div>
    <div body>
      <ey-input class="d-block mb-20" formControlName="name" [meta]="fieldsMeta.name"></ey-input>
      <ey-input class="d-block mb-20" formControlName="email" [meta]="fieldsMeta.email"></ey-input>
      <ey-drop-down [positioningDropdownsUnderModalWindow]="true" class="d-block mb-20" formControlName="areaId" (ngModelChange)="onAreaChanged($event)" [meta]="fieldsMeta.areaId" [values]="areas"></ey-drop-down>
      <ey-drop-down [positioningDropdownsUnderModalWindow]="true" class="d-block mb-20" formControlName="countryId" [meta]="fieldsMeta.countryId" [values]="countries"></ey-drop-down>
      <ey-drop-down [positioningDropdownsUnderModalWindow]="true" class="d-block mb-20" formControlName="serviceLineId" (ngModelChange)="onSlChanged($event)" [meta]="fieldsMeta.serviceLineId" [values]="sls"></ey-drop-down>
      <ey-drop-down [positioningDropdownsUnderModalWindow]="true" class="d-block" formControlName="subServiceLineId" [meta]="fieldsMeta.subServiceLineId" [values]="subSls"></ey-drop-down>
    </div>
    <div footer class="d-flex w-100">
      <div class="d-flex w-100">
        <button class="btn mr-4 btn-border-color" type="submit" (click)="onCompleteProfile()"
                [ngClass]="'btn--primary'">
          Proceed
        </button>
        <button class="btn btn--secondary" type="button" (click)="onCancel()">Cancel</button>
      </div>
    </div>
  </ey-modal-template>
</form>
