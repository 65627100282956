
export const CATEGORY_PERCENT = 'Percentage';

export const CATEGORIES: DropdownItem[] = [
  { name: 'Number', value: 'Number'},
  { name: CATEGORY_PERCENT, value: CATEGORY_PERCENT }
];

export const DATE_FORMATS: DropdownItem[] = [
  { name: 'dd/mm/yyyy', value: 'dd/MM/yyyy'},
  { name: 'mm/dd/yyyy', value: 'MM/dd/yyyy' },
  { name: 'dd.mm.yyyy', value: 'dd.MM.yyyy' },
  { name: 'mm.dd.yyyy', value: 'MM.dd.yyyy' },
  { name: 'dd-mm-yyyy', value: 'dd-MM-yyyy' },
  { name: 'mm-dd-yyyy', value: 'MM-dd-yyyy' },
  { name: 'dd Month yyyy', value: 'dd MMMM yyyy' }
];
export const DECIMAL_SEPARATORS: DropdownItem[] = [
  { name: 'Dot', value: 'Dot'},
  { name: 'Comma', value: 'Comma'},
  { name: 'Blank Space', value: 'Blank Space'},
];
export const THOUSANDS_SEPARATORS: DropdownItem[] = [
  { name: 'Comma', value: 'Comma'},
  { name: 'Blank Space', value: 'Blank Space'},
  { name: 'Dot', value: 'Dot'},
];

export const ERROR_TEXT = '######';
export const ERROR_DATE = '#Wrong date format';


export enum SeparatorEnum {
  Dot = 1,
  Comma,
  'Blank Space'
}

export const SEPARATOR_FORMAT_HELPER = {
  Comma: ',',
  'Blank Space': ' ',
  Dot: '.'
};

export interface DropdownItem {
  name: string;
  value: any;
}

export enum MappingFieldTypes {
  text = 'Text',
  number = 'Number',
  date = 'Date'
}

