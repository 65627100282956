<app-fluid-footer-template>
    <ng-container appPageContent>
        <h2>Unknow page type</h2>
        <h3>Payload:</h3>
        <div>
            {{ page?.moduleFlowPage | json }}
        </div>
    </ng-container>
    <ng-container appPageFooter>
        <div class="d-flex justify-content-end">
            <button class="btn btn--secondary m-0 mr-4" (click)="onPrev()">
                <i class="icon-ico_shevron_left emp-icon button-icon"></i>
                Prev
            </button>
            <button class="btn btn--primary btn-border-color m-0" (click)="onNext()">
                Next
                <i class="icon-ico_shevron_right emp-icon m-0 button-icon"></i>
            </button>
        </div>
    </ng-container>
</app-fluid-footer-template>
