import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { HomeComponent } from './page-layout/home/home.component';
import { LogoutPageComponent } from './user/logout-page/logout-page.component';
import { PrivacyNoticeDeclinedComponent } from './user/privacy-notice-declined/privacy-notice-declined.component';
import { UserProfileNotCompletedComponent } from './user/user-profile-not-completed/user-profile-not-completed.component';
import { AccessDeniedComponent } from './user/access-denied/access-denied.component';
import { MsalGuard } from './core/authentication';
import { AdminGuard } from './admin/admin.guard';

export const ROUTING_PATHS = {
  projects: 'projects',
  designer: 'designer',
  admin: 'admin',
  designModule: 'design-module',
  privacyNoticeDeclined: 'privacy-notice-declined',
  profileNotCompleted: 'profile-not-completed',
  acceessDenied: 'access-denied',
  logout: 'logout'
};

const routes: Routes = [
  { // processing for legacy response urls, redirecting to the new url
    matcher: (u: UrlSegment[]) => {
      const legacyUrl = /\/render\/([0-9a-f-]+)\/?$/.exec(location.href);

      const isCurrentUrl = location.href.includes('design-module');

      if(!isCurrentUrl && legacyUrl && legacyUrl[1]) {
        const projectId = legacyUrl[1];

        return {
          consumed: u,
          posParams: {'taskId': new UrlSegment(projectId, {}) }
        };
      }

      return null;
    },
    redirectTo: '/' + ROUTING_PATHS.designModule + '/:taskId/submit-response',
    pathMatch: 'full',
    canActivate: [MsalGuard],
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(d => d.DashboardModule),
        canActivate: [MsalGuard],
        data: { title: 'Dashboard'}
      },
      {
        path: ROUTING_PATHS.projects,
        loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
        canActivate: [MsalGuard],
      },
      {
        path: ROUTING_PATHS.designer,
        loadChildren: () => import('./modules/modules.module').then(m => m.ModulesModule),
        canActivate: [MsalGuard],
      },
      {
        path: ROUTING_PATHS.admin,
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [MsalGuard, AdminGuard],
      },
    ]
  },
  {
      path: ROUTING_PATHS.designModule,
      loadChildren: () => import('./designer/designer.module').then(m => m.DesignerModule),
      canActivate: [MsalGuard],
  },
  {
    path: ROUTING_PATHS.privacyNoticeDeclined,
    component: PrivacyNoticeDeclinedComponent,
  },
  {
    path: ROUTING_PATHS.profileNotCompleted ,
    component: UserProfileNotCompletedComponent,
  },
  {
    component: AccessDeniedComponent,
    matcher: (u) => {
      if (u.length > 0 && u[0].path === ROUTING_PATHS.acceessDenied) {
        const posParams = u.length > 1
          ? { enrolmentStatus: u[1]}
          : null;

        return {consumed: u, posParams};
      }

      return null;
    }
  },
  {
    path: ROUTING_PATHS.logout + '/:auto',
    component: LogoutPageComponent,
  },
  {
    path: ROUTING_PATHS.logout,
    component: LogoutPageComponent,
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: isInIframe() ? 'disabled' : undefined,
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export function isInIframe(): boolean {
  return window !== window.parent && !window.opener;
}
