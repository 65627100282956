import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import {catchError, delay, map, switchMap, tap} from 'rxjs/operators';
import { Page } from './page.model';
import { formatCellContent } from '../builders/table-builder/table-builder.functions';
import {DatePipe} from '@angular/common';
import { NumberFormatService } from '../builders/table-builder/table/number-format.service';
import { TableBuilderControlTypes } from '../builders/table-builder/controls/controls.meta';
import { IOption } from '../workflow-designer/properties-editor/dynamic-controls/scores-control/scores-control.component';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { PropertyInput } from './property-input.model';
import {SaveNotificationServiceEndUserForm} from './save-notification-service-end-user-form.service';
import { SummaryDocumentModel } from './summary-document.model';
import { QRMApproval, QRMTypes } from '../../admin/manage-approval-workflow/manage-approval-workflow.model';
import {Subject} from 'rxjs/internal/Subject';
import {FormBuilderControlTypes} from '../builders/form-builder/form-builder-control.types';
import {InfoDialogTypes} from '../../shared/components/ey-info-dialog/info-dialog-types.enum';
import {InfoDialogOptions} from '../../shared/components/ey-info-dialog/ey-info-dialog.model';
import {EyInfoDialogService} from '../../shared/components/ey-info-dialog/ey-info-dialog.service';
import { MailAttachmentInfo } from './view-response-attachments/view-response-attachments.model';
import { SummaryPdfNameComponents } from './page-content/response-summary/response-summary.model';

export const TABLE_TYPE = 'TABLE';
export const FORM_TYPE = 'FORM';
export const START_TYPE = 'START';
export const END_TYPE = 'END';

export enum  FlowType {
  preview = 'previewFlow',
  response = 'ResponseFlow',
  review = 'ReviewFlow',
  reviewDraft = 'ReviewDraftFlow'
}

const RESPONSE_LOCKED_ON_START_COLLABORATION_MODE_DATA = {
  dialogType: InfoDialogTypes.dismiss,
  msg: 'You have started editing response on collaboration mode. Others cannot edit this response until you unlock it.',
  title: 'Locking Response',
};

@Injectable({
  providedIn: 'root',
})
export class ModuleFlowService {
  disableScrollToTopOnToolValueChanges = false;
  summaryPageProgressCount = new Subject<number>();
  responseHeaderMobileViewChange = new Subject<boolean>();
  progressBarSteps = new Subject<number>();
  public collaborationResponseStatusLocked = new Subject<boolean>();
  public hideSaveAndProgressBarOnErrorFlowPage = new Subject<boolean>();
  public currentPage$ = new ReplaySubject<Page>(1);

  private baseUrl = environment.baseUrl;
  private currentPage: Page = null;

  constructor(private httpClient: HttpClient, private dPipe: DatePipe,
              private numberFormatService: NumberFormatService,
              private saveNotificationServiceEndUserForm: SaveNotificationServiceEndUserForm,
              private dialogService: EyInfoDialogService) {}

  doNext(properties?: any, flowType = FlowType.preview, delayMiliseconds: number = 300): Observable<Page> {
    const id = this.currentPage.sessionId;
    
    return of(null).pipe(
      delay(delayMiliseconds),
      switchMap(() => properties 
        ? this.httpClient.post<Page>(`${this.baseUrl}/${flowType}/${id}/next`, properties)
        : this.httpClient.post<Page>(`${this.baseUrl}/${flowType}/${id}/next`, [])
      ),
      tap((p) => this.setCurrentPage(p))
    );
  }

  doPrev(properties?: any, flowType = FlowType.preview, delayMiliseconds: number = 300): Observable<Page> {
    const id = this.currentPage.sessionId;

    return of(null).pipe(
      delay(delayMiliseconds),
      switchMap(() => properties 
        ? this.httpClient.post<Page>(`${this.baseUrl}/${flowType}/${id}/prev`, properties)
        : this.httpClient.post<Page>(`${this.baseUrl}/${flowType}/${id}/prev`, [])
      ),
      tap((p) => this.setCurrentPage(p))
    );
  }

  recalculateCurrent(pageId: string, properties: PropertyInput[], flowType = FlowType.preview): Observable<Page> {
    const sessionId = this.currentPage.sessionId;

    return this.httpClient.post<Page>(`${this.baseUrl}/${flowType}/${sessionId}/recalculate-current/${pageId}`, properties);
  }

  saveCurrent(pageId: string, properties: PropertyInput[] = []): Observable<Page> {
    const sessionId = this.currentPage.sessionId;
    this.saveNotificationServiceEndUserForm.dispatchIsSaving();
    return this.httpClient.post<Page>(`${this.baseUrl}/${FlowType.response}/${sessionId}/save-current/${pageId}`, properties)
      .pipe(tap(() => this.saveNotificationServiceEndUserForm.dispatchIsSaved()));
  }

  initPreviewFlow(versionId: string): Observable<Page> {
    return this.httpClient.post<Page>(`${this.baseUrl}/${FlowType.preview}/init/${versionId}`, {})
      .pipe(tap((p) => this.setCurrentPage(p)));
  }

  initReviewFlow(taskId: string, responseId: string): Observable<Page> {
    return this.httpClient.post<Page>(`${this.baseUrl}/${FlowType.review}/init/${taskId}/${responseId}`, {})
      .pipe(tap((p) => this.setCurrentPage(p)));
  }

  initReviewDraftFlow(taskId: string, responseId: string): Observable<Page> {
    return this.httpClient.post<Page>(`${this.baseUrl}/${FlowType.reviewDraft}/init/${taskId}/${responseId}`, {})
      .pipe(tap((x) => this.setCurrentPage(x)));
  }

  initResponseFlow(taskId: string, responseId: string = null): Observable<Page> {
    return this.httpClient.post<Page>(`${this.baseUrl}/${FlowType.response}/init/${taskId}/${responseId}`, {})
      .pipe(
        tap((p) => this.setCurrentPage(p)),
        catchError((e, caught) => { this.currentPage$.next(null); return of(null); }));
  }

  completeReview(): Observable<boolean> {
    const id = this.currentPage.sessionId;

    return this.httpClient.post<boolean>(`${this.baseUrl}/${FlowType.review}/${id}/complete-review`, null);
  }

  initApprovalWorkflow(qrmType: QRMTypes, responseId: string, targetVersionId: string, targetProjectId: string): Observable<boolean> {
    const qrmApproval: QRMApproval = { qrmTypeId: qrmType, responseId, targetVersionId, targetProjectId };

    return this.httpClient.post<boolean>(`${this.baseUrl}/${FlowType.response}/init-approval-workflow`, qrmApproval);
  }

  submitOnComplete(flowType = FlowType.preview): Observable<any> {
    const id = this.currentPage.sessionId;
    return this.httpClient.post<any>(`${this.baseUrl}/${flowType}/${id}/submit`, {});
  }

  getSummary(responseId: string = null): Observable<Page[]> {
    const id = responseId !== null ? responseId : this.currentPage.sessionId;
    return this.httpClient.get<Page[]>(`${this.baseUrl}/${FlowType.response}/${id}/summary`);
  }

  getAttachmentsInfo(responseId: string, mailNotificationId:string): Observable<MailAttachmentInfo[]> {
    return this.httpClient.get<MailAttachmentInfo[]>(`${this.baseUrl}/${FlowType.response}/attachments-info/${responseId}/${mailNotificationId}`);
  }

  getDocList(responseId: string = null): Observable<SummaryDocumentModel[]> {
    const id = responseId !== null ? responseId : this.currentPage.sessionId;
    return this.httpClient.get<SummaryDocumentModel[]>(`${this.baseUrl}/${FlowType.response}/${id}/documents`)
      .pipe(map(x => x.map(item => ({...item, sessionId: id}) as SummaryDocumentModel)));
  }

  downloadFileFromSummaryUploadPage(responseId: string, fileId: string): Observable<any> {
    const id = responseId !== null ? responseId : this.currentPage.sessionId;

    return this.httpClient.get(`${this.baseUrl}/${FlowType.response}/${id}/uploaded-file/${fileId}`,
      {observe: 'response', responseType: 'blob' });
  }

  getSummaryPdfComponents(responseId: string): Observable<SummaryPdfNameComponents> {
    return this.httpClient.get<SummaryPdfNameComponents>(`${this.baseUrl}/ResponseFlow/summary-pdf-name-components/${responseId}`);
  }

  setCurrentPage(page: Page): void {
    if (page) {
      this.progressBarSteps.next(page?.progressBarPercentage);
      this.transformCurrentPageData(page);
      this.currentPage = page;
      this.currentPage$.next(page);
    }
  }

  transformCurrentPageData(page: Page): void {
    this.transformDataForTableTool(page);
    this.transformDataForFormTool(page);
    this.transformDataForStartTool(page);
    this.transformDataForEndTool(page);
  }

  transformDataForTableTool(page: Page): Page {
    if (page.moduleFlowPage.type !== TABLE_TYPE) {
      return;
    }
    const ch = page.moduleFlowPage.children;
    return page.moduleFlowPage.children = ch
      ? this.mapTableChildren(ch)
      : null;
  }

  transformDataForFormTool(page: Page): Page {
    if (page.moduleFlowPage.type !== FORM_TYPE) {
      return;
    }
    return page.moduleFlowPage.properties = page.moduleFlowPage.properties.map((p) => this.transformFormProperty(p));
  }

  transformDataForStartTool(page: Page): Page {
    if (page.moduleFlowPage.type !== START_TYPE) {
      return;
    }
    page.moduleFlowPage.isFooterFixed = true;
    return page;
  }

  transformDataForEndTool(page: Page): Page {
    if (page.moduleFlowPage.type !== END_TYPE) {
      return;
    }
    page.moduleFlowPage.isFooterFixed = true;
    return page;
  }

  resetCurrentFlow(): void {
    this.currentPage$.complete();
    this.currentPage$ = new ReplaySubject<Page>(1);
  }

  mapTableChildren(children: any): any {
      return children.map((ca) =>
        ca.map((c) => {
          return {
            ...c,
            formattedControlContent: this.getTableFormattedControlContent(c),
            inputValue: null,
          };
        })
      );
  }

  getTableFormattedControlContent(cell: any): any {
    if (cell.type === TableBuilderControlTypes.dropdown) {
      return cell.properties.options.find((o: IOption) => o.conId === cell.value)
    }

    return formatCellContent({ ...cell, type: this.translateTableTypes(cell.type)}, this.numberFormatService, this.dPipe, true);
  }

  translateTableTypes(type: TableBuilderControlTypes): TableBuilderControlTypes {
    switch (type) {
      case TableBuilderControlTypes.numberInput:
        return TableBuilderControlTypes.number;
      default:
        return type;
    }
  }

  transformFormProperty(p: any): any {
    switch (p.type as FormBuilderControlTypes) {
      case FormBuilderControlTypes.dropDown:
        {
          p.value = p.options.find((o) => (o.id === p.value));
        }
        break;
      case FormBuilderControlTypes.date:
        {
          if (p.value) {
            const vals = p.value.split('T')[0].split('-');
            const d: NgbDateStruct =  {day: +(vals[2]) , month: +(vals[1]) , year: +(vals[0])};
            p.value = d;
          }
        }
        break;
      case FormBuilderControlTypes.eyEmailLookup:
        p.value = JSON.parse(p.value);
        break;
    }
    return p;
  }

  showLockedResponseMessageOnStartFlow(): void {
    const infoDialogOptions = this.getLockedResponseMessageOnStartFlowMsgs();
    this.dialogService
      .openInfoDialog(infoDialogOptions.msg, infoDialogOptions.title, infoDialogOptions.dialogType)
      .subscribe((result) => {
        return;
      });
  }

  getLockedResponseMessageOnStartFlowMsgs(): InfoDialogOptions {
    return RESPONSE_LOCKED_ON_START_COLLABORATION_MODE_DATA;
  }
}
