export enum DraftModalAction {
  createNewResponse,
  resumeDraft
}

export class DraftModalResult {
  constructor(
    public draftModalAction: DraftModalAction,
    public responseId?: string) { }
}
