import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';
import * as _ from 'lodash';

@Component({
  selector: 'ey-drop-down-small',
  templateUrl: './ey-drop-down-small.component.html',
  styleUrls: ['./ey-drop-down-small.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyDropDownSmallComponent),
      multi: true,
    }
  ]
})
export class EyDropDownSmallComponent extends EyBaseFormControlComponent implements ControlValueAccessor {
  @Input() labelName = 'name';
  @Input() set values(val) {
      this._values = val;
      this.filteredValues = val;
  }

  @Input() set defaultValue(val) {
    this.hasDefaultValue = true;
    this.valSelected(val);
  }
  hasDefaultValue = false;

  _values: any[] = [];
  _selectedValue: any = {};
  valueSelected = false;
  filteredValues: any[] = [];
  @Input() dropUp = false;
  @Input() noInfo = false;
  @Output() onType: EventEmitter<string> = new EventEmitter<string>();
  @Output() onValueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() filteringDisabled = false;
  isExpanded = false;

  constructor(private eRef: ElementRef, private controlContainer: ControlContainer) {
  super(controlContainer);
  }

  @HostListener('document:click', ['$event'])
  clickOut(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isExpanded = false;
    }
  }

  filter(val): void {
    if (val) {
      this.onType.emit(val);
      if (!this.filteringDisabled) {
        const startsWith = this._values.filter(v => v[this.labelName].search(new RegExp('^' + val, 'i')) !== -1)
        let containsList = this._values.filter(v => v[this.labelName].search(new RegExp(val, 'i')) !== -1);
        containsList = containsList.filter(e => !startsWith.includes(e));
        this.filteredValues = [...startsWith, ...containsList];
      }
      this.isExpanded = true;
    } else {
      this.filteredValues = this._values;
    }
  }

  toggleExpand(): void {
    if ((this.valueSelected && !this.hasDefaultValue) || this.isDisabled){
      return;
    }
    this.isExpanded = !this.isExpanded;
    //this.onChange(this.selectedValue);
    //this.onTouched(this.selectedValue);
  }
  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  set selectedValue(val) {
    this._selectedValue = val;
    this.onChange(val);
    this.onTouched(val);
  }

  get selectedValue(): any {
    return this._selectedValue;
  }

  writeValue(initValue: any): void {
    this._selectedValue = initValue || [];
    this.valueSelected = initValue != null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  valSelected(v: any): void {
    this.isExpanded = false;
    this.selectedValue = v;
    this.valueSelected = true;

    if (this.hasDefaultValue) {
      this.onTouched(this.selectedValue);
      this.onChange(this.selectedValue);
      this.onValueChanged.emit(this.selectedValue);
    }
  }

  getSelectedValue(): any {
    return this.selectedValue == null ? null : this.selectedValue[this.labelName];
  }

  blur(): void {
    this.onTouched(this.selectedValue);
    this.onChange(this.selectedValue);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}

