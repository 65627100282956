import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionTypes } from '../module-actions.dictionary';
import { ActionTypes as VersionActionTypes } from '../version/version-action.dictionary';
import { IAction } from './action.model';
import { DropDownParentViewTypes } from '../../shared/components/ey-drop-down/ey-drop-down.enum'

export interface  IActionEventArgs {
  id: string;
  actionType: ActionTypes | VersionActionTypes;
}

export const SHOW_CANVAS_FLOW_TEXT = 'Designer';
export const SHOW_PREVIEW_TEXT = 'Preview';
export const UNKNOWN_ACTION_TYPE_TEXT = 'UNKNOWN';
export const SHOW_REVIEW_TEXT = 'Review';
export const SHOW_VIEW_TEXT = 'View';


@Component({
  selector: 'app-grid-actions',
  templateUrl: './grid-actions.component.html',
  styleUrls: ['./grid-actions.component.scss']
})
export class GridActionsComponent implements OnInit {
  @Input() id: string;
  @Input() lightTable = false;
  @Input() actions: IAction[] = [];
  @Output() actionSelect: EventEmitter<IActionEventArgs> = new EventEmitter<IActionEventArgs>();
  @Input() cardView = false;
  @Input() parentViewFlag: DropDownParentViewTypes = DropDownParentViewTypes.grid;

  constructor() { }

  ngOnInit(): void {

  }

  onActionClick(selActionType: ActionTypes): void {
    const actionEventArgs: IActionEventArgs = {
      id: this.id,
      actionType: selActionType
    }
    this.actionSelect.emit(actionEventArgs);
  }
}
