import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortDirection } from '../models/sort-direction.model';

export interface ISortEventArgs {
  column: string;
  direction: SortDirection;
}


@Component({
  selector: 'ey-grid-sort',
  templateUrl: './ey-grid-sort.component.html',
  styleUrls: ['./ey-grid-sort.component.scss']
})
export class EyGridSortComponent implements OnInit {
  @Input() column: string;
  @Input() asc = SortDirection.ASC;
  @Input() des = SortDirection.DES;
  @Input() toolTipContent: string;
  @Output() sort: EventEmitter<ISortEventArgs> = new EventEmitter<ISortEventArgs>();
  constructor() { }

  ngOnInit(): void {
  }

  onSort(column: string, dir: SortDirection): void {
    this.sort.emit({ column: column, direction: dir });
  }



}
