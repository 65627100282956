import {Component, ElementRef, forwardRef, OnInit} from '@angular/core';
import {TableBuilderFormBaseComponent} from '../table-builder-form-base.component';
import {FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DES_EDITOR_META} from '../../../form-builder/controls/description/description-editor/description-editor.meta';

@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormulaComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormulaComponent),
      multi: true
    }]
})
export class FormulaComponent extends TableBuilderFormBaseComponent {

  form: FormGroup = this.fbs.group(
    {
    },
    { updateOn: 'blur' }
  );
  meta = DES_EDITOR_META; // TODO(mk): TO BE REPLACED WITH FORM SPECIFIC META

  constructor(private fbs: FormBuilder,  private els: ElementRef) {
    super(fbs, els);
  }

}
