import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {EyBaseFormControlComponent} from '../ey-base-form-control/ey-base-form-control';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {IEyRadioGroupModel} from '../ey-radio-group/ey-radio-group.model';

export enum EyRadioGroupOptionsPosition {
  left = 'justify-content-start',
  right = 'justify-content-end',
  center = 'justify-content-center',
}

@Component({
  selector: 'ey-radio-group-plain',
  templateUrl: './ey-radio-group-plain.component.html',
  styleUrls: ['./ey-radio-group-plain.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyRadioGroupPlainComponent),
      multi: true,
    }
  ]
})
export class EyRadioGroupPlainComponent extends EyBaseFormControlComponent implements ControlValueAccessor {
  @Input() values: { id: string, text: string }[] = [];
  @Input() thinLabels = false;
  @Input() horizontal = false;
  @Input() narrowOptions = false;
  @Input() optionPosition = EyRadioGroupOptionsPosition.left;

  @Output() blur = new EventEmitter<any>();
  _selectedValue: any;

  constructor(private eRef: ElementRef, private controlContainer: ControlContainer) {
    super(controlContainer);
  }
  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  onBlur(): void {
    this.onTouched(this.selectedValue);
    this.onChange(this.selectedValue);
    this.blur.emit(this.selectedValue);
  }

  set selectedValue(val) {
    this._selectedValue = val;
    this.onBlur();
  }

  get selectedValue(): any {
    return this._selectedValue;
  }

  get containerClass(): any {
    const klass = {};
    klass[this.optionPosition] = this.narrowOptions;

    return klass;
  }

  writeValue(initValue: any): void {
    this._selectedValue = initValue || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelectionChange(val: any): void {
    this.selectedValue = val;
  }

  isSelected(val: any): boolean {
    return JSON.stringify(this._selectedValue) === JSON.stringify(val);
  }
}
