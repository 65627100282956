import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TableData } from '../shared/components/ey-grid/models/table-data.model';
import { Task } from './my-task-card/task.model';
import {GridState} from '../shared/components/ey-grid/models/grid-state.model';
import {ProjectCard} from './my-projects-card/project-card.model';
import {CollaborationResponseStatuses, ResponseCard, ResponseStatuses} from './my-responses-card/response-card.model';
import {ResponseInfo} from './my-responses/response-info.model';
import { SortDirection } from '../shared/components/ey-grid/models/sort-direction.model';
import {UNKNOWN_RESPONSE_STATUS} from '../projects/responses/responses.const';

@Injectable()
export class DashboardService {
  private baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  getTasks(gridState: GridState): Observable<TableData<Task>> {
      return this.httpClient.get<TableData<Task>>(`${this.baseUrl}/dashboard/my-tasks`, { params: {
        pageSize: gridState.pageSize.toString(),
        sortBy: gridState.sortBy.toString(),
        sortDirection: gridState.sortDirection.toString(),
        pageNumber: gridState.pageNumber.toString(),
        search: gridState.search.toString()
      }});
  }

  getMostRecentProjects(): Observable<ProjectCard[]> {
    return this.httpClient.get<TableData<ProjectCard>>(`${this.baseUrl}/dashboard/my-most-recent-projects`, { params : {
        pageNumber: '1',
        pageSize: '4',
        sortDirection: SortDirection.DES.toString(),
        sortBy: 'responseDateCreated'
    }}).pipe(map(d => d.data));
  }

  getProjectsCount(): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}/dashboard/my-projects-count`);
  }

  getResponsesTableData(gridState: GridState): Observable<TableData<ResponseInfo>> {
    return this.httpClient.post<TableData<ResponseInfo>>(`${this.baseUrl}/dashboard/my-responses-list`, {
        pageSize: gridState.pageSize.toString(),
        sortBy: gridState.sortBy.toString(),
        sortDirection: gridState.sortDirection.toString(),
        pageNumber: gridState.pageNumber.toString(),
        activeFilters : gridState.activeFilters
      }).pipe(map(d => {
        d.data.forEach(res => {
          res.statusPills = this.getResponsePillStatus(res.responseStatus, res.isCollaborationLocked);
        });
        return d;
    }));
  }

  getResponsePillStatus(statusId: number, isCollaborationLocked: boolean = null): any {
    const status = ResponseStatuses.find((s) => s.id === statusId);
    if (isCollaborationLocked !== null && status.labelText !== 'Completed') {
      const collaborationModeStatus = CollaborationResponseStatuses.find((s) => s.isLocked === isCollaborationLocked);
      return collaborationModeStatus !== undefined ? collaborationModeStatus : UNKNOWN_RESPONSE_STATUS;
    }
    return status !== undefined ? status : UNKNOWN_RESPONSE_STATUS;
  }

  getResponsesUniqueProjectsList(): Observable<ResponseInfo[]> {
    return this.httpClient.get<ResponseInfo[]>(`${this.baseUrl}/dashboard/responses-unique-project-list`);
  }

  getResponsesCount(): Observable<number> {
    return this.httpClient.get<number>(`${this.baseUrl}/dashboard/my-responses-count`);
  }

  getMostRecentResponses(): Observable<ResponseCard[]> {
    return this.httpClient.get<TableData<ResponseCard>>(`${this.baseUrl}/dashboard/my-responses`, { params: {
        pageNumber: '1',
        pageSize: '4',
        sortDirection: SortDirection.DES.toString(),
        sortBy: 'dateCreated'
      }}).pipe(map(d => d.data));
  }

  getTopTasks(): Observable<TableData<Task>> {
    return this.httpClient.get<TableData<Task>>(`${this.baseUrl}/dashboard/my-tasks`, { params: {
      pageNumber: '1',
      pageSize: '4',
      sortDirection: SortDirection.DES.toString(),
      sortBy: 'dateCreated'
    }});
  }
}
