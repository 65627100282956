import {Component, ElementRef, forwardRef, Input, OnInit} from '@angular/core';
import {TableBuilderFormBaseComponent} from '../table-builder-form-base.component';
import {
  AbstractControl,
  FormBuilder, FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import {MappingFieldTypes} from '../controls.const';
import {MappingField} from 'src/app/modules/version/version-mapping-data.model';
import {MappingFieldUtilService} from 'src/app/designer/designer-services/mapping-field-util.service';


const META = {
  source: {
    title: 'Source',
    required: true,
  }
};

@Component({
  selector: 'app-map-field',
  templateUrl: './map-field.component.html',
  styleUrls: ['./map-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MapFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MapFieldComponent),
      multi: true
    }]
})
export class MapFieldComponent extends TableBuilderFormBaseComponent implements OnInit {
  @Input() mapFields: MappingField[] = [];
  numberFormatting: any;
  dateFormatting: any;
  form: FormGroup = this.fbs.group(
    {
      source: [, ],
      properties: [{}]
    },
    { updateOn: 'blur' }
  );
  meta = META;

  constructor(private fbs: FormBuilder,  private els: ElementRef, private mappingFieldUtilService: MappingFieldUtilService) {
    super(fbs, els);
  }

  get sourceCtrl(): FormControl {
    return this.form.get('source') as FormControl;
  }

  get sourceCtrlType(): string {
    return ((this.sourceCtrl.value) as MappingField)?.type;
  }

  get isNumber(): boolean {
    return this.sourceCtrlType === MappingFieldTypes.number;
  }

  get isDate(): boolean {
    return this.sourceCtrlType === MappingFieldTypes.date;
  }

  ngOnInit(): void {
    this.mapFields = this.mappingFieldUtilService.filterMapFieldForTableBuilder(this.mapFields);
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges(): void {
    this.form.valueChanges.subscribe(v => {
      this.onChange(this.convertToReturnValue(v));
    });

    this.form.statusChanges.subscribe(this.onValidatorChange);

    this.onChange(this.convertToReturnValue(this.form.value));
  }

  convertToReturnValue(value: any): any {
    let formatProps = {};
    if (this.isNumber) {
      formatProps = this.numberFormatting;
    }
    if (this.isDate) {
      formatProps = this.dateFormatting;
    }
    return {
      source: value.source,
      ...formatProps
    };
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { subformerror: 'Number Input Editor Form Error!' };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  onChange: (val: any) => void = () => {};
  onValidatorChange: () => void = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  triggerUpdate(): void {
    this.onChange(this.convertToReturnValue(this.form.value));
  }

  writeValue(val: any): void {
    if (val) {
      if (val.source != null && val.source.type === MappingFieldTypes.date) {
        this.dateFormatting = val;
      }
      if (val.source != null && val.source.type === MappingFieldTypes.number) {
        this.numberFormatting = val;
      }
      const patchValue = val.source
        ? { source: val.source }
        : val;
      this.form.patchValue(patchValue);
      this.subscribeToFormChanges();
    }
  }

}
