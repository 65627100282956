import {Pipe, PipeTransform} from '@angular/core';
import {ViewTypes} from '../page.model';

@Pipe({
  name: 'conditionalClassContentViewer'
})
export class ConditionalClassContentViewerPipe implements PipeTransform {

  transform(isPageFooterFixed: boolean, selectedViewType: ViewTypes, overflowInheritClass: string, overflowClipClass: string): string {
    const isDesktopView = selectedViewType === ViewTypes.desktop;
    return isPageFooterFixed ? (isDesktopView ? overflowInheritClass : overflowClipClass) : null;  }

}
