import { Injectable } from '@angular/core';
import {TableBuilderControlTypes} from '../controls/controls.meta';
import {CATEGORY_PERCENT, ERROR_TEXT, SEPARATOR_FORMAT_HELPER} from '../controls/controls.const';
import {isCalcFormula} from '../table-builder.functions';

@Injectable({
  providedIn: 'root'
})
export class NumberFormatService {

  constructor() { }
  transform(val: any, type: TableBuilderControlTypes, prop: any): any {
    if (!type || !prop) {
      return val;
    }
    if (type === TableBuilderControlTypes.number && isCalcFormula(val) == null) {
      if (val == null || val === undefined) {
        return '';
      }

      if (isNaN(+val) || val?.toString().includes('e') ) {
        return ERROR_TEXT;
      }
      return this.formatNumber(+val, prop) ;
    }
    return val;
  }

  formatNumber(val: number, prop: any, summaryView = false): string {
    if (String(val).length === 0 && summaryView) {
      return;
    }
    let retVal: any = this.round(+val, prop.decimalPlaces);
    retVal = this.decimalSeparator(retVal, SEPARATOR_FORMAT_HELPER[prop.decimalSeparator]);
    if (prop.useThousandsSeparator && prop.thousandsSeparator) {
      retVal =  this.thousandSeparator(retVal, SEPARATOR_FORMAT_HELPER[prop.thousandsSeparator], SEPARATOR_FORMAT_HELPER[prop.decimalSeparator]);
    }

    retVal = this.addDecimalPlaces(retVal, prop.decimalPlaces, SEPARATOR_FORMAT_HELPER[prop.decimalSeparator]);
    if (prop.category === CATEGORY_PERCENT) {
      return retVal.toString() + '%';
    }
    return retVal;
  }

  formatTableCellNumber(val: number, prop: any, summaryView = false): string {
    if (String(val).length === 0 && summaryView) {
      return;
    }
    let retVal: any = this.round(+val, prop.properties.decimalPlaces);
    retVal = this.decimalSeparator(retVal, SEPARATOR_FORMAT_HELPER[prop.properties.decimalSeparator]);
    if (prop.properties.useThousandsSeparator && prop.properties.thousandsSeparator) {
      retVal =  this.thousandSeparator(retVal, SEPARATOR_FORMAT_HELPER[prop.properties.thousandsSeparator], SEPARATOR_FORMAT_HELPER[prop.properties.decimalSeparator]);
    }

    retVal = this.addDecimalPlaces(retVal, prop.properties.decimalPlaces, SEPARATOR_FORMAT_HELPER[prop.properties.decimalSeparator]);
    if (prop.category === CATEGORY_PERCENT) {
      return retVal.toString() + '%';
    }
    return retVal;
  }

  addDecimalPlaces(val: string, decimalPlaces: number,  decimalSeparator: string): string {
    let ctr = 0;
    if (val.indexOf(decimalSeparator) === -1) {
      if (decimalPlaces > 0) {
        val = val + decimalSeparator;
      }
      ctr = +decimalPlaces;
    } else if (val.split(decimalSeparator)[1].length !== (+decimalPlaces)) {
      ctr = (+decimalPlaces) - val.split(decimalSeparator)[1].length;
    }
    for (ctr; ctr--; ctr > 0) {
      val += '0';
    }
    return val;
  }

  round(num: number, decimalPlaces: number): number {
    return +(num.toFixed(decimalPlaces));
  }

  thousandSeparator(val: string, thSeparator: string, decimalSeparator: string): string {
    const parts = val.toString().split(decimalSeparator);
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thSeparator);
    return parts.join(decimalSeparator);
  }

  decimalSeparator(val: number, decSep: string): string {
    return val.toString().replace('.', decSep);
  }

  systemNumberFormat(val: number, decimalPlacesToBeRoundedOf = 0): string {
    const fieldProperties = {
      category: 'Number',
      useThousandsSeparator: true,
      thousandsSeparator: 'Comma',
      decimalSeparator: 'Dot',
      decimalPlaces: decimalPlacesToBeRoundedOf,
    };

    return this.formatNumber(val, fieldProperties);
  }

  systemNumberConversion(val: number, decimalPlacesToBeRoundedOf = 0): string {
    if (decimalPlacesToBeRoundedOf === 0) {
      if (val < 10000) {
        return this.systemNumberFormat(val);
      }
      if (val >= 1000000) {
        return (Math.trunc(val / 100000)).toString() + 'm+';
      }
      if (val >= 10000) {
        return (Math.trunc(val / 1000)).toString() + 'k+';
      }
    } else {
      if (val < 10000) {
        return this.systemNumberFormat(val, decimalPlacesToBeRoundedOf);
      }
      if (val >= 1000000) {
        return this.roundOfToSpecifiedDecimalPlaces((val / 100000), decimalPlacesToBeRoundedOf) + 'm+';
      }
      if (val >= 10000) {
        return this.roundOfToSpecifiedDecimalPlaces((val / 1000), decimalPlacesToBeRoundedOf) + 'k+';
      }
    }
  }

  roundOfToSpecifiedDecimalPlaces(value, places): string {
    const multiplier = Math.pow(10, places);
    return (Math.round(value * multiplier) / multiplier).toString();
  }
}
