import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {RESPONSE_DUPLICATION_ALLOWED, RESPONSE_DUPLICATION_FORBIDDEN, ResponseStatus} from './responses.const';
import {NavPlacement, ProjectStatus, ProjectTabs} from '../project.const';
import {ActionTypes, RESPONSE_ACTIONS, ViewType} from './responses-action.dictionary';
import {IAction} from '../grid-actions/action.model';
import {BasePermissionsService} from '../base-permissions.service';
import {
  BULK_DOWNLOAD_RESPONSE_ACTIONS,
  BulkDownloadActionTypes
} from '../manage-project/bulk-download-responses/bulk-download-responses.action.dictionary';
import {DownloadStatuses} from '../manage-project/bulk-download-responses/bulk-download-responses.const';
import { DUPLICATE_RESPONSE_MSG } from 'src/app/dashboard/my-responses-card/my-responses-card.component';
import { ResponseActionsPermissions } from './response-permissions.const';

@Injectable({
  providedIn: 'root',
})
export class ResponsesPermissionsService extends BasePermissionsService{

  getResponsesActionNavElements(projectStatus: ProjectStatus, responseStatus: ResponseStatus,
                                projectTab: ProjectTabs, permissionList: string[],
                                collaborationModeResponseFlags: {isCollaborationLocked: boolean, isCurrentUserCanUnlock: boolean} = null,
                                isRespondent: boolean, isDuplicatable: number): IAction[] {
    let actions: IAction[] = _.cloneDeep(RESPONSE_ACTIONS);

    switch (projectTab) {
      case ProjectTabs.myProjects:
        actions = this.actionsResponses(actions, projectStatus, responseStatus, collaborationModeResponseFlags, isRespondent, isDuplicatable, ViewType.Projects, permissionList);
        break;
      case ProjectTabs.mgrProjects:
          actions = this.actionsResponses(actions, projectStatus, responseStatus, collaborationModeResponseFlags, isRespondent, isDuplicatable, ViewType.Projects, permissionList);
          break;
    }
    if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
      return actions;
    }
    return permissionList === null ? actions : this.removeActionsBasedOnPermissions(actions, permissionList);
  }

  getResponsesActionNavElementsMyDashboard(projectStatus: ProjectStatus,
                                           responseStatus: ResponseStatus, permissionList: string[],
                                           collaborationModeResponseFlags: {isCollaborationLocked: boolean, isCurrentUserCanUnlock: boolean} = null,
                                           isRespondent: boolean, isDuplicatable: number): IAction[] {
      let actions: IAction[] = _.cloneDeep(RESPONSE_ACTIONS);
      actions = this.actionsResponses(actions, projectStatus, responseStatus, collaborationModeResponseFlags, isRespondent, isDuplicatable, ViewType.Dashboard, permissionList);
      if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
        return actions;
      }
      return (permissionList === null || permissionList.length === 0) ? actions : this.removeActionsBasedOnPermissions(actions, permissionList);
  }

  getResponsesActionNavElementsSubmitResponse(collaborationMode: boolean): IAction[] {
    let actions: IAction[] = _.cloneDeep(RESPONSE_ACTIONS);
    actions = this.actionsResponsesOnSubmitResponse(actions, collaborationMode);
    return actions;
  }

  private actionsResponsesOnSubmitResponse(actions: IAction[], collaborationMode: boolean): IAction[] {
    const noAvailableActions: any[] = [];
    this.setNavPlacement(actions, ActionTypes.ResumeDraft,  NavPlacement.primary);
    noAvailableActions.push(ActionTypes.ViewSummary);
    if (!collaborationMode) {
      noAvailableActions.push(ActionTypes.Lock);
      noAvailableActions.push(ActionTypes.Unlock);
      noAvailableActions.push(ActionTypes.ListOfRespondents);
    }
    return actions.filter(a => !noAvailableActions.some(n => n === a.actionType));
  }

  getBulkDownloadResponsesActionNavElements(downloadStatus: number, permissionList: string[]): IAction[] {
    let actions: IAction[] = _.cloneDeep(BULK_DOWNLOAD_RESPONSE_ACTIONS);
    actions = this.actionsOnDownloadResponses(actions, downloadStatus);
    return permissionList === null ? actions : this.removeActionsBasedOnPermissions(actions, permissionList);
  }

  actionsOnDownloadResponses(actions: IAction[], downloadStatus: number): IAction[] {
    const noAvailableActions: any[] = [];
    switch (downloadStatus) {
      case DownloadStatuses.Error:
        noAvailableActions.push(BulkDownloadActionTypes.Download);
        this.setNavPlacementForBulkDownloadResponses(actions, BulkDownloadActionTypes.Retry,  NavPlacement.primary);
        break;
      case DownloadStatuses.Ready:
        noAvailableActions.push(BulkDownloadActionTypes.Retry);
        this.setNavPlacementForBulkDownloadResponses(actions, BulkDownloadActionTypes.Download,  NavPlacement.primary);
        break;
      default:
        noAvailableActions.push(BulkDownloadActionTypes.Download);
        noAvailableActions.push(BulkDownloadActionTypes.Retry);
        break;
    }
    return actions.filter(a => !noAvailableActions.some(n => n === a.actionType));
  }

  private processDupicateAction(actions: IAction[], responseViewType: ViewType, isDuplicatable: number, permissionList: string[]) {
    const isDuplicatableByCurrentUser = 
      isDuplicatable === RESPONSE_DUPLICATION_ALLOWED &&
      responseViewType === ViewType.Dashboard;

    const isDuplicatableByTeamMember = 
      isDuplicatable !== RESPONSE_DUPLICATION_FORBIDDEN &&
      this.hasPermission([ResponseActionsPermissions.createResponses], permissionList) &&
      responseViewType === ViewType.Projects;
    
    if (isDuplicatableByCurrentUser || isDuplicatableByTeamMember) {
      this.setNavPlacement(actions, ActionTypes.Duplicate,  NavPlacement.secondary);
    } else {
      this.setNavPlacement(actions, ActionTypes.Duplicate,  NavPlacement.none);
    }
  }

  private actionsResponses(actions: IAction[], projectStatus: ProjectStatus, responseStatus: ResponseStatus,
                           collaborationModeResponseFlags: {isCollaborationLocked: boolean, isCurrentUserCanUnlock: boolean} = null,
                           isRespondent: boolean, isDuplicatable: number, responseViewType: ViewType, permissionList: string[]): IAction[] {
      const noAvailableActions: any[] = [];

      switch (projectStatus) {
        case ProjectStatus.Test:
          switch (responseStatus) {
            case ResponseStatus.Draft:
              this.processDupicateAction(actions, responseViewType, isDuplicatable, permissionList);

              if (isRespondent) {
                this.setNavPlacement(actions, ActionTypes.ResumeDraft,  NavPlacement.primary);
              } else {
                noAvailableActions.push(ActionTypes.ResumeDraft);
              }

              this.setNavPlacement(actions, ActionTypes.ViewSummary,  NavPlacement.none);
              if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
                if (collaborationModeResponseFlags.isCollaborationLocked && collaborationModeResponseFlags.isCurrentUserCanUnlock) {
                  this.setNavPlacement(actions, ActionTypes.Unlock,  NavPlacement.secondary);
                  this.setNavPlacement(actions, ActionTypes.Lock,  NavPlacement.none);
                } else {
                  this.setNavPlacement(actions, ActionTypes.Lock,  NavPlacement.secondary);
                  this.setNavPlacement(actions, ActionTypes.Unlock,  NavPlacement.none);
                }
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.secondary);
              } else {
                this.setNavPlacement(actions, ActionTypes.Unlock,  NavPlacement.none);
                this.setNavPlacement(actions, ActionTypes.Lock,  NavPlacement.none);
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.none);
              }
              break;
            case ResponseStatus.Completed:
              this.processDupicateAction(actions, responseViewType, isDuplicatable, permissionList);

              noAvailableActions.push(ActionTypes.ResumeDraft);
              noAvailableActions.push(ActionTypes.Lock);
              noAvailableActions.push(ActionTypes.Unlock);
              noAvailableActions.push(ActionTypes.ReviewDraft);
              this.setNavPlacement(actions, ActionTypes.ViewSummary,  NavPlacement.primary);
              if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.secondary);
              } else {
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.none);
              }
              break;
          }
          break;
        case ProjectStatus.Live:
          switch (responseStatus) {
            case ResponseStatus.Draft:
              this.processDupicateAction(actions, responseViewType, isDuplicatable, permissionList);

              if (isRespondent) {
                this.setNavPlacement(actions, ActionTypes.ResumeDraft,  NavPlacement.primary);
              } else {
                noAvailableActions.push(ActionTypes.ResumeDraft);
              }

              this.setNavPlacement(actions, ActionTypes.ViewSummary,  NavPlacement.none);
              if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
                if (collaborationModeResponseFlags.isCollaborationLocked && collaborationModeResponseFlags.isCurrentUserCanUnlock) {
                  this.setNavPlacement(actions, ActionTypes.Unlock,  NavPlacement.secondary);
                  this.setNavPlacement(actions, ActionTypes.Lock,  NavPlacement.none);
                } else if (collaborationModeResponseFlags.isCollaborationLocked && !collaborationModeResponseFlags.isCurrentUserCanUnlock) {
                  this.setNavPlacement(actions, ActionTypes.Unlock,  NavPlacement.none);
                  this.setNavPlacement(actions, ActionTypes.Lock,  NavPlacement.none);
                } else {
                  this.setNavPlacement(actions, ActionTypes.Lock,  NavPlacement.secondary);
                  this.setNavPlacement(actions, ActionTypes.Unlock,  NavPlacement.none);
                }
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.secondary);
              } else {
                this.setNavPlacement(actions, ActionTypes.Unlock,  NavPlacement.none);
                this.setNavPlacement(actions, ActionTypes.Lock,  NavPlacement.none);
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.none);
              }
              break;
            case ResponseStatus.Completed:
              this.processDupicateAction(actions, responseViewType, isDuplicatable, permissionList);

              noAvailableActions.push(ActionTypes.ResumeDraft);
              noAvailableActions.push(ActionTypes.Lock);
              noAvailableActions.push(ActionTypes.Unlock);
              noAvailableActions.push(ActionTypes.ReviewDraft);
              this.setNavPlacement(actions, ActionTypes.ViewSummary,  NavPlacement.primary);
              if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.secondary);
              } else {
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.none);
              }
              break;
          }
          break;
        case ProjectStatus.TestClosed:
          switch (responseStatus) {
            case ResponseStatus.Draft:
              noAvailableActions.push(ActionTypes.Duplicate);
              noAvailableActions.push(ActionTypes.Lock);
              noAvailableActions.push(ActionTypes.Unlock);
              noAvailableActions.push(ActionTypes.ReviewDraft);
              this.setNavPlacement(actions, ActionTypes.ResumeDraft,  NavPlacement.none);
              this.setNavPlacement(actions, ActionTypes.ViewSummary,  NavPlacement.none);
              if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.secondary);
              } else {
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.none);
              }
              break;
            case ResponseStatus.Completed:
              noAvailableActions.push(ActionTypes.Duplicate);
              noAvailableActions.push(ActionTypes.Lock);
              noAvailableActions.push(ActionTypes.Unlock);
              noAvailableActions.push(ActionTypes.ResumeDraft);
              noAvailableActions.push(ActionTypes.ReviewDraft);
              this.setNavPlacement(actions, ActionTypes.ViewSummary,  NavPlacement.primary);
              if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.secondary);
              } else {
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.none);
              }
              break;
          }
          break;
        case ProjectStatus.TestInactive:
          noAvailableActions.push(ActionTypes.Duplicate);
          noAvailableActions.push(ActionTypes.Lock);
          noAvailableActions.push(ActionTypes.Unlock);
          noAvailableActions.push(ActionTypes.ResumeDraft);
          noAvailableActions.push(ActionTypes.ReviewDraft);
          this.setNavPlacement(actions, ActionTypes.ViewSummary,  NavPlacement.primary);
          if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
            this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.secondary);
          } else {
            this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.none);
          }
          break;
        case ProjectStatus.TestExpired:
          noAvailableActions.push(ActionTypes.Duplicate);
          noAvailableActions.push(ActionTypes.Lock);
          noAvailableActions.push(ActionTypes.Unlock);
          noAvailableActions.push(ActionTypes.ResumeDraft);
          noAvailableActions.push(ActionTypes.ReviewDraft);
          this.setNavPlacement(actions, ActionTypes.ViewSummary,  NavPlacement.primary);
          if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
            this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.secondary);
          } else {
            this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.none);
          }
          break;
        case ProjectStatus.LiveClosed:
          switch (responseStatus) {
            case ResponseStatus.Draft:
              noAvailableActions.push(ActionTypes.Duplicate);
              noAvailableActions.push(ActionTypes.Lock);
              noAvailableActions.push(ActionTypes.Unlock);
              noAvailableActions.push(ActionTypes.ReviewDraft);
              this.setNavPlacement(actions, ActionTypes.ResumeDraft,  NavPlacement.none);
              this.setNavPlacement(actions, ActionTypes.ViewSummary,  NavPlacement.none);
              this.setNavPlacement(actions, ActionTypes.Delete,  NavPlacement.secondary);
              if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.secondary);
              } else {
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.none);
              }
              break;
            case ResponseStatus.Completed:
              noAvailableActions.push(ActionTypes.Duplicate);
              noAvailableActions.push(ActionTypes.ReviewDraft);
              noAvailableActions.push(ActionTypes.ResumeDraft);
              noAvailableActions.push(ActionTypes.Lock);
              noAvailableActions.push(ActionTypes.Unlock);
              this.setNavPlacement(actions, ActionTypes.ViewSummary,  NavPlacement.primary);
              if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.secondary);
              } else {
                this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.none);
              }
              break;
          }
          break;
        case ProjectStatus.LiveInactive:
          noAvailableActions.push(ActionTypes.Duplicate);
          noAvailableActions.push(ActionTypes.ReviewDraft);
          noAvailableActions.push(ActionTypes.ResumeDraft);
          noAvailableActions.push(ActionTypes.Lock);
          noAvailableActions.push(ActionTypes.Unlock);
          this.setNavPlacement(actions, ActionTypes.ViewSummary,  NavPlacement.primary);
          if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
            this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.secondary);
          } else {
            this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.none);
          }
          break;
        case ProjectStatus.LiveExpired:
          noAvailableActions.push(ActionTypes.Duplicate);
          noAvailableActions.push(ActionTypes.ReviewDraft);
          noAvailableActions.push(ActionTypes.ResumeDraft);
          this.setNavPlacement(actions, ActionTypes.ViewSummary,  NavPlacement.primary);
          this.setNavPlacement(actions, ActionTypes.Unlock,  NavPlacement.none);
          this.setNavPlacement(actions, ActionTypes.Lock,  NavPlacement.none);
          if (collaborationModeResponseFlags.isCollaborationLocked !== null) {
            this.setNavPlacement(actions, ActionTypes.ListOfRespondents,  NavPlacement.secondary);
          }
          break;
        default:
          noAvailableActions.push(ActionTypes.ReviewDraft);
          noAvailableActions.push(ActionTypes.ResumeDraft);
          noAvailableActions.push(ActionTypes.ViewSummary);
          noAvailableActions.push(ActionTypes.Delete);
          noAvailableActions.push(ActionTypes.Lock);
          noAvailableActions.push(ActionTypes.Unlock);
          noAvailableActions.push(ActionTypes.ListOfRespondents);
          noAvailableActions.push(ActionTypes.Duplicate);
          break;
      }
      return actions.filter(a => !noAvailableActions.some(n => n === a.actionType));
  }

  setNavPlacement(actions: IAction[], actionType: ActionTypes, navPlacement: NavPlacement): IAction[] {
    actions.find((a) => a.actionType === actionType).navPlacement = navPlacement;
    return actions;
  }

  setNavPlacementForBulkDownloadResponses(actions: IAction[], actionType: BulkDownloadActionTypes, navPlacement: NavPlacement): IAction[] {
    actions.find((a) => a.actionType === actionType).navPlacement = navPlacement;
    return actions;
  }
}
