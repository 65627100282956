import { IProjectWorkflowSettings } from './get-web-link.model';
import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IRespondent } from '../get-web-link/get-web-link.model';
import { ProjectsService } from '../projects.service';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { GET_WEB_LINK_FIELDS_META } from './get-web-link.meta';
import { IEyRadioGroupModel } from 'src/app/shared/components/ey-radio-group/ey-radio-group.model';
import { EyMultiselectSearchComponent } from 'src/app/shared/components/ey-multiselect-search/ey-multiselect-search.component';
import { EyTabset } from 'src/app/shared/components/ey-tabset/ey-tabset.component';
import { Country } from '../../core/models/dictionaries/country.model';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import { ProjectInfo } from '../manage-project/project-info.model';
import { Dictionary } from 'src/app/core/models/dictionaries/dictionary.model';
import { ButtonClass } from 'src/app/shared/components/ey-button/ey-button.component';
import { validate, parse, NIL as NIL_UUID } from 'uuid';
import { ResponseLinkService } from 'src/app/core/services/response-link.service';

export enum WebLinkAccess {
  eyPeople = 1,
  specificPeople
}

export const LINK_ACCESS_OPTIONS = [
  {
    id: WebLinkAccess.eyPeople,
    text: 'People in EY'
  },
  {
    id:  WebLinkAccess.specificPeople,
    text: 'Specific People (EY and client)'
  }
];

export const DEFAULT_ALLOW_DRAFT = true;
export const DEFAULT_AUTOSAVE_RESPONSE = false;
export const DEFAULT_DRAFT_DOCUMENT_GENERATION = false;
export const DEFAULT_RESPONSE_MODE = 0;

export const MULTIPLE_RESPONSE_MODE_ID = 0;
export const SINGLE_RESPONSE_MODE_ID = 1;
export const COLLABORATION_RESPONSE_MODE_ID = 2;

export const RESPONSE_MODES: Dictionary[] = [
  {
    id: MULTIPLE_RESPONSE_MODE_ID,
    name: 'Multiple response per user',
  },
  {
    id: SINGLE_RESPONSE_MODE_ID,
    name: 'One response per user',
  },
  {
    id: COLLABORATION_RESPONSE_MODE_ID,
    name: 'Collaboration mode',
  },
];

export const RESPONSE_MODES_FOR_SINGLE_RESPONSE: Dictionary[] = [
  {
    id: MULTIPLE_RESPONSE_MODE_ID,
    name: 'Multiple response per user',
  },
  {
    id: SINGLE_RESPONSE_MODE_ID,
    name: 'One response per user',
  },
];

export const DATA_RESIDENCY_TYPE_MEDIUM_HIGH: Dictionary[] = [
  {
    id: 2,
    name: 'Medium',
  },
  {
    id: 3,
    name: 'High',
  }
];

@Component({
  selector: 'app-get-web-link',
  templateUrl: './get-web-link.component.html',
  styleUrls: ['./get-web-link.component.scss']
})
export class GetWebLinkComponent implements OnInit, AfterContentChecked {
  @ViewChild(EyMultiselectSearchComponent) multiSelect: EyMultiselectSearchComponent;
  @ViewChild(EyTabset) tabSet: EyTabset;
  @Input() projectId: string;
  btnClass = ButtonClass;
  fieldMeta = GET_WEB_LINK_FIELDS_META;

  showMsgRespondentsEmpty = false;
  showMsgCountriesEmpty = false;
  showErrorAck = false;
  verifyShowErrorAck = false;
  responseModesDisabled = false;
  editProjectWorkflowSettings = false;

  project: ProjectInfo;
  workflowSettings: IProjectWorkflowSettings;

  /* lookups */
  countries: Country[] = [];
  countriesAck = '';

  responseModes: Dictionary[] = RESPONSE_MODES;
  isAllowDraft = DEFAULT_ALLOW_DRAFT;

  showSpinnerRespondentId = false;
  searchText = '';
  searchResults: IRespondent[] = null;

  linkAccessValues = LINK_ACCESS_OPTIONS;
  showLinkAccessSectionForEyPeople: boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();

  /* Collaboration Mode Flags */
  showCollaborationModeError = false;
  collaborationModeSelected = false;
  showCollaborationMsg = false;

  /* Placeholder value to be shown on multiselect component */
  placeholderValue = 'Accessible in';

  getWebLinkForm: FormGroup = this.fb.group(
    {
      allowedAccess: [WebLinkAccess.eyPeople],
      countryIds: [[]],
      countryAcknowledge: [false, { updateOn: 'change'}],
      respondentId: [[]],
      responseMode: [RESPONSE_MODES[DEFAULT_RESPONSE_MODE], Validators.required],
      allowDraft: [DEFAULT_ALLOW_DRAFT],
      autosaveResponse: [DEFAULT_AUTOSAVE_RESPONSE],
      draftDocumentGeneration: [DEFAULT_DRAFT_DOCUMENT_GENERATION],
    },
    { updateOn: 'blur' }
  );

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectsService,
    public activeModal: NgbActiveModal,
    private spinnerService: EyAppSpinnerService,
    private dictionaryService: DictionaryService,
    private changeDetector: ChangeDetectorRef,
    private responseLinkService: ResponseLinkService
  ) { }

  get webLinkPage(): string {
      return window.location.origin + this.responseLinkService.getResponseLink(this.projectId);
  }

  showHideAllowedCountriesErrorMessage(countryIds: any): void {
    const countryMediumHigh = countryIds.some(c => DATA_RESIDENCY_TYPE_MEDIUM_HIGH.some(rt => rt.id === c.dataResidencyType ));
    const errorsValue = (!countryMediumHigh || (this.verifyShowErrorAck && !this.showErrorAck)) ? null : {incorrect: true}

    this.getWebLinkForm.controls.countryIds.setErrors(errorsValue);
    this.getWebLinkForm.controls.countryIds.markAsTouched();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();

    this.showHideAllowedCountriesErrorMessage(this.getWebLinkForm.getRawValue().countryIds);
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.spinnerService
      .withLoadingIndicator(
        forkJoin([
          this.dictionaryService.getCountry(),
          this.projectService.getProjectInfo(this.projectId),
          this.projectService.getProjectWorkflowSettings(this.projectId),
          this.projectService.getProjectResponsesTotalCount(this.projectId),
        ]),
        this.destroy$
      )
      .subscribe(([countries, project, workflowSettings, responsesTotalCount]) => {
        this.countries = countries as Country[];
        this.project = project as ProjectInfo;
        this.workflowSettings = workflowSettings as IProjectWorkflowSettings;

        const tmpCountryIds: Country[] = [];
        this.searchResults = [];
        this.showSpinnerRespondentId = false;

        this.editProjectWorkflowSettings = this.workflowSettings.id !== NIL_UUID;

        if (this.editProjectWorkflowSettings) {
          if(responsesTotalCount == 1 && workflowSettings.responseMode == COLLABORATION_RESPONSE_MODE_ID)
          {
            this.responseModesDisabled = true;
          }
          else if(responsesTotalCount == 1 && workflowSettings.responseMode != COLLABORATION_RESPONSE_MODE_ID)
          {
            this.responseModes = RESPONSE_MODES_FOR_SINGLE_RESPONSE;
          }
          else if(responsesTotalCount > 1)
          {
            this.responseModesDisabled = true;
          }

          this.isAllowDraft = this.workflowSettings.allowDraft;
          this.getWebLinkForm.patchValue({ allowedAccess: this.workflowSettings.allowedAccess });
          this.onLinkAccessSelectionChange(this.workflowSettings.allowedAccess);

          this.workflowSettings.allowedCountriesIds.forEach((y) => tmpCountryIds.push(this.countries.find((x) => x.id === y)));
          this.getWebLinkForm.patchValue({ countryIds: tmpCountryIds });

          this.verifyShowErrorAck = this.workflowSettings.countryAcknowledge;
          this.getWebLinkForm.patchValue({ countryAcknowledge: this.workflowSettings.countryAcknowledge});
          this.getWebLinkForm.patchValue({ respondentId: workflowSettings.allowedRespondents });

          this.getWebLinkForm.patchValue({ responseMode: RESPONSE_MODES[workflowSettings.responseMode] });
          this.getWebLinkForm.patchValue({ allowDraft: workflowSettings.allowDraft });
          this.getWebLinkForm.patchValue({ autosaveResponse: workflowSettings.autosaveResponse });
          this.getWebLinkForm.patchValue({ draftDocumentGeneration: workflowSettings.draftDocumentGeneration });
          if ((this.getWebLinkForm.get('responseMode').value).id === COLLABORATION_RESPONSE_MODE_ID) {
            this.collaborationModeSelected = true;
            this.showCollaborationMsg = false;
          }
        } else {
          tmpCountryIds.push(this.countries.find((x) => x.id === this.project.countryId));

          this.getWebLinkForm.patchValue({ countryIds: tmpCountryIds });

          this.projectService.getPrepopulatedRespondents(this.projectId)
                            .pipe(takeUntil(this.destroy$))
                            .subscribe((respondents) => {
                              this.getWebLinkForm.patchValue({ respondentId: respondents });
                            });
        }

        this.showHideAllowedCountriesErrorMessage(this.getWebLinkForm.getRawValue().countryIds);
      });
  }

  openWebLinkPage(): void {
    this.responseLinkService.openSubmitResponseTab(this.projectId);
  }

  onLinkAccessSelectionChange(linkAccessOptionSelected: number): void {
    this.showCollaborationModeError = false;
    this.showLinkAccessSectionForEyPeople = linkAccessOptionSelected === WebLinkAccess.eyPeople;

    if (this.showLinkAccessSectionForEyPeople) {
      this.showSpinnerRespondentId = false;
      this.searchText = '';
      this.searchResults = null;
    }
  }

  onCountryAcknowledgeChange(): void {
    this.showErrorAck = !this.getWebLinkForm.getRawValue().countryAcknowledge;
    this.verifyShowErrorAck = !this.verifyShowErrorAck;
  }

  onSearchUsers(searchText: string): void {
    this.showMsgRespondentsEmpty = false;

    this.searchText = searchText;
    if (this.searchText && this.searchText.length >= 2) {
      this.showSpinnerRespondentId = true;
      this.projectService
        .getRespondentCandidates(this.projectId, this.searchText)
        .pipe(takeUntil(this.destroy$))
        .subscribe((u) => {
          this.searchResults = u;
          this.showSpinnerRespondentId = false;
        });
    } else {
      this.searchResults = null;
    }
  }

  removeItem(user: IRespondent): void{
    this.multiSelect.selectedValues = this.multiSelect.selectedValues.filter(u => u.id !== user.id);
  }

  onAllowDraftChange(allowDraft: boolean): void {
    this.isAllowDraft = allowDraft;
  }

  onCountriesChange(selectedCountries: Country[]): void {
    this.showMsgCountriesEmpty = false;
    const tmpCountriesAck: string[] = [];
    selectedCountries.forEach((c) => {
      if (DATA_RESIDENCY_TYPE_MEDIUM_HIGH.some(rt => rt.id === c.dataResidencyType)) {
        tmpCountriesAck.push(c.name);
      }
    });

    this.getWebLinkForm.controls.countryIds.setErrors(null);
    this.countriesAck = '';
    if (tmpCountriesAck.length > 0 ) {
      this.countriesAck = tmpCountriesAck.join(',');
      this.getWebLinkForm.controls.countryIds.setErrors( {incorrect: true});
      this.getWebLinkForm.patchValue({countryAcknowledge: false});
    }
    this.verifyShowErrorAck = false;
    this.showErrorAck = false;
  }

  onResponseModeChange(responseMode: {id: number, name: string}): void {
    if (responseMode?.id === COLLABORATION_RESPONSE_MODE_ID) {
      this.getWebLinkForm.get('allowDraft').patchValue(true);
      this.getWebLinkForm.get('autosaveResponse').patchValue(true);
      this.getWebLinkForm.get('draftDocumentGeneration').patchValue(true);
      this.collaborationModeSelected = true;
      this.showCollaborationMsg = true;
    } else {
      this.showCollaborationModeError = false;
      this.collaborationModeSelected = false;
      this.showCollaborationMsg = false;
    }
  }

  onCancel(): void {
    this.activeModal.close('cancel');
  }

  validationsFail(): boolean {
    this.showMsgRespondentsEmpty = false;
    this.showMsgCountriesEmpty = false;
    this.showErrorAck = false;

    if (this.getWebLinkForm.getRawValue().allowedAccess === WebLinkAccess.eyPeople) {
      this.showMsgCountriesEmpty = this.getWebLinkForm.getRawValue().countryIds.length === 0 ? true : false;
      this.showErrorAck = !this.showMsgCountriesEmpty && this.countriesAck.length > 0 &&
                          !this.getWebLinkForm.getRawValue().countryAcknowledge ? true : false;
    } else {
      this.showMsgRespondentsEmpty = this.getWebLinkForm.getRawValue().respondentId.length === 0 ? true : false;
    }

    return this.showMsgRespondentsEmpty || this.showMsgCountriesEmpty || this.showErrorAck;
  }

  collaborationModeValidationFail(): boolean {
    const selectedSpecificUsers = this.getWebLinkForm.get('respondentId').value;
    if (!this.showLinkAccessSectionForEyPeople) {
      return selectedSpecificUsers.length < 2 ? true : false;
    } else {
      return true;
    }
  }
  onApply(): void {
    if (this.validationsFail() ) {
      this.tabSet.select('Sharing');
      return;
    }

    this.getWebLinkForm.controls.countryIds.setErrors(null);
    this.showCollaborationModeError = false;
    if (this.getWebLinkForm.get('responseMode').value.id === COLLABORATION_RESPONSE_MODE_ID && this.collaborationModeValidationFail()) {
        this.showCollaborationModeError = true;
        return;
    }

    if (this.getWebLinkForm.valid)
    {
      const formValues: any = this.getWebLinkForm.value;
      this.workflowSettings.projectId = this.projectId;
      this.workflowSettings.allowedAccess = formValues.allowedAccess;
      this.workflowSettings.countryAcknowledge = formValues.countryAcknowledge;
      this.workflowSettings.responseMode = formValues.responseMode.id;
      this.workflowSettings.allowDraft = formValues.allowDraft;
      if (this.workflowSettings.allowDraft) {
        this.workflowSettings.autosaveResponse = formValues.autosaveResponse;
        this.workflowSettings.draftDocumentGeneration = formValues.draftDocumentGeneration;
      } else {
        this.workflowSettings.autosaveResponse = false;
        this.workflowSettings.draftDocumentGeneration = false;
      }

      this.workflowSettings.allowedCountriesIds = formValues.countryIds.map(c => c.id);
      this.workflowSettings.allowedRespondents = formValues.respondentId;

      let prjService$;
      if (this.editProjectWorkflowSettings) {
        prjService$ = this.spinnerService.withLoadingIndicator(this.projectService.updateProjectWorkflowSettings(this.workflowSettings));
      } else {
        prjService$ = this.spinnerService.withLoadingIndicator(this.projectService.createProjectWorkflowSettings(this.workflowSettings));
      }

      prjService$.subscribe((x) => {
        this.activeModal.close('submit');
      });
    } else {
      this.getWebLinkForm.markAllAsTouched();
    }
  }

  onTabChange(tab: any): void {
    this.showCollaborationModeError = false;
    if (tab.nextId === 'Settings') {
      this.showSpinnerRespondentId = false;
      this.searchText = '';
      this.searchResults = null;
    }
  }
}
