import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, timer } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';
export const TIMER_DELAY_TIME = 500;

@Injectable({
  providedIn: 'root'
})
export class EyAppSpinnerService {
  private showSpinnerSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  showSpinner$: Observable<boolean>;

  constructor() {
    this.showSpinner$ = this.showSpinnerSubject.asObservable();
  }

  withLoadingIndicator<TData>(request$: Observable<TData>, destroy$: Subject<boolean> = new Subject<boolean>()): Observable<TData> {
    const sharedRequest$ = request$.pipe(takeUntil(destroy$), share());
    timer(TIMER_DELAY_TIME).pipe(takeUntil(destroy$), takeUntil(sharedRequest$)).subscribe(() => {
      this.show();
    }, () => {
      this.hide();
    });

    destroy$.asObservable().subscribe(() => {
      this.hide();
    });

    sharedRequest$.subscribe(() => {
      this.hide();
    }, () => {
      this.hide();
    });

    return sharedRequest$;
  }
  show(): void {
    this.showSpinnerSubject.next(true);
  }
  hide(): void {
    this.showSpinnerSubject.next(false);
  }
}
