<form autocomplete="noAutocomplete" [formGroup]="getWebLinkForm" id="getWebLinkForm">
  <ey-modal-template class="get-web-link-only">
    <div header>
      <h2 class="modal__title" i18n="Get Web Link| Header Text">{{project?.projectName}} - Web Link</h2>
    </div>
    <div body>
      <div class="subheader info">
        Share the web link via email, embed it on SharePoint or your website
        <div class="d-flex ">
          <ey-button class="mt-20 mb-20 start-pub-appr" title="Open link" [btnClass]="btnClass.secondary" text="{{ (webLinkPage?.length>70) ? (webLinkPage | slice:0:70)+'...':webLinkPage }}" (click)="openWebLinkPage()"></ey-button>
          <ey-copy-to-clipboard class="mb-20 copy-link" [content]="webLinkPage" [showIcon]="true" [showText]="true">
            <a class="preview-link" href="javascript:void(0)"> <span class="ml-4 copy-link-text">Copy Link</span></a>
          </ey-copy-to-clipboard>
        </div>
      </div>
      <ey-tabset [tabsetStyle]="'tetrary-reverse'" (tabChange)="onTabChange($event)">
        <ey-tab [id]="'Sharing'" title="Sharing">
          <ng-template eyTabContent>
            <div class="sharing-tab">
              <h4 class="fonts-col__heading-4 mb-20">Who can access the link?</h4>
              <ey-radio-group-plain
                [values]="linkAccessValues"
                formControlName="allowedAccess"
                [meta]="fieldMeta.linkAccess"
                [thinLabels]="true"
                extra="none"
                (blur)="onLinkAccessSelectionChange($event)">
              </ey-radio-group-plain>
              <ng-container *ngIf="showCollaborationModeError">
                <div class="d-flex mt-20">
                  <i class="icon-ico_error_toast chk_ack_icon mr-2"></i>
                  <div class="chk_ack_errmsg">
                    Collaboration mode is selected in “Settings”. Specify at least two respondents (select “Specific People can respond”) or change Response Mode in “Settings” tab to proceed.
                  </div>
                </div>
              </ng-container>
              <div *ngIf="showLinkAccessSectionForEyPeople">
                <h4 class="fonts-col__heading-4 mb-20 pt-20">Allowed Countries</h4>
                <ey-multi-select [placeHolderValue]="placeholderValue" [searchable]="true" (ngModelChange)="onCountriesChange($event)" class="d-block mb-0" [isDisabled]="false" [values]="countries" [meta]="fieldMeta.countriesIds" formControlName="countryIds"></ey-multi-select>
                <div *ngIf="showMsgCountriesEmpty">
                  <div class="d-flex warning">
                    <i class="material-icons warning-color emp-icon mr-2">warning</i>
                    <div class="info eyfontlight mb-20">
                      Choose countries for the Project
                    </div>
                  </div>
                </div>
                <div *ngIf="countriesAck.length > 0">
                  <div class="d-flex text_ackmsg">
                    <i class="icon-ico_info_toast emp-icon mr-2"></i>
                    <div class="info eyfontlight mb-20 text_ack">
                      You have selected all EY users from {{countriesAck}} to have access to the link. The geography you have selected means that you will need to consult with your Local or Regional Privacy Leader or GCO/RM team to confirm that any client engagement data or personal data you are collecting on Empowerment may be processed and stored offshore. For more information, you can contact your Local or Regional Privacy Leader whose details can be found on the Data Protection Community Site.
                    </div>
                  </div>
                  <div class="chk_ack">
                    <label class="checkbox checkbox-margin mb-10">
                      <input formControlName="countryAcknowledge" class="checkbox__input" type="checkbox" (change)="onCountryAcknowledgeChange()"/>
                      <span class="checkbox__checkmark checkbox-background" [ngClass]="{ 'checkbox__err': showErrorAck, 'checkbox__ok': !showErrorAck }"></span>
                      <p class="checkbox__label">I acknowledge my responsibility.</p>
                    </label>
                    <div *ngIf="showErrorAck">
                      <div class="d-flex">
                        <i class="icon-ico_error_toast chk_ack_icon mr-2"></i>
                        <div class="chk_ack_errmsg">
                          Agree to proceed
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!showLinkAccessSectionForEyPeople">
                <div class="d-flex pt-20">
                  <h4 class="fonts-col__heading-4 mb-20">Respondents</h4>
                  <div class="tooltip tooltip--light tooltip--respondents">
                    <i class="icon-ico_tooltip emp-icon respondents-info"></i>
                    <div class="tooltiptext">
                      <p>Users available to add here is based on Target Audience setting of the Project that you provided during Project Creation.</p>
                    </div>
                  </div>
                </div>
                <div>
                  <ey-multiselect-search class="d-block mb-30" [showSpinner]="showSpinnerRespondentId" [meta]="fieldMeta.respondentsIds" [values]="searchResults" (searchTextChanged)="onSearchUsers($event)" labelName2="email" formControlName="respondentId">
                    <ng-template eyTemplate let-selectedValues>
                      <ul class="d-flex w-100 p-0 flex-column m-0 ">
                        <li class="d-flex w-100 px-2 py-3 border-bottom" *ngFor="let user of selectedValues" >
                          <div class="flex-grow-1">
                            <div class="d-flex align-items-center">
                              <div class="user flex-grow-1 mr-3">
                                <div class="user-title eyfontlight">{{user.name}}</div>
                                <div class="user-email eyfontlight">{{user.email}}</div>
                              </div>
                              <div class="user-creator-label eyfontbold d-flex align-items-center mr-20" *ngIf="user.isCreator">
                                Project Creator
                              </div>
                              <div class="user-manager-label eyfontbold d-flex align-items-center mr-20" *ngIf="user.isProjectManager && !user.isCreator">
                                Project Manager
                              </div>
                              <div class="user-external-label eyfontbold d-flex align-items-center mr-20" *ngIf="user.isExternal">
                                External
                              </div>
                              <i role="button" title="Delete Respondent" (click)="removeItem(user)" class="emp-icon icon-ico_delete" style="position: relative; top: -2px;"></i>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </ng-template>
                  </ey-multiselect-search>
                </div>
                <div *ngIf="showMsgRespondentsEmpty">
                  <div class="d-flex warning">
                    <i class="material-icons warning-color emp-icon mr-2">warning</i>
                    <div class="info eyfontlight mb-20">
                      Choose respondents for the Project
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ey-tab>
        <ey-tab [id]="'Settings'" title="Settings">
          <ng-template eyTabContent>
            <div class="settings-tab">
              <h4 class="fonts-col__heading-4 mb-20">Settings</h4>
              <ey-drop-down class="d-block mb-20" [isDisabled]="responseModesDisabled" [values]="responseModes" formControlName="responseMode" [meta]="fieldMeta.responseMode" (ngModelChange)="onResponseModeChange($event)"></ey-drop-down>
              <ng-container *ngIf="collaborationModeSelected && showCollaborationMsg">
                <div class="d-flex">
                  <i class="emp-icon icon-ico_info_toast mr-2"></i>
                  <p class="mv-0 mh-0">At least two specific respondents are required to activate the Collaboration mode. Select “<strong>Specific People can respond</strong>” in the "<strong>Sharing</strong>" tab, and add people who will cooperate on a response.</p>
                </div>
              </ng-container>
              <fieldset #fieldSet>
                <ey-form-toggle [showDisabledTooltipText]="false" [isDisabled]="fieldSet.disabled" [meta]="fieldMeta.allowDraft" (ngModelChange)="onAllowDraftChange($event)" formControlName="allowDraft"></ey-form-toggle>
              </fieldset>
              <ey-form-toggle [meta]="fieldMeta.autosaveResponse" *ngIf="isAllowDraft" formControlName="autosaveResponse"></ey-form-toggle>
              <ey-form-toggle [meta]="fieldMeta.draftDocumentGeneration" *ngIf="isAllowDraft" formControlName="draftDocumentGeneration"></ey-form-toggle>
              <ng-container *ngIf="showCollaborationModeError">
                <div class="d-flex mt-20">
                  <i class="icon-ico_error_toast chk_ack_icon mr-2"></i>
                  <div class="chk_ack_errmsg">
                    Specify at least two respondents to proceed with Collaboration mode (select “Specific People can respond” option in "Sharing" tab)
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </ey-tab>
      </ey-tabset>
    </div>
    <div footer class="d-flex w-100">
      <div class="d-flex w-100">
        <button class="btn mr-20 btn--primary btn-border-color" type="button" (click)="onApply()">Apply</button>
        <button class="btn btn--secondary ml-0" type="button" (click)="onCancel()">Cancel</button>
      </div>
    </div>
  </ey-modal-template>
</form>
