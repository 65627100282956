<ng-template #responseActions let-data>
  <app-grid-actions class="d-block w-100" [ngClass]="{'mt-40': !showDocumentsDownloadSection(), 'margin-top': showDocumentsDownloadSection()}" [parentViewFlag]="1" [cardView]="true"  [showLabelText]="true" [lightTable]="false" [id]="data.id" [actions]="getResponseActionNavElements(data)" (actionSelect)="onActionSelect($event, data)"></app-grid-actions>
</ng-template>

<ng-template #noCount>
  <span class="document-count"></span>
</ng-template>

<div class="my-project-card flex-fill p-20">
  <p class="mb-20 text-white d-flex" [attr.title]="response?.projectName">
    <span *ngIf="response?.isCollaborationLocked !== null" class="mr-10"><i (click)="onCollaborationModeListOfRespondents(response.projectId, response.id)" class="icon-ico_mult_users emp-icon align-middle" title="Collaboration mode &#013;Check who else is working on this response."></i></span>
    <span *ngIf="response?.uploadId && response?.isCollaborationLocked === null" class="mr-10"><i class="icon-ico_responses_upload emp-icon align-middle" title="This is prepopulated response &#013;generated for you by &#013;EY project team."></i></span>
    <span class="flex-grow-1 project-name no-wrap-ellipsis mr-10">
    {{ response?.projectName }}
    </span>
    <ey-pill *ngIf="responseStatus.labelText === 'Draft' || responseStatus.labelText === 'Locked' || responseStatus.labelText === 'Unlocked'" [pillType]="responseStatus.pillType" [toolTip]="responseStatus.toolTip">
      {{responseStatus.labelText ? responseStatus.labelText : responseStatus.status}}
    </ey-pill>
  </p>

  <p class="response-type mb-0">Response Date</p>
  <p class="text-white no-wrap-ellipsis">{{ response.responseDate | date:'dd MMMM yyyy' }}</p>

  <div *ngIf="showDocumentsDownloadSection()">
    <p class="response-type custom-margin-bottom">Generated Documents</p>
    <div class="d-flex">
      <div *ngIf="response.excelCount > 0" (click)="openDocumentDownloadModal(response.excelCount, response.projectId,  response.id, response.respondentName, templateFileTypesEnum.Excel, response.responseDate, response.responseStatus)" class="position_relative">
        <span [title]="response.responseStatus === 2 ? 'Download Draft' : 'Download'"><i [class.icon-ico_Excel_Plain_Draft]="response.responseStatus === 2" class="icon-ico_Excel_Plain emp-icon"></i></span>
        <span [class.draft-document-count]="response.responseStatus === 2" *ngIf="response.excelCount > 1; else noCount" class="document-count">{{response.excelCount}}</span>
      </div>
      <div *ngIf="response.powerPointCount > 0" (click)="openDocumentDownloadModal(response.powerPointCount, response.projectId, response.id, response.respondentName, templateFileTypesEnum.PowerPoint, response.responseDate, response.responseStatus)" class="position_relative">
        <span [title]="response.responseStatus === 2 ? 'Download Draft' : 'Download'"><i [class.icon-ico_PPoint_Plain_Draft]="response.responseStatus === 2" class="icon-ico_PPoint_Plain emp-icon"></i></span>
        <span [class.draft-document-count]="response.responseStatus === 2" *ngIf="response.powerPointCount > 1; else noCount" class="document-count">{{response.powerPointCount}}</span>
      </div>
      <div *ngIf="response.wordCount > 0" (click)="openDocumentDownloadModal(response.wordCount, response.projectId, response.id, response.respondentName, templateFileTypesEnum.Word, response.responseDate, response.responseStatus)" class="position_relative">
        <span [title]="response.responseStatus === 2 ? 'Download Draft' : 'Download'"><i [class.icon-ico_Word_Plain_Draft]="response.responseStatus === 2" class="icon-ico_Word_Plain emp-icon"></i></span>
        <span [class.draft-document-count]="response.responseStatus === 2" *ngIf="response.wordCount > 1; else noCount" class="document-count">{{response.wordCount}}</span>
      </div>
      <div *ngIf="response.wordCount === 0 && response.excelCount === 0 && response.powerPointCount === 0">
        <span class="no-document">-</span>
      </div>
    </div>
  </div>

  <div *ngIf="response?.uploadId && !showDocumentsDownloadSection()">
    <p class="response-type mb-0">Response ID</p>
    <p class="text-white no-wrap-ellipsis">{{ response.uploadResponseId || '-' }}
  </div>
  <div class="d-flex custom-margin-top" [class.compress-margin-top]="response?.uploadId && !showDocumentsDownloadSection()">
      <ng-container *ngTemplateOutlet="responseActions; context:{$implicit: response}"></ng-container>
  </div>
</div>
