<p class="range-selection-text">Selection limits</p>
<p class="eyfontlight range-selection-text">Set rules to limit the number of options end-users can select within this checkbox group.</p>

<ey-drop-down 
    class="d-block mb-20" 
    [meta]="rangeMeta.selectionLimits" 
    [values]="selectionLimits" 
    [defaultValue]="selectionLimitsDefault" 
    noInfo="true"
    [(ngModel)]="selectionLimit"
    ngModelOptions="{ standalone: true }"
    (ngModelChange)="selectionLimitChange()"
    >
</ey-drop-down>

<div class="mb-10">
    <form [formGroup]="range">
        <ey-input 
            *ngIf="currentLimit === 1" 
            type="number"
            extra="none" 
            [meta]="rangeMeta.min"
            noErrorLabel="true"
            formControlName="min">
        </ey-input>
        <ey-input 
            *ngIf="currentLimit === 2" 
            extra="none" 
            type="number"
            [meta]="rangeMeta.max"
            showError="false"
            noErrorLabel="true"
            formControlName="max"
            ></ey-input>
        <ng-container *ngIf="currentLimit === 3">
            <div class="d-flex align-items-center">
                <ey-input 
                    noErrorLabel="true" 
                    type="number" 
                    showError="false" 
                    formControlName="min" 
                    extra="none" 
                    noErrorLabel="true"
                    class="flex-fill" 
                    [meta]="rangeMeta.min">
                </ey-input>
            
                <span class="mh-10 eyfontlight range-selection-text">To</span>
                <ey-input 
                    type="number" 
                    showError="false" 
                    formControlName="max" 
                    extra="none" 
                    noErrorLabel="true"
                    class="flex-fill" 
                    [meta]="rangeMeta.max">
                </ey-input>
            </div>
        </ng-container>
        <ey-input 
            type="number" 
            showError="false" 
            formControlName="exactly" 
            *ngIf="currentLimit === 4" 
            noErrorLabel="true"
            extra="none" 
            [meta]="rangeMeta.exactly">
        </ey-input>
    </form>
</div>
<p *ngIf="formInvalid && (isMin || (isRange && range.controls.min.invalid))" class="error range-selection-text">
    <i class="icon-ico_error_toast emp-icon emp-icon-small mr-1" style="vertical-align: bottom;"></i> 
    Min should be lower than number of options
</p>
<p *ngIf="formInvalid && (isMax || (isRange && range.controls.max.invalid))" class="error range-selection-text">
    <i class="icon-ico_error_toast emp-icon emp-icon-small mr-1" style="vertical-align: bottom;"></i> 
    Max should be lower or equal to the number of options
</p>
<p *ngIf="formInvalid && isRange && range.errors?.minShouldBeLowerThanMax" class="error range-selection-text">
    <i class="icon-ico_error_toast emp-icon emp-icon-small mr-1" style="vertical-align: bottom;"></i> 
    Min should be lower than Max
</p>
<p *ngIf="formInvalid && isExactly" class="error range-selection-text">
    <i class="icon-ico_error_toast emp-icon emp-icon-small mr-1" style="vertical-align: bottom;"></i> 
    Exactly should be lower or equal to the number of options
</p>