<form [formGroup]="optionForm"  #formVC="ngForm" class="border  p-10">
  <div class="d-flex w-100 mb-10">
    <div class="flex-grow-1"><i class="emp-icon icon-ico_dnd pl-10"></i></div>

    <div>
      <i class="emp-icon icon-ico_arrow_up" title="Move up" *ngIf="displayOrder > minDisplayOrder" (click)="onActionClick(actions.up)"></i>
      <i class="emp-icon icon-ico_arrow_down" title="Move down" *ngIf="displayOrder < maxDisplayOrder"(click)="onActionClick(actions.down)"></i>
      <ey-copy-to-clipboard class="copy-to-clip" [content]="optionName"></ey-copy-to-clipboard>
      <!--i class="emp-icon icon-ico_copy" title="Copy to clipboard" (click)="onActionClick(actions.copy)"></i-->
      <i class="emp-icon icon-ico_delete" *ngIf="!disableDelete" title="Delete" (click)="onActionClick(actions.remove)"></i>
    </div>
  </div>
  <div class="d-flex">
    <ey-textarea [maxLength]="maxLength" formControlName="name" [mini]="true" (blur)="triggerSubmit()" [meta]="meta?.name" [toolTipHolderOption]="toolTipHolderOptions.hidden" class="flex-grow-1 mr-10"></ey-textarea>
    <ey-input *ngIf="!hideScore" formControlName="score" (blur)="triggerSubmit()" [meta]="meta?.score" class="score " extra="none" type="number" ></ey-input>
  </div>
  {{focus}}
</form>



