import { Component, Input, OnInit } from '@angular/core';
import { IToolMeta } from './tool-meta';
import { ToolType} from './tool-type';
import {FormBuilderControlTypes} from '../../builders/form-builder/form-builder-control.types';

const toolMap = new Map<ToolType | FormBuilderControlTypes, string>([
    [ToolType.Statement, 'tool-statement'],
    [ToolType.YesNo, 'tool-yes-no'],
    [ToolType.Decision, 'tool-decision'],
    [ToolType.Form, 'tool-form'],
    [ToolType.Letter, 'tool-letter'],
    [ToolType.List, 'tool-list'],
    [ToolType.Upload, 'tool-upload'],
    [ToolType.Letter, 'tool-letter'],
    [ToolType.Mail, 'tool-mail'],
    [ToolType.Table, 'tool-table'],
    [ToolType.Connector, 'tool-connector']
]);

@Component({
    selector: 'app-tool-menu-item',
    templateUrl: './tool-menu-item.component.html',
    styleUrls: ['./tool-menu-item.component.scss']
})
export class ToolMenuItemComponent implements OnInit {
    @Input() meta: IToolMeta;
    @Input() toolPanelMinimised = false;

    @Input() toolClass: string = null;

    ngOnInit(): void {
        if (this.toolClass == null) {
          this.toolClass = toolMap.get(this.meta.type);
        }
    }
}
