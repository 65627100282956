import {FormBuilderControlTypes} from './form-builder-control.types';

export const CONTROL_TYPE_TO_NAME_PREFIX = new Map<FormBuilderControlTypes, string>(
  [
    [FormBuilderControlTypes.checkbox, 'checkbox'],
    [FormBuilderControlTypes.checkboxGroup, 'checkboxgroup'],
    [FormBuilderControlTypes.date, 'date'],
    [FormBuilderControlTypes.description, 'description'],
    [FormBuilderControlTypes.dropDown, 'dropdown'],
    [FormBuilderControlTypes.eyEmailLookup, 'mail'],
    [FormBuilderControlTypes.multilineInput, 'multiline'],
    [FormBuilderControlTypes.numberInput, 'number'],
    [FormBuilderControlTypes.radioButton, 'radio'],
    [FormBuilderControlTypes.textInput, 'text'],
    [FormBuilderControlTypes.divider, 'divider'],
  ]
);
