import { props } from '@ngrx/store';
import { createNonUndoableAction } from 'src/app/designer/workflow-designer/utils/action-utils';
import { VersionStructure } from 'src/app/modules/version/version-structure.model';
import {SaveStatus} from '../../designer/builders/form-builder/form-builder.state';

export const ACTION_TYPE = '[Save]';
export const ACTION_TOGGLE_AUTO_SAVE = ACTION_TYPE + ' Toggle auto save';
export const ACTION_SAVE_CANVAS_STATE = ACTION_TYPE + ' Save canvas state';
export const ACTION_SAVE_FORM_BUILDER_STATE = ACTION_TYPE + ' Save Form builder state';
export const ACTION_UPDATE_SAVE_STATUS = ACTION_TYPE + ' Update Save Status';

export const saveToggleAutoSave = createNonUndoableAction(ACTION_TOGGLE_AUTO_SAVE, props<{ autoSave: boolean }>());
export const saveCanvasState = createNonUndoableAction(ACTION_SAVE_CANVAS_STATE, props<{ versionStructure: VersionStructure }>());
export const saveFormBuilderState = createNonUndoableAction(ACTION_SAVE_FORM_BUILDER_STATE);
export const updateSaveStatus = createNonUndoableAction(ACTION_UPDATE_SAVE_STATUS, props<{ toolName: string; saveStatus: SaveStatus }>());


