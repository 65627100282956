import {Component, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import { User } from '../../../models/user/user.model';
import { environment } from '../../../../../environments/environment';
import { UserService } from '../../../services/user-service';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @Input() mobileViewChange = false;
  @Input() isMobileBreakpoint = false;
  userName: string = null;
  user: User = new User();
  roles = '';

  showUserDropdown = false;
  userDropdownExpanded = false;

  internalSiteHelp: string = environment.internalSiteHelp;
  @Input() showInternalSiteHelp = true;

  constructor(
    private userService: UserService,
    private loginService: LoginService, private eRef: ElementRef) {

    const accounts = this.loginService.getAccounts();

    this.userName = accounts.length > 0 ? accounts[0].username : '';

    this.userService.getCurrentUser().subscribe((result: User) => {
      this.user = result;
      this.roles = result.roles.join(', ');
    });
  }

  @HostListener('document:click', ['$event'])
  clickOut(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showUserDropdown = false;
    }
  }

  onLogout(): void {
    this.loginService.logout();
  }

  onUsernameClick(event): void {
    this.showUserDropdown = !this.showUserDropdown;
    this.userDropdownExpanded = !this.userDropdownExpanded;
    event.stopPropagation();
  }

  ngOnInit(): void {
  }

}
