  <ey-modal-template [showSubHeader]="false">
    <div header>
      <span class="d-flex">
        <i *ngIf="infoDialogType === infoDialogTypes.dismiss || infoDialogType === infoDialogTypes.info" class="material-icons mr-10 color-ey-grey-1">info</i>
        <h2 class="modal__title">{{title}}</h2>
      </span>
    </div>
    <div body>
      <div class="d-flex">
        <span *ngIf="infoDialogType === infoDialogTypes.warning" class="emp-icon icon-ico_waring_toast mr-2"></span>
        <span class="font-size-4" [innerHTML]="htmlBody">
        </span>
      </div>

    </div>
    <div footer class="d-flex w-100" [ngSwitch]="infoDialogType">

      <span *ngSwitchCase="infoDialogTypes.dismiss" class="w-100 d-flex justify-content-center">
        <button class="btn btn--secondary" type="button" (click)="close(modalResult.dismiss)">{{texts?.dismissText}}</button>
      </span>
      <span *ngSwitchCase="infoDialogTypes.delete">
        <button class="btn btn--delete" type="button" (click)="close(modalResult.submit)">{{texts?.deleteText}}</button>
        <button class="btn btn--secondary" type="button" (click)="close(modalResult.dismiss)">{{texts?.cancelText}}</button>
      </span>

      <span *ngSwitchCase="infoDialogTypes.yesNo">
        <button class="btn btn--primary btn-border-color" type="button" (click)="close(modalResult.submit)">{{texts?.yesText}}</button>
        <button class="btn btn--secondary" type="button" (click)="close(modalResult.dismiss)">{{texts?.noText}}</button>
      </span>

      <span *ngSwitchCase="infoDialogTypes.info">
        <button class="btn btn--primary btn-border-color" type="button" (click)="close(modalResult.submit)">{{texts?.acceptText}}</button>
      </span>

      <span *ngSwitchCase="infoDialogTypes.warning">
        <button class="btn btn--primary btn-border-color" type="button" (click)="close(modalResult.submit)">
          <span *ngIf="btnIcons" [class]="btnIcons"></span>
          <span [class.submit-button-with-icon]="btnIcons" class="button-font">{{texts?.acceptWarningText}}</span>
        </button>
        <button class="btn btn--secondary" type="button" (click)="close(modalResult.dismiss)">{{texts?.rejectWarningText}}</button>
      </span>

    </div>
  </ey-modal-template>
