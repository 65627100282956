import { Injectable } from '@angular/core';
import { IProjectStatus, ProjectStatus, ProjectTabs, NavPlacement } from './project.const';
import { ACTIONS, ActionTypes } from './project-actions.dictionary';
import * as _ from 'lodash';
import { UserPermissionsService } from '../core/user-permissions.service';
import { Observable } from 'rxjs';
import { UserPermissions } from '../core/user-permissions.model';
import { IAction } from './grid-actions/action.model';
import { BasePermissionsService } from './base-permissions.service';


@Injectable({
  providedIn: 'root',
})
export class ProjectPermissionsService extends BasePermissionsService {

  constructor(private ups: UserPermissionsService) {
    super();
  }

  getPermissions(): Observable<UserPermissions> {
    return this.ups.getPermissions();
  }

  getProjectActionNavElements(projectStatus: IProjectStatus, projectTab: ProjectTabs, permissionList: string[]): IAction[] {
    let actions: IAction[] = _.cloneDeep(ACTIONS);

    switch (projectTab) {
      case ProjectTabs.myProjects:
        actions = this.actionsProjects(actions, projectStatus); // , isSync
        break;
      case ProjectTabs.mgrProjects:
        actions = this.actionsProjects(actions, projectStatus);
        break;
    }

    return permissionList === null ? actions : this.removeActionsBasedOnPermissions(actions, permissionList);
  }

  private actionsProjects(actions: IAction[], projectStatus: IProjectStatus): IAction[] {
    const noAvailableActions: any[] = [];

    switch (projectStatus.label) {
      case ProjectStatus.Test:
        actions.find((a) => a.actionType === ActionTypes.getWebLink).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.bulkDownloadResponses).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.closeReopen).toolTip = 'Close Project';
        noAvailableActions.push(ActionTypes.projectApproval);
        break;
      case ProjectStatus.TestInactive:
        actions.find((a) => a.actionType === ActionTypes.editProject).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.bulkDownloadResponses).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.closeReopen).toolTip = 'Close Project';
        noAvailableActions.push(ActionTypes.projectApproval);
        break;
      case ProjectStatus.TestClosed:
        actions.find((a) => a.actionType === ActionTypes.bulkDownloadResponses).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.closeReopen).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.closeReopen).icon = 'icon-ico_lock';
        actions.find((a) => a.actionType === ActionTypes.closeReopen).toolTip = 'Reopen Project';
        noAvailableActions.push(ActionTypes.projectApproval);
        break;
      case ProjectStatus.TestExpired:
        actions.find((a) => a.actionType === ActionTypes.projectInformation).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.bulkDownloadResponses).navPlacement = NavPlacement.primary;
        noAvailableActions.push(ActionTypes.projectApproval);
        noAvailableActions.push(ActionTypes.getWebLink);
        noAvailableActions.push(ActionTypes.bulkUploadResponses);
        noAvailableActions.push(ActionTypes.closeReopen);
        break;
      case ProjectStatus.LiveApprovalRequired:
      case ProjectStatus.LiveInApproval:
        actions.find((a) => a.actionType === ActionTypes.projectApproval).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.addTeamMember).navPlacement = NavPlacement.primary;
        noAvailableActions.push(ActionTypes.getWebLink);
        noAvailableActions.push(ActionTypes.bulkUploadResponses);
        noAvailableActions.push(ActionTypes.bulkDownloadResponses);
        noAvailableActions.push(ActionTypes.closeReopen);
        break;
      case ProjectStatus.Live:
        actions.find((a) => a.actionType === ActionTypes.getWebLink).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.bulkDownloadResponses).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.closeReopen).toolTip = 'Close Project';
        noAvailableActions.push(ActionTypes.projectApproval);
        break;
      case ProjectStatus.LiveInactive:
        actions.find((a) => a.actionType === ActionTypes.editProject).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.bulkDownloadResponses).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.closeReopen).toolTip = 'Close Project';
        noAvailableActions.push(ActionTypes.projectApproval);
        break;
      case ProjectStatus.LiveClosed:
        actions.find((a) => a.actionType === ActionTypes.bulkDownloadResponses).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.closeReopen).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.closeReopen).icon = 'icon-ico_lock';
        actions.find((a) => a.actionType === ActionTypes.closeReopen).toolTip = 'Reopen Project';
        noAvailableActions.push(ActionTypes.projectApproval);
        break;
      case ProjectStatus.LiveExpired:
        actions.find((a) => a.actionType === ActionTypes.projectInformation).navPlacement = NavPlacement.primary;
        actions.find((a) => a.actionType === ActionTypes.bulkDownloadResponses).navPlacement = NavPlacement.primary;
        noAvailableActions.push(ActionTypes.projectApproval);
        noAvailableActions.push(ActionTypes.getWebLink);
        noAvailableActions.push(ActionTypes.bulkUploadResponses);
        noAvailableActions.push(ActionTypes.closeReopen);
        break;
      }

    return actions.filter(a => !noAvailableActions.some(n => n === a.actionType));
  }

}
