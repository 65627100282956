<form [formGroup]="form">
  <div class="d-flex flex-fill flex-column">
    <p class="eyfontbold">Dropdown Options</p>
    <div class="d-flex flex-fill flex-column">
      <ng-container formArrayName="options" >
        <div cdkDropList (cdkDropListDropped)="drop($event)">

          <div   *ngFor="let fa of optionsArray?.controls; index as i">
            <ng-container>
              <div class="example-box" cdkDrag (cdkDragStarted)="dragStart($event)">
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                <app-option [enableStoreDispatch]="false" [hideScore]="true" [minDisplayOrder]="getMinDisplayOrder()" [maxDisplayOrder]="getMaxDisplayOrder()" [formControl]="optionsArray?.controls[i]" (actionClick)="onAction($event)" class="d-block mb-10"></app-option>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <a *ngIf="optionsArray?.controls?.length < 200" (click)="onAddOption()" class="add--option mv-10"><i class="emp-icon icon-ico_plus"></i><span class="mb-auto mt-auto">Add Option</span></a>

    </div>
  </div>
</form>

