import { NavPlacement } from './project.const';
import { IAction } from './grid-actions/action.model';
import { ProjectActionsPermissions } from './project-permissions.const';
import { UserOtherPermissions, UserProjectPermissions } from '../core/user-permissions.model';


export enum ActionTypes {
  editProject = 'Edit Project',
  projectApproval = 'Project Approval',
  addTeamMember = 'Add Team Member',
  getWebLink = 'Get Web Link',
  projectInformation = 'Project Information',
  documentTemplateUpdate = 'Document Template Update',
  deleteProject = 'Delete Project',
  bulkUploadResponses = 'Bulk Upload Responses',
  bulkDownloadResponses = 'Bulk Download Responses',
  closeReopen = 'Close/Reopen'
}

export const ACTIONS: IAction[] = [
  {
    actionType: ActionTypes.editProject,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_edit',
    requiredPermissions: [
      ProjectActionsPermissions.updateProject,
      new UserProjectPermissions().UpdateAllProjects
    ]
  },
  {
    actionType: ActionTypes.projectApproval,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_certify',
    requiredPermissions: [ 
      ProjectActionsPermissions.updateProject,
      new UserProjectPermissions().UpdateAllProjects
    ]
  },
  {
    actionType: ActionTypes.addTeamMember,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_grant_access',
    requiredPermissions: [
      ProjectActionsPermissions.updateProjectMembers,
      new UserProjectPermissions().UpdateAllProjectsMembers
    ]
  },
  {
    actionType: ActionTypes.getWebLink,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_weblink',
    requiredPermissions: [
      ProjectActionsPermissions.updateProjectParticipants,
      new UserProjectPermissions().UpdateAllProjectsParticipiants,
      new UserProjectPermissions().ReadMyProjects
    ]
  },
  {
    actionType: ActionTypes.projectInformation,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_tooltip',
    requiredPermissions: [
      ProjectActionsPermissions.readProject,
      new UserProjectPermissions().ReadAllProjects
     ]
  },
  {
    actionType: ActionTypes.documentTemplateUpdate,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_tooltip',
    requiredPermissions: [
      ProjectActionsPermissions.updateProject,
      new UserProjectPermissions().UpdateAllProjects
    ]
  },
  {
    actionType: ActionTypes.deleteProject,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_delete',
    requiredPermissions: [
      ProjectActionsPermissions.deleteProject,
      new UserProjectPermissions().UpdateAllProjects
    ]
  },
  {
    actionType: ActionTypes.bulkUploadResponses,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_responses_upload',
    requiredPermissions: [
      ProjectActionsPermissions.createResponses
    ]
  },
  {
    actionType: ActionTypes.bulkDownloadResponses,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_responses_download',
    requiredPermissions: [ 
      ProjectActionsPermissions.readResponses,
      new UserOtherPermissions().ReadAllResponses
    ]
  },
  {
    actionType: ActionTypes.closeReopen,
    navPlacement: NavPlacement.secondary,
    icon: 'icon-ico_unlock',
    requiredPermissions: [
      ProjectActionsPermissions.updateProject,
      new UserProjectPermissions().UpdateAllProjects
    ]
  }
];
