import { Injectable } from '@angular/core';
import { IAction } from './grid-actions/action.model';

@Injectable({
  providedIn: 'root',
})
export class BasePermissionsService {

  public removeActionsBasedOnPermissions(actions: IAction[], permissionsList: string[]): IAction[] {
    return actions.filter((a) => this.hasPermission(a.requiredPermissions, permissionsList));
  }

  public hasPermission(requiredPermission: string[], permissionList: string[]): boolean {
    if (!permissionList) {
      return false;
    }
    
    return permissionList.find(p => requiredPermission.some(permission => permission === p)) !== undefined;
  }
}
