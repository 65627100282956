

export class UserModulePermissions {
  ReadFavouriteModules = 'favouritemodules.read';
  ReadModulesLibrary = 'moduleslibrary.read';
  ReadMyModules = 'mymodules.read';
  ReadMyReview = 'myreviews.read';
  CreateModules = 'modules.create';
  ReadAllGoldModules = 'allgoldmodules.read';
  ReadAllStdModules = 'allstdmodules.read';
  CreateGoldModules = 'goldmodules.create';
  ReadAllHiddenModules = 'allhidemodules.read';
  DeleteAllModulesFlow = 'allmodulesflow.delete';
  CreateAllModulesFlow = 'allmodulesflow.create';
  DeleteAllModules = 'allmodules.delete';
  UpdateAllModules = 'allmodules.update';
  ReadAllGoldModulesFlow = 'allgoldmodulesflow.read';
  ReadAllHiddenModulesFlow = 'allhidemodulesflow.read';
  ReadAllModulesBenefit = 'allmodulesbenefit.read';
  UpdateAllModulesBenefit = 'allmodulesbenefit.update';
  ReadAllModulesFlow = 'allmodulesflow.read';
  UpdateAllModulesFlow = 'allmodulesflow.update';
  CreateAllModulesMembers = 'allmodulesmembers.create';
  UpdateAllModulesMembers = 'allmodulesmembers.update';
  ReadAllModulesMembers = 'allmodulesmembers.read';
  DeleteAllModulesMembers = 'allmodulesmembers.delete';
  ReadAllModulesProjects = 'allmodulesprojects.read';
  ReadAllStdModulesFlow = 'allstdmodulesflow.read';
  ReadAllStdModulesProjects = 'allstdmodulesprojects.read';
  ReadAllGoldModulesProjects = 'allgoldmodulesprojects.read';
}

export class UserProjectPermissions {
  CreateProjects = 'projects.create';
  ReadProjects = 'projects.read';
  ReadAllDemoProjects = 'alldemoprojects.read';
  UpdateAllDemoProjects = 'alldemoprojects.update';
  DeleteAllProjects = 'allprojects.delete';
  ReadAllProjects = 'allprojects.read';
  UpdateAllProjects = 'allprojects.update';
  CreateAllProjectsMembers = 'allprojectsmembers.create';
  ReadAllProjectsMembers = 'allprojectsmembers.read';
  UpdateAllProjectsMembers = 'allprojectsmembers.update';
  DeleteAllProjectsMembers = 'allprojectsmembers.delete';
  ReadAllProjectsParticipiants = 'allprojectsparticipiants.read';
  UpdateAllProjectsParticipiants = 'allprojectsparticipiants.update';
  DeleteAllProjectsParticipiants = 'allprojectsparticipiants.delete';
  CreateAllProjectsParticipiants = 'allprojectsparticipiants.create';
  ReadMyProjects = 'myprojects.read';
}

export class UserOtherPermissions
{
  ReadAdminSetup = 'adminsetup.read';
  ReadAllBenefit = 'allbenefit.read';
  CreationAllCollaborationResponses = 'allcollaborationresponses.creation';
  CreateAllQRMResponses = 'allQRMresponses.create';
  CreateAllResponses = 'allresponses.create';
  DeleteAllResponses = 'allresponses.delete';
  ReadAllResponses = 'allresponses.read';
  UpdateAllResponses = 'allresponses.update';
  ReadAllResponsesFiles = 'allresponsesfiles.read';
  ReadAllResponsesHiddenAutDec = 'allresponseshiddenautdec.read';
  ReadAllResponsesHiddenFiles = 'allresponseshiddenfiles.read';
  ReadAllResponsesHiddenReport = 'allresponseshiddenreport.read';
  ReadAllResponsesHiddenTools = 'allresponseshiddentools.read';
  ReadApprovalWorkflow = 'approvalworkflow.read';
  UpdateApprovalWorkflow = 'approvalworkflow.update';
  ReadCountryPolicy = 'countrypolicy.read';
  UpdateCountryPolicy = 'countrypolicy.update';
  ReadDataPrivacyNotice = 'dataprivacynotice.read';
  UpdateDataPrivacyNotice = 'dataprivacynotice.update';
  ReadEymasterdata = 'eymasterdata.read';
  ReadGeneralSetting = 'generalsetting.read';
  UpdateGeneralSetting = 'generalsetting.update';
  ReadHelp = 'help.read';
  ReadLogCenter = 'logcenter.read';
  UpdateMasterData = 'masterdata.update';
  ReadMyResponses = 'myresponses.read';
  ReadMyTasks = 'mytasks.read';
  ReadReportAuditTrail = 'reportaudittrail.read';
  ReadReportDataAccess = 'reportdataaccess.read';
  ReadReportSummary = 'reportsummary.read';
  ReadReportUsage = 'reportusage.read';
  ReadReportUserLogin = 'reportuserlogin.read';
  ReadReportUserRole = 'reportuserrole.read';
  ReadSyncSetting = 'syncsetting.read';
  UpdateSyncSetting = 'syncsetting.update';
  ActivityUser = 'user.activity';
  CreateUserEx = 'userex.create';
  DeleteUserEx = 'userex.delete';
  ReadUserEx = 'userex.read';
  UpdateUserEx = 'userex.update';
  CreateUserEy = 'userey.create';
  DeleteUserEy = 'userey.delete';
  ReadUserEy = 'userey.read';
  UpdateUserEy = 'userey.update';
  ReadDomainSettings = 'domainsettings.read';
  UpdateDomainSettings = 'domainsettings.update';
  DeleteDomainSettings = 'domainsettings.delete';
  DashboardRead = 'dashboard.read';
}

export class UserPermissions {
  module = new UserModulePermissions();
  project = new UserProjectPermissions();
  other = new UserOtherPermissions();
}
