import {FormBuilderControlTypes} from './form-builder-control.types';

export enum SaveStatus  {
  notSaved = 'Unsaved',
  saveInProgress = 'Saving...',
  saved = 'Saved'
}


export interface FormBuilderControlHelper {
  fbs: FormBuilderControl[];
  specificPartId: string;
  hintDescription: string;
  isHidden: boolean;
  name: string;
}

export interface FormBuilderControl {
    controlId: string;
    type: FormBuilderControlTypes;
    order: number;
    properties: FormBuilderControlProperties;
    isActive: boolean;
    index: number;
}

export interface FormBuilderControlProperties {
  name: string;
  question?: string;
  hint?: string;
  required?: boolean;
  options?: Array<any>;
  id: string;
  criteria?: Array<any>;
  operator?: string;
  isCriteriaSet?: boolean;
  selectedOptionIndex?: number;
  restrictedCombinations?: Array<any>;
  selectionLimits: SelectionLimits;
}

export interface FormBuilderState {
  formBuilderId: string;
  moduleId: string;
  versionId: string;
  name: string;
  controls: FormBuilderControl[];
  activeControlIndex: number;
  activeControlType: FormBuilderControlTypes;
  specificPartId: string;
  hintDescription: string;
  saveStatus: SaveStatus;
  dataLoaded: boolean;
  isHidden: boolean;
  versionStatus: number;
}

export interface SelectionLimits {
  selectionLimit?: number;
  min?: string;
  max?: string;
  exactly?: string;
}


