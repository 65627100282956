import { Component, ElementRef, forwardRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { TableBuilderFormBaseComponent } from '../table-builder-form-base.component';
import { LabelComponent } from '../label/label.component';
import {
  OptionAction,
  OptionActionEventArg,
  OptionComponent,
} from '../../../../workflow-designer/properties-editor/dynamic-controls/scores-control/option/option.component';
import {
  CSS_DND_CURSOR,
  FOCUS_DELAY,
  INHERIT_CURSOR_CSS_CLASS,
  IOption,
} from '../../../../workflow-designer/properties-editor/dynamic-controls/scores-control/scores-control.component';

import * as _ from 'lodash';
import { CdkDragStart } from '@angular/cdk/drag-drop';
import { v4 as uuid } from 'uuid';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastTypes } from '../../../../../shared/components/ey-toast/toast-types.enum';
import { IFieldMeta } from '../../../../../shared/components/ey-base-form-control/field-meta.model';
import { EyToastService } from '../../../../../shared/components/ey-toast/ey-toast.service';

const OPTION_TOKEN_NAME = 'CTR_TOKEN';
const OPTION_NAME = `Option ${OPTION_TOKEN_NAME}`;

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabelComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LabelComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent extends TableBuilderFormBaseComponent {
  bodyElement: HTMLElement = document.body;
  form: FormGroup;
  destroy$ = new Subject<boolean>();
  optionsMeta: IFieldMeta[] = [];
  @ViewChildren(OptionComponent) optionComponents: QueryList<OptionComponent>;


  public onTouched: () => void = () => {};
  onChange: (val: any) => void = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get optionsArray(): FormArray {
    return this.form.get('options') as FormArray;
  }
  getRawValues(): IOption[] {
    return this.optionsArray.getRawValue();
  }

  constructor(private fbs: FormBuilder, private els: ElementRef, private eyToastService: EyToastService) {
    super(fbs, els);
    this.form = this.fbs.group({
      options: this.fbs.array([]),
    });
  }

  getMinDisplayOrder(): number {
    return this.optionsArray.getRawValue().length > 0
      ? _.orderBy(
          this.optionsArray.getRawValue(),
          ['displayOrder']
        )[0].displayOrder
      : 0;
  }

  drop(ea: any): void {
    if (ea.currentIndex !== ea.previousIndex) {
      const oldOrder = this.getRawValues()[ea.previousIndex].displayOrder;
      const swapCandidate = this.getRawValues()[ea.currentIndex];
      const opt = this.changeDisplayOrder(oldOrder, OptionAction.up, swapCandidate);
      this.writeValue({options: opt});
      this.onChange({options: opt});
      this.onTouched();
    }
    this.bodyElement.classList.remove(INHERIT_CURSOR_CSS_CLASS);
    this.bodyElement.style.cursor = 'unset';
  }

  writeValue(val: any): void {
    this.createOptions(val);
  }

  createOptions(val: any): void {
    let options: IOption[] = [];

    this.form = this.fbs.group({
      options: this.fbs.array([]),
    });

    if (val?.options) {
      options = val.options;
      if (options && options.length > 0) {
        _.orderBy(options, ['displayOrder']).forEach((o) => this.createNewOption(o));
      }
    } else {
      this.onAddOption(true);
      timer(FOCUS_DELAY)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.onChange( {options: this.getRawValues()});
          this.onTouched();
        });
    }

    this.subscribeToValueChanges();
  }

  subscribeToValueChanges(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((opt: any) => {
      this.onChange( opt);
      this.onTouched();
    });

  }

  changeDisplayOrder(oldOrder: number, action: OptionAction, swapCandidate: IOption = null): IOption[] {
    const values: IOption[] = this.getRawValues();

    const first = { ...values.find((t) => t.displayOrder === oldOrder) };
    swapCandidate = swapCandidate == null ? this.getSwapCandidate(values, oldOrder, action) : swapCandidate;
    const newOrder = swapCandidate.displayOrder;
    const newValues = [
      ...values.filter((t) => t.displayOrder !== oldOrder && t.displayOrder !== newOrder),
      { ...first, displayOrder: newOrder },
      { ...swapCandidate, displayOrder: oldOrder },
    ];

    return newValues;
  }

  getSwapCandidate(values: IOption[], itemOrder, action: OptionAction): IOption {
    if (action === OptionAction.up) {
      return _.orderBy(
        values.filter((o) => o.displayOrder < itemOrder),
        ['displayOrder']
      ).reverse()[0];
    } else {
      return _.orderBy(
        values.filter((o) => o.displayOrder > itemOrder),
        ['displayOrder']
      )[0];
    }
  }

  getMaxDisplayOrder(): number {
    const options = this.optionsArray.getRawValue();
    const displayOrder = options.length > 0
      ? _.orderBy(
        options,
          ['displayOrder']
        ).reverse()[0].displayOrder
      : 0;
    return displayOrder;
  }

  dragStart(event: CdkDragStart): void {
    this.bodyElement.classList.add(INHERIT_CURSOR_CSS_CLASS);
    this.bodyElement.style.cursor = CSS_DND_CURSOR;
  }

  onAddOption(suppressFocus: boolean = false): void {
    const maxOrder = this.getMaxDisplayOrder();
    const newDisplayOrder = this.optionsArray.length === 0 ? 0 : maxOrder + 1;

    const newOption: IOption = {
      name: OPTION_NAME.replace(OPTION_TOKEN_NAME, (newDisplayOrder + 1).toString()),
      score: 0,
      displayOrder: newDisplayOrder,
      conId: uuid(),
    };
    this.createNewOption(newOption);

    /* todo(MK): might be ok if we get any errors logged with focus pls get in touch with me */
    /* alternative solution would be to set focus via input in control (after view init)
    however we would have to deal with concurrent patch operations and that might be complex */
    if (suppressFocus) {
      return;
    }

    timer(FOCUS_DELAY)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.optionComponents.last.setFocus());
  }

  createNewOption(option: IOption): void {
    this.optionsMeta.push({
      title: 'Option',
    });

    this.optionsArray.push(
      this.fbs.control(
          option
      )
    );
  }

  onAction(actionEA: OptionActionEventArg): void {
    let newValues: IOption[] = [];
    switch (actionEA.optionAction) {
      case OptionAction.up:
        newValues = this.changeDisplayOrder(actionEA.displayOrder, OptionAction.up);
        break;
      case OptionAction.down:
        newValues = this.changeDisplayOrder(actionEA.displayOrder, OptionAction.down);
        break;
      case OptionAction.remove:
        newValues = this.getRawValues().filter((o) => o.displayOrder !== actionEA.displayOrder);
        break;
      case OptionAction.copy:
        navigator.clipboard.writeText(actionEA.name).then(() => {
          this.eyToastService.add({ id: '1', text: 'copied to clipboard', type: ToastTypes.info });
        });
        return;
      default:
        alert('Unknown Action');
        return;
    }
    this.writeValue({options: newValues});
    this.onChange( {options: newValues});
    //this.onTouched();
  }


}
