import {Directive, ElementRef, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import {NgControl} from '@angular/forms';
import {NumberFormatService} from '../../../builders/table-builder/table/number-format.service';
import {CATEGORY_PERCENT, SeparatorEnum} from '../../../builders/table-builder/controls/controls.const';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tableCellFormatOnBlur]',
  exportAs: 'tableCellFormatOnBlur'
})
export class TableCellInputFocusDirective implements OnInit {
  @Input() tableCellFormatOnBlur: any;
  @Input() transformFormItem = true;
  @HostBinding('class.my-focused-element') isFocused: boolean;
  tableCellValue: any;
  constructor(public model: NgControl, private elRef: ElementRef, private numberFormatService: NumberFormatService) {}

  @HostListener('focus', ['$event']) onFocus(e): void {
    this.isFocused = true;
    this.elRef.nativeElement.getElementsByTagName('input')[0].value = (Number(this.tableCellValue) === 0 || !this.tableCellValue) ? '' : Number(this.tableCellValue);
  }

  @HostListener('blur', ['$event']) onblur(e): void {
    this.isFocused = false;
    this.tableCellValue = this.tableCellFormatOnBlur.value;
    this.elRef.nativeElement.getElementsByTagName('input')[0].value = this.formatTableNumber(this.tableCellValue, this.tableCellFormatOnBlur);
  }

  formatTableNumber(val: number, item: any): string {
    if (val == null || undefined === val ) {
      return '';
    }
    let fieldProperties = item;
    if (this.transformFormItem) {
      fieldProperties = {
        ...item,
        category: item.properties.isPercentage ? CATEGORY_PERCENT : '',
        useThousandsSeparator: item.properties.thousandsSeparator != null ? true : false,
        thousandsSeparator:   SeparatorEnum[item.properties.thousandsSeparator],
        decimalSeparator: SeparatorEnum[item.properties.decimalSeparator],
      };
    }
    return this.numberFormatService.formatTableCellNumber(val, fieldProperties);
  }

  ngOnInit(): void {
    this.elRef.nativeElement.getElementsByTagName('input')[0].addEventListener('focus', (e) => this.onFocus(e));
  }
}
