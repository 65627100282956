import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';
import { catchError, elementAt, map, tap } from 'rxjs/operators';

export enum ModalSize {
    Small = 'sm',
    Large = 'lg',
    MonstrouslyBig = 'xl'
}

export interface ModalCloseResult {
    reason: string;
}

export enum DismissedBecause {
    EscapeClick = "Escape",
    CrossClick = "Cross",
    BackdropClick = "Backdrop",
    DismissButtonClick = "Dismiss",
}

export interface ModalOptions {
    header?: string;
    closeButtonName?: string;
    closeButtonTitle?: string;
    disabledCloseButtonTooltip?: string;
    dismissButtonName?: string;
    dismissButtonTooltip?: string;
    size?: ModalSize;
    forceScrollToBottom?: boolean;
    isContentHeavyModal?: boolean;
    modalClasses?: Array<string>;
}

export const defaultOptions: ModalOptions = {
    header: 'Modal header',
    closeButtonName: 'Horosho',
    dismissButtonName: 'Close',
    size: ModalSize.Large,
    forceScrollToBottom: false,
    isContentHeavyModal: false,
    modalClasses: [],
}

@Component({
    selector: 'app-modal',
    templateUrl: './modal-popup.component.html',
    styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnChanges, AfterViewChecked {
    private CONTENT_HEAVY_CLASS = 'modal-heavy';

    @Input() body: TemplateRef<any> = null;
    @Input() options: ModalOptions = null;

    @ViewChild('modal') modal: TemplateRef<any> = null;

    public disableCloseButton: boolean = false;

    private modalRef: NgbModalRef = null;

    private bodyScrolled: boolean = false;
    private isScrollable: boolean = false;

    constructor(private modalService: NgbModal) {
    }

    ngAfterViewChecked(): void {
        const element = document.getElementById('modalBody');

        // If the modal is opened second time
        // scrollHeigh will be equal to client height though it should not
        // I suppose it's a browser bug. Therefore we need to maintain this
        // Between modal openings
        if (!this.isScrollable) {
            if (element !== null && !this.bodyScrolled) {
                this.disableCloseButton = this.options.forceScrollToBottom && (element.scrollHeight !== element.clientHeight)
                    ? true
                    : null;
                this.isScrollable = this.disableCloseButton;
            }
        } else if(!this.bodyScrolled) {
            this.disableCloseButton = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.options = {...defaultOptions, ...this.options };
    }

    open(): Observable<ModalCloseResult> {
        this.modalRef = this.modalService.open(
            this.modal,
            { 
                backdrop: 'static',
                size: this.options.size,
                windowClass: this.getModalClass()
            });
        
        return from(this.modalRef.result).pipe(
            tap(() => this.bodyScrolled = false),
            map(result => { return { reason: result }}),
            catchError(err => {
                this.bodyScrolled = false;
                throw this.getDismissReason(err);
            })
        );
    }

    onScroll(event: UIEvent) {
        this.bodyScrolled = true;
        const target = event.target as Element;
        const gapBeforeElementEnd = 10;

        const scrolled = target.scrollHeight - target.scrollTop
        const scrolledToBottom = scrolled < (target.clientHeight + gapBeforeElementEnd);

        this.disableCloseButton = this.options.forceScrollToBottom && !scrolledToBottom
            ? true
            : null;
    }

    get closeButtonTooltip(): string {
        if (this.disableCloseButton) {
            return this.options.disabledCloseButtonTooltip;
        }
        
        return this.options.closeButtonTitle;
    }

    get dismissButtonTooltip(): string {
        return this.options.dismissButtonTooltip;
    }

    private getModalClass(): string {
        const classes = this.options.modalClasses;

        if (this.options.isContentHeavyModal) {
            classes.push(this.CONTENT_HEAVY_CLASS);
        }

        return classes.reduce((prev, next) => `${prev} ${next}`, "");
    }
   
    private getDismissReason(reason: any): string {
        switch(reason) {
            case ModalDismissReasons.ESC: 
                return DismissedBecause.EscapeClick;
            case ModalDismissReasons.BACKDROP_CLICK:
                return DismissedBecause.BackdropClick
            case 'cross click':
                return DismissedBecause.CrossClick
            case 'dismiss button':
                return DismissedBecause.DismissButtonClick
        }
    }
}
