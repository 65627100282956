import { Component, EventEmitter, Input, Output } from '@angular/core';
let tgl_id = 0;
@Component({
    selector: 'ey-toggle-template',
    templateUrl: './ey-toggle-template.component.html',
    styleUrls: ['./ey-toggle-template.component.scss']
})
export class EyToggleTemplateComponent {
    @Input() displayEnableDisable = false;
    @Input() ariaLabel = 'toggle';
    @Input() selectedValue: boolean;
    @Input() slim: boolean;
    @Output() selectedChanged = new EventEmitter<boolean>();
    @Input() disabledTooltip = '';
    @Input() isDisabled = false;

    id = tgl_id++;
    onChange(): void {
        this.selectedValue = !this.selectedValue;
        this.selectedChanged.next(this.selectedValue);
    }
}
