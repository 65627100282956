import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Page, ViewTypes} from '../../page.model';
import {ButtonClass} from '../../../../shared/components/ey-button/ey-button.component';
import {File} from './summary-sections/download-documents-summary/download-documents-summary.component';
import { SummaryDocumentModel } from '../../summary-document.model';
import {ModuleFlowService} from '../../module-flow.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {saveAs as importedSaveAs} from 'file-saver';
import {Subject} from 'rxjs/internal/Subject';
import {EyAppSpinnerService} from '../../../../shared/components/ey-app-spinner/ey-app-spinner.service';
import {ActivatedRoute} from '@angular/router';
import {ProjectsService} from '../../../../projects/projects.service';
import { format } from 'date-fns';
import { SummaryPdfNameComponents } from './response-summary.model';

const PROGRESS_COUNT = 100;
export const DOC_LIST: File[] = [
  {
    fileName: 'sample-file-name.doc',
    fileId: 'fdafdsaffd'
  },
  {
    fileName: 'sample-file-name.doc',
    fileId: 'fdafdsaffd'
  },
  {
    fileName: 'sample-file-name.doc',
    fileId: 'fdafdsaffd'
  },
  {
    fileName: 'sample-file-name.doc',
    fileId: 'fdafdsaffd'
  }
];

@Component({
  selector: 'app-response-summary',
  templateUrl: './response-summary.component.html',
  styleUrls: ['./response-summary.component.scss']
})
export class ResponseSummaryComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() summary: Page[];
  @Input() selectedViewType: ViewTypes = ViewTypes.desktop;
  @Input() docList: SummaryDocumentModel[];
  @Input() summaryPreviewModeOn = false;
  @Input() responseId: string;
  btnClass = ButtonClass;
  stepsQty: number;
  private baseUrl = environment.baseUrl;
  private summaryPdfNameComponents: SummaryPdfNameComponents = new SummaryPdfNameComponents();;

  constructor(private flowService: ModuleFlowService, private httpClient: HttpClient, private spinnerService: EyAppSpinnerService,
              private route: ActivatedRoute, private projectService: ProjectsService, ) { }

  ngOnInit(): void {
    this.responseId = this.summary[0].sessionId;
    this.flowService.getSummaryPdfComponents(this.responseId).subscribe(parts => {
      this.summaryPdfNameComponents.projectName = parts.projectName;
      this.summaryPdfNameComponents.respondentName = parts.respondentName;
      this.summaryPdfNameComponents.responseDate = parts.responseDate;
    });

    this.flowService.summaryPageProgressCount.next(PROGRESS_COUNT);
    this.stepsQty = this.summary.length;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  downloadResponsePdf(summary: string): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/DocumentPipeline/summary-pdf`, { summary } , {observe: 'response', responseType: 'blob' });
  }

  generateSummaryPDF( ): void {
    const html = document.documentElement.outerHTML;
    const css = '<style>' + this.getAllStyles() + '</style>';

    this.spinnerService.withLoadingIndicator(this.downloadResponsePdf(css + html), this.destroy$).subscribe(x => {
      const dateTime = new Date(this.summaryPdfNameComponents.responseDate);
      const formattedDate = format(dateTime, 'ddMMyyyy');
      const formattedTime = format(dateTime, 'HH-mm-ss');
      const formattedDateTime = `${formattedDate}_${formattedTime}`;

      const downloadedSummaryFileName = this.summaryPdfNameComponents.projectName + '_' + this.summaryPdfNameComponents.respondentName + '_' + formattedDateTime;
      importedSaveAs(x.body, (downloadedSummaryFileName));
    });
  }

  getAllStyles(): string {
    let css = ''; // variable to hold all the css that we extract
    const styletags = document.getElementsByTagName('style');

    // loop over all the style tags
    for (let i = 0; i < styletags.length; i++)
    {
        css += styletags[i].innerHTML; // extract the css in the current style tag
    }

    // loop over all the external stylesheets
    for (let i = 0; i < document.styleSheets.length; i++)
    {
        const currentsheet = document.styleSheets[i];
        // loop over all the styling rules in this external stylesheet
        for (let e = 0; e < currentsheet.cssRules.length; e++)
        {
            css += currentsheet.cssRules[e].cssText; // extract all the styling rules
        }
    }

    return css;
}
}
