import { Component, forwardRef } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EyBaseToggleControlComponent } from '../ey-base-form-control/ey-base-toggle-control';

@Component({
  selector: 'ey-toggle',
  templateUrl: './ey-toggle.component.html',
  styleUrls: ['./ey-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyToggleComponent),
      multi: true,
    },
  ],
})
export class EyToggleComponent extends EyBaseToggleControlComponent {
  constructor(controlContainer: ControlContainer) {
    super(controlContainer);
  }
}
