<form>
    <div class="d-flex autosave">
        <ng-container *ngIf="showAutoSave && !readOnlyState">
            <div class="mr-2 label">Autosave</div>
            <div class="save-toggle"><ey-toggle title="Save/Auto save" [(ngModel)]="isAutoSave" (ngModelChange)="onChanges($event)" [ngModelOptions]="{ standalone: true}"></ey-toggle></div>
        </ng-container>
        <div class="mx-2">
            <a href class="anchor-button" [class.disabled]="saveDisabled || readOnlyState" (click)="onSaveClick(); $event.preventDefault();">
                <i class="icon-ico_save emp-icon" title="Save"></i>
            </a>
        </div>
    </div>
</form>
