import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import {ActivatedRoute, Data, NavigationEnd, Params, Router, RouterState} from '@angular/router';
import { Title } from '@angular/platform-browser';
import {ApplicationState} from '../../application-state/applictaion.reducer';
import { select, Store} from '@ngrx/store';

export const PAGE_TITLE_BASE = 'Empowerment ';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  private currentPageTitleUpdatedFromStore: string = null;
  constructor(private store: Store<{ applicationState: ApplicationState }>) {}

  setPageTitle(router: Router, titleService: Title, activatedRoute: ActivatedRoute): void {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => router)
      )
      .subscribe((event) => {
        this.getChild(activatedRoute).data.subscribe((data) => {
          this.setTitleForCurrentTab(data, titleService, router);
          this.subscribeToStoreChangesAndSetTitleForCurrentTab(titleService);
        });
      });
  }

  setTitleForCurrentTab(data: Data, titleService: Title, router: Router): void {
    if (data.title !== undefined) {
      titleService.setTitle(PAGE_TITLE_BASE + data.title);
    } else {
      const title = this.getTitle(router.routerState);
      titleService.setTitle(title);
    }
  }

  subscribeToStoreChangesAndSetTitleForCurrentTab(titleService: Title): void {
    this.store.pipe(select((state) => state.applicationState)).subscribe((state) => {
      if (this.currentPageTitleUpdatedFromStore !== state?.pageData?.title) {
        this.currentPageTitleUpdatedFromStore = state?.pageData?.title;
        titleService.setTitle(this.currentPageTitleUpdatedFromStore);
      }
    });
  }

  private upperCase(title: string): string {
    const words = title.split(' ');
    return words
      .map((word) => {
        if (word.length > 0) {
          return word[0].toUpperCase() + word.substring(1);
        }
        return '';
      })
      .join(' ');
  }

  private getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private getTitle(state: RouterState): string {
    try {
      const guidPattern = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/gi;
      let url = state.snapshot.url;
      url = url.split('?')[0]; // get rid of query params
      url = url.split('#')[0]; // get rid of ms tokens
      url = url.replace(guidPattern, ''); // get rid of quids
      url = url.replace(/[\/\-]/g, ' ').toLowerCase(); // split
      return PAGE_TITLE_BASE + this.upperCase(url);
    } catch (ex) {
      return PAGE_TITLE_BASE;
    }
  }
}
