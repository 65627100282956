import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl } from '@angular/forms';
import { IFieldMeta } from './field-meta.model';
import { DEFAULT_TITLE } from '../constants';

let control_id = 0;

@Component({
  selector: 'ey-base-form-control',
  template: ''
})
export class EyBaseFormControlComponent {
  @Input() formControl: FormControl;
  @Input() formControlName: string | number;
  @Input() meta: IFieldMeta = {title: DEFAULT_TITLE};
  @Input() standaloneHasError = false;

  @Input() isDisabled: boolean;
  /* set disable state is not called
  read more https://github.com/angular/angular/issues/35309
  as a temp solution converting isDisabled to input field
  */

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  ctrl_id = control_id++;

  isFocus: boolean = false;

  // If formControlName is given, then controlContainer.control is the parent FormGroup/FormArray instance.
  get control(): any {
    const path = typeof this.formControlName === 'number' ? [this.formControlName] : this.formControlName;
    return this.formControl || this.cc.control.get(path);
  }
  public get isRequired(): any {

    return (this.control as AbstractControl).getError('required');
  }

  public get showError(): boolean {
    if (!this.control) {
      return this.standaloneHasError;
    }

    const { dirty, touched, disabled } = this.control;
    // this.control.status !== 'PENDING' this checks if the control being validated is not in pending state. This is done so as to
    // overcome te issue when controls have async validators and they are not yet completed it still shows the error unless control
    // is in pending state(which means not valid) or the async operation is not yet completed. Adding the check resolves the issue and is expected behaviour.
    return (!this.control.valid && this.control.status !== 'PENDING' && !disabled) ? (dirty || touched) : false;
  }

  constructor(private cc: ControlContainer, private ref: ChangeDetectorRef = null) {
  }

  onBlur(): void {
    this.isFocus = false;
  }

  onFocus(): void {
      this.isFocus = true;
      /* opening modal creates race condition. Fixes ExpressionChangedAfterItHasBeenCheckedError */
      this.ref?.detectChanges();
  }
}
