<form [formGroup]="form">
  <div class="d-flex flex-fill flex-column">
    <p class="eyfontbold">Number Formatting</p>
    <div class="d-flex flex-fill flex-column">
      <ey-drop-down formControlName="category" [meta]="meta.category" [values]="categories" [defaultValue]="defCategoryNumber" noInfo="true" class="mb-20"></ey-drop-down>
      <ey-input formControlName="decimalPlaces" type="number" [meta]="meta.decimalPlaces" extra="none" class="mb-20"></ey-input>
      <ng-container *ngIf="categoryCtrl?.value?.name === defCategoryNumber.name">
      <fieldset [disabled]="true">
      <ey-drop-down formControlName="decimalSeparator" [isDisabled]="true" [meta]="meta.decimalSeparator" [values]="decimalSeparators" [defaultValue]="defDecSeparator" noInfo="true" class="mb-20"></ey-drop-down>
      </fieldset>
        <br />
      <label class="checkbox checkbox-margin">
        <input
          class="checkbox__input"
          type="checkbox"
          formControlName="useThousandsSeparator"
        />
        <span class="checkbox__checkmark checkbox-background"></span>
        <p class="checkbox__label">Use 1000 separator</p>
      </label>
      <ey-drop-down
        *ngIf="thousandsSeparatorChecked"
        formControlName="thousandsSeparator"
        [meta]="meta.thousandSeparator"
        [values]="thousandsSeparators"
        [defaultValue]="defThoSeparator"
        noInfo="true">
      </ey-drop-down>
      </ng-container>
    </div>
  </div>
</form>

<div *ngIf="showFormulaSection">
  <hr />
  <p class="eyfontbold mb-10">Formulas</p>
  <p class="fonts-col__body-copy mb-0">
  You can use formulas to do in-table calculations, ie.:
  </p>
  <ul class="p-0 mb-10">
    <li class="fonts-col__body-copy">- Basic math operations "=A2+B2+C2", "=A1*A2"</li>
    <li class="fonts-col__body-copy">- Adding range of cells "=SUM(A1:C2)"</li>
    <li class="fonts-col__body-copy">- Bracket equations "=((A2+B2)*C2)/D2"</li>
  </ul>
  <a class="preview-link mt-10" target="_blank" href="https://sites.ey.com/sites/Empowerment/Empowerment%20Wiki/Formulas%20on%20Table%20tool.aspx"><i class="emp-icon icon-ico_new_window"></i>  <span class="ml-0">Learn more</span></a>
</div>


