import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

export const LOGOUT_MSG =  'You have been logged out successfully.';
export const AUTO_LOGOUT_MSG =  'You have been logged out automatically due to inactivity.';

@Component({
    selector: 'app-logged-out',
    templateUrl: './logged-out-with-reason.component.html',
    styleUrls: ['./logged-out-with-reason.component.scss']
})
export class LoggedOutWithReasonComponent implements OnInit {
    environment$: Observable<string> = null;

    @Input() noticeText: string;
    @Input() isSuccessfull = false;
    @Input() auto = false;
    @Input() isExternalUserWithoutAccount = false;
    @Input() isUserBlocked = false;
    logoutMsg = '';

    constructor(private router: Router) {
    }

    ngOnInit(): void {
      this.logoutMsg = this.auto ? AUTO_LOGOUT_MSG : LOGOUT_MSG;
    }

  onLoginAgain(): void {
        this.router.navigate(['']);
    }
}
