import { createAction, props } from '@ngrx/store';
import { DecisionShape, RectangularShape, Shape } from './shape';
import {createNonUndoableAction} from '../utils/action-utils';
import { CanvasDesignerPersistentState } from '../domain/canvas-state';

export const addShapeToCanvas = createAction('[Canvas Actions] Add Shape to Canvas', props<{ shape: Shape }>());
export const copyComplexShapeToCanvas = createAction('[Canvas Actions] Copy Complex Shape to Canvas', props<{ shape: Shape, toolId: string }>());
export const addSimpleShape = createAction('[Canvas Actions] Add Simple Shape to Canvas', props<{ shape: Shape }>());
export const addDecisionShape = createAction('[Canvas Actions] Add Deciosion Shape to Canvas', props<{ shape: DecisionShape }>());
export const addYesNoShape = createAction('[Canvas Actions] Add Yes/No Shape to Canvas', props<{ shape: RectangularShape }>());
export const addConnectionToDecisionTool =
    createAction('[Canvas Actions] Add Connection to Decision tool', props<{ decisionToolId: string, connectionId: string, label: string }>());
export const changeConnectionLabel =
    createAction('[Canvas Actions] Change Connection Label', props<{ connectionId: string, newLabel: string }>());
export const moveShape = createAction('[Canvas Actions] Move shape', props<{ shape: Shape }>());
export const moveShapes = createAction('[Canvas Action] Move multiple shapes', props<{ shapes: Shape[] }>());
export const deleteShapes = createAction('[Canvas Actions] Delete shapes', props<{ ids: string[] }>());
export const deleteDependentConnections = createNonUndoableAction('[Canvas Actions] Delete dependent conns', props<{ ids: string[] }>());

export const clearShapes = createNonUndoableAction('[Canvas Actions] Clear shapes');
export const loadState = createNonUndoableAction('[Canvas Actions] Load Config', props<{ shapes: Shape[] }>());

export const updateShape = createAction('[Canvas Actions] Update Shape', props<{ shape: Shape }>());
export const renameTool = createAction('[Canvas Action] Rename Tool', props<{ id: string, name: string}>());

export const persistDesignerPosition = createNonUndoableAction('[Canvas Actions] Persist designer position', props<{ position: CanvasDesignerPersistentState }>());
export const clearDesignerPosition = createNonUndoableAction('[Canvas Actions] Clear designer position');


