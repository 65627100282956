import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { CompleteProfileComponent } from '../user/complete-profile/complete-profile.component';
import { CoreModule } from './../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from './../shared/components/components.module';
import { MultipleSessionsActiveModalComponent } from './multiple-sessions-active-modal/multiple-sessions-active-modal.component';

@NgModule({
  imports: [NgbModule, CommonModule, CoreModule, FormsModule, ReactiveFormsModule, ComponentsModule],
  declarations: [CompleteProfileComponent, MultipleSessionsActiveModalComponent],
  exports: [CompleteProfileComponent],
  entryComponents: [CompleteProfileComponent]
})

export class UserModule {}
