import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { IUserFromSearch } from '../models/user/user-from-search.model';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  private usersEndpoint = 'https://graph.microsoft.com/v1.0/users';
  private externalUserMarker = '#EXT#';

  constructor(private httpClient: HttpClient) {}

  searchUsers(searchString): Observable<any> {
    const request = `${this.usersEndpoint}?$filter=startswith(displayName,\'${searchString}\') + or + startswith(givenName,\'${searchString}\') + or + startswith(surname,\'${searchString}\') + or + startswith(mail,\'${searchString}\') + or + startswith(userPrincipalName,\'${searchString}\')`;
    return this.httpClient.get<any>(request).pipe(map((x) => x.value));
  }

  searchUserByEmail(searchString): Observable<IUserFromSearch> {
    const request = `${this.usersEndpoint}?$filter=mail eq \'${searchString}\'`;
    return this.httpClient.get<any>(request).pipe(
      map((x) => x.value),
      map((em: any[]) => {
        if (em.length > 0) {
          const e = em[0];
          return {
            isExisting: true,
            searchText: searchString,
            id: e.id,
            name: e.displayName,
            email: e.mail,
            isExternal: (e.userPrincipalName as string).includes(this.externalUserMarker),
          } as IUserFromSearch;
        } else {
          return { isExisting: false, searchText: searchString, id: Date.now().toString() } as IUserFromSearch;
        }
      })
    );
  }

  searchInternalUsersInTools(searchString): Observable<any> {
    return this.searchUsers(searchString).pipe(
      map((x) => x as Array<any>),
      map((usrs) => {
        return usrs.filter((x) => !(x.userPrincipalName as string).includes(this.externalUserMarker)).map((u) => {
          return {
            ...u, isExternal: (u.userPrincipalName as string).includes(this.externalUserMarker)
          };
        });
      }));
  }

  searchAllUsers(searchString): Observable<any> {
    return this.searchUsers(searchString).pipe(
      map((x) => x as Array<any>), map((usrs) => {
        return usrs.map((u) => {
          return {
            ...u, isExternal: (u.userPrincipalName as string).includes(this.externalUserMarker)
          };
        });
      }));
  }

  searchInternalUsers(searchString): Observable<any> {
    return this.searchUsers(searchString).pipe(
      map((x) => x as Array<any>),
      switchMap((x) => from(x)),
      filter((x) => !(x.userPrincipalName as string).includes(this.externalUserMarker))
    );
  }
  searchExternalUsers(searchString): Observable<any> {
    return this.searchUsers(searchString).pipe(
      map((x) => x as Array<any>),
      switchMap((x) => from(x)),
      filter((x) => (x.userPrincipalName as string).includes(this.externalUserMarker))
    );
  }
}
