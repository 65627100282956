import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {Page, ViewTypes} from '../../../page.model';


@Component({
  selector: 'app-summary-section-base',
  template: ''
})
export class SummarySectionBaseComponent implements OnInit, OnDestroy, OnChanges {
  public destroy$ = new Subject<boolean>();
  private selectedViewTypeInternal = ViewTypes.desktop;
  public page: Page;
  public htmlContent: any;
  // NOTE: Lets not use getters or any function directly on the template as it will keep on running for ever change detection
  // cycle for component as well as for parent components. Will cause rendering issue and performance issues as well.
  // get html(): string {
  //   return this.page?.moduleFlowPage.body;
  // }
  @Input() set selectedViewType(val: ViewTypes) {
    this.selectedViewTypeInternal = val;
  }

  ngOnInit(): void {
    this.htmlContent = this.page?.moduleFlowPage.body;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page) {
      this.htmlContent = this.calculateHtmlContent();
    }
  }

  calculateHtmlContent(): any {
    return this.page?.moduleFlowPage.body;
  }

  get selectedViewType(): ViewTypes {
    return this.selectedViewTypeInternal;
  }

  viewTypes = ViewTypes;

  constructor() {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

}
