import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastTypes } from './toast-types.enum';
import { EyToastService } from './ey-toast.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';

export interface Toast {
  type: ToastTypes;
  text: string;
  id?: string;
}

export const REM_MSG_DELAY_SPAN_MS = 3000;

@Component({
  selector: 'ey-toast',
  templateUrl: './ey-toast.component.html',
  styleUrls: ['./ey-toast.component.scss']
})
export class EyToastComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  removeMessage$: Subject<string> = new Subject<string>();
  toasts: Toast[] = [];

  toastTypes = ToastTypes;
  constructor(private eyToastService: EyToastService) {
    this.removeMessage$.asObservable().pipe(takeUntil(this.destroy$)).subscribe((id) => this.remove(id));
  }

  ngOnInit(): void {
    this.eyToastService.toast$.pipe(takeUntil(this.destroy$)).subscribe((t) => {
      this.add(t);
      timer(REM_MSG_DELAY_SPAN_MS).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.removeMessage$.next(t.id);
      });
    });
  }

  add(toast: Toast): void {
    this.toasts = [...this.toasts, toast];
  }

  remove(id: string): void {
      this.toasts = [...this.toasts.filter(t => t.id !== id)];
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
