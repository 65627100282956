import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {IBulkImportResponsesPayload, IBulkUploadResponse, IMappingTemplate} from './bulk-upload.model';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {EXCEL_COLUMNS, MAPPING_TEMPLATES_MOCK, MODULE_FIELDS} from './bulk-upload.mock';
import {DataPurposes} from './data-purpose/data-purpose.component';

@Injectable({
  providedIn: 'root'
})
export class BulkUploadService {
  private baseUrl = environment.baseUrl;

  constructor( private httpClient: HttpClient) { }

  getTemplate(projId: string, dataPurpose: DataPurposes): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/project-mapping-fields/file-template/${dataPurpose}/${projId}`,
      {},
      {observe: 'response', responseType: 'blob'}
    );
  }

  getBulkUploadStatus(projectId: string): Observable<IBulkUploadResponse> {
    return this.httpClient.get<IBulkUploadResponse>(`${environment.baseUrl}/project-bulk-import/status/${projectId}`);
  }

  postBulkUpload(payload: IBulkImportResponsesPayload, projectId: string): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}/project-bulk-import/create/${projectId}`, payload);
  }

  getMappingTemplates(projId: string): Observable<IMappingTemplate[]> {
    return this.httpClient.get<IMappingTemplate[]>(`${this.baseUrl}/project-bulk-import/mapping-template/${projId}`);
  }

  getModuleFields(projId: string, dataPurpose: DataPurposes): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.baseUrl}/project-mapping-fields/upload/${dataPurpose}/${projId}`);
  }

  getExcelColumns(projId: string, fileIds: any[]): Observable<string[]> {
     return this.httpClient.post<string[]>(`${this.baseUrl}/project-bulk-import/excel-columns`, fileIds);
  }

  saveModuleFields(payload: IMappingTemplate, projId: string): Observable<any>  {
    return this.httpClient.post(`${environment.baseUrl}/project-bulk-import/module-fields/${projId}`, payload);
  }
}
