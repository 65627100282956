import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ButtonClass} from '../../shared/components/ey-button/ey-button.component';
import {ProgressBarStep} from '../../shared/components/ey-form-progress-bar/progress-bar-step.model';
import {DATA_MAPPING_STEP, PROGRESS_BAR_STEPS, progressBarStepIds} from './bulk-upload-responses.steps';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {ProgressBarService} from '../../shared/components/ey-form-progress-bar/progress-bar.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalResult} from '../../shared/components/ey-modal-template/ey-modal-result.enum';
import {DataPurposeComponent, DataPurposes} from './data-purpose/data-purpose.component';
import {UploadMethodComponent, UploadMethods} from './upload-method/upload-method.component';
import {UploadYourDataComponent} from './upload-your-data/upload-your-data.component';
import {DataMappingComponent} from './data-mapping/data-mapping.component';
import {IBulkImportResponsesPayload} from './bulk-upload.model';
import {BulkUploadModalService} from './bulk-upload-modal.service';

@Component({
  selector: 'app-bulk-upload-modal',
  templateUrl: './bulk-upload-modal.component.html',
  styleUrls: ['./bulk-upload-modal.component.scss'],
})
export class BulkUploadModalComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  btnClass = ButtonClass;
  progressBarSteps: ProgressBarStep[] = [];
  currentStep: ProgressBarStep = new ProgressBarStep();
  stepsDef = progressBarStepIds;
  uploadForm: FormGroup;
  selectedUploadMethod: UploadMethods = UploadMethods.UseOurTemplate;
  @Input() projectID: string;
  @Input() projectName: string;
  @Input() isCompleteResponseAllowed: boolean;
  @Input() isPartialResponseAllowed: boolean;

  @ViewChild(DataPurposeComponent) dataPurposeComponent;
  @ViewChild(UploadMethodComponent) uploadMethodComponent;
  @ViewChild(UploadYourDataComponent) uploadYourDataComponent;
  @ViewChild(DataMappingComponent) dataMappingComponent;

  disableDataPurposeSelection = false;
  uploadedFileIds = [];

  constructor(private bulkUploadModalService: BulkUploadModalService, private fb: FormBuilder, private progressBarService: ProgressBarService, public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.progressBarSteps = _.cloneDeep(PROGRESS_BAR_STEPS);
    this.currentStep = this.progressBarSteps[0];

    this.uploadForm = this.getFBGroup(this.getInitialDataPurposeValue());
    this.subscribeToUploadMethod();
  }

  getInitialDataPurposeValue(): DataPurposes {
    if (this.isCompleteResponseAllowed === true && this.isPartialResponseAllowed === true) {
      return DataPurposes.NotSelected;
    }
    if (this.isCompleteResponseAllowed === true) {
      return DataPurposes.CompletedResponseData;
    }

    return DataPurposes.PartialResponseData;
  }

  subscribeToUploadMethod(): void {

    (this.uploadForm.get('uploadMethod') as FormControl).valueChanges.subscribe((val) => {
        this.selectedUploadMethod = val.uploadMethod;
        this.addRemoveDataMappingStep();
    });
  }

  addRemoveDataMappingStep(): void {
    if (this.selectedUploadMethod === UploadMethods.UseMapping) {
      if (this.progressBarSteps.findIndex((s) => s.id === progressBarStepIds.dataMapping) === -1) {
        this.progressBarSteps = [...this.progressBarSteps, {...DATA_MAPPING_STEP}];
      }
    } else {
      this.progressBarSteps = [...this.progressBarSteps.filter((s) => s.id !==  progressBarStepIds.dataMapping)];
    }
  }

  getFBGroup(initDataPurposeVal: DataPurposes): FormGroup {
    let preSelectedDataPurpose = {};
    if (initDataPurposeVal !== DataPurposes.NotSelected) {
      preSelectedDataPurpose = { dataPurpose: +initDataPurposeVal};
      this.disableDataPurposeSelection = true;
    }
    return this.fb.group(
      {
        dataPurpose: preSelectedDataPurpose,
        uploadMethod: {},
        uploadYourData: {},
        dataMapping: {}
      },
      { updateOn: 'change' }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onPrev(e: Event): void {
    if (e && e.target) {
      (e.target as HTMLElement).blur();
    }
    if (this.currentStep.id !== progressBarStepIds.dataPurpose) {
      this.progressBarSteps = this.progressBarService.prev(this.progressBarSteps);
      this.currentStep = this.progressBarService.getActiveStep(this.progressBarSteps);
      this.setFocus(this.currentStep);
    }
  }

  onNext(e: Event): void {
    const dataPurpose = this.uploadForm.get('dataPurpose').value;
    this.bulkUploadModalService.dataPurpose.next(dataPurpose);
    if (this.currentStep.id === progressBarStepIds.dataMapping) {
      this.bulkUploadModalService.checkForDataMappingErrors.next(true);
      if (this.validateStep(this.currentStep)) {
        this.onSubmit();
        return;
      }
      return;
    }

    if (this.currentStep.id === progressBarStepIds.uploadYourData && this.progressBarSteps.length < 4) {
      this.onSubmit();
    } else {
      if (this.validateStep(this.currentStep)) {
        if (this.currentStep.id === progressBarStepIds.uploadYourData) {
         if (this.onSubmit(false)) {
           return;
         }
        }
        this.progressBarSteps = this.progressBarService.next(this.progressBarSteps);
        this.currentStep = this.progressBarService.getActiveStep(this.progressBarSteps);
        if (this.currentStep.id === progressBarStepIds.dataMapping) {
          this.dataMappingComponent.initLoad(this.uploadedFileIds);
        }
        this.setFocus(this.currentStep);
      }
    }
  }

  setFocus(step: ProgressBarStep): void {
    this[step.componentName].setFocusOnTheFirstFormElement();
  }

  validateStep(step: ProgressBarStep): boolean {
    // this.getFormValidationErrorsDIA();
    if (!this.uploadForm.get(step.formName).valid) {
      this[step.componentName].markAsTouched();
      return false;
    }
    return true;
  }

  onCancel(): void {
    this.activeModal.close(ModalResult.dismiss);
  }

  onSubmit(closeModal = true): boolean {
    const fileIds = this.uploadYourDataComponent.uploadedFiles.map((f) => f.fileId);
    if (fileIds.length > 0) {
      this.uploadedFileIds = fileIds;
      const savePayload: IBulkImportResponsesPayload = {
        ...this.uploadForm.getRawValue().dataPurpose,
        ...this.uploadForm.getRawValue().uploadMethod,
        // ...this.uploadForm.getRawValue().uploadYourData,
        ...this.mapDataMappings(this.uploadForm.getRawValue().dataMapping),
        documentIds: fileIds,
      };
      if (closeModal) {
        this.activeModal.close(savePayload);
      }

      this.uploadYourDataComponent.hasError = false;
    } else {
      this.uploadYourDataComponent.hasError = true;
    }
    return this.uploadYourDataComponent.hasError;
  }

  mapDataMappings(dataMapping: any): any {
    const dm = dataMapping;
    delete dm.search;
    delete dm.mappingTemplates;

    dm.mappings = dm?.mappings?.map( m => {
      return {mappingField: m.mappingField, excelColumn: (m.excelColumn?.name == null) ? '' : m.excelColumn?.name };
    });

    return dm;
  }

}
