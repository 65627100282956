import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GridState} from '../../shared/components/ey-grid/models/grid-state.model';
import {TableData} from '../../shared/components/ey-grid/models/table-data.model';
import {ListOfRespondentsInfo} from '../../dashboard/list-of-respondents-modal/list-of-respondents.modal';
import {SimpleUser} from '../models/user/user.model';
import {ResponseLockInfo} from '../models/collaboration/responselockinfo.model';

@Injectable({
  providedIn: 'root'
})
export class CollaborationModeResponsesService {
  private baseUrl = environment.baseUrl;
  constructor(private httpClient: HttpClient) { }

  onCollaborationModeResponseLock(projectId: string, responseId: string): Observable<ResponseLockInfo> {
    return this.httpClient.put<ResponseLockInfo>(`${this.baseUrl}/project-collaboration/${projectId}/lock/${responseId}`, {});
  }

  onCollaborationModeResponseUnlock(projectId: string, responseId: string): Observable<ResponseLockInfo> {
    return this.httpClient.put<ResponseLockInfo>(`${this.baseUrl}/project-collaboration/${projectId}/unlock/${responseId}`, {});
  }

  getListOfRespondentsOnCollaborationMode(projectId: string, responseId: string, gridState: GridState): Observable<TableData<ListOfRespondentsInfo>> {
    return this.httpClient.post<TableData<ListOfRespondentsInfo>>(`${this.baseUrl}/project-collaboration/details/${projectId}/${responseId}`, { params: {
        pageSize: gridState.pageSize.toString(),
        sortBy: gridState.sortBy.toString(),
        sortDirection: gridState.sortDirection.toString(),
        pageNumber: gridState.pageNumber.toString(),
        search: gridState.search.toString()
      }});
  }

  getResponseLastModifiedBy(projectId: string, responseId: string): Observable<SimpleUser> {
    return this.httpClient.get<SimpleUser>(`${this.baseUrl}/project-collaboration/${projectId}/response-last-modified-by/${responseId}`);
  }

  getResponseLockInfo(projectId: string, responseId: string): Observable<ResponseLockInfo> {
    return this.httpClient.get<ResponseLockInfo>(`${this.baseUrl}/project-collaboration/${projectId}/response-lock-info/${responseId}`);
  }
}
