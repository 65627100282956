<form autocomplete="noAutocomplete" [formGroup]="form">
  <h3 class="fonts-col__heading-3 m-0 mb-40">Upload Your Data <span> (3/{{getNumberOfSteps()}})</span></h3>
  <div *ngIf="selectedUploadMethod === useOurTemplate">
  <h4 class="fonts-col__heading-4 mb-10 pb-0 mt-0">STEP 1. <a href="javascript:void(0)" (click)="downloadTemplate()"> Download Excel Template </a></h4>
  <hr />
  <h4 class="fonts-col__heading-4 mb-10 pb-0 mt-0">STEP 2. Fill the Template with your data</h4>
  <hr />
  <h4 class="fonts-col__heading-4 mb-10 pb-0 mt-0">STEP 3. Upload filled Template</h4>
  </div>

  <ey-file-upload
    [fileExt]="fileExtension"
    [maxFiles]="1"
    (initFileUpload)="upload($event)"
    (deleteFile)="deleteFile($event)"
    [docType]='docType'
    [hasError]="hasError"
    filesSubTitle=""
    dragAndDropTitle="Drag and drop .xls file or "
    requiredErrMsg="You need to upload file to continue"
  >
  </ey-file-upload>
</form>
<div *ngIf="uploadedFiles.length < 1">
<div class="mt-30" *ngIf="selectedUploadMethod === useOurTemplate">
  <h4 class="fonts-col__heading-4"><i class="emp-icon icon-ico_info_toast"></i>How to fill Excel Template?</h4>

  <ul class="bulk-upload-responses-list eyfontlight">
    <li >
      All data is available on the first sheet of the uploaded file. Also, ensure that column headers are on the first row, starting at cell A1.
    </li>
    <li class="eyfontlight">
      Response::CreatedBy Column - A column on spreadsheet with email address of the end-user to whom the pre-populated response needs to be assigned to. Post the bulk upload process, end-users visiting your Project link will be automatically taken to their pre-populated response which they can then complete.
    </li>
    <li class="eyfontlight">
      Response ID column (Optional) - A column on spreadsheet with unique Response ID if you would like to assign a unique reference for each response. For example, Entity ABC-Dec2020
    </li>
  </ul>

</div>
<div *ngIf="selectedUploadMethod !== useOurTemplate" class="mt-30">
  <h4 class="fonts-col__heading-4"><i class="emp-icon icon-ico_info_toast"></i>How to fill Excel Template?</h4>
  <ul class="bulk-upload-responses-list eyfontlight">
    <li >
      All data is available on the first sheet of the uploaded file. Also, ensure that column headers are on the first row, starting at cell A1.
    </li>
    <li class="eyfontlight">
      Response::CreatedBy Column - A column on spreadsheet with email address of the end-user to whom the pre-populated response needs to be assigned to. Post the bulk upload process, end-users visiting your Project link will be automatically taken to their pre-populated response which they can then complete.
    </li>
    <li class="eyfontlight">
      Response ID column (Optional) - A column on spreadsheet with unique Response ID if you would like to assign a unique reference for each response. For example, Entity ABC-Dec2020
    </li>
  </ul>
</div>
</div>
