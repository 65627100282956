import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-hide-from-end-users',
  templateUrl: './hide-from-end-users.component.html',
  styleUrls: ['./hide-from-end-users.component.scss']
})
export class HideFromEndUsersComponent implements OnInit {
  @Input() text: string;
  @Input() hideIcon = false;
  constructor() { }

  ngOnInit(): void {
  }

}
