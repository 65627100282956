import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionTypes as ResponseActionTypes } from '../../../projects/responses/responses-action.dictionary';
import { IAction } from './action.model';
import { DropDownParentViewTypes } from '../../../shared/components/ey-drop-down/ey-drop-down.enum';
import { NavPlacement } from '../../../projects/project.const';

export interface  IActionEventArgs {
  id: string;
  actionType: ResponseActionTypes;
 }

export const SHOW_VIEW_SUMMARY_TEXT = 'View Summary';
export const SHOW_RESUME_DRAFT_TEXT = 'Resume Draft';
export const SHOW_DELETE_TEXT = 'Delete';
export const UNKNOWN_ACTION_TYPE_TEXT = 'UNKNOWN';

@Component({
  selector: 'app-grid-actions',
  templateUrl: './grid-actions.component.html',
  styleUrls: ['./grid-actions.component.scss']
})
export class GridActionsComponent implements OnInit {

  @Input() id: string;
  @Input() lightTable = false;
  @Input() cardView = false;
  @Input() actions: IAction[] = [];
  @Output() actionSelect: EventEmitter<IActionEventArgs> = new EventEmitter<IActionEventArgs>();
  @Input() parentViewFlag: DropDownParentViewTypes = DropDownParentViewTypes.grid;
  @Input() showLabelText = false;

  getLabelText(action: ResponseActionTypes): string {
    switch (action) {
      case ResponseActionTypes.ViewSummary:
        return SHOW_VIEW_SUMMARY_TEXT;
      case ResponseActionTypes.ResumeDraft:
        return SHOW_RESUME_DRAFT_TEXT ;
      case ResponseActionTypes.Delete:
          return SHOW_DELETE_TEXT ;
      default:
          return UNKNOWN_ACTION_TYPE_TEXT;
    }
  }

  get primaryActions(): IAction[] {
    return this.actions.filter((a) => a.navPlacement ===  NavPlacement.primary);
  }

  get secondaryActions(): IAction[] {
    return this.actions.filter((a) => a.navPlacement ===  NavPlacement.secondary);
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  onActionClick(selActionType: ResponseActionTypes): void {
    const actionEventArgs: IActionEventArgs = {
      id: this.id,
      actionType: selActionType
    };

    this.actionSelect.emit(actionEventArgs);
  }
}
