import {Component, Input} from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { ConfigService } from '../../services/configuration.service';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-environment',
    templateUrl: './environment.component.html',
    styleUrls: ['./environment.component.scss']
})
export class EnvironmentComponent {
    @Input() isMobileBreakpoint = false;
    public environment: Observable<string> = null;
    public showEnvironment: boolean;

    constructor(private configService: ConfigService) {
        this.showEnvironment = !configService.isProductionEnvironment();

        if (this.showEnvironment) {
            this.environment = forkJoin([configService.getEnviromentName(), configService.getBuildNumber()])
                .pipe(map(([env, build]) => `${env} - ${build}`));
        }
    }
}
