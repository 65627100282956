export enum MailBarSectionsTypes {
  to,
  cc,
  subject,
  attachment
}

export enum MailBarActions {
  moveUp,
  moveDown,
  delete,
  clone
}
