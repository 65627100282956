import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {DEF_GRID_SATE, EyGridComponent} from '../../shared/components/ey-grid/ey-grid.component';
import {GridState} from '../../shared/components/ey-grid/models/grid-state.model';
import {DashboardService} from '../dashboard.service';
import {UserPermissionsService} from '../../core/user-permissions.service';
import {takeUntil} from 'rxjs/operators';
import {ResponseStatus} from '../../projects/responses/responses.const';
import {IAction} from '../../projects/grid-actions/action.model';
import {
  DRAFT_RESPONSE_STATUS,
  ProjectStatus,
  ProjectStatuses,
  UNKNOWN_PROJECT_STATUS
} from '../../projects/project.const';
import {IActionEventArgs} from '../../projects/grid-actions/grid-actions.component';
import {Response} from '../../projects/responses/response.model';
import {ActionTypes} from '../../projects/responses/responses-action.dictionary';
import {ResponsesPermissionsService} from '../../projects/responses/responses-permissions.service';
import {EyMultiselectSearchComponent} from '../../shared/components/ey-multiselect-search/ey-multiselect-search.component';
import {ResponseInfo} from './response-info.model';
import {MY_RESPONSES_FIELDS_META} from './my-responses.meta';
import {ActiveFilter} from '../../shared/components/ey-grid/models/active-filter.model';
import {SortDirection} from '../../shared/components/ey-grid/models/sort-direction.model';
import {DEF_TEXTS, EyInfoDialogService} from 'src/app/shared/components/ey-info-dialog/ey-info-dialog.service';
import { ResponseService } from 'src/app/designer/preview/submit-response/response.service';
import {
  ModalResult,
  ModalVerticalSizeWindowClass,
  ModalWidth
} from 'src/app/shared/components/ey-modal-template/ey-modal-result.enum';
import { ResponseLinkService } from 'src/app/core/services/response-link.service';
import {
  AllReadResponsesFiles,
  ReadResponsesFiles,
  TemplateFileTypes
} from '../my-responses-card/response-card.model';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ListOfRespondentsModalComponent} from '../list-of-respondents-modal/list-of-respondents-modal.component';
import {CollaborationModeResponsesService} from '../../core/services/collaboration-mode-responses.service';
import {EyToastService} from '../../shared/components/ey-toast/ey-toast.service';
import {
  RESP_LOCKED_MSG,
  RESP_UNLOCKED_MSG,
  RESP_LOCKED_BY_ANOTHER_USER_MSG,
  RESP_LOCK_ERROR_MSG,
  RESP_UNLOCK_ERROR_MSG, DUPLICATE_RESPONSE_MSG,
} from '../my-responses-card/my-responses-card.component';
import {ReviewResponseService} from '../../projects/manage-project/review-responses-modal/review-response.service';
import {ButtonIconTypes, InfoDialogTypes} from '../../shared/components/ey-info-dialog/info-dialog-types.enum';
import {DownloadGeneratedDocumentsModalWindowInputDataInputData} from '../../cross-feature-module/download-generated-documents-modal/download-generated-documents-modal-window-input-data.modal';
import {DownloadGeneratedDocumentsService} from '../../cross-feature-module/download-generated-documents-modal/download-generated-documents.service';

export const DRAFT_RESPONSE_TEMPLATE_DOWNLOAD_WARNING = 'Warning: You are downloading partially filled document for a Draft response, it may contain incomplete data or calculations. Please use this file with caution.';
export const DOWNLOAD_GENERATED_TEMPLATES_TITLE = 'Download Generated Document';

@Component({
  selector: 'app-my-responses',
  templateUrl: './my-responses.component.html',
  styleUrls: ['./my-responses.component.scss']
})
export class MyResponsesComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild(EyMultiselectSearchComponent) multiSelect: EyMultiselectSearchComponent;
  @ViewChild(EyGridComponent) grid: EyGridComponent;

  gridState: GridState = {...DEF_GRID_SATE, sortBy: 'responseDate', sortDirection: SortDirection.DES};
  otherUserPermissions: string[];
  permissionList: string[];
  filterValues = [];

  /* filter section */
  incomingFilterValues = 0;
  meta = {...MY_RESPONSES_FIELDS_META};

  /* placeholder value if nothing is selected on multiselect filter */
  placeholderValue = 'Filter by Project';

  templateFileTypesEnum = TemplateFileTypes;

  constructor(
    private userPermissionService: UserPermissionsService,
    private dashboardService: DashboardService,
    private responsesPermissionsService: ResponsesPermissionsService,
    private dialogService: EyInfoDialogService,
    private responseService: ResponseService,
    private responseLinkService: ResponseLinkService,
    private modalService: NgbModal,
    private collaborationModeResponseService: CollaborationModeResponsesService,
    private toastsService: EyToastService,
    private eyInfoDialogService: EyInfoDialogService,
    private reviewResponseService: ReviewResponseService,
    private downloadGeneratedDocumentsService: DownloadGeneratedDocumentsService
  ) {}

  ngOnInit(): void {
    this.userPermissionService.getRawPermissions().pipe(takeUntil(this.destroy$)).subscribe((up) => {
      this.otherUserPermissions = up;
    });
    this.dashboardService.getResponsesUniqueProjectsList().subscribe(projData => {
      this.filterValues = projData;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  getData = (state) => this.dashboardService.getResponsesTableData(state);

  getProjectStatus(statusId: number): any {
    const status = ProjectStatuses.find((s) => s.id === statusId);
    return status !== undefined ? status : UNKNOWN_PROJECT_STATUS;
  }

   getResponseActionNavElements(data: ResponseInfo): IAction[] {
    this.permissionList = data.userPermissions;
    this.permissionList.concat(this.otherUserPermissions);
    const projectStatus: ProjectStatus = this.getProjectStatus(data.projectStatus).label as ProjectStatus;
    const responseStatus: ResponseStatus = this.responseService.getResponseStatus(data.responseStatus).label as ResponseStatus;
    const collaborationModeResponseData: {isCollaborationLocked: boolean, isCurrentUserCanUnlock: boolean} = {
       isCollaborationLocked: data.isCollaborationLocked,
       isCurrentUserCanUnlock: data.isCurrentUserCanUnlock
     };
    return this.responsesPermissionsService.getResponsesActionNavElementsMyDashboard(projectStatus, responseStatus, this.permissionList, collaborationModeResponseData, data.isRespondent, data.isDuplicatable);
  }

  onActionSelect(aea: IActionEventArgs, responseData: Response): void {
    switch (aea.actionType) {
      case ActionTypes.ViewSummary:
        this.responseLinkService.openViewSummaryWindow(responseData.projectId, responseData.id);
        break;
      case ActionTypes.ResumeDraft:
        this.responseLinkService.openSubmitResponseTab(responseData.projectId, responseData.id);
        break;
      case ActionTypes.Delete:
        this.onDeleteResponse(responseData);
        break;
      case ActionTypes.Lock:
        this.onCollaborationModeResponseLock(responseData.projectId, responseData.id);
        break;
      case ActionTypes.Unlock:
        this.onCollaborationModeResponseUnlock(responseData.projectId, responseData.id);
        break;
      case ActionTypes.ListOfRespondents:
        this.onCollaborationModeListOfRespondents(responseData.projectId, responseData.id);
        break;
      case ActionTypes.ReviewDraft:
        this.reviewResponseService.openReviewDraftResponseTab(responseData.projectId, responseData.id);
        break;
      case ActionTypes.Duplicate:
        this.onDuplicateResponse(responseData.id, responseData.createdBy);
        break;
      default:
        alert('WIP');
    }
  }

  onDuplicateResponse(responseId: string, respondentId: string): void {
    this.dialogService
      .openInfoDialog( DUPLICATE_RESPONSE_MSG.content, DUPLICATE_RESPONSE_MSG.header,
        InfoDialogTypes.warning, {...DEF_TEXTS, acceptWarningText: 'Duplicate', rejectWarningText: 'Cancel'}, ButtonIconTypes.none)
      .subscribe((result: ModalResult) => {
        if (result === ModalResult.submit) {
          this.generateDuplicateResponse(responseId, respondentId);
        }
      });
  }

  generateDuplicateResponse(responseId: string, respondentId: string): void {
    this.responseService.duplicateResponse(responseId, respondentId).subscribe(duplicate => {
      if (duplicate) {
        this.grid.loadData();
      }
    });
  }

  onCollaborationModeResponseLock(projectId: string, responseId: string): void {
    this.collaborationModeResponseService.onCollaborationModeResponseLock(projectId, responseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(responseLockInfo => {
        if (responseLockInfo) {
          if (!responseLockInfo.isLockedByAnotherUser) {
            this.toastsService.add(RESP_LOCKED_MSG);
            this.grid.loadData();
          } else {
            this.toastsService.add(RESP_LOCKED_BY_ANOTHER_USER_MSG);
          }
        } else {
          this.toastsService.add(RESP_LOCK_ERROR_MSG);
        }
      });
  }

  onCollaborationModeResponseUnlock(projectId: string, responseId: string): void {
    this.collaborationModeResponseService.onCollaborationModeResponseUnlock(projectId, responseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(responseLockInfo => {
        if (responseLockInfo) {
          this.toastsService.add(RESP_UNLOCKED_MSG);
          this.grid.loadData();
        } else {
          this.toastsService.add(RESP_UNLOCK_ERROR_MSG);
        }
      });
  }

  onCollaborationModeListOfRespondents(projectId: string, responseId: string): void {
    const listOfRespondentsModal: NgbModalOptions = {
      backdrop: 'static',
      size: ModalWidth.Large,
      windowClass: ModalVerticalSizeWindowClass.contentHeavyDoubleHeader,
    };
    const modalRef = this.modalService.open(ListOfRespondentsModalComponent, listOfRespondentsModal);
    modalRef.componentInstance.projectId = projectId;
    modalRef.componentInstance.responseId = responseId;
    modalRef.result.then((result) => {
      return;
    });
  }

  onDeleteResponse(responseData: Response): void {
    const infoDialogOptions = this.responseService.getResponseDeleteDialogOptions();

    this.dialogService
      .openInfoDialog(infoDialogOptions.msg, infoDialogOptions.title, infoDialogOptions.dialogType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result === ModalResult.submit) {
          this.responseService.deleteResponse(responseData.projectId, responseData.id)
          .subscribe(res => {
            this.grid.loadData();
          });
        }
      });
  }

  onSearchValueChange(event: Array<{name: string, projectId: string}>): void {
    const searchIds: any = [];

    event.forEach(el => searchIds.push(el.projectId));
    const activeFilters: ActiveFilter[] = [
        {
          filterName: 'projectIds',
          valueType: 'stringArray',
          value: searchIds
        }
      ];
    this.grid.onFiltersChange(activeFilters);
  }

  openDocumentDownloadModal(templateCount: number, projectId: string, responseId: string, respondentName: string, templateType: number, responseDate: string, responseStatus: number): void {
    if (templateCount > 1) {
      const modalWindowInputData: DownloadGeneratedDocumentsModalWindowInputDataInputData = {
        responseId,
        isResponseInDraftState: this.responseService.checkIfResponseIsInDraftState(responseStatus) ? true : false,
        projectId,
        templateType,
        fileData: null,
        downloadDocumentsBasedOnTemplateType: true
      };
      this.downloadGeneratedDocumentsService.openDownloadGeneratedDocumentsModal(modalWindowInputData);
    } else {
        this.downloadGeneratedDocumentsService.checkResponseStatusAndDownloadTemplate(projectId, responseId, respondentName, templateType, responseDate, responseStatus, this.destroy$);
    }
  }

  showDocumentsDownloadSection(response: ResponseInfo): boolean {
    if (this.otherUserPermissions.includes(AllReadResponsesFiles) || response.userPermissions.includes(ReadResponsesFiles)) {
      return !this.checkIfResponseIsDraftBulkUpload(response) && !this.checkIfDocumentsNotAvailable(response);
    }
    return false;
  }

  checkIfResponseIsDraftBulkUpload(response: ResponseInfo): boolean {
    return response.uploadId && response.isCollaborationLocked === null && response.responseStatus == DRAFT_RESPONSE_STATUS;
  }

  checkIfDocumentsNotAvailable(response: ResponseInfo): boolean {
    return (response.wordCount === 0 && response.excelCount === 0 && response.powerPointCount === 0);
  }
}
