import { Component, forwardRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import {ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IFieldMeta } from '../../../../../shared/components/ey-base-form-control/field-meta.model';
import { OptionAction, OptionActionEventArg, OptionComponent } from './option/option.component';

import * as _ from 'lodash';
import { EyToastService } from '../../../../../shared/components/ey-toast/ey-toast.service';
import { ToastTypes } from '../../../../../shared/components/ey-toast/toast-types.enum';
import { CdkDragStart } from '@angular/cdk/drag-drop';
import { v4 as uuid } from 'uuid';
import { Store } from '@ngrx/store';
import { PropertiesEditorService } from '../../properties-editor.service';
import { Tool } from '../../../tools/tool';
import { addConnectionToDecisionTool, deleteDependentConnections } from '../../../shape/actions';

const OPTION_TOKEN_NAME = 'CTR_TOKEN';
const OPTION_NAME = `Option ${OPTION_TOKEN_NAME}`;
export const INHERIT_CURSOR_CSS_CLASS = 'inheritCursors';
export const CSS_DND_CURSOR = 'move';
export const FOCUS_DELAY = 500; /* 0.5 sec */

export interface IOption {
  name: string;
  score: number;
  displayOrder: number;
  conId?: string;
  id?: string;
}

@Component({
  selector: 'app-scores-control',
  templateUrl: './scores-control.component.html',
  styleUrls: ['./scores-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScoresControlComponent),
      multi: true,
    },
  ],
})
export class ScoresControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
  bodyElement: HTMLElement = document.body;
  optionsMeta: IFieldMeta[] = [];
  scoresForm: FormGroup;
  tool: Tool;
  optionNames: IOption[] = [];
  @ViewChildren(OptionComponent) optionComponents: QueryList<OptionComponent>;
  get optionsArray(): FormArray {
    return this.scoresForm.get('scores') as FormArray;
  }

  destroy$ = new Subject<boolean>();
  onTouched: (val: any) => void = () => {};
  onChange: (val: any) => void = () => {};

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private propertiesEditorService: PropertiesEditorService,
    private eyToastService: EyToastService) {
    this.scoresForm = this.fb.group({
      scores: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.propertiesEditorService.tool$
      .pipe(takeUntil(this.destroy$))
      .subscribe(t => this.tool = t);
  }

  createOptions(options: IOption[]): void {
    this.scoresForm = this.fb.group({
      scores: this.fb.array([], {}),
    });

    if (options && options.length > 0) {
      _.orderBy(options, ['displayOrder']).forEach((o) => this.createNewOption(o));
    }

    this.subscribeToValueChanges();
  }

  subscribeToValueChanges(): void {
    this.scoresForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((options: any) => {
      this.emitUpdate(options);
    });
  }
  emitUpdate(options: any): any {
    const mappedValue = [...options.scores.map((s) => s.value)];
    this.optionNames = mappedValue.map(f => {
      return {name: f.name, id: f.conId} as IOption;
    } );
    this.onTouched(mappedValue);
    return mappedValue;
  }
  blur(index: number): void {
      if (this.optionsArray.at(index).valid) {
        const mappedValue = [...this.scoresForm.getRawValue().scores.map((s) => s.value)];
        this.onChange(mappedValue);
        this.onTouched(mappedValue);
      }
  }

  createNewOption(option: IOption, emitEventAdd: boolean = false): void {
    this.optionsMeta.push({
      title: 'Option',
    });

    this.optionsArray.push(
      this.fb.group(
        {
          value: option,
        },
        { emitEvent: emitEventAdd }
      )
    );

    if (emitEventAdd) {
      this.onChange(this.emitUpdate(this.scoresForm.getRawValue()))
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  writeValue(options: any): void {
    this.createOptions(options);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  onAddOption(): void {
    const maxOrder = this.getMaxDisplayOrder();
    const newDisplayOrder = this.optionsArray.length === 0 ? 0 : maxOrder + 1;

    const newOption: IOption = {
      name: OPTION_NAME.replace(OPTION_TOKEN_NAME, (newDisplayOrder + 1).toString()),
      score: 0,
      displayOrder: newDisplayOrder,
      conId: uuid()
    };
    this.createNewOption(newOption, true);

    /* todo(MK): might be ok if we get any errors logged with focus pls get in touch with me */
    /* alternative solution would be to set focus via input in control (after view init)
    however we would have to deal with concurrent patch operations and that might be complex */
    timer(FOCUS_DELAY)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.optionComponents.last.setFocus());

    this.store.dispatch(addConnectionToDecisionTool(
      {
        decisionToolId: this.tool.id,
        connectionId: newOption.conId,
        label: newOption.name
      }));
  }

  onAction(actionEA: OptionActionEventArg): void {
    let newValues: IOption[] = [];
    switch (actionEA.optionAction) {
      case OptionAction.up:
        newValues = this.changeDisplayOrder(actionEA.displayOrder, OptionAction.up);
        break;
      case OptionAction.down:
        newValues = this.changeDisplayOrder(actionEA.displayOrder, OptionAction.down);
        break;
      case OptionAction.remove:
        newValues = this.getRawValues().filter((o) => o.displayOrder !== actionEA.displayOrder);
        const optionToRemove = this.getRawValues().find(o => o.displayOrder === actionEA.displayOrder);
        this.store.dispatch(deleteDependentConnections({ ids: [optionToRemove.conId]}));
        break;
      case OptionAction.copy:
        navigator.clipboard.writeText(actionEA.name).then(() => {
          this.eyToastService.add({ id: '1', text: 'copied to clipboard', type: ToastTypes.info });
        });
        /* lines below are to copy item and add to the bottom of array
         keeping those lines as after chat wi PO we may want to add this as well
         newValues = this.getRawValues();
         newValues = [...newValues, { ...(newValues.find((o) => o.displayOrder === actionEA.displayOrder)), displayOrder: this.getMaxDisplayOrder() + 1 }];
         */

        return;
      default:
        alert('Unknown Action');
        return;
    }
    this.onChange(newValues);
    this.onTouched(newValues);
  }

  getRawValues(): IOption[] {
    return this.optionsArray.getRawValue().map((v) => v.value);
  }

  getMaxDisplayOrder(): number {
    return this.optionsArray.getRawValue().length > 0 ? _.orderBy(
      this.optionsArray.getRawValue().map((v) => v.value),
      ['displayOrder']
    ).reverse()[0].displayOrder : 0;
  }

  getMinDisplayOrder(): number {
    return this.optionsArray.getRawValue().length > 0 ? _.orderBy(
      this.optionsArray.getRawValue().map((v) => v.value),
      ['displayOrder']
    )[0].displayOrder : 0;
  }

  changeDisplayOrder(oldOrder: number, action: OptionAction, swapCandidate: IOption = null): IOption[] {
    const values: IOption[] = this.getRawValues();

    const first = { ...values.find((t) => t.displayOrder === oldOrder) };
    swapCandidate = swapCandidate == null ? this.getSwapCandidate(values, oldOrder, action) : swapCandidate;
    const newOrder = swapCandidate.displayOrder;
    const newValues = [
      ...values.filter((t) => t.displayOrder !== oldOrder && t.displayOrder !== newOrder),
      { ...first, displayOrder: newOrder },
      { ...swapCandidate, displayOrder: oldOrder },
    ];

    return newValues;
  }

  getSwapCandidate(values: IOption[], itemOrder, action: OptionAction): IOption {
    if (action === OptionAction.up) {
      return _.orderBy(
        values.filter((o) => o.displayOrder < itemOrder),
        ['displayOrder']
      ).reverse()[0];
    } else {
      return _.orderBy(
        values.filter((o) => o.displayOrder > itemOrder),
        ['displayOrder']
      )[0];
    }
  }
  drop(ea: any): void {
    if (ea.currentIndex !== ea.previousIndex) {
      const oldOrder = this.getRawValues()[ea.previousIndex].displayOrder;
      const swapCandidate = this.getRawValues()[ea.currentIndex];
      const options = this.changeDisplayOrder(oldOrder, OptionAction.up, swapCandidate);
      this.onChange(options);
      this.onTouched(options);
    }
    this.bodyElement.classList.remove(INHERIT_CURSOR_CSS_CLASS);
    this.bodyElement.style.cursor = 'unset';
  }

  dragStart(event: CdkDragStart): void {
    this.bodyElement.classList.add(INHERIT_CURSOR_CSS_CLASS);
    this.bodyElement.style.cursor = CSS_DND_CURSOR;
  }
}
