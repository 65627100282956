import { AfterViewInit, Component, OnInit } from '@angular/core';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ButtonClass } from 'src/app/shared/components/ey-button/ey-button.component';
import { FlowType, ModuleFlowService } from '../../module-flow.service';
import { SaveNotificationServiceEndUserForm } from '../../save-notification-service-end-user-form.service';
import { BasePageContent } from '../base-page-content';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';

@Component({
  selector: 'app-statement.d-flex.flex-fill',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})
export class StatementComponent extends BasePageContent implements OnInit, AfterViewInit {
  btnClass = ButtonClass;
  constructor(
    flowService: ModuleFlowService,
    protected spinnerService: EyAppSpinnerService,
    private saveNotification: SaveNotificationServiceEndUserForm) {
      super(flowService, spinnerService);
  }

  ngOnInit(): void {
    this.flowService.responseHeaderMobileViewChange.pipe(takeUntil(this.destroy$)).subscribe(mobileViewChange => {
      this.isMobileBreakpoint = mobileViewChange;
    });

    // TODO SO: refactor this quick fix to a normal operation
    if (this.flowType === FlowType.response && this.saveNotification.saveCurrentProperties$.observers.length === 0) {
      this.saveNotification.saveCurrentProperties$
        .pipe(
          switchMap(() => this.flowService.saveCurrent(this.page.moduleFlowPage.id)),
          takeUntil(this.destroy$)
        ).subscribe(response => {
          this.flowService.transformCurrentPageData(response);
          this.page = response;
        });
    }
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }

}
