import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../core/services/user-service';
import { User } from '../core/models/user/user.model';
import { UserOtherPermissions } from '../core/user-permissions.model';

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(private userService: UserService, private router: Router)
    {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.userService.getCurrentUser()
            .pipe(
                map((result: User) => {
                    const otherPermissions = new UserOtherPermissions();
                    var hasPermission = result.permissions != null ? result.permissions.some(p => p === otherPermissions.ReadAdminSetup) : false;
                    if (!hasPermission) {
                        this.router.navigate(['/']);
                        return false;
                    }
                    return true;
                })
            );
    }
}
