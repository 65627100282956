<form [formGroup]="form">
    <ey-input
        formControlName="name"
        [background]="background"
        extra="clipboard-copy"
        [meta]="meta"
        (blur)="onBlur()"
        maxlength="150"
        >
    </ey-input>
</form>
<div *ngIf="showWarning" class="warning-info">
    Square brackets for Name are recommended. Use brackets for Name, ex.: [{{suggestedName | titlecase}} 1]
</div>
