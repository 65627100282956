<div>
  <div class="fluid-table-component" [ngClass]="{'light-table': lightTable, 'table-borders-none': tableBordersNone}" *ngIf="loaded; else loading">
    <div class="row" *ngIf="showSearch" >
      <div class="col col-12">
        <form class="fluid-table-component_search pr-3">
          <div class="input-group d-flex align-items-center w-100">
            <input #searchInput [(ngModel)]="gridState.search"
              id="search"
              type="text"
              class="form-control text-color-light fluid-table-component_search__input text-transform--none flex-grow-1 fonts-col__body-copy borderBtm"
              name="search"
              placeholder="Search"
             />
            <i class="material-icons">search</i>
            <span *ngIf="showFilter" (click)="openFilterOptions()"
                  class="input-group-addon pointer">
              <span
                class="d-flex flex-column align-items-center justify-content-center fluid-table-component_search__filters"
              >
                <i class="material-icons">filter_list</i>
                <span>Filter</span>
              </span>
            </span>
          </div>
        </form>
      </div>
      <!-- MK this can be removed possibly as this type of filtering functionality will not be used in the system
      div class="col col-12" *ngIf="showFilter" >
        <ey-filters
          (filtersChangeEvent)="onFiltersChange($event)"
          (closeFiltersEvent)="onCloseFilteringEvent($event)"
          [isFilteringHidden]="isFilteringHidden"
          [content]="content"

        ></ey-filters>
       </div-->
    </div>
    <div *ngIf="useCustomFiltering" class="row">
      <ng-content select="[filter]"></ng-content>
    </div>
    <div class="position-relative">
      <div id="table-container" (scroll)="updateGridOffsetPosition()" #tableHolder class="table-responsive scroll-bar" [class.scroll-bar-light]="lightTable" *ngIf="!isReloadingData; else reloadingData">
        <table [class.fixed-width]="fixedWidth" class="table  mb-0 pb-0">
          <thead class="pl-2">
              <ng-content select="[header]"></ng-content>
          </thead>
          <tbody>
              <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="content.data">
                <tr>
                  <ng-content *ngTemplateOutlet="bodyTemplate; context: {$implicit: addRowIndex(rowData, rowIndex), rowIndex: rowIndex}"></ng-content>
                  <td *ngIf="hasSubTable">
                    <ey-row-expand [toolTipText]="subTableExpandToolTip" [index]="rowIndex" (updateExpandStatus)="onExpandStatusChanged($event)"></ey-row-expand>
                  </td>
                </tr>
                <tr class="sub-grid-wrapper-tr"  *ngIf="hasSubTable && this.subTablesVisibility[rowIndex]?.visible">
                  <td class="sub-grid-wrapper p-0" colspan="100%">
                    <ng-content *ngTemplateOutlet="subTableTemplate; context: {$implicit: rowData, rowIndex: rowIndex}" ></ng-content>
                  </td>
                </tr>
              </ng-template>
          </tbody>
        </table>
        <ng-container *ngIf="noContent">
          <div class="d-flex justify-content-center align-items-center no-content">
            <ng-container *ngIf="noContentTemplate; else noContentDefault">
              <ng-content *ngTemplateOutlet="noContentTemplate"></ng-content>
            </ng-container>
            <ng-template #noContentDefault>
              <p class="text-transform--none">
                No data present
              </p>
            </ng-template>
          </div>
        </ng-container>
      </div>
      <ng-template #reloadingData>
        <p>Reloading data</p>
      </ng-template>
    </div>
    <ey-pagination *ngIf="enablePaging" [lightPaginationPanel]="lightPaginationPanel"
      (paginationEvent)="currentPageChanged($event)"
      (paginationPageSizeEvent)="currentPageSizeChanged($event)"
      [currentPage]="gridState.pageNumber"
      [totalPages]="content.totalPages"
    ></ey-pagination>
  </div>

  <ng-template #loading>
  </ng-template>
</div>
