import { Pipe, PipeTransform } from '@angular/core';
import {NumberFormatService} from '../../designer/builders/table-builder/table/number-format.service';

@Pipe({
  name: 'numberRank'
})
export class NumberRankPipe implements PipeTransform {
  constructor(private numberFormatService: NumberFormatService) {
  }
  transform(value: number | string): string {
    return this.numberFormatService.systemNumberConversion(+value);
  }
}

@Pipe({
  name: 'numberSystem'
})
export class NumberSystemPipe implements PipeTransform {
  constructor(private numberFormatService: NumberFormatService) {
  }
  transform(value: number | string): string {
    return this.numberFormatService.systemNumberFormat(+value);
  }
}
