import { Injectable } from '@angular/core';
import {MappingField} from 'src/app/modules/version/version-mapping-data.model';
import {ToolNames} from 'src/app/shared/components/ey-condition/ey-condition.meta';


const FORM_CHECKBOX_GROUP_SELECTED_OPTION_DESCRIPTOR = 'FormCheckboxGroupSelectedOptions';
export const RESPONSE_COMPLETION_DATE_MAP_FIELD = 'ResponseCompletionDate';
export const RESPONDENT_NAME_MAP_FIELD = 'RespondentName';
export const RESPONDENT_EMAIL_MAP_FIELD = 'RespondentEmail';

@Injectable({
  providedIn: 'root'
})
export class MappingFieldUtilService {

  // Service shall only be used to manipulate the incoming data of mapping fields collection.
  // Service can be extended but should not be manipulated.

  constructor() { }

  // filter out all the specific mapfields which should not be available in condition.
  filterFieldsFromSourceValues(values: MappingField[]): MappingField[] {
    return values.filter(s => (s.toolType !== ToolNames.Mail &&
      s.toolType !== ToolNames.Upload && s.toolType !== ToolNames.Letter &&
      s.descriptor !== RESPONSE_COMPLETION_DATE_MAP_FIELD && s.descriptor !== RESPONDENT_NAME_MAP_FIELD && s.descriptor !== RESPONDENT_EMAIL_MAP_FIELD &&
      s.descriptor !== FORM_CHECKBOX_GROUP_SELECTED_OPTION_DESCRIPTOR));
  }

  filterMapFieldForTableBuilder(values: MappingField[]): MappingField[] {
    return values.filter(t => (t.descriptor !== RESPONSE_COMPLETION_DATE_MAP_FIELD && t.descriptor !== RESPONDENT_NAME_MAP_FIELD && t.descriptor !== RESPONDENT_EMAIL_MAP_FIELD));
  }

  filterFieldsFromInsertMappingFieldModal(values: MappingField[]): MappingField[] {
    return values.filter(t => (t.descriptor !== RESPONSE_COMPLETION_DATE_MAP_FIELD));
  }
}
