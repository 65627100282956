import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TableBuilderControl, TableBuilderControlHelper, TableBuilderState } from './table-builder.state';
import { IToolMeta } from '../../workflow-designer/tool-menu-item/tool-meta';
import { TABLE_CELL_TYPES } from './table-control-types.meta';
import { TableBuilderControlTypes } from './controls/controls.meta';
import { formatCellContent } from './table-builder.functions';
import { DatePipe } from '@angular/common';
import { NumberFormatService } from './table/number-format.service';
import * as _ from 'lodash';
import { VersionMappingData } from 'src/app/modules/version/version-mapping-data.model';
import {Subject} from 'rxjs/internal/Subject';
export const MAP_FIELDS_URL = '/module/version-mappings/';

export interface Child {
  name: string;
  content: string;
  order: number;
  property: any;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class TableBuilderService {
  currentCaretPositionOnTableBuilderFormulaEditor = new Subject<number>();
  private baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient, private dPipe: DatePipe, private numberFormatService: NumberFormatService) {}

  getTableBuilderData(versionId, toolId): Observable<TableBuilderControlHelper | null> {
    const tbd$ = this.httpClient.get<any>(`${this.baseUrl}/specificpart/${versionId}/${toolId}`);
    const mf$ = this.getMappedFields(versionId);

    return forkJoin([tbd$, mf$])
      .pipe(map(([r, mf]) => (r ? this.mapToTableBuilderControl(r?.children, r?.id, r?.name, mf, r?.hintDescription) : null)));
  }

  mapToTableBuilderControl(
    ch: TableBuilderControl[][],
    id: string,
    tableToolName: string = 'NAME FROM API',
    mappingFieldsRetVal: any,
    hintDescription: string
  ): TableBuilderControlHelper {
    const retVal = {
      fbs: ch
        ? ch.map((ca) =>
            ca.map((c) => {
              let props = {};
              if (c.properties) {
                props = {properties: {...c.properties, ...this.mapFormulaFieldTableBuilderToState(c?.properties?.formula)}};
              }
              return {
                ...c,
                typeLookup: this.findTypeLookup(c.type),
                showEditor: false,
                formattedControlContent: formatCellContent(c, this.numberFormatService, this.dPipe),
                ...props
              };
            })
          )
        : null,
      specificPartId: id,
      name: tableToolName,
      hintDescription,
      mappingFields: mappingFieldsRetVal?.mapFields,
    };
    return retVal;
  }

  findTypeLookup(type: TableBuilderControlTypes): IToolMeta {
    return TABLE_CELL_TYPES.find((t) => t.type === type);
  }

  insertUpdateTableBuilderData(fbs: TableBuilderState): Observable<VersionMappingData> {
    return this.httpClient.post<VersionMappingData>(`${this.baseUrl}/specificpart/${fbs.versionId}`, this.fbsToRequestType(fbs));
  }

  fbsToRequestType(fbs: TableBuilderState): any {
    return {
      class: 'TablePart',
      Id: fbs.specificPartId,
      toolId: fbs.tableBuilderId,
      hintDescription: fbs.hintDescription,
      children: fbs.controls.map((c) => {

        return c.map((ctrl) => {
          let props = {};
          if (ctrl.properties) {
            props = {properties: {...ctrl.properties, ...this.mapFormulaFieldTableBuilderToRequest(ctrl?.properties?.formula)}};
          }
          return {
            ...ctrl,
            value: _.isEmpty(ctrl.value) ? '' : ctrl.value,
            formattedControlContent: _.isEmpty(ctrl.formattedControlContent) ? '' : ctrl.formattedControlContent,
            ...props
          };
        });
      }),
    };
  }
  mapFormulaFieldTableBuilderToRequest(formula: any): any {
    if (formula != null) {
      const retVal = formula.map(f => {
        if (f.type === 0) {
          return {...f, content: '', tagContent: f.content};
        }
        return  f;
      });
      return {formula: retVal};
    }
    return formula;
  }

  mapFormulaFieldTableBuilderToState(formula: any): any {
    if (formula != null) {
      const retVal =  formula.map(f => {
        if (f.type === 0) {
          return {...f, content: f.tagContent};
        }
        return f;
      });
      return {formula: retVal};
    }
    return {};
  }

  getMappedFields(versionId: string): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}${MAP_FIELDS_URL}${versionId}`);
  }
}
