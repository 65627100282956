import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ViewTypes} from '../../page.model';

@Component({
  selector: 'app-sticky-footer-template',
  templateUrl: './sticky-footer-template.component.html',
  styleUrls: ['./sticky-footer-template.component.scss']
})
export class StickyFooterTemplateComponent {
  @ViewChild('body') bodyRef: ElementRef;
  @ViewChild('footer') footerRef: ElementRef;
  @ViewChild('templateContainer') templateContainerRef: ElementRef;
  @Input() removePaddingTopOnReviewDateDisplay = false;
  @Input() removePaddingBottomOnReviewDateDisplay = false;
  @Input() selectedViewType = ViewTypes.desktop;
  constructor() { }
}
