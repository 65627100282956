<app-ey-app-spinner></app-ey-app-spinner>
<div class="mb-20 response-submitted-container" *ngIf="!summaryPreviewModeOn">
  <h1><i class="icon-ico_success_toast"></i> Response has been submitted</h1>
</div>
<app-download-documents-summary *ngIf="!summaryPreviewModeOn && docList?.length > 0" [docList]="docList" class="d-block"></app-download-documents-summary>
<div class="d-flex mt-30 mb-20 flex-column download-summary-container" *ngIf="!summaryPreviewModeOn" [ngClass]="{'grey-summary': docList?.length < 1}">
  <h2 class="summary-header">Download Summary</h2>
  <p>Review summary of your response below or download summary PDF. You can also find all your responses details in <a href="/">Empowerment Dashboard.</a></p>
  <ey-button icon="icon-ico_download" text="Download Summary" [btnClass]="docList?.length < 1 ? btnClass.primary : btnClass.secondary" (click)="generateSummaryPDF()"></ey-button>
</div>
<div class="mb-20">
  <div class="d-flex flex-row">
    <h2 class="response-header flex-grow-1">Response Summary</h2>
    <ey-button icon="icon-ico_download" class="mb-10" *ngIf="summaryPreviewModeOn" text="Download Summary" [btnClass]="btnClass.primary" (click)="generateSummaryPDF()"></ey-button>
  </div>
  <div *ngFor="let page of summary; let i = index;" class="d-flex flex-column">
    <div class="section-header pt-10 pb-10">
      <h2 class="">{{i+1}}/{{stepsQty}}</h2>
    </div>
    <div class="section-body p-20">
      <ng-container appSummarySectionLoaderDirective [page]="page" [selectedViewType]="selectedViewType"></ng-container>
    </div>
  </div>
</div>
<div class="d-flex">
  <ey-button class="mt-20" icon="icon-ico_download" text="Download Summary" [btnClass]="btnClass.primary" (click)="generateSummaryPDF()"></ey-button>
    <div class="flex-grow-1"></div>
</div>
