import { Component, OnInit } from '@angular/core';
import {SummarySectionBaseComponent} from '../summary-section-base.component';

@Component({
  selector: 'app-statement-summary',
  templateUrl: './statement-summary.component.html',
  styleUrls: ['./statement-summary.component.scss']
})
export class StatementSummaryComponent  extends SummarySectionBaseComponent implements OnInit {
  body: string;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.body = this.page?.moduleFlowPage.body;
  }

}
