import { props } from '@ngrx/store';
import { Version } from '../../modules/version/version.model';
import { ModuleInfo } from '../../modules/manage-module/module-info.model';
import { VersionStructure } from '../../modules/version/version-structure.model';
import { createNonUndoableAction } from './utils/action-utils';


export const designerLoadCanvasState = createNonUndoableAction('[Designer] Load Canvas Sate', props<{ moduleId: string, versionId: string}>());
export const designerCanvasStateLoaded = createNonUndoableAction(
    '[Designer] Canvas State Loaded',
    props<{ module: ModuleInfo, version: Version, versionStructure: VersionStructure }>());
export const designerResetDataLoaded = createNonUndoableAction('[Designer] Reset Data Loaded');
export const designerSetDataReady = createNonUndoableAction('[Designer] Set Data Ready');
export const designerSetDefaultShapesAdded = createNonUndoableAction('[Designer] Set Default Shapes Added');
export const designerUpdateVersionStatus = createNonUndoableAction('[Designer] Update Version Status', props<{ version: Version, status: number}>());
