import {AfterViewInit, Component, ElementRef, forwardRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';

@Component({
  selector: 'ey-multi-select',
  templateUrl: './ey-multi-select.component.html',
  styleUrls: ['./ey-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyMultiSelectComponent),
      multi: true,
    }
  ]
})
export class EyMultiSelectComponent extends EyBaseFormControlComponent implements ControlValueAccessor {
  container: HTMLElement = null;
  @Input() set selectedFieldIds(val) {
    if (val && val.length > 0) {
      this.mapFieldIds(val);
    }
  }
  @Input() showDarkPlaceHolder = false;
  @Input() showFilterPills = true;
  @Input() placeHolderValue = null;
  @Input() floatingLabels = false;
  @Input() showDarkScroll = false;
  @Input() showClearAllOption = false;
  @Input() labelName = 'name';
  @Input() searchable: boolean = false;
  @Input() dropUp = false;
  @Input() toolTipVisible = true;
  @Input() darkMode = false;
  @Input() positioningDropdownsUnderModalWindow = false;
  @Input() positioningDropdownsWithRespectToBrowserWindow = false;
  @Input() set values(val) {
    this.incomingValues = val;
    this.filteredValues = val;
  }
  @Input() showLargeBadges = false;

  filteredValues: any[] = [];
  isExpanded = false;
  incomingValues: any[] = [];
  _selectedValues = [];

  constructor(private eRef: ElementRef, private controlContainer: ControlContainer) {
    super(controlContainer);
  }

  toggleExpand(container: HTMLElement): void {
    this.container = container;
    if (this.isDisabled) {
        return;
    }
    this.isExpanded = !this.isExpanded;
    if (this.positioningDropdownsWithRespectToBrowserWindow) {
      this.getDirectionOfDropdownOpeningWithRespectToWindow(container);
    } else if (this.positioningDropdownsUnderModalWindow) {
      this.getDirectionOfDropdownOpeningWithRespectToModal(container);
    }
    /* info(MK | 02-11-2021): this has been commented out to address refresh with no changes to params (admin - benefit tracking)
    if for some reason this becomes an issue pls get in touch
    this.onChange(this.selectedValues);
    this.onTouched(this.selectedValues);
    */
  }
  @HostListener('document:click', ['$event'])
  clickOut(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isExpanded = false;
      if (this.positioningDropdownsWithRespectToBrowserWindow) {
        this.getDirectionOfDropdownOpeningWithRespectToWindow(this.container);
      } else if (this.positioningDropdownsUnderModalWindow) {
        this.getDirectionOfDropdownOpeningWithRespectToModal(this.container);
      }
    }
  }

  getDirectionOfDropdownOpeningWithRespectToWindow(container: HTMLElement): void {
    if (!container) {
      return;
    }
    // visibility has been hidden in both the cases to make sure no dropdown appears before the operation is complete
    // though the timeout is of 0sec but still visibility is hidden till operation completes so the ser doesnot even see
    // any flickers on the screen.
    container.style.visibility = 'hidden';

    // timeout is used as we need coordinates and for that we have used getBoundingClientRect()
    // but till the time user clicks on dropdown to open it. the dropdown div is set to display none which means
    // getBoundingClientRect wont be able to get any coordinate as its not on DOM. settimeout of 0sec will make sure
    // the operation is async and so DOM gets populated before the operation starts. Hence we get the result.

    setTimeout(() => {
      // calculating height of the dropdown and the y coordinate of dropdown which determines the space from the top
      // of the window till the point where dropdown starts.
      // the formula contains adding the height of the dropdown(heightOfDropdown) + the space between top of the window and the
      // point where dropdown starts (dropdownCoordinateY) should not be more that the total height of window.
      // if it is then it means dropdown does not have space downwards and in that case we open dropdown upwards.

      const dropdownPosition = container?.getBoundingClientRect();

      // space between top of the window and the point where dropdown starts.
      const dropdownCoordinateY = dropdownPosition?.y;

      // height of the dropdown
      const heightOfDropdown = dropdownPosition?.height;

      // formula --> dropdownCoordinateY + heightOfDropdown > window.innerHeight
      if (dropdownCoordinateY + heightOfDropdown > window.innerHeight) {
        this.dropUp = true;
      } else {
        this.dropUp = false;
      }
      container.style.visibility = 'visible';
    }, 0);
  }

  getDirectionOfDropdownOpeningWithRespectToModal(container: HTMLElement): void {
    if (!container) {
      return;
    }
    container.style.visibility = 'hidden';
    setTimeout(() => {

      // modal height and coordinates
      const modalWindow = document.getElementById('modalBody');
      const modalRect = modalWindow.getBoundingClientRect();
      const modalY = modalRect.y;
      const heightOfModalBody = modalRect.height;

      // dropdown height and coordinates
      const position = container.getBoundingClientRect();
      const dropdownY = position.y;
      const heightOfDropDown = position.height;

      // calculating space left from top of the modal window till start of dropdown
      const topSpacingBetweenModalAndDropdown = dropdownY - modalY;

      // calculating where should dropdown open
      if (topSpacingBetweenModalAndDropdown + heightOfDropDown > heightOfModalBody) {
        this.dropUp = true;
      } else {
        this.dropUp = false;
      }
      container.style.visibility = 'visible';
    }, 0);
  }

  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  set selectedValues(val) {
    this._selectedValues = val;
    this.onChange(val);
    this.onTouched(val);
  }


  get selectedValues(): any {
    return this._selectedValues;
  }

  writeValue(initValue: any): void {
    this._selectedValues = initValue || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  isChecked(v): boolean {
    return _.isEmpty(this.selectedValues) ? false : !(this.selectedValues.find((t) => JSON.stringify(t) === JSON.stringify(v)) === undefined);
  }

  changed(v): any {
    if (_.isEmpty(this.selectedValues)) {
      this.selectedValues = [{...v}];
    } else {
      const item = this.selectedValues.find((t) => JSON.stringify(t) === JSON.stringify(v));
      if (item === undefined) {
        this.selectedValues = [...this.selectedValues, {...v}];
      } else {
        this.removeItem(item);
      }
    }

  }

  hidePlaceholder(): boolean {
    if (this.floatingLabels) {
      return (this.isExpanded || this.selectedValues.length > 0) ? true : false;
    } else {
      return true;
    }
  }

  onClearAll(): void {
    this.selectedValues = [];
  }

  filter(val): void {
    if (val) {
        const startsWith = this.incomingValues.filter(v => v[this.labelName].search(new RegExp('^' + val, 'i')) !== -1)
        let containsList = this.incomingValues.filter(v => v[this.labelName].search(new RegExp(val, 'i')) !== -1);
        containsList = containsList.filter(e => !startsWith.includes(e));
        this.filteredValues = [...startsWith, ...containsList];
        this.isExpanded = true;
    } else {
      this.filteredValues = this.incomingValues;
    }
  }

  removeItem(item: any): void {
    this.selectedValues = this.selectedValues.filter((t) => JSON.stringify(t) !== JSON.stringify(item));
  }

  setInputValue(): any {
    if (this.placeHolderValue) {
      return null;
    }
    return 0;
  }

  getPlaceHolderValue(): string {
    return !this.isExpanded ? this.placeHolderValue : '';
  }


  mapFieldIds(val): void {
    const selectedFields = [];
    val.forEach(el => {
      selectedFields.push(this.filteredValues?.find(x => x.id === el));
    });
    this.selectedValues = selectedFields;
  }
}
