import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AllowedDomain } from '../models/admin/alloweddomain.model';
import { IUserFromSearch } from '../models/user/user-from-search.model';
import { GridState } from '../../shared/components/ey-grid/models/grid-state.model';
import { TableData } from '../../shared/components/ey-grid/models/table-data.model';
import { ClientUser } from '../../admin/client-user.model';
import { saveAs as importedSaveAs } from 'file-saver';
import { SyncSetting } from '../models/admin/syncsetting.model';
import { OtherSetting } from '../models/admin/othersettings.model';

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    private baseUrl = environment.baseUrl;

    constructor(private httpClient: HttpClient) {}

    getAllowedDomains(): Observable<AllowedDomain[]> {
        return this.httpClient.get<AllowedDomain[]>(`${this.baseUrl}/admin/get-allowed-domains`);
    }

    listAllowedDomains(gridState: GridState): Observable<TableData<AllowedDomain>> {
        return this.httpClient.get<TableData<AllowedDomain>>(`${this.baseUrl}/admin/list-allowed-domains`, { params: {
            pageSize: gridState.pageSize.toString(),
            sortBy: gridState.sortBy.toString(),
            sortDirection: gridState.sortDirection.toString(),
            pageNumber: gridState.pageNumber.toString(),
        }});
    }

    listSyncSettings(gridState: GridState): Observable<TableData<SyncSetting>> {
        return this.httpClient.get<TableData<SyncSetting>>(`${this.baseUrl}/admin/list-sync-settings`, { params: {
            pageSize: gridState.pageSize.toString(),
            sortBy: gridState.sortBy.toString(),
            sortDirection: gridState.sortDirection.toString(),
            pageNumber: gridState.pageNumber.toString(),
        }});
    }

    getInstanceName(): Observable<string> {
        return this.httpClient.get<string>(`${this.baseUrl}/admin/get-instance-name`);
    }
    
    updateSyncSettings(syncSetting: SyncSetting): Observable<boolean> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.put<boolean>(`${this.baseUrl}/admin/update-sync-settings`, syncSetting);
    }

    getSyncSettings(instanceName: string): Observable<SyncSetting> {
        return this.httpClient.get<SyncSetting>(`${this.baseUrl}/admin/get-sync-settings/${instanceName}`);
    }

    listOtherSettings(gridState: GridState): Observable<TableData<OtherSetting>> {
        return this.httpClient.get<TableData<OtherSetting>>(`${this.baseUrl}/admin/list-other-settings`, { params: {
            pageSize: gridState.pageSize.toString(),
            sortBy: gridState.sortBy.toString(),
            sortDirection: gridState.sortDirection.toString(),
            pageNumber: gridState.pageNumber.toString(),
        }});
    }

    updateOtherSettings(otherSetting: OtherSetting): Observable<boolean> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.put<boolean>(`${this.baseUrl}/admin/update-other-settings`, otherSetting);
    }

    isAllowedDomainNameUnique(domainId: number, domainName: string): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseUrl}/admin/${domainId}/is-domain-name-unique`, {
            params: {
                domainName: encodeURIComponent(domainName),
            },
        });
    }

    deleteAllowedDomain(id: number): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.baseUrl}/admin/delete-allowed-domain/${id}`);
    }

    updateAllowedDomain(domain: AllowedDomain): Observable<boolean> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.put<boolean>(`${this.baseUrl}/admin/update-allowed-domain`, domain);
    }

    createAllowedDomain(domain: AllowedDomain): Observable<AllowedDomain> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.post<AllowedDomain>(`${this.baseUrl}/admin/create-allowed-domain`, domain);
    }

    createNewEyUsers(users: IUserFromSearch[]): Observable<boolean> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.post<boolean>(`${this.baseUrl}/admin/create-ey-users`, users);
    }

    createNewClientUsers(users: IUserFromSearch[]): Observable<boolean> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.post<boolean>(`${this.baseUrl}/admin/create-ex-users`, users);
    }

    verifyUserExists(email: string): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseUrl}/admin/verify-user-exists/${email}`);
    }

    getEyUserInfo(id: string): Observable<IUserFromSearch> {
        return this.httpClient.get<IUserFromSearch>(`${this.baseUrl}/admin/ey-user-info/${id}`);
    }

    updateEyUserInfo(eyUserInfo: IUserFromSearch): Observable<boolean> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.patch<boolean>(`${this.baseUrl}/admin/update-ey-user-info`, eyUserInfo);
    }

    getClientUser(id: string): Observable<ClientUser> {
        return this.httpClient.get<IUserFromSearch>(`${this.baseUrl}/admin/client-user-info/${id}`);
    }

    updateClientUser(eyUserInfo: ClientUser): Observable<boolean> {
        return this.httpClient.patch<boolean>(`${this.baseUrl}/admin/update-client-user-info`, eyUserInfo);
    }

    blockEyUser(id: string): Observable<boolean> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.patch<boolean>(`${this.baseUrl}/admin/block-ey-user/${id}`, {}, { headers });
    }

    unblockEyUser(id: string): Observable<boolean> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.patch<boolean>(`${this.baseUrl}/admin/unblock-ey-user/${id}`, {}, { headers });
    }

    generateUserRoleReport(): Observable<any> {
      return this.httpClient.get(`${this.baseUrl}/admin/report/user-role`, {observe: 'response', responseType: 'blob' });
    }

    generateUserLoginReport(): Observable<any> {
      return this.httpClient.get(`${this.baseUrl}/admin/report/user-login`, {observe: 'response', responseType: 'blob' });
    }

    generateSummaryByModulesReport(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/admin/report/summary-by-modules`, {observe: 'response', responseType: 'blob' });
    }

    generateSummaryByProjectsReport(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/admin/report/summary-by-projects`, {observe: 'response', responseType: 'blob' });
    }

    generateUsageByModulesReport(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/admin/report/usage-by-modules`, {observe: 'response', responseType: 'blob' });
    }

    generateUsageByProjectsReport(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/admin/report/usage-by-projects`, {observe: 'response', responseType: 'blob' });
    }

    generateDataAccessByModulesReport(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/admin/report/data-access-by-modules`, {observe: 'response', responseType: 'blob' });
    }

    generateDataAccessByProjectsReport(): Observable<any> {
        return this.httpClient.get(`${this.baseUrl}/admin/report/data-access-by-projects`, {observe: 'response', responseType: 'blob' });
    }

    generateFile(response: any, selectedFilter: string, instance: string, fileName: string): void {
      const d = new Date();
      const dString: string = this.getCurrentDate();
      selectedFilter = selectedFilter ? selectedFilter + '_' : '';
      const downloadedFileName = (selectedFilter + instance + '_' + dString + '_').toString();
      importedSaveAs(response.body, fileName.replace('%TS%', (downloadedFileName + d.getHours() + '-' + d.getMinutes() + '-' + d.getSeconds())));
      return;
    }

  getCurrentDate(): string {
    const d = new Date();
    const dString: string = d.getDate().toString().padStart(2, '0') + (d.getMonth() + 1).toString().padStart(2, '0') + d.getFullYear();
    return dString;
  }
}
