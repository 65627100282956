import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[eyTemplate]',
  host: {}
})
export class EyTemplateDirective {
  @Input() type: string;
  @Input('eyTemplate') name: string;
  constructor(public template: TemplateRef<any>) {}
  getType(): string {
    return this.name;
  }
}
