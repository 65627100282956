import {Component, ElementRef, forwardRef} from '@angular/core';
import {FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TableBuilderFormBaseComponent} from '../table-builder-form-base.component';
import {DES_EDITOR_META} from '../../../form-builder/controls/description/description-editor/description-editor.meta';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ListComponent),
      multi: true
    }]
})
export class ListComponent extends TableBuilderFormBaseComponent {

  form: FormGroup = this.fbs.group(
    {
    },
    { updateOn: 'blur' }
  );
  meta = DES_EDITOR_META; // TODO(mk): TO BE REPLACED WITH FORM SPECIFIC META

  constructor(private fbs: FormBuilder,  private els: ElementRef) {
    super(fbs, els);
  }

}
