<ng-template #tipContent>
    <span *ngIf="!copied">Copy to clipboard</span>
    <span  *ngIf="copied"><i class="icon-ico_success_toast emp-icon mr-2" style="vertical-align: middle;"></i>Copied to clipboard</span>
</ng-template>
<span tooltipClass="emp-tooltip" [ngbTooltip]="tipContent" placement="left" triggers="manual">
<span  *ngIf="!showIcon" title="Copy to clipboard" (click)="onClipboardCopy()">
<ng-content ></ng-content>
</span>
<span class="d-flex">
<i  *ngIf="showIcon"
    class="icon-ico_copy emp-icon"
    (click)="onClipboardCopy()"
    title="Copy to clipboard"
    ></i>
</span>
</span>
