export const CURSOR_GRAB = 'grab';
export const CUSROR_DEFAULT = 'default';
export const CURSOR_GRABBING = 'grabbing';
export const CURSOS_DRAW_CONNECTION = 'crosshair';
export const CURSOR_MOVE = 'move';
export const CURSOR_ADD_TO_SELECTION = 'copy';
export const CURSOR_POINTER = 'pointer';


export const BUTTON_DELETE = 'Delete';
export const BUTTON_CTRL_LEFT = 'ControlLeft';
export const BUTTON_CTRL_RIGHT = 'ControlRight';
export const BUTTON_SPACE = 'Space';

export const START_TOOL = 'start';
export const END_TOOL = 'end';
export const STATEMENT_TOOL = 'statement';
export const YES_NO_TOOL = 'yes-no';
export const DECISION_TOOL = 'decision';
export const LIST_TOOL = 'list';
export const FORM_TOOL = 'form';
export const UPLOAD_TOOL = 'upload';
export const LETTER_TOOL = 'letter';
export const MAIL_TOOL = 'mail';
export const TABLE_TOOL = 'table';
export const CONNECTOR_TOOL = 'connector';

export const START_TOOL_SPECIFIC_PART_CLASS = 'StartToolPart';
export const END_TOOL_SPECIFIC_PART_CLASS = 'EndToolPart';
export const CONNECTOR_TOOL_SPECIFIC_PART_CLASS = 'ConnectorToolPart';
export const STATEMENT_TOOL_SPECIFIC_PART_CLASS = 'StatementPart';
export const LIST_TOOL_SPECIFIC_PART_CLASS = 'ListPart';
export const UPLOAD_TOOL_SPECIFIC_PART_CLASS = 'UploadPart';
export const DECISION_TOOL_SPECIFIC_PART_CLASS = 'DecisionPart';
export const FORM_TOOL_SPECIFIC_PART_CLASS = 'FormPart';
export const LETTER_TOOL_SPECIFIC_PART_CLASS = 'LetterPart';
export const MAIL_TOOL_SPECIFIC_PART_CLASS = 'MailPart';
export const YES_NO_TOOL_SPECIFIC_PART_CLASS = 'YesNoPart';
export const TABLE_TOOL_SPECIFIC_PART_CLASS = 'TablePart';

export const STATEMENT_NAME_ALIAS = 'Stmt';
export const LIST_TOOL_NAME_ALIAS = 'List';
export const UPLOAD_TOOL_NAME_ALIAS = 'Upload';
export const YES_NO_TOOL_NAME_ALIAS = 'Yn';
export const DECISION_TOOL_NAME_ALIAS = 'Decision';
export const FORM_TOOL_NAME_ALIAS = 'Form';
export const LETTER_TOOL_NAME_ALIAS = 'Letter';
export const TABLE_TOOL_NAME_ALIAS = 'Table';
export const MAIL_TOOL_NAME_ALIAS = 'Mail';
export const CONNECTOR_TOOL_NAME_ALIAS = 'Connector';

export const CONNECTION = 'Connection';
export const TOOL = 'Tool';

export const SHAPES_KEY = 'empowement-shapes';

export const undoType = '[Undo History] Undo';
export const redoType = '[Undo History] Redo';
export const undoHistoryClearType = '[Undo History] Clear';

export const ZoomInScaleFactor = 1.01;
export const ZoomOutScaleFactor = 0.99;

export const DRAFT_ID_QUERY_PARAM_NAME = 'draftId';
