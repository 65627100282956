<div class="d-flex">
<div class="textinput-group flex-grow-1" [ngClass]="{'z-index-top-up': isPickerOpen, 'mb-20': showError}">
  <input  [class.darkBkg] = "darkMode"
    (blur)="onBlur()"
    (input)="onInput()"
    [disabled]="isDisabled"
    [id]="'dp' + ctrl_id"
    class="textinput-group__textinput focusable"
    [ngClass]="{error: showError}"
    [placeholder]="placeholder"
    [(ngModel)]="selectedValue"
    (dateSelect)="onDateSelect($event)"
    ngbDatepicker #d="ngbDatepicker"
    (closed)="isPickerOpen = false"
          [attr.aria-label]="showError ? meta?.errorMsg: meta?.title + (meta?.info ? meta?.info : '' )"
          [attr.aria-invalid]="showError"
  />
  <i class="emp-icon icon-ico_date" [class.darkBkgIco]="darkMode" (click)="togglePicker(d)"></i>
  <label class="textinput-group__label focus" [class.darkBkgLabel] = "darkMode" [for]="'inp' + ctrl_id" [ngClass]="{ asterisk: meta?.required }" >{{ meta.title }}</label>
  <label *ngIf="showError" class="textinput__errmsg"><i class="err_icon icon-ico_error_toast"></i>{{meta.errorMsg}}</label>

</div>
  <div class="tooltip--holder" *ngIf="showToolTipHolder">
    <ey-input-tooltip [toolTipContent]="meta.info" ></ey-input-tooltip>
  </div>
</div>
