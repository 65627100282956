import {
  Directive,
  HostListener,
  HostBinding,
  Input,
  ElementRef,
  OnInit,
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {CATEGORY_PERCENT, SeparatorEnum} from '../../../builders/table-builder/controls/controls.const';
import {NumberFormatService} from '../../../builders/table-builder/table/number-format.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formatOnBlur]',
  exportAs: 'formatOnBlur'
})
export class FormatOnBlur implements OnInit {
  @Input() formatOnBlur: any;
  @Input() transformFormItem = true;
  @HostBinding('class.my-focused-element') isFocused: boolean;
  value: any;
  constructor(public model: NgControl, private elRef: ElementRef, private numberFormatService: NumberFormatService) {}

  @HostListener('focus', ['$event']) onFocus(e): void {
    this.isFocused = true;
    this.elRef.nativeElement.getElementsByTagName('input')[0].value = Number(this.value) === 0 || isNaN(Number(this.value)) ? '' : Number(this.value);
  }

  @HostListener('blur', ['$event']) onblur(e): void {
    this.isFocused = false;
    this.value = this.elRef.nativeElement.getElementsByTagName('input')[0].value;
    this.elRef.nativeElement.getElementsByTagName('input')[0].value = this.formatNumber(this.value, this.formatOnBlur);
  }

  formatNumber(val: any, item: any): string {
    if (val == null || undefined === val || val === '') {
      return '';
    };
    let fieldProperties = item;
    if (this.transformFormItem) {
      fieldProperties = {
        ...item,
        category: item.isPercentage ? CATEGORY_PERCENT : '',
        useThousandsSeparator: item.thousandSeparator != null ? true : false,
        thousandsSeparator:   SeparatorEnum[item.thousandSeparator],
        decimalSeparator: SeparatorEnum[item.decimalSeparator],
      };
    }
    return this.numberFormatService.formatNumber(val, fieldProperties);
  }

  ngOnInit(): void {
    this.elRef.nativeElement.getElementsByTagName('input')[0].addEventListener('focus', (e) => this.onFocus(e));
  }

}
