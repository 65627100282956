import {Component, ElementRef, Input, OnDestroy} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {EyFormBaseComponent} from '../../../../../shared/components/ey-form-base/ey-base-form-control';
import {Subject} from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fb-form-base',
  template: ''
})
export class FormBuilderFormBaseComponent extends EyFormBaseComponent implements OnDestroy, ControlValueAccessor {
  form: FormGroup = this.fb.group(
    {
      name: [, Validators.required],
      description: [, Validators.required]
    },
    { updateOn: 'blur' }
  );
  @Input() requiredFieldCrossValidationError = false;
  showError = false;
  protected destroy$ = new Subject<boolean>();

  constructor(private fb: FormBuilder,  private el: ElementRef) {
    super();
  }

  writeValue(val: any): void {
    if (val) {
      this.form.patchValue(val, { emitEvent: false });
    }
  }

  public onTouched: () => void = () => {};

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(v => {
      if (this.form.valid) {
        if (!v?.required || (v?.required && !this.requiredFieldCrossValidationError)) {
          fn(v);
        }
      }
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { subformerror: 'Form Builder Editor Form Error!' };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.form.statusChanges.subscribe(fn);
  }
  markAsTouched(): void {
    this.form.markAllAsTouched();
    this.focusOnError(this.form, this.el);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  onRequired(fcValue: boolean): void {
    this.showError = true ? fcValue === true : false;
  }
}
