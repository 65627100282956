export const RADIUS = 8;
export const INNER_RADIUS = 2;
export const HOVER_RADIUS = 16;

export enum ConnectorPosition {
   Up = 1,
   Right,
   Bottom,
   Left
}

export enum ConnectorOnConnectionPosition {
    Beginning = 10000,
    End
}

export interface Connector {
    readonly id: number;
    readonly parentId: string;
    readonly x: number;
    readonly y: number;
    readonly isHovered: boolean;
}

export function pointInsideConnector(connector: Connector, x: number, y: number): boolean {
    const boundingRect = getCircleBoundingRectangle(connector);

    return x >= boundingRect.x && x <= (boundingRect.x + boundingRect.width) &&
        y >= boundingRect.y && y <= (boundingRect.y + boundingRect.height);
}

export function drag(connector: Connector, offsetX: number, offsetY: number): Connector {
    return {
        ...connector,
        x: connector.x + offsetX,
        y: connector.y + offsetY,
    };
}

export function draw(connector: Connector, ctx: CanvasRenderingContext2D): void {
    ctx.save();
    ctx.lineWidth = 2;
    ctx.fillStyle = 'rgba(46, 46, 56, 0.6)';
    ctx.beginPath();
    ctx.arc(connector.x, connector.y, connector.isHovered ? HOVER_RADIUS : RADIUS, 0, Math.PI * 2);
    ctx.fill();

    ctx.fillStyle = '#F6F6FA';
    ctx.beginPath();
    ctx.arc(connector.x, connector.y, INNER_RADIUS, 0, Math.PI * 2);
    ctx.fill();

    if (connector.isHovered) {
        ctx.shadowColor = 'rgba(46, 46, 56, 0.2)';
        ctx.shadowBlur = 10;
        ctx.fillStyle = 'white';
        ctx.font = '13px EYInterstate';
        const tooltipX = connector.x + HOVER_RADIUS + 5;
        const tooltipY = connector.y - 15;
        ctx.fillRect(tooltipX, tooltipY, 180, 30);
        ctx.fillStyle = 'black';
        ctx.textBaseline = 'middle';
        ctx.fillText('Drag connection from here', tooltipX + 10, connector.y);
    }
    ctx.restore();
}

export function createConnector(id: number, parentId: string, x: number, y: number, width: number, height: number): Connector {
    // tslint:disable-next-line: max-line-length
    const { x: connectorX, y: connectorY } = calculateConnectorPosition(id, x, y, { width, height });

    return { id, parentId, x: connectorX, y: connectorY, isHovered: false };
}

export function calculateConnectorPosition(
    id: number,
    x: number,
    y: number,
    shape: { width: number, height: number}): { x: number, y: number} {
    switch (id) {
        case ConnectorPosition.Up:
            return { x: x + shape.width / 2, y };
        case ConnectorPosition.Right:
            return { x: x + shape.width, y: y + shape.height / 2};
        case ConnectorPosition.Bottom:
            return { x: x + shape.width / 2, y: y + shape.height};
        case ConnectorPosition.Left:
            return { x, y: y + shape.height / 2};
        default:
            return { x: 0, y: 0 };
    }
}

function getCircleBoundingRectangle(connector: Connector): { x: number, y: number, width: number, height: number} {
    return {
        x: connector.x - HOVER_RADIUS,
        y: connector.y - HOVER_RADIUS,
        width: HOVER_RADIUS * 2,
        height: HOVER_RADIUS * 2
    };
}
