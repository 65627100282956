<label class="switch switch--round mb-0" [class.d-flex]="displayEnableDisable">
  <input [attr.aria-label]="ariaLabel" [id]="id" class="switch__input" [checked]="selectedValue" (change)="onChange()" type="checkbox" [attr.disabled]="isDisabled || null" />
  <span class="switch__slider"></span>

  <ng-container *ngIf="slim; then slimIndicator else fullIndicator"></ng-container>
  <ng-template #slimIndicator>
    <span *ngIf="!selectedValue" aria-label="Off" class="switch-label no--off" [class.margin-top-adjust]="displayEnableDisable">{{displayEnableDisable ? 'Disabled' : 'Off'}}</span>
    <span *ngIf="selectedValue" aria-label="On" class="switch-label no--off" [class.margin-top-adjust]="displayEnableDisable">{{displayEnableDisable ? 'Enabled' : 'On'}}</span>
  </ng-template>
  <ng-template #fullIndicator>
    <span  class="switch-label" aria-label="On">On</span>
  </ng-template>

</label>
