import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';


export const NOT_SAVED = 'Unsaved';
export const SAVED = 'Saved';
export const SAVING = 'Saving ...';

@Injectable()
export class SaveNotificationServiceEndUserForm {
  saveCurrentProperties$ = new Subject<void>();
  saveStatus$ = new BehaviorSubject(SAVED);
  constructor() {
    this.saveStatus$.next(SAVED);
  }

  public dispatchIsSaving(): void {
    this.saveStatus$.next(SAVING);
  }

  public dispatchIsSaved(): void {
    this.saveStatus$.next(SAVED);
  }

  public dispatchNotSaved(): void {
    this.saveStatus$.next(NOT_SAVED);
  }

}
