import {Component, ElementRef, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validators
} from '@angular/forms';
import {TableBuilderFormBaseComponent} from '../table-builder-form-base.component';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';
import {CATEGORY_PERCENT} from '../controls.const';
import {Subject} from 'rxjs/internal/Subject';

const META = {
  name: {
    title: 'Name',
  },
  question: {
    title: 'Question',
    required: true,
    errorMsg: 'Question is required. Max length 2000 characters',
  },
  hint: {
    title: 'Hint',
    errorMsg: 'Max length 1000 characters'
  },
  category: {
    title: 'Category'
  },
  decimalPlaces: {
    title: 'Decimal places'
  },
  decimalSeparator: {
    title: 'Decimal Separator'
  },
  thousandSeparator: {
    title: '1000 Separator'
  }
};

export const CATEGORIES: DropdownItem[] = [
  { name: 'Number', value: 'Number'},
  { name: 'Percentage', value: 'Percentage' }
];

export const DECIMAL_SEPARATORS: DropdownItem[] = [
  { name: 'Dot', value: 'Dot'},
  { name: 'Comma', value: 'Comma'},
  { name: 'Blank Space', value: 'Blank Space'},
];
export const THOUSANDS_SEPARATORS: DropdownItem[] = [
  { name: 'Comma', value: 'Comma'},
  { name: 'Blank Space', value: 'Blank Space'},
  { name: 'Dot', value: 'Dot'},
];


interface DropdownItem {
  name: string;
  value: any;
}

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true
    }]
})
export class NumberInputComponent extends TableBuilderFormBaseComponent implements OnInit, OnDestroy {
  meta = META;
  destroy$: Subject<boolean> = new Subject<boolean>();
  defCategoryNumber = CATEGORIES[0];
  defDecSeparator = DECIMAL_SEPARATORS[0];
  defThoSeparator = THOUSANDS_SEPARATORS.filter((ts) => ts.value !== this.defDecSeparator.value)[0];
  categories: DropdownItem[] = CATEGORIES;
  decimalSeparators: DropdownItem[] = DECIMAL_SEPARATORS;
  thousandsSeparators: DropdownItem[] = THOUSANDS_SEPARATORS.filter((ts) => ts.value !== this.defDecSeparator.value);

  form = this.fbs.group(
    {
      category: [this.defCategoryNumber, Validators.required],
      decimalPlaces: [0, [Validators.required, Validators.min(0), Validators.max(10)]],
      decimalSeparator: [this.defDecSeparator, Validators.required],
      useThousandsSeparator: [false, { updateOn: 'change'}],
      thousandsSeparator: [this.defThoSeparator, Validators.required],
    },
    { updateOn: 'blur' }
  );

  get thousandsSeparatorControl(): FormControl {
    return this.form.get('useThousandsSeparator') as FormControl;
  }

  get categoryCtrl(): FormControl {
    return this.form.get('category') as FormControl;
  }

  get thousandsSeparatorChecked(): boolean {
    return this.thousandsSeparatorControl?.value;
  }

  get decimalPlacesControl(): FormControl {
    return this.form.controls.decimalPlaces as FormControl;
  }
  get decimalSeparatorCtrl(): FormControl {
    return this.form.controls.decimalSeparator as FormControl;
  }

  constructor(private fbs: FormBuilder,  private els: ElementRef) {
    super(fbs, els);
  }



  ngOnInit(): void {
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges(): void {


    this.thousandsSeparatorControl.valueChanges.subscribe(v => {
      const controlName = 'thousandsSeparator';

      if (v) {
        this.form.addControl(controlName, this.fbs.control(this.defThoSeparator, Validators.required));
      } else {
        this.form.removeControl(controlName);
      }
    });

    this.decimalPlacesControl.valueChanges.subscribe(v => {
      const decimalPlaces = +v;

      if (decimalPlaces > 10) {
        this.decimalPlacesControl.setValue('10', {emitEvent: false});
      }

      if (decimalPlaces < 0) {
        this.decimalPlacesControl.setValue('0', {emitEvent: false});
      }
    });

    this.categoryCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if ( val?.name === CATEGORY_PERCENT ) {
        this.form.patchValue({decimalPlaces: 2},  {emitEvent: false});
      } else {
        this.form.patchValue({decimalPlaces: 0},  {emitEvent: false});
      }
    });

    this.decimalSeparatorCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      this.thousandsSeparators = THOUSANDS_SEPARATORS.filter((ts) => ts.value !== v.value);
      this.defThoSeparator = this.thousandsSeparators[0];
      this.form.patchValue({thousandsSeparator: [this.thousandsSeparators[0]]},  {emitEvent: false});
    });


    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(v => {
      if (v.category !== null) {
      this.onChange(this.convertToReturnValue(v));
      }
    });

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(this.onValidatorChange);
    if (this.form.value.category !== null) {
      this.onChange(this.convertToReturnValue(this.form.value));
    }
  }


  convertToReturnValue(value: any): any {
    return {
      ...value,
      category: value?.category?.value,
      decimalSeparator: value?.decimalSeparator?.value,
      thousandsSeparator: value?.thousandsSeparator?.value,
    };
  }



  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { subformerror: 'Number Input Editor Form Error!' };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  onChange: (val: any) => void = () => {};
  onValidatorChange: () => void = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: any): void {
    if (val) {

      const patchValue = val.category
        ? {
          ...val,
          category: this.categories.find(c => c.value === val.category),
          decimalSeparator: this.decimalSeparators.find(c => c.value === val.decimalSeparator),
          thousandsSeparator: this.thousandsSeparators.find(c => c.value === val.thousandsSeparator),
        }
        : val;

      this.form.patchValue(patchValue);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

