import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {FlowType, ModuleFlowService} from '../module-flow.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';
import {Subject} from 'rxjs/internal/Subject';
import {Page, ViewTypes} from '../page.model';
import {BreakpointObserver} from '@angular/cdk/layout';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {DraftModalComponent} from './draft-modal/draft-modal.component';
import {DraftModalResult} from './draft-modal/draft-modal-result';
import {
  ModalVerticalSizeWindowClass,
  ModalWidth
} from 'src/app/shared/components/ey-modal-template/ey-modal-result.enum';
import {ResponseService} from '../submit-response/response.service';
import {GridState} from '../../../shared/components/ey-grid/models/grid-state.model';
import {DEF_GRID_SATE} from '../../../shared/components/ey-grid/ey-grid.component';
import {SortDirection} from '../../../shared/components/ey-grid/models/sort-direction.model';
import {concatMap, tap} from 'rxjs/operators';
import {CollaborationModeStartMessageModalComponent} from '../collaboration-mode-start-message-modal/collaboration-mode-start-message-modal.component';
import {of} from 'rxjs';
import {BLANK_TARGET} from "../page-templates/fluid-footer-template/fluid-footer-template.component";

export enum ContentViewerBreakpoints {
  tablet = '(max-width: 1280px)',
  mobile = '(max-width: 768px)'
}

@Component({
  selector: 'app-content-viewer',
  templateUrl: './content-viewer.component.html',
  styleUrls: ['./content-viewer.component.scss']
})
export class ContentViewerComponent implements OnInit, OnDestroy {
  @Input() selectedViewType: ViewTypes = ViewTypes.desktop;
  @Input() flowType: FlowType = FlowType.preview;
  @Input() lastTechReviewDate: string = null;
  @Input() draftId: string = null;
  @Input() autoSaveResponse = false;
  @Input() collaborationMode = false;
  @Output() collaborationResponseStatus: EventEmitter<any> = new EventEmitter();
  @Output() storedResponseId: EventEmitter<any> = new EventEmitter();
  currentPage: Page;
  payload: any;
  sessionId = null;
  moduleId: string;
  versionId: string;
  viewTypes = ViewTypes;
  private destroy$ = new Subject<boolean>();
  taskId: string;
  targetVersionId: string;
  targetProjectId: string;
  qrmType: string;
  reviewReponseId: string;
  // modal option
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    size: ModalWidth.MonstrouslyBig,
    windowClass: ModalVerticalSizeWindowClass.contentHeavyDoubleHeader
  };
  // grid state
  state: GridState = {
    ...DEF_GRID_SATE,
    pageSize: 50,
    sortBy: 'dateCreated',
    sortDirection: SortDirection.DES
  };

  isMobileBreakpoint = false;
  targetResponseId: string = null;
  showDraftsPopup = false;

  responseId: string = null;

  constructor(
    private flowService: ModuleFlowService,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private responseService: ResponseService,
    private ngbModal: NgbModal,
    private router: Router,
  ) {}

  get showTabset(): boolean {
    return this.currentPage?.moduleFlowPage?.hint
      && (this.isMobileBreakpoint || this.selectedViewType === this.viewTypes.mobile);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((p: ParamMap) => {
        this.moduleId = p.get('moduleId');
        this.versionId = p.get('versionId');
        this.taskId = p.get('taskId');
        this.qrmType = p.get('qrmType');
        this.targetVersionId = p.get('targetVersionId');
        this.targetProjectId = p.get('targetProjectId');
        this.targetResponseId = p.get('targetResponseId');
        this.reviewReponseId = p.get('responseId');
        this.onStartFlow();
      });

    this.flowService.currentPage$.pipe(takeUntil(this.destroy$)).subscribe(p => {
      this.currentPage = p;
    });

    this.breakpointObserver.observe([ContentViewerBreakpoints.tablet, ContentViewerBreakpoints.mobile])
      .pipe(takeUntil(this.destroy$))
      .subscribe(o => {
        this.isMobileBreakpoint = o.breakpoints[ContentViewerBreakpoints.mobile];
        if (this.isMobileBreakpoint) {
          this.flowService.responseHeaderMobileViewChange.next(true);
        } else {
          this.flowService.responseHeaderMobileViewChange.next(false);
        }
      });
  }

  ngOnDestroy(): void {
    this.flowService.resetCurrentFlow();
    this.destroy$.next(true);
  }

  onStartFlow(): void {
    if (this.flowType === FlowType.response) {
      this.responseService
        .isResponseInDraftState(this.taskId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(value => {
          if (value.isCollaborationMode) {
            if (value.draftResponseAvailable) {
              this.responseService
                .getUserDraftResponses(this.taskId, this.state)
                .pipe(takeUntil(this.destroy$))
                .subscribe(res => {
                  this.initResponseFlow(res.data[0].id);
                });
            } else {
              this.initResponseFlow();
            }
          } else {
            if (value.draftResponseAvailable) {
              if (this.draftId == null || this.draftId.length < 1) {
                this.showDraftsPopup = true;
                const modalOptions: NgbModalOptions = {
                  size: ModalWidth.MonstrouslyBig,
                  backdrop : 'static',
                  keyboard : false
                };
                const modalRef = this.ngbModal.open(DraftModalComponent, modalOptions);
                modalRef.componentInstance.collaborationMode = value.isCollaborationMode;
                modalRef.componentInstance.showDraftsPopup = true;
                modalRef.componentInstance.taskId = this.taskId;
                modalRef.result.then((result: DraftModalResult) => {
                  this.showDraftsPopup = false;
                  this.initResponseFlow(result.responseId);
                }).catch(() => this.initResponseFlow());
              } else {
                this.initResponseFlow(this.draftId);
              }
            } else {
              if (this.qrmType) {
                this.initApprovalFlow();
              } else {
                this.initResponseFlow();
              }
            }
          }
        });
    } else if (this.flowType === FlowType.review) {
      this.initReviewFlow();
    } else if (this.flowType === FlowType.reviewDraft) {
      this.initReviewDraftFlow();
    } else {
      this.initPreviewFlow();
    }
  }

  initPreviewFlow(): void {
    this.flowService.initPreviewFlow(this.versionId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.process(x);
        this.sessionId = x.sessionId;
      });
  }

  initReviewFlow(): void {
    this.flowService.initReviewFlow(this.taskId, this.reviewReponseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.process(x);
        this.sessionId = x.sessionId;
      });
  }

  initReviewDraftFlow(): void {
    this.flowService.initReviewDraftFlow(this.taskId, this.reviewReponseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.process(x);
        this.sessionId = x.sessionId;
      });
  }

  initResponseFlow(existingResponseId: string = null): void {
    this.flowService.initResponseFlow(this.taskId, existingResponseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.responseId = existingResponseId ?? x?.sessionId;
        this.storedResponseId.emit(this.responseId);
        if (this.collaborationMode) {
          if (existingResponseId) {
            this.collaborationResponseStatus.emit({isLocked: true});
            this.flowService.collaborationResponseStatusLocked.next(true);
            this.showCollaborationModeMessage(existingResponseId);
          } else {
            this.collaborationResponseStatus.emit({isLocked: false});
            this.flowService.collaborationResponseStatusLocked.next(false);
          }
        }
        this.process(x);
        this.sessionId = x?.sessionId;
      });
  }

  showCollaborationModeMessage(responseId: string): void {
    const collaborationModeMessageOnStart: NgbModalOptions = {
      backdrop: 'static',
      size: ModalWidth.default,
      windowClass: ModalVerticalSizeWindowClass.auto,
    };
    const modalRef = this.ngbModal.open(CollaborationModeStartMessageModalComponent, collaborationModeMessageOnStart);
    modalRef.componentInstance.taskId = this.taskId;
    modalRef.componentInstance.responseId = responseId;
    modalRef.result.then((result) => {
      return;
    }, () => {});
  }

  initApprovalFlow(): void {
    const responseId = (this.targetResponseId && this.targetResponseId !== 'null') ? this.targetResponseId : null;
    this.flowService.initResponseFlow(this.taskId, responseId)
      .pipe(
        takeUntil(this.destroy$),
        tap(x => {
            this.process(x);
            this.sessionId = x.sessionId;
         }),
        concatMap(x => {
          // in case of 'null' in approval link, approval flow is initialized with newly created response (sessionId equals to response id)
          return !responseId ? this.initApprovalWorkflow(x.sessionId) : of(null);
        }))
      .subscribe();
  }

  initApprovalWorkflow(sessionId: any): any {
    return this.flowService
    .initApprovalWorkflow(+this.qrmType, sessionId, this.targetVersionId, this.targetProjectId)
    .pipe(takeUntil(this.destroy$));
  }

  onGoBack(): void {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  private process(userResponsePage: any): void {
     this.payload = userResponsePage?.moduleFlowPage;
  }

  setViewType(viewType: ViewTypes): void {
    this.selectedViewType = viewType;
  }
}
