import { IUndoableState, UndoableState } from '../domain/undoable-state';
import { undoType, redoType, undoHistoryClearType } from '../constants';
import { ActionReducer, Action } from '@ngrx/store';
import { NonUndoableTypedAction } from './action-utils';
import { environment } from 'src/environments/environment';
import { undoHistoryClear } from '../undo-redo.actions';

export function createUndoableReducer<T>(initState: IUndoableState<T>, reducer: ActionReducer<T, Action>): (state: IUndoableState<T>,action: Action) => IUndoableState<T> {
    return (state: IUndoableState<T> = initState, action: Action): IUndoableState<T> => {
        const { past, present, future } = state;

        switch (action.type) {
            case undoType:
                if (!past.length) {
                    return state;
                }

                const previous = past[past.length - 1];
                const newPast = past.slice(0, past.length - 1);

                const newState = new UndoableState(newPast, previous, [present, ...future]);
                return newState;

            case redoType:
                if (!future.length) {
                    return state;
                }

                const next = future[0];
                const newFuture = future.slice(1);

                return new UndoableState([...past, present], next, newFuture);

            case undoHistoryClearType:
                return new UndoableState([], present, []);

            default:
                const newPresent = reducer(present, action);
                const isNonUndoable = actionIsNonUndoable(action);

                if (present === newPresent) {
                    return state;
                }

                if (isNonUndoable) {
                    return new UndoableState(past, newPresent, future);
                }

                return createUndoableStateWithinHistoryLimit(past, present, newPresent);
        }
    };
}

function actionIsNonUndoable<T extends string>(action: Action | NonUndoableTypedAction<T>): action is NonUndoableTypedAction<T> {
    return (action as NonUndoableTypedAction<T>).skipCreateUndo !== undefined;
}

function createUndoableStateWithinHistoryLimit<T>(past: T[], present: T, newPresent: T): IUndoableState<T> {
    if (environment.undoHistoryCount === -1 || past.length < environment.undoHistoryCount) {
        const newState = new UndoableState([...past, present], newPresent, []);
        return newState;
    }

    const newPast = past.slice(1);

    const newState = new UndoableState([...newPast, present], newPresent, []);
    return newState;
}
