import { Component, Input, OnInit } from '@angular/core';

export const PillType = {
  green: 'pill--approved',
  green2: 'pill--green',
  teal: 'pill--teal',
  blue: 'pill--blue',
  purple: 'pill--purple',
  maroon: 'pill--maroon',
  red: 'pill--failed',
  red2: 'pill--red',
  orange: 'pill--hold',
  orange2: 'pill--orange',
  dark: 'pill--open',
  darkWithBorder: 'pill--closed',
  draft: 'pill--draft',
  expired: 'pill--expired',
  lightGray: 'pill-light-gray'
};

@Component({
  selector: 'ey-pill',
  templateUrl: './ey-pill.component.html',
  styleUrls: ['./ey-pill.component.scss']
})
export class EyPillComponent implements OnInit {
  @Input() pillType: string;
  @Input() standard = true;
  @Input() toolTip = '';
  constructor() { }

  ngOnInit(): void {
  }

}
