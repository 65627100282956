import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalResult } from './ey-modal-result.enum';
@Component({
  selector: 'ey-modal-template',
  templateUrl: './ey-modal-template.component.html',
  styleUrls: ['./ey-modal-template.component.scss']
})
export class EyModalTemplateComponent implements OnInit {
  @Input() decreaseModalBodyPaddingRight = false;
  @Input() showInfoPanelInBody = false;
  @Input() showSubHeader = false;
  @Input() showDarkScroll = false;
  @Input() showBanner = false;
  @Input() enableGrayBodyBackground = false;
  @Input() setHeightAuto = false;
  @Input() showDraftsPopup = false;

  constructor(private activeModal: NgbActiveModal) { }
  modalResult = ModalResult;
  ngOnInit(): void {
  }

  public close(val: any): void {
    this.activeModal.close(val);
  }
}
