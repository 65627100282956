<ey-multiselect-search
	[tooltipPadding]="false"
	[limit]="1"
	[showSpinner]="showSpinner"
	[meta]="meta"
	[values]="values"
	(searchTextChanged)="onSearch($event)"
	[ngModel]="selectedValues"
	(ngModelChange)="onModelChange($event)"
  labelName2="email"
	>
</ey-multiselect-search>
