import {ComponentFactoryResolver, Directive, Input, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {Subject} from 'rxjs';
import {FlowType, ModuleFlowService} from 'src/app/designer/preview/module-flow.service';
import {ListPageComponent} from './page-content/list-page/list-page.component';
import {UploadPageComponent} from './page-content/upload-page/upload-page.component';
import {StartPageComponent} from './page-content/start-page/start-page.component';
import {EndPageComponent} from './page-content/end-page/end-page.component';
import {UnknownPageComponent} from './page-content/unknown-page/unknown-page.component';
import {StatementComponent} from './page-content/statement-page/statement.component';
import {ViewTypes} from './page.model';
import {BasePageContent} from './page-content/base-page-content';
import {DecisionPageComponent} from './page-content/decision-page/decision-page.component';
import {YesNoPageComponent} from './page-content/yes-no-page/yes-no-page.component';
import {FormPageComponent} from './page-content/form-page/form-page.component';
import {TablePageComponent} from './page-content/table-page/table-page.component';
import {LetterPageComponent} from './page-content/letter-page/letter-page.component';
import {MailPageComponent} from './page-content/mail-page/mail-page.component';
import {ErrorPageComponent} from './page-content/error-page/error-page.component';
import {EyAppSpinnerService} from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';

export const TABLE_TYPE = 'TABLE';

const typeToComponentMap = new Map<string, any>(
  [
    ['START', StartPageComponent],
    ['END', EndPageComponent],
    ['STATEMENT', StatementComponent],
    ['LIST', ListPageComponent],
    ['UPLOAD', UploadPageComponent],
    ['DECISION', DecisionPageComponent],
    ['YES/NO', YesNoPageComponent],
    ['FORM', FormPageComponent],
    ['TABLE', TablePageComponent],
    ['LETTER', LetterPageComponent],
    ['MAIL', MailPageComponent],
    ['ERROR', ErrorPageComponent],
  ]
);

export const START_TOOL_NAME = 'START';
export const END_TOOL_NAME = 'END';

@Directive({
  selector: '[appDynamicContent]'
})
export class DynamicContentDirective implements OnInit, OnDestroy {
  @Input() lastTechReviewDate: string = null;
  @Input() flowType: FlowType = FlowType.preview;
  @Input() autoSaveResponse = false;
  @Input() disableSubmitOnReview = false;
  @Input() collaborationMode = false;
  @Input() isMobileBreakPoint = false;

  selectedViewTypeP = ViewTypes.desktop;
  private destroy$ = new Subject<boolean>();
  component: any =  null;
  @Input() set selectedViewType(viewType: ViewTypes) {
    this.selectedViewTypeP = viewType;
    if (this.component) {
      (this.component.instance as BasePageContent).selectedViewType = this.selectedViewType;
    }
  }
  get selectedViewType(): ViewTypes {
    return this.selectedViewTypeP;
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private flowService: ModuleFlowService,
    private spinnerService: EyAppSpinnerService,
    private viewContainerRef: ViewContainerRef) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
      this.spinnerService
        .withLoadingIndicator(this.flowService.currentPage$, this.destroy$)
        .subscribe(p => {
          this.component = null;
          const componentToCreate = (!p) ? ErrorPageComponent : // in case of API error it returns empty payload instead of page
            typeToComponentMap.get(p?.moduleFlowPage?.type) || UnknownPageComponent;
          const createNewComponent = !(this.component?.instance instanceof componentToCreate);
          const isInstanceOfTable = (this.component?.instance instanceof componentToCreate) && p?.moduleFlowPage?.type !== TABLE_TYPE;

          if (!isInstanceOfTable || createNewComponent) {
            this.viewContainerRef.clear();
            const factory = this.componentFactoryResolver.resolveComponentFactory<any>(componentToCreate);
            this.component = this.viewContainerRef.createComponent<any>(factory);

          }
          if ((p?.moduleFlowPage?.type === START_TOOL_NAME || p?.moduleFlowPage?.type === END_TOOL_NAME) && this.collaborationMode) {
            this.component.instance.collaborationMode = this.collaborationMode;
          }
          this.component.instance.page = p;
          this.component.instance.isMobileBreakpoint = this.isMobileBreakPoint;
          this.component.instance.flowType = this.flowType;
          this.component.instance.selectedViewType = this.selectedViewTypeP;
          this.component.instance.lastTechReviewDate = this.lastTechReviewDate;
          this.component.instance.disableSubmitOnReview = this.disableSubmitOnReview;
          this.component.instance.flowValidationError = p?.flowValidationError || null;
          // MK(note): used for end user form with allow draft option available
          try {
            this.component.instance.isAutoSave = this.autoSaveResponse;
          } catch (e) {
            console.log('no support for autoSave option');
          }
        });
  }
}
