<div class="container-fluid p-0">
    <div class="login-page d-flex flex-column justify-content-center align-items-center">
        <div class="login-container">
            <div class="login-prompt mb-2">
                <h1 class="eyfontlight mb-3" i18n="logged out with reason|title">EY Empowerment</h1>
                <h2 *ngIf="!isExternalUserWithoutAccount" class="eyfontlight" [class.danger]="!isSuccessfull" i18n="logged out with reason|access denied">{{ isSuccessfull ? logoutMsg : 'Access denied'}}</h2>
                <h2 *ngIf="!isSuccessfull && !isUserBlocked" class="eyfontlight" i18n="logged out with reason|accepting text">{{ noticeText }}</h2>
                <div *ngIf="!isExternalUserWithoutAccount && !isUserBlocked" class="row">
                    <div class="col-md-6 col-12">
                        <button alt="button client user" title="Login Again" class="btn btn--primary-dark" (click)="onLoginAgain()" i18n="logged out with reason|login again button">Login Again</button>
                    </div>
                </div>
            </div>
            <div class="environment mx-2 mx-sm-0">
                <app-environment></app-environment>
            </div>
        </div>
    </div>
</div>
