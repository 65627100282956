<app-fluid-footer-template class="d-flex flex-fill">
    <ng-container appPageContent>
        <div *ngIf="isAutomated" class="d-flex align-items-center automation-warning p-10 mt-10 mb-10">
          <app-hide-from-end-users [text]="'Automated decision has been enabled. This page will be hidden from end-users.'"></app-hide-from-end-users>
        </div>
        <ey-froala-content-wrapper class="d-block mb-10" [editorContent]="html"></ey-froala-content-wrapper>
        <div class="mt-10 mb-20">
            <div
                class="option d-flex align-items-center p-20 mb-10"
                *ngFor="let option of options"
                [class]="{ 'is-selected': isSelected(option), 'automated': isAutomated, error: hasError }"
                (click)="isAutomated ? $event.stopPropagation() : onOptionClick(option)">
                <div class="flex-grow-1">{{option?.description}}</div>
                <i *ngIf="!isSelected(option)" class="icon-ico_check emp-icon" ></i>
                <i *ngIf="isSelected(option)" class="icon-ico_success_toast success-icon" ></i>
            </div>
            <div class="error-message" *ngIf="hasError">
              <label class="textinput__errmsg position"><i class="err_icon icon-ico_error_toast"></i>Select option to proceed.</label>
            </div>
        </div>
    </ng-container>
    <ng-container appPageFooter>
        <div>
          <div class="d-flex">
            <ey-button class="ml-auto mr-20" [btnClass]="btnClass.btnPrev" text="Prev" (click)="onPrev()"></ey-button>
            <ey-button [btnClass]="btnClass.btnNext" text="Next" (click)="onNext()"></ey-button>
          </div>
          <div *ngIf="lastTechReviewDate && !isMobileBreakpoint">
            <span class="reviewDate spacing">Last Technical Review: {{lastTechReviewDate}}</span>
          </div>
        </div>
    </ng-container>
</app-fluid-footer-template>
