  <div class="dropdown dropdown--single-select dropup" [ngClass]="{dropup: dropUp}">
    <a
      aria-expanded="false"
      aria-haspopup="true"
      class="dropdown-toggle"
      data-toggle="dropdown"
      [id]="'single-select-active'"
      role="button"
      (click)="toggleExpand()"
    >
      <input [id]="'dd_'+ctrl_id"
             autocomplete="noAutocomplete"
        class="textinput-group__textinput focusable"
        [ngClass]="{ error: showError }"
        (ngModelChange)="filter($event)"
        [ngModel]="getSelectedValue()"
        [readOnly]="valueSelected || isDisabled"
        (blur)="blur()"
        >

      <i class="emp-icon icon-ico_shevron_down" *ngIf="!valueSelected || hasDefaultValue"></i>
    </a>
    <div aria-labelledby="single-select-active " class="dropdown-menu mb-auto" [ngClass]="{ show: isExpanded}">
      <button *ngFor="let v of filteredValues" class="dropdown-item-custom  w-100 lh-1-5rem" (click)="valSelected(v)"><span class="font-875">{{ v[labelName] }}</span></button>
    </div>
    <label  *ngIf="showError" class="textinput-group__errmsg">{{ meta.errorMsg }}</label>
  </div>
