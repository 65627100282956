<app-fluid-footer-template class="d-flex flex-fill" [selectedViewType]="selectedViewType">
    <ng-container appPageContent>
        <ey-froala-content-wrapper class="d-block mb-20" [editorContent]="html"></ey-froala-content-wrapper>
        <div [formGroup]="listForm" class="mb-20">
            <div class="d-flex align-items-center p-0 m-0 mb-20" formArrayName="items" *ngFor="let item of items.controls; let i = index;">
                <span class="item-el2">{{i + 1}}.</span>
                <ey-input-placeholder class="flex-grow-1 ml-10 mr-10 input-height" formControlName="{{i}}" [meta]="fieldMeta.item" extra="none"></ey-input-placeholder>
                <i class="item-el2" role="button" title="Delete" (click)="removeItem(i)" class="emp-icon icon-ico_delete"></i>
            </div>
        </div>
        <div class="d-block mb-20">
        <a class="preview-link ml-30 " *ngIf="items.length < itemsProperty.maxLength; else itemsLimitExceeded" href (click)="addItem(); $event.preventDefault();">+ Add {{!items.length ? "First " : ""}}Item</a>
        <ng-template #itemsLimitExceeded>
            <span class="items-exceeded ml-30 ">
                <i class="info icon-ico_info_toast emp-icon"></i>
                <span class="info eyfontlight">
                    You reached maximum answers for this question.
                </span>
            </span>
        </ng-template>
        </div>
    </ng-container>
    <ng-container appPageFooter>
        <div>
          <div class="d-flex">
            <ey-button class="ml-auto mr-20" [btnClass]="btnClass.btnPrev" text="Prev" (click)="onPrev()"></ey-button>
            <ey-button [btnClass]="btnClass.btnNext" text="Next" (click)="onNext()"></ey-button>
          </div>
          <div *ngIf="lastTechReviewDate && !isMobileBreakpoint">
            <span class="reviewDate spacing">Last Technical Review: {{lastTechReviewDate}}</span>
          </div>
        </div>
    </ng-container>
</app-fluid-footer-template>
