import { BrowserModule } from '@angular/platform-browser';
import {NgModule, APP_INITIALIZER, ErrorHandler} from '@angular/core';
import { HttpClient, HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { UserModule } from './user/user.module';

import { PageLayoutModule } from './page-layout/page-layout.module';
import { ErrorInterceptor } from './core/interceptors/error-handling.interceptor';

import { MsalService, MSAL_INSTANCE, MsalGuard, MsalInterceptor, MsalBroadcastService } from './core/authentication';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from './core/authentication/constants';
import { MsalGuardConfiguration } from './core/authentication/msal.guard.config';
import { MsalInterceptorConfig } from './core/authentication/msal.interceptor.config';
import { environment } from 'src/environments/environment';
import { Config, ConfigService, CONFIG_URL } from './core/services/configuration.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, Observable, ObservableInput, of } from 'rxjs';

import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor-paragraph-format-extended-plugin';
import { ComponentsModule } from './shared/components/components.module';
import { EyAppSpinnerService } from './shared/components/ey-app-spinner/ey-app-spinner.service';
import {DatePipe} from '@angular/common';
import {GlobalErrorHandler} from './core/services/global-error-handler';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppInsightsService } from './core/services/appinsights.service';
import { RequestTimestampInterceptor } from './core/interceptors/request-timestamp-interceptor';
import {StoreModule} from '@ngrx/store';
import {applicationReducer, undoableApplicationState} from './application-state/applictaion.reducer';
import {designerPositionReducer, designerReducer} from './designer/workflow-designer/designer.reducers';
import {undoableShapeReducers} from './designer/workflow-designer/shape/reducers';
import {selectedShapesReducers} from './designer/workflow-designer/shape-selection/reducers';
import {nameCountsReducer} from './designer/workflow-designer/name-counters/reducers';
import {specificPartsReducers} from './designer/workflow-designer/specific-parts/reducers';
import {mappingsReducer} from './designer/workflow-designer/mappings.reducers';
import {saveReducer} from './core/save/save.reducers';
import { AdminGuard } from './admin/admin.guard';

function load(http: HttpClient, configService: ConfigService): (() => Promise<boolean>) {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      from(fetch(CONFIG_URL))
         .pipe(switchMap((r) => from(r.json())),
           map((x: Config) => {
            configService.setConfig((x as Config));
            resolve(true);
           }),
           catchError((x: { status: number }, caught: Observable<void>): ObservableInput<{}> => {
             if (x.status !== 404) {
               resolve(false);
             }
             resolve(true);
             return of({});
           })
         ).subscribe();
    });
  };
}

function MSALInstanceFactory(config: ConfigService): IPublicClientApplication {
  const c = config.getConfig();

  return new PublicClientApplication({
      auth: {
        clientId: c.environment.clientId,
        redirectUri: c.environment.redirectUrl,
        authority: c.environment.authority,
        postLogoutRedirectUri: c.environment.postLogoutRedirectUrl,
      },
      cache: {
        storeAuthStateInCookie: true,
        cacheLocation: BrowserCacheLocation.SessionStorage
      },
      });
}

function MSALInterceptorConfigFactory(configService: ConfigService): MsalInterceptorConfig {
  const config = configService.getConfig();
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.baseUrl, config.environment.scopeUri);

  // passing token for graph calls, User.ReadBasic.All permission is enabled in AAD by default for EY users
  protectedResourceMap.set('https://graph.microsoft.com/v1.0', ['User.ReadBasic.All']);

  const x: MsalInterceptorConfig = {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };

  return x;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(
      {
        designer: designerReducer,
        designerPosition: designerPositionReducer,
        shapes: undoableShapeReducers,
        selectedShapes: selectedShapesReducers,
        nameCounts: nameCountsReducer,
        specificParts: specificPartsReducers,
        mappings: mappingsReducer,
        save: saveReducer,
        applicationState: applicationReducer
      }),
    CoreModule,
    UserModule,
    PageLayoutModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientXsrfModule,
    NgbModule,
    ComponentsModule,
  ],
  providers: [
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: load,
      deps: [
        HttpClient,
        ConfigService
      ],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: RequestTimestampInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [
        ConfigService
      ],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect
      } as MsalGuardConfiguration
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [
        ConfigService
      ],
    },
    MsalService,
    MsalGuard,
    AdminGuard,
    MsalBroadcastService,
    EyAppSpinnerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
