import {Component, Input, OnInit} from '@angular/core';
import {CollaborationModeResponsesService} from '../../core/services/collaboration-mode-responses.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GridState} from '../../shared/components/ey-grid/models/grid-state.model';
import {DEF_GRID_SATE} from '../../shared/components/ey-grid/ey-grid.component';
import {SortDirection} from '../../shared/components/ey-grid/models/sort-direction.model';
import {ModalResult} from '../../shared/components/ey-modal-template/ey-modal-result.enum';

@Component({
  selector: 'app-list-of-respondents-modal',
  templateUrl: './list-of-respondents-modal.component.html',
  styleUrls: ['./list-of-respondents-modal.component.scss']
})
export class ListOfRespondentsModalComponent implements OnInit {
  @Input() projectId: string;
  @Input() responseId: string;
  state: GridState = {
    ...DEF_GRID_SATE,
    pageSize: 50,
    sortBy: 'dateCreated',
    sortDirection: SortDirection.DES
  };

  constructor(private collaborationModeResponseService: CollaborationModeResponsesService, private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  getData(): any {
    return this.getResponseData;
  }

  getResponseData = state => this.collaborationModeResponseService.getListOfRespondentsOnCollaborationMode(this.projectId, this.responseId, state);

  onDismiss(): void {
    this.activeModal.close(ModalResult.dismiss);
  }

}
