import {Component, ElementRef, forwardRef, Input, OnInit} from '@angular/core';
import {FormBuilderFormBaseComponent} from '../../shared/form-builder-form-base.component';
import {AbstractControl, FormBuilder, FormControl,  NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators} from '@angular/forms';

interface DropdownItem {
  name: string;
  value: any;
}

export const DIVIDER_STYLE_HELPER = {
  'Thin (1px)': '1px',
  'Medium (2px)': '2px',
  'Thick (3px)': '3px',
};

@Component({
  selector: 'app-divider-editor',
  templateUrl: './divider-editor.component.html',
  styleUrls: ['./divider-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DividerEditorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DividerEditorComponent),
      multi: true
    }]
})
export class DividerEditorComponent extends FormBuilderFormBaseComponent {
  meta = {
    dividerStyle: {
      title: 'Style'
    },
  };

  dividerStyles: DropdownItem[] = [
    { name: Object.keys(DIVIDER_STYLE_HELPER)[0], value: Object.keys(DIVIDER_STYLE_HELPER)[0]},
    { name: Object.keys(DIVIDER_STYLE_HELPER)[1], value: Object.keys(DIVIDER_STYLE_HELPER)[1]},
    { name: Object.keys(DIVIDER_STYLE_HELPER)[2], value: Object.keys(DIVIDER_STYLE_HELPER)[2]},
  ];

  get dividerStyleDefault(): DropdownItem {
    return this.dividerStyles[1];
  }

  form = this.fbs.group(
    {
      id: [''],
      dividerStyle: [this.dividerStyleDefault, Validators.required],
      name: [''],
    },
    { updateOn: 'blur' }
  );

  constructor(private fbs: FormBuilder,  private els: ElementRef) {
    super(fbs, els);
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(v => {
      this.onChange(this.convertToReturnValue(v));
    });

    this.form.statusChanges.subscribe(this.onValidatorChange);

    this.onChange(this.convertToReturnValue(this.form.value));
  }

  convertToReturnValue(value: any): any {
    return {
      ...value,
      dividerStyle: value.dividerStyle.value,
    };
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { subformerror: 'Form Builder Editor Form Error!' };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  onChange: (val: any) => void = () => {};
  onValidatorChange: () => void = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(val: any): void {
    if (val) {

      const patchValue = val.dividerStyle
      ? {
        ...val,
        dividerStyle: this.dividerStyles.find(c => c.value === val.dividerStyle)
      }
      : val;

      this.form.patchValue(patchValue);
    }
  }
}
