import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const HAS_BRACKETS_REGEXP = /^$|^\[\w{0}.*\]$/;

export function shouldBeInBrackets(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    return HAS_BRACKETS_REGEXP.test(value)
        ? null
        : { notInBrackets: true };
}
