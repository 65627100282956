import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Config, ConfigService } from 'src/app/core/services/configuration.service';
import { LoginService } from 'src/app/core/services/login.service';
import { UserService } from 'src/app/core/services/user-service';
import { PrivacyNoticeService } from '../../services/privacy-notice.service';
import { DismissedBecause, ModalOptions, ModalPopupComponent } from '../modal-popup/modal-popup.component';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-privacy-notice',
    templateUrl: './privacy-notice.component.html',
    styleUrls: ['./privacy-notice.component.scss']
})
export class PrivacyNoticeComponent implements AfterViewInit, OnDestroy {
    @ViewChild(ModalPopupComponent) modal: ModalPopupComponent;
    @Output() accepted: EventEmitter<boolean> = new EventEmitter();

    public privacyNoticeText: string = null;

    public modalOptions: ModalOptions = {
        header: 'Privacy Notice',
        forceScrollToBottom: true,
        isContentHeavyModal: true,
        closeButtonName: 'Accept',
        dismissButtonName: 'Dismiss',
        closeButtonTitle: 'Accept Privacy Notice',
        disabledCloseButtonTooltip: 'Please scroll to read the complete Privacy Notice'
    };

    private privacyNoticeSubscription: Subscription = null;
    private config: Config = this.configService.getConfig();

    constructor(
        private privacyNoticeService: PrivacyNoticeService,
        private userService: UserService,
        private loginService: LoginService,
        private configService: ConfigService,
        public sanitizer: DomSanitizer) {
    }

    ngOnDestroy(): void {
        this.privacyNoticeSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.showPrivacyNotice();
    }

    private showPrivacyNotice(): void {
        this.privacyNoticeSubscription =
            this.userService.getCurrentUser().pipe(
                switchMap(user => {
                    return user.isPrivacyNoticeAccepted
                        ? of(user.isPrivacyNoticeAccepted)
                        : this.privacyNoticeService.getPrivacyNotice();
                }),
                switchMap(accepterOrNotice => {
                    if (typeof(accepterOrNotice) === 'string') {
                        this.privacyNoticeText = accepterOrNotice;

                        return this.modal.open();
                    }

                    return of(accepterOrNotice);
                }),
                switchMap(acceptedOrModalClosed => {
                    if (typeof(acceptedOrModalClosed) === 'boolean') {
                        return of(acceptedOrModalClosed);
                    }

                    return this.privacyNoticeService.acceptPrivacyNotice();
                })
            )
            .subscribe(
                () => {
                    this.accepted.emit(true);
                },
                err => {
                    if (err !== DismissedBecause.DismissButtonClick && err !== DismissedBecause.CrossClick) {
                        this.accepted.emit(false);
                        return;
                    }

                    this.loginService.logout(`${this.config.environment.redirectUrl}/privacy-notice-declined`);
                }
            );
    }
}
