<app-fluid-footer-template class="d-flex flex-fill">
    <ng-container appPageContent>
        <ey-froala-content-wrapper class="d-block mb-10 in-html" [editorContent]="html"></ey-froala-content-wrapper>
        <div class="mt-10 mb-20 d-flex flex-fill">
            <ey-file-upload class="d-block flex-fill"
                filesSubTitle="Uploaded Files"
                [fileExt]="fileExtension"
                [maxFiles]="maxFiles"
                [maxSize]="64"
                [files]="files"
                (initFileUpload)="upload($event)"
                (deleteFile)="deleteFile($event)"
                [showVisibilityColumn]="false"
                [dragAndDropTitle] = "dragAndDropTitle"
                [dragAndDropSubTitle] = "dragAndDropSubTitle"
                [subHeaderLink]='allowAnyFileOnPreview'
                [hasError]='throwError'
                [isRequired]='isRequired'
                [docType]='docType'
            >
                <p class="doc-type-des m-0 p-0"></p>
            </ey-file-upload>
        </div>
    </ng-container>
    <ng-container appPageFooter>
        <div>
          <div class="d-flex">
            <ey-button class="ml-auto mr-20" [btnClass]="btnClass.btnPrev" text="Prev" (click)="onPrev(); $event.preventDefault()"></ey-button>
            <ey-button [btnClass]="btnClass.btnNext" text="Next" (click)="onNext(); $event.preventDefault()"></ey-button>
          </div>
          <div *ngIf="lastTechReviewDate && !isMobileBreakpoint">
            <span class="reviewDate spacing">Last Technical Review: {{lastTechReviewDate}}</span>
          </div>
        </div>
    </ng-container>
</app-fluid-footer-template>
<ng-template #option let-name let-option="option">
    <div
        class="option flex-fill d-flex align-items-center justify-content-center p-20 mb-10 mr-10"
        [class]="{ 'is-selected': isSelected(option), error: hasError }"
        (click)="onOptionClick(option)">
        <div class="option-name eyfontlight">{{name}}</div>
        <i *ngIf="!isSelected(option)" class="icon-ico_check emp-icon positioned-icon" ></i>
        <i *ngIf="isSelected(option)" class="icon-ico_success_toast success-icon positioned-icon" ></i>
    </div>
</ng-template>
