<ey-modal-template [showSubHeader]="true" > <!--[enableGrayBodyBackground]="currentStep.id === stepsDef.dataMapping"-->
  <div header>
    <h2 class="modal__title">Bulk Upload Responses</h2>
  </div>
  <div sub-header>
    <ey-form-progress-bar [steps]="progressBarSteps"></ey-form-progress-bar>
  </div>
  <div body>
    <form autocomplete="noAutocomplete" [formGroup]="uploadForm" id="uploadForm">
      <fieldset [disabled]="disableDataPurposeSelection">
        <app-data-purpose [isPartialResponseAllowed]="isPartialResponseAllowed" [isCompleteResponseAllowed]="isCompleteResponseAllowed" [selectedUploadMethod]="selectedUploadMethod" [hidden]="currentStep.id !== stepsDef.dataPurpose" formControlName="dataPurpose"></app-data-purpose>
      </fieldset>
      <app-upload-method [selectedUploadMethod]="selectedUploadMethod" [hidden]="currentStep.id !== stepsDef.uploadMethod" formControlName="uploadMethod"></app-upload-method>
      <app-upload-your-data [projectName]="projectName" [projectID]="projectID" [selectedUploadMethod]="selectedUploadMethod" [hidden]="currentStep.id !== stepsDef.uploadYourData" formControlName="uploadYourData"></app-upload-your-data>
      <app-data-mapping  [projectID]="projectID"  [hidden]="currentStep.id !== stepsDef.dataMapping" formControlName="dataMapping"></app-data-mapping>
    </form>
  </div>
  <div footer class="d-flex w-100">
    <div class="d-flex w-100">
      <ey-button [btnClass]="btnClass.secondary" text="Cancel" (click)="onCancel()"></ey-button>
      <ey-button
        class="ml-auto mr-2"
        [btnClass]="btnClass.btnPrev"
        text="Prev"
        (click)="onPrev($event)"
        *ngIf="currentStep.id !== stepsDef.dataPurpose"
      ></ey-button>
      <ey-button
        class="mr-20"
        [btnClass]="btnClass.btnNext"
        text="Next"
        (click)="onNext($event)"
        [class]="{ 'ml-auto': currentStep.id === stepsDef.dataPurpose }"
      ></ey-button>
    </div>
  </div>
</ey-modal-template>
