export enum ModuleActionsPermissions {
  update = 'module.update',
  read = 'module.read',
  delete = 'module.delete',
  deleteModuleFlow = 'moduleflow.delete',
  createModuleFlow = 'moduleflow.create',
  readModuleFlow = 'moduleflow.read',
  updateModuleFlow = 'moduleflow.update',
  readModuleMembers = 'modulemembers.read',
  readModuleProjects = 'moduleprojects.read',
  readModuleBenefits = 'modulebenefit.read',
  createModuleReview = 'modulereview.create',
  updateModuleMembers = 'modulemembers.update'
}
