import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ValidationErrors,
  Validators,
  FormControl, ValidatorFn,
} from '@angular/forms';
import { EyFormBaseComponent } from '../../../../../../shared/components/ey-form-base/ey-base-form-control';
import { ToolTipHolderOptions } from '../../../../../../shared/components/constants';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { changeConnectionLabel } from '../../../../shape/actions';
import * as _ from 'lodash';

export interface IOption {
  name: string;
  id: string;
}

export enum OptionAction {
  up,
  down,
  copy,
  remove,
  move,
}

export interface OptionActionEventArg {
  optionAction: OptionAction;
  displayOrder: number;
  name: string;
  newPosition?: number;
}

export const ERROR_MSG_MANDATORY = `Please fill the name of option, it can't be empty.`;
export const ERROR_MSG_NAME_UNIQUE = 'Unique option name is required.';
export const ERROR_MSG_NAME_MAX_LEN = 'Maximum 500 character allowed.';

export const META_OPTION = {
  name: {
    title: 'Option',
    errorMsg: ERROR_MSG_MANDATORY,
    required: true,
  },
  score: {
    title: 'Score',
  },
};

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OptionComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OptionComponent),
      multi: true,
    },
  ],
})
export class OptionComponent extends EyFormBaseComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Output() blur = new EventEmitter<void>();
  @Output() actionClick = new EventEmitter<OptionActionEventArg>();
  @Input() maxDisplayOrder: number = 3;
  @Input() minDisplayOrder: number = 0;
  @Input() hideScore = false;
  @Input() enableStoreDispatch = true;
  @Input() maxLength = 500;
  @Input() optionNames: IOption[] = [];
  @Input() disableDelete = false;
  @ViewChild('formVC') ngForm: NgForm;
  actions = OptionAction;
  meta = _.cloneDeep(META_OPTION);
  focus: string;
  optionForm: FormGroup = this.fb.group(
    {
      name: [null, [this.uniqueOptionName()]],
      score: [0, [Validators.required, Validators.min(-9999), Validators.max(9999)]],
      displayOrder: [,],
      conId: [null],
    },
    { updateOn: 'blur' }
  );
  optionId: string;

  get optionName(): string {
    return this.optionForm.get('name')?.value;
  }

  private destroy$ = new Subject<boolean>();

  setFocus(): void {
    const ctrl = this.el.nativeElement.querySelector('[formcontrolname="name"] .focusable');
    ctrl.focus();
  }

  get displayOrder(): number {
    return this.optionForm.get('displayOrder').value;
  }
  toolTipHolderOptions = ToolTipHolderOptions;

  onChange: (val: any) => void = () => {};
  onTouched: (val: any) => void = () => {};

  constructor(private fb: FormBuilder, private el: ElementRef, private store: Store) {
    super();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {

    if (this.enableStoreDispatch) {
      this.optionForm
        .get('name')
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((s) => this.store.dispatch(changeConnectionLabel({ connectionId: this.optionForm.get('conId').value, newLabel: s })));
    }

    this.scoreControl.valueChanges.subscribe((val) => {
      if (!val) {
        this.scoreControl.setValue('0', { emitEvent: false });
        return;
      }
      if (+val > 9999) {
        this.scoreControl.setValue('9999', { emitEvent: false });
      } else if (+val < -9999) {
        this.scoreControl.setValue('-9999', { emitEvent: false });
      }
    });
  }

  writeValue(val: any): void {
    if (val) {
      this.optionForm.patchValue(val, { emitEvent: false });
      this.optionId = val.conId;
    }
  }

  registerOnChange(fn: any): void {
   // this.onChange = fn;
    this.optionForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.optionForm.disable() : this.optionForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.optionForm.valid ? null : { subformerror: 'Module Options Form Error!' };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.optionForm.statusChanges.subscribe(fn);
  }

  markAsTouched(): void {
    this.optionForm.markAllAsTouched();
    this.focusOnError(this.optionForm, this.el);
  }

  onActionClick(action: OptionAction): void {
    this.actionClick.emit({ optionAction: action, displayOrder: this.displayOrder, name: this.optionForm.getRawValue().name });
  }

  triggerSubmit(): void {
    this.blur.emit();
  }

  get scoreControl(): FormControl {
    return this.optionForm.controls.score as FormControl;
  }

  uniqueOptionName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const val: string =  control.value;
      if (val == null || val.length < 1) {
        this.meta.name.errorMsg = ERROR_MSG_MANDATORY;
        return {required: {value: control.value}};
      }

      if (this.optionNames.filter(n => n.name.toLowerCase() === val.toLowerCase() && n.id !== this.optionId).length > 0) {
        this.meta.name.errorMsg = ERROR_MSG_NAME_UNIQUE;
        return {notUnique: {value: control.value}};
      }

      if (val == null || val.length  > 500) {
        this.meta.name.errorMsg = ERROR_MSG_NAME_MAX_LEN;
        return {maxLen: {value: control.value}};
      }

      return  null ;
    };
  }
}
