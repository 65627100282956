<nav class="navigation-bar-secondary" [ngClass]="{'tetrary-reverse': tabsetStyle === 'tetrary-reverse', 'grey-bottom-border': showBottomBorder }" >
<ul class="pl-0" role="tablist">
  <li class="nav-item" *ngFor="let tab of tabs">
    <button [id]="tab.id" class="navigation-bar-secondary__nav-link" [class.active]="tab.id === activeId" [class.disabled]="tab.disabled"
            (click)="select(tab.id); $event.preventDefault()" role="tab" [attr.tabindex]="(tab.disabled ? '-1': undefined)"
            [attr.aria-controls]="(!destroyOnHide || tab.id === activeId ? tab.id + '-panel' : null)"
            [attr.aria-selected]="tab.id === activeId" [attr.aria-disabled]="tab.disabled" >{{tab.title}}<ng-template [ngTemplateOutlet]="tab.titleTpl?.templateRef"></ng-template>
    </button>
    <!-- a [id]="tab.id" class="nav-link" [class.active]="tab.id === activeId" [class.disabled]="tab.disabled"
       href (click)="select(tab.id); $event.preventDefault()" role="tab" [attr.tabindex]="(tab.disabled ? '-1': undefined)"
       [attr.aria-controls]="(!destroyOnHide || tab.id === activeId ? tab.id + '-panel' : null)"
       [attr.aria-selected]="tab.id === activeId" [attr.aria-disabled]="tab.disabled">
      {{tab.title}}<ng-template [ngTemplateOutlet]="tab.titleTpl?.templateRef || null"></ng-template>
    </a -->
  </li>
</ul>
</nav>
<div class="tab-content">
  <ng-template ngFor let-tab [ngForOf]="tabs">
    <div
      class="tab-pane {{tab.id === activeId ? 'active' : null}}"
      *ngIf="!destroyOnHide || tab.id === activeId"
      role="tabpanel"
      [attr.aria-labelledby]="tab.id" id="{{tab.id}}-panel">
      <ng-template [ngTemplateOutlet]="tab.contentTpl?.templateRef || null"></ng-template>
    </div>
  </ng-template>
</div>
