import { ComponentFactoryResolver, Directive, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { FlowType } from 'src/app/designer/preview/module-flow.service';
import {Page, ViewTypes} from '../../page.model';
import {SummarySectionBaseComponent} from './summary-sections/summary-section-base.component';
import {StatementSummaryComponent} from './summary-sections/statement-summary/statement-summary.component';
import {ListSummaryComponent} from './summary-sections/list-summary/list-summary.component';
import {DecisionSummaryComponent} from './summary-sections/decision-summary/decision-summary.component';
import {YesNoSummaryComponent} from './summary-sections/yes-no-summary/yes-no-summary.component';
import {FormSummaryComponent} from './summary-sections/form-summary/form-summary.component';
import {TableSummaryComponent} from './summary-sections/table-summary/table-summary.component';
import {LetterSummaryComponent} from './summary-sections/letter-summary/letter-summary.component';
import {MailSummaryComponent} from './summary-sections/mail-summary/mail-summary.component';
import {UploadSummaryComponent} from './summary-sections/upload-summary/upload-summary.component';
import {StartSummaryComponent} from './summary-sections/start-summary/start-summary.component';
import {EndSummaryComponent} from './summary-sections/end-summary/end-summary.component';

export enum toolTypes {
  START = 'START',
  END = 'END',
  STATEMENT = 'STATEMENT',
  LIST = 'LIST',
  UPLOAD = 'UPLOAD',
  DECISION = 'DECISION',
  YES_NO = 'YES/NO',
  FORM =  'FORM',
  TABLE = 'TABLE',
  LETTER = 'LETTER',
  MAIL = 'MAIL'
}

const typeToComponentMap = new Map<string, any>(
  [
    [toolTypes.START, StartSummaryComponent],
    [toolTypes.STATEMENT, StatementSummaryComponent],
    [toolTypes.LIST , ListSummaryComponent],
    [toolTypes.UPLOAD, UploadSummaryComponent],
    [toolTypes.DECISION, DecisionSummaryComponent],
    [toolTypes.YES_NO, YesNoSummaryComponent],
    [toolTypes.FORM, FormSummaryComponent],
    [toolTypes.TABLE, TableSummaryComponent],
    [toolTypes.LETTER, LetterSummaryComponent],
    [toolTypes.MAIL, MailSummaryComponent],
    [toolTypes.END, EndSummaryComponent],
  ]
);

@Directive({
  selector: '[appSummarySectionLoaderDirective]'
})
export class SummarySectionLoaderDirective implements OnInit, OnDestroy {
  @Input() lastTechReviewDate: string = null;
  @Input() flowType: FlowType = FlowType.preview;
  @Input() autoSaveResponse = false;
  @Input() page: Page;

  selectedViewTypeP = ViewTypes.desktop;
  private destroy$ = new Subject<boolean>();
  component: any =  null;
  @Input() set selectedViewType(viewType: ViewTypes) {
    this.selectedViewTypeP = viewType;
    if (this.component) {
      (this.component.instance as SummarySectionBaseComponent).selectedViewType = this.selectedViewType;
    }
  }
  get selectedViewType(): ViewTypes {
    return this.selectedViewTypeP;
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    const componentToCreate = typeToComponentMap.get(this.page.moduleFlowPage.type) || null;
    if (componentToCreate !== null) {
          this.viewContainerRef.clear();
          const factory = this.componentFactoryResolver.resolveComponentFactory<any>(componentToCreate);
          this.component = this.viewContainerRef.createComponent<any>(factory);
          this.component.instance.page = this.page;
          this.component.instance.selectedViewType = this.selectedViewTypeP;
        }
    }

}
