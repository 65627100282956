import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
  name: 'getHtmlContentForFroalaContentWrapper'
})
export class GetHtmlContentForFroalaContentWrapperPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }

  transform(editorContent: string): SafeHtml {
    if (editorContent) {
      return this.domSanitizer.bypassSecurityTrustHtml(editorContent);
    }
    return '';
  }

}
