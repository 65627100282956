import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ButtonIconTypes, InfoDialogTypes} from './info-dialog-types.enum';
import {ModalResult} from '../ey-modal-template/ey-modal-result.enum';
import {IDialogButtonText} from './ey-info-dialog.model';


@Component({
  selector: 'app-ey-info-dialog',
  templateUrl: './ey-info-dialog.component.html',
  styleUrls: ['./ey-info-dialog.component.scss']
})
export class EyInfoDialogComponent implements OnInit {
  @Input() title: string;
  @Input() htmlBody: string;
  @Input() texts: IDialogButtonText;
  @Input() infoDialogType: InfoDialogTypes = InfoDialogTypes.info;
  @Input() btnIcons: ButtonIconTypes = ButtonIconTypes.none;


  infoDialogTypes = InfoDialogTypes;
  modalResult = ModalResult;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {}

  public close(val: any): void {
    this.activeModal.close(val);
  }
}
