import {Component, Input, OnInit} from '@angular/core';
import { ModuleFlowService} from 'src/app/designer/preview/module-flow.service';
import { ButtonClass } from 'src/app/shared/components/ey-button/ey-button.component';
import { BasePageContent } from '../base-page-content';
import {properties} from '../../../workflow-designer/tools/start-tool';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import {takeUntil} from 'rxjs/internal/operators/takeUntil';

@Component({
  selector: 'app-start-page.d-flex.flex-fill',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss'],
})
export class StartPageComponent extends BasePageContent implements OnInit {
  body: string;
  @Input() collaborationMode = false;
  btnClass = ButtonClass;
  constructor(flowService: ModuleFlowService, protected spinnerService: EyAppSpinnerService) {
    super(flowService, spinnerService);


  }
  ngOnInit(): void {
    this.flowService.responseHeaderMobileViewChange.pipe(takeUntil(this.destroy$)).subscribe(mobileViewChange => {
      this.isMobileBreakpoint = mobileViewChange;
    });
    this.body = this.page?.moduleFlowPage.body ? this.page?.moduleFlowPage.body : properties[0].options.defaultContent;
  }
}
