<ey-modal-template [showSubHeader]="false" *ngIf="isInitialized">
  <div header>
    <span class="d-flex">
      <h2 *ngIf="!isLockedByAnotherUser" class="modal__title ml-2">Collaboration Mode</h2>
      <i *ngIf="isLockedByAnotherUser" class="material-icons mr-0 color-ey-grey-1">info</i>
      <h2 *ngIf="isLockedByAnotherUser" class="modal__title ml-2 top">Response is Locked</h2>
    </span>
  </div>
  <div body>
    <div *ngIf="!isLockedByAnotherUser;else locked_by_anoter_user">
      <p class="m-0" *ngIf="!userAlreadyHasEdited;else edited_by_anoter_user">You have started editing response on collaboration mode. Others cannot edit this response until you unlock it.</p>
      <ng-template #edited_by_anoter_user>
        <p class="m-0">You have started editing response on collaboration mode. Others cannot edit this response until you unlock it. Continue from where {{userAlreadyHasEdited}} partially completed the response.</p>
      </ng-template>
    </div>
    <ng-template #locked_by_anoter_user>
      <p class="m-0">This response is locked by {{lockedBy}}. This person has to unlock it before you save your changes.</p>
    </ng-template>
  </div>
  <div footer class="d-flex w-100 justify-content-center">
    <button class="btn btn--secondary ml-0" type="button" (click)="onDismiss()">Dismiss</button>
  </div>
</ey-modal-template>
