<div class="d-flex flex-row">
<span *ngFor="let s of steps; i as index" class="p-0 d-flex flex-row align-content-around">
  <span [ngSwitch]="s.state" class="pl-20 mb-auto mt-auto">
    <img src="/assets/icons/ico_step_inProgress.svg" title="In Progress" *ngSwitchCase="1" />
    <img src="/assets/icons/ico_step_done.svg" title="Completed"  *ngSwitchCase="2" />
    <img src="/assets/icons/ico_step_undone.svg" *ngSwitchDefault title=""  />
  </span>
  <div class="d-flex flex-column flex-fill">
  <span class="pl-10 pr-20 mb-auto mt-auto" [ngClass]="{'font-weight-bold': s.active}">{{s.title}}</span>
  <span class="pl-10 pr-20 subtitle" *ngIf="s.subTitle" >{{s.subTitle}}</span>
  </div>
</span>
</div>
