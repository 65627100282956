<a title="{{toolTipContent}}" [attr.aria-label]="toolTipContent">
<span class="d-inline-flex flex-column sort-tile ml-2">
                    <span tabindex="0" [attr.aria-label]="column + 'sort ascending'" class="sort-tile_arrow arrow-up" (click)="onSort(column, asc)">
                      <i class="material-icons">arrow_drop_up</i>
                    </span>
                    <span tabindex="0" [attr.aria-label]="column + 'sort descending'" class="sort-tile_arrow arrow-down" (click)="onSort(column, des)">
                      <i class="material-icons">arrow_drop_down</i>
                    </span>
</span>
</a>
