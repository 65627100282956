import { PillType } from '../../shared/components/ey-pill/ey-pill.component';


export const LIVE_STATUS_ID = 12;
export const LIVE_IN_RENEWAL_ID = 11;
export const DRAFT_STATUS_ID  = 1;
export const DRAFT_IN_REVIEW_STATUS_ID  = 2;
export const DRAFT_REVIEWED = 3;
export const DRAFT_IN_PUBLISH  = 4;

export const TEST_STATUS_ID = 5;
export const TEST_IN_REVIEW_STATUS_ID  = 6;
export const TEST_REVIEWED = 7;
export const TEST_IN_PUBLISH  = 8;
export const EXPIRED_STATUS_ID  = 9;
export const EXPIRED_IN_RENEWAL_ID  = 10;
export const DRAFT_REJECTED = 14;
export const TEST_REJECTED = 13;

export enum VersionStatus {
  Draft = 'Draft',
  DraftInReview = 'Draft: In Review',
  DraftReviewed = 'Draft: Reviewed',
  DraftInPublishing = 'Draft: In Publishing',
  Test = 'Test',
  TestInReview = 'Test: In Review',
  TestReviewed = 'Test: Reviewed',
  TestInPublishing = 'Test: In Publishing',
  Expired = 'Expired',
  ExpiredInRenewal = 'Expired: In Renewal',
  LiveInRenewal = 'Live: In Renewal',
  Live = 'Live',
  Unknown = 'Unknown',
}

export interface IModuleStatus {
  pillType: string;
  label: VersionStatus;
  id: number;
}

export const UNKNOWN_VERSION_STATUS = { color: PillType.red, label: VersionStatus.Unknown, id: 0 };

export const VersionStatuses: IModuleStatus[] = [
  { pillType: PillType.draft, label: VersionStatus.Draft, id: DRAFT_STATUS_ID },
  { pillType: PillType.draft, label: VersionStatus.DraftInReview, id: DRAFT_IN_REVIEW_STATUS_ID },
  { pillType: PillType.draft, label: VersionStatus.DraftReviewed, id: DRAFT_REVIEWED },
  { pillType: PillType.draft, label: VersionStatus.DraftInPublishing, id: DRAFT_IN_PUBLISH },
  { pillType: PillType.orange, label: VersionStatus.Test, id: TEST_STATUS_ID },
  { pillType: PillType.orange, label: VersionStatus.TestInReview, id: TEST_IN_REVIEW_STATUS_ID },
  { pillType: PillType.orange, label: VersionStatus.TestReviewed, id: TEST_REVIEWED },
  { pillType: PillType.orange, label: VersionStatus.TestInPublishing, id: TEST_IN_PUBLISH },
  { pillType: PillType.red, label: VersionStatus.Expired, id: EXPIRED_STATUS_ID },
  { pillType: PillType.red, label: VersionStatus.ExpiredInRenewal, id: EXPIRED_IN_RENEWAL_ID },
  { pillType: PillType.green, label: VersionStatus.LiveInRenewal, id: LIVE_IN_RENEWAL_ID },
  { pillType: PillType.green, label: VersionStatus.Live, id: LIVE_STATUS_ID },
];

export interface IAllVersionStatus {
  name: string;
  id: number;
}

export const AllVersionsStatus: IAllVersionStatus[] = [
  {
    name: VersionStatus.Draft,
    id: DRAFT_STATUS_ID,
  },
  {
    name: VersionStatus.DraftInReview,
    id: DRAFT_IN_REVIEW_STATUS_ID,
  },
  {
    name: VersionStatus.DraftReviewed,
    id: DRAFT_REVIEWED,
  },
  {
    name: VersionStatus.DraftInPublishing,
    id: DRAFT_IN_PUBLISH,
  },
  {
    name: VersionStatus.Test,
    id: TEST_STATUS_ID,
  },
  {
    name: VersionStatus.TestInReview,
    id: TEST_IN_REVIEW_STATUS_ID,
  },
  {
    name: VersionStatus.TestReviewed,
    id: TEST_REVIEWED,
  },
  {
    name: VersionStatus.TestInPublishing,
    id: TEST_IN_PUBLISH,
  },
  {
    name: VersionStatus.Expired,
    id: EXPIRED_STATUS_ID,
  },
  {
    name: VersionStatus.ExpiredInRenewal,
    id: EXPIRED_IN_RENEWAL_ID,
  },
  {
    name: VersionStatus.Live,
    id: LIVE_STATUS_ID,
  },
  {
    name: VersionStatus.LiveInRenewal,
    id: LIVE_IN_RENEWAL_ID,
  },
];

export const VERSION_MSGS = {
  EXPIRE_CONFIRMATION_MSG: 'Are you sure you want to expire this Version?',
  EXPIRE_CONFIRMATION_PROJECTLINKED_MSG: 'Once Expired, all projects connected with this version will be inactive. Adding new responses won\'t be possible. Are you sure you want to expire this Version?',
  DELETE_CONFIRMATION_MSG: 'Are you sure you want to delete this version?',
  DELETE_CONFIRMATION_TEST_PROJECT: `<i class="material-icons warning mr-1">warning</i> This Version is being used on Projects. <br /> <br />
      Are you sure you want to delete this version? <br /><br />
      If you proceed to delete, all connected projects will be temporarily deactivated. To reactivate these Projects again, Project team member will need to choose another version of this Module.`,

  DISMISS_MSG_LIVE_EXPIRED: 'You cannot delete this version, because it is published as Live. Please contact system administrator',
  DELETE_CONFIRMATION_EXPIRED_ADMIN:
    `Are you sure you want to delete this Version? <br /><br />
     All connected Projects will be temporarily deactivated. To reactivate these Projects again, Project team member will need to choose another version of this Module. <br /><br />
     <i class="material-icons warning mr-1">warning</i>  Any information about saved hours will be lost. Please do not delete this version unless it is necessary`,
};

export enum  VersionViewTypes {
  gridView,
  cardView
}

export enum RenewalStatus {
  RenewalNotDone = 'RenewalNotDone',
  RenewalInProgress = 'RenewalInProgress',
  RenewalCompleted = 'RenewalCompleted',
}

export interface IRenewalStatus {
  pillType: string;
  label: RenewalStatus;
  labelText: string;
  id: number;

}
export const RENEWAL_NOT_DONE_ID = 1;
export const RENEWAL_IN_PROGRESS = 2;
export const RENEWAL_COMPLETED = 3;

export const RenewalStatuses: IRenewalStatus[] = [
  {
    pillType: PillType.orange,
    label: RenewalStatus.RenewalNotDone,
    labelText: 'Renewal: Not Done',
    id: RENEWAL_NOT_DONE_ID
  },
  {
    pillType: PillType.orange,
    label: RenewalStatus.RenewalInProgress,
    labelText: 'Renewal: In Progress',
    id: RENEWAL_IN_PROGRESS
  },
  {
    pillType: PillType.green,
    label: RenewalStatus.RenewalCompleted,
    labelText: 'Renewal: Completed',
    id: RENEWAL_COMPLETED
  }
];

export enum PublishLiveStatus {
  PublishLiveNotDone = 'ApprovalNotDone',
  PublishLiveInProgress = 'ApprovalInProgress',
  PublishLiveCompleted = 'ApprovallCompleted',
}

export interface IPublishLiveStatus {
  pillType: string;
  label: PublishLiveStatus;
  labelText: string;
  id: number;

}
export const PUBLISH_LIVE_NOT_DONE_ID = 1;
export const PUBLISH_LIVE_IN_PROGRESS = 2;
export const PUBLISH_LIVE_COMPLETED = 3;

export const PublishLiveStatuses: IPublishLiveStatus[] = [
  {
    pillType: PillType.orange,
    label: PublishLiveStatus.PublishLiveNotDone,
    labelText: 'Approval: Not Done',
    id: PUBLISH_LIVE_NOT_DONE_ID
  },
  {
    pillType: PillType.orange,
    label: PublishLiveStatus.PublishLiveInProgress,
    labelText: 'Approval: In Progress',
    id: PUBLISH_LIVE_IN_PROGRESS
  },
  {
    pillType: PillType.green,
    label: PublishLiveStatus.PublishLiveCompleted,
    labelText: 'Approval: Completed',
    id: PUBLISH_LIVE_COMPLETED
  }
];
