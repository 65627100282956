import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {DEFAULT_TITLE, ToolTipHolderOptions} from '../constants';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';



@Component({
  selector: 'ey-textarea',
  templateUrl: './ey-textarea.component.html',
  styleUrls: ['./ey-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyTextareaComponent),
      multi: true,
    }
  ]
})
export class EyTextareaComponent extends EyBaseFormControlComponent implements ControlValueAccessor {
  @Input() mini = false;
  isExpanded = false;
  _selectedValue: any;
  @Input() maxLength = 4000;

  @Output() blur = new EventEmitter<void>();
  @Input() toolTipHolderOption: ToolTipHolderOptions = ToolTipHolderOptions.visible;
  toolTipHolderOptions = ToolTipHolderOptions;
  constructor(private controlContainer: ControlContainer) {
    super(controlContainer);
  }
  onBlur(): void {
    this.isFocus = false;
    this.onTouched(this.selectedValue);
    this.blur.emit();
  }
  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  set selectedValue(val) {
    this._selectedValue = val;
    this.onChange(val);
    this.onTouched(val);
  }

  get selectedValue(): any {
    return this._selectedValue;
  }

  writeValue(initValue: any): void {
    this._selectedValue = initValue || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}

