import { Injectable, Inject } from '@angular/core';
import { MSAL_GUARD_CONFIG } from '../authentication/constants';
import { MsalService } from '../authentication/msal.service';
import { MsalGuardConfiguration } from '../authentication/msal.guard.config';
import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { AccountInfo, InteractionType } from '@azure/msal-browser';
import { catchError, map } from 'rxjs/operators';

@Injectable({
     providedIn: 'root'
 })
 export class LoginService {
     constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private location: Location,
     ) {}

    /**
     * Builds the absolute url for the destination page
     * @param path Relative path of requested page
     * @returns Full destination url
     */
    getDestinationUrl(path: string): string {
        // Absolute base url for the application (default to origin if base element not present)
        const baseElements = document.getElementsByTagName('base');
        const baseUrl = this.location.normalize(baseElements.length ? baseElements[0].href : window.location.origin);

        // Path of page (including hash, if using hash routing)
        const pathUrl = this.location.prepareExternalUrl(path);

        // Hash location strategy
        if (pathUrl.startsWith('#')) {
            return `${baseUrl}/${pathUrl}`;
        }

        // If using path location strategy, pathUrl will include the relative portion of the base path (e.g. /base/page).
        // Since baseUrl also includes /base, can just concatentate baseUrl + path
        return `${baseUrl}${path}`;
    }

    logout(postLogoutRedirectUri?: string): void {
        this.authService.logout({ postLogoutRedirectUri });
    }

    getAccounts(): AccountInfo[] {
        return this.authService.getAllAccounts();
    }
}
