import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { v4 as uuid } from 'uuid';
import {EyBaseFormControlComponent} from '../../../../../shared/components/ey-base-form-control/ey-base-form-control';
import {TableBuilderService} from '../../table-builder.service';

export function getTagHtmlfn(tag: TagEditorElement): string {
  return TAG_HTML.replace(PART_ID_TOKEN, tag.content.id).replace(TAG_NAME_TOKEN, tag.content.name).replace(PART_ID, Date.now().toString()).replace(TITLE_TOKEN, tag.content.name).replace(INDEX_TOKEN, uuid());
}

export enum TagEditorPartType {
  tag,
  text,
}

export interface TagEditorElement {
  type: TagEditorPartType;
  content: any;
  id: string;
}

export const EMPTY_TEXT_PART = {
  content: '',
  type: TagEditorPartType.text,
  id: '',
};

export const EMPTY_TAG_PART = {
  content: { name: 'test' },
  type: TagEditorPartType.tag,
  id: '',
};
export const PART_ID_TOKEN = '#PartId';
export const TAG_NAME_TOKEN = '#tagName';
export const PART_ID = '#tagId';
export const TITLE_TOKEN = '#title';
export const INDEX_TOKEN = '#index';

export const TAG_HTML = `<pill class="pill m-0 pill-pill-non-std pill-light-gray" data-index="${INDEX_TOKEN}" title="${TITLE_TOKEN}" role="button" tabindex="1" data-partid="${PART_ID_TOKEN}" id="${PART_ID}" contenteditable="false">${TAG_NAME_TOKEN}</pill>`;
export const REGEX = new RegExp('<pill(.*?)</pill>', 'g');

@Component({
  selector: 'app-ey-tag-editor-table-builder',
  templateUrl: './ey-tag-editor-table-builder.component.html',
  styleUrls: ['./ey-tag-editor-table-builder.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyTagEditorTableBuilder),
      multi: true,
    },
  ],
})
export class EyTagEditorTableBuilder extends EyBaseFormControlComponent implements ControlValueAccessor {
  @Output() blur = new EventEmitter<void>();
  @Output() addTagClick = new EventEmitter<void>();
  @Input() Tags: any[];
  @Input() readonly = false;
  @Input() updateOnBlur = false;
  @Input() placeholder = '';
  @Input() showTagInsert = true;

  htmlContent: SafeHtml;
  writeValContent: any = '';
  _selectedValue: TagEditorElement[] = [];
  caretPos = 0;
  lastTouchedElementIndex = 0;

  @ViewChild('spanInput') spanInput;
  @ViewChild('spanTemplate') spanTemplate;
  getTagHtml = getTagHtmlfn;

  isFormula(): boolean {
    try {
      return this.spanInput.nativeElement.innerHTML[0] === '=';
    } catch (ex) {
      return false;
    }
  }

  getTextPart(cnt: string): TagEditorElement {
    return { ...EMPTY_TEXT_PART, content: cnt, id: Date.now().toString() };
  }
  getTagPart(cnt: any): TagEditorElement {
    return { ...EMPTY_TAG_PART, content: cnt, id: Date.now().toString() };
  }

  constructor(private controlContainer: ControlContainer, private sanitizer: DomSanitizer, private tableBuilderService: TableBuilderService) {
    super(controlContainer);
  }

  emitUpdate(): void {
    this.getArrayFromContent();
    this.isFocus = false;
    this.onTouched(this.selectedValue);
  }

  onFormulaChange(kpe: KeyboardEvent): void {
    if (!this.updateOnBlur) {
      this.emitUpdate();
    }
  }

  onKeyPress(kpe: KeyboardEvent): void {
    /* this code removes tag element on delete button press. once this enabled we get typical angular issues (expression changed after update)
    if (kpe && kpe.code === 'Delete') {
      const el = ((kpe.target as HTMLElement));
      if (el.hasAttribute('data-partid')) {
        this.writeValContent = this.sanitizer.bypassSecurityTrustHtml(this.spanInput.nativeElement.innerHTML.replace(el.outerHTML, ''));
        // this.emitUpdate();
        kpe.stopPropagation();
        kpe.preventDefault();
      }
      return;
    }
    */
    if (kpe && kpe.code === 'Enter') {
      this.emitUpdate();
      kpe.stopPropagation();
      kpe.preventDefault();
    }
  }

  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  set selectedValue(val: TagEditorElement[]) {
    this._selectedValue = val;
    this.onChange(val);
    this.onTouched(val);
  }

  get selectedValue(): TagEditorElement[] {
    return this._selectedValue;
  }

  getArrayFromContent(): void {
    this.htmlContent = this.spanInput.nativeElement.innerHTML;

    const textElement = this.spanInput.nativeElement.innerHTML.replace(REGEX, '&#x2800').split('&#x2800');
    let retVal: TagEditorElement[] = [];

    let ctr = 0;
    for (const item of this.spanInput.nativeElement.getElementsByTagName('pill')) {
      const tagId = item.getAttribute('data-partid');
      const tagName = item.innerHTML;
      retVal.push(this.getTextPart(textElement[ctr]));
      retVal.push(this.getTagPart({ id: tagId, name: tagName }));
      ctr++;
    }
    retVal = [...retVal, ...textElement.slice(ctr).map((t) => this.getTextPart(t))];

    this.selectedValue = retVal;
  }

  getTagEditorContent(tec: TagEditorElement[]): string {
    let retVal = '';
    if (tec != null) {
      tec.forEach((t) => {
        retVal += t.type === TagEditorPartType.text ? t.content : this.getTagHtml(t);
      });
    }

    if (retVal == null || retVal.length < 1) {
      retVal = ''; // '='
    }
    return retVal;
  }

  placeCaretAtEnd(el): void {
    el.focus();
    if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  writeValue(initValue: any): void {
    // this is to stop circular value updates
    if (document.activeElement === this.spanInput?.nativeElement) {
      return;
    }
    this.writeValContent = this.sanitizer.bypassSecurityTrustHtml(this.getTagEditorContent(initValue));
    this._selectedValue = initValue || [];
    // if (!this.updateOnBlur || this.writeValContent === '=') {
    /*
      of(true)
        .pipe(delay(100))
        .subscribe(() => {
          try {
            this.placeCaretAtEnd(this.spanInput.nativeElement);
          } catch (e) {
            console.log('focus set failed');
          }
        });
*/
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /* code below sets the caret position */
  onFocusOut(): void {
    try {
      const target = document.createTextNode('\u0001');
      document.getSelection().getRangeAt(0).insertNode(target);
      this.caretPos = this.spanInput.nativeElement.innerHTML.indexOf('\u0001');
      this.tableBuilderService.currentCaretPositionOnTableBuilderFormulaEditor.next(this.caretPos);
      target.parentNode.removeChild(target);
    } catch (ex) {
      console.log('could not set the caret position');
    }
    this.emitUpdate();
  }

  onAddTagClick(): void {
    this.addTagClick.emit();
  }
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const text = event.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  }
  addTag(tag = this.getTagPart({ name: 'test' }), caretPos: number = null): void {
    // todo split + tag insert
    // Commenting out below block as we need to update caretposition everytime addTag is triggerred.
    // if (this.caretPos === 0) {
    //   this.caretPos = this.spanInput.nativeElement.innerHTML.length;
    // }
    const innerHtml = this.spanInput.nativeElement.innerHTML;
    // The function has to be commented out as it doesnt take into account if we have formulas like <pill>abc</pill> + something.
    // Might change later
    // this.caretPos = this.amendCaretPosition(this.caretPos, innerHtml);
    this.spanInput.nativeElement.innerHTML = innerHtml.slice(0, caretPos) + ' '
      + this.getTagHtml(tag) + '&nbsp;' + innerHtml.slice(caretPos);
    this.caretPos = this.spanInput.nativeElement.innerHTML.length;
    this.emitUpdate();
}

  // if cursor is inside mapping field change cursor pos to insert after mapping field
  amendCaretPosition(caretPosition: number, html: string): number {
    const match  = html.match(REGEX);
    if (match != null && match.length > 0) {
      match.forEach((m) => {
        const firstIndex = html.indexOf(m);
        const lastIndex  = firstIndex + m.length;
        if (caretPosition > firstIndex && caretPosition < lastIndex) {
          caretPosition = lastIndex;
        }
      });
    }
    return caretPosition;
  }

}
