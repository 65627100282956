<app-fluid-footer-template class="d-flex flex-fill" [hintPresent]="page?.moduleFlowPage?.hint" [selectedViewType]="selectedViewType">
  <ng-container appPageContent>
    <ey-froala-content-wrapper [editorContent]="this.page?.moduleFlowPage.body"></ey-froala-content-wrapper>
  </ng-container>
  <ng-container appPageFooter>
    <div>
      <div class="d-flex">
        <ey-button class="ml-auto mr-20" [btnClass]="btnClass.btnPrev" text="Prev" (click)="onPrev()"></ey-button>
        <ey-button [btnClass]="btnClass.btnNext" text="Next" (click)="onNext()"></ey-button>
      </div>
      <div *ngIf="lastTechReviewDate && !isMobileBreakpoint">
        <span class="reviewDate spacing">Last Technical Review: {{lastTechReviewDate}}</span>
      </div>
    </div>
  </ng-container>
</app-fluid-footer-template>
