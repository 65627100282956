import { Component, OnInit } from '@angular/core';
import {ModalResult} from '../../../shared/components/ey-modal-template/ey-modal-result.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auto-logout-popup',
  templateUrl: './auto-logout-popup.component.html',
  styleUrls: ['./auto-logout-popup.component.scss']
})
export class AutoLogoutPopupComponent implements OnInit {
  secondsLeft = 60;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    setInterval(() => {
      this.secondsLeft--;
      if (this.secondsLeft < 1) {
        this.logout();
      }
    }, 1000);
  }

  getMoreTime(): void {
    this.activeModal.close(ModalResult.dismiss);
  }

  logout(): void {
    this.activeModal.close(ModalResult.submit);
  }

}
