import {
  RectangularTool,
  RectToolType,
  Colors,
  createRectangularTool,
  drawSquareTool,
  fillAndStroke,
  drawConnectors,
  drawInvalidToolIcon
} from './rectangular-tool';
import { PropertyPlacement, PropertyType } from '../domain/property';

export const WIDTH = 120;
export const HEIGHT = 60;
export const BORDER_RADIUS = 30;
export const PADDING = 4;

export const END_TOOL_NAME = 'End tool';

const colors: Colors = {
    selectedFillColor: '#2E2E38',
    hoverFillColor: '#2E2E38',
    fillColor: '#2E2E38',
    fontColor: '#FFFFFF',
};

export const properties = [
    {
        label: 'Body',
        name: 'body',
        placement: PropertyPlacement.Body,
        type: PropertyType.RichText,
        options: {
            placeholderText: '<p style="white-space: normal">&lt;Place your content here or click &#39;Reset to Default Content&#39;&gt;</p>',
            defaultContent: '<p><h2 style="text-align:center;">Almost Done</h2></p><p style="text-align:center; font-size: 18px;">This is the last step.<br>Click [SUBMIT] to complete your response</p>'
        }
    }
];

export function draw(rect: RectangularTool, ctx: CanvasRenderingContext2D): void {
    ctx.save();
    ctx.lineWidth =  2;
    ctx.fillStyle = rect.colors.fillColor;

    drawSquareTool(rect, ctx, BORDER_RADIUS, PADDING, fillAndStroke, fillAndStroke);

    ctx.fillStyle = rect.colors.fontColor;
    //ctx.font = '12px EYInterstate-Bold';
    ctx.font = '12px Arial';
    ctx.textAlign = 'center';
    ctx.fillText('END', rect.x + WIDTH / 2, rect.y + ((HEIGHT + 6) / 2)),
    ctx.restore();

    if (rect.isInvalid) {
      drawInvalidToolIcon(rect, ctx);
    }

    drawConnectors(rect, ctx);
}

export function createEndTool(
        id: string,
        x: number,
        y: number,
        name: string,
        isSelected: boolean = false,
        isHovered: boolean = false): RectangularTool {

    return createRectangularTool(id, x, y, RectToolType.EndTool, isSelected, colors, isHovered, name, properties, WIDTH, HEIGHT);
}
