import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { Title } from '@angular/platform-browser';
import {PageTitleService} from './core/services/page-title.service';
import {AutoLogoutService} from './core/services/auto-logout.service';
import {MultipleBrowserInstancesService} from './user/multiple-sessions-active-modal/multiple-browser-instances.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AppInsightsService } from './core/services/appinsights.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'empowerment';
  constructor(
    private router: Router,
    private pageTitleService: PageTitleService,
    private multipleBrowserInstancesService: MultipleBrowserInstancesService,
    private modalService: NgbModal,
    private appInsightsService: AppInsightsService,
    private autoLogoutService: AutoLogoutService, // do not remove this, otherwise auto logout will not work
    titleService: Title,
    activatedRoute: ActivatedRoute) {
    this.pageTitleService.setPageTitle(router, titleService, activatedRoute);
    this.multipleBrowserInstancesService.check();
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.appInsightsService.logEvent('Application start', { event } );
        this.modalService.dismissAll();
        // Note: Below line of code will make sure whenever the route is changed we display the page from top.
        window.scrollTo(0, 0);
      }
    });
  }
}
