<ng-template #modal let-modal>
    <div class="modal-header modal__header">
        <h2 class="modal__title">{{ options.header }}</h2>

      <button class="close modal__close" (click)="modal.dismiss('cross click')">
        <i class="emp-icon icon-ico_close"></i>
      </button>

    </div>
    <div class="modal-body modal__body" id="modalBody" tabindex="0" #modalBody (scroll)="onScroll($event)" >
        <ng-container *ngTemplateOutlet="body"></ng-container>
    </div>
    <div class="modal-footer modal__footer">
        <button class="btn btn--primary btn-border-color" data-dismiss="modal" type="button" [attr.disabled]="disableCloseButton" [attr.title]="closeButtonTooltip" (click)="modal.close()">{{options.closeButtonName}}</button>
        <button class="btn btn--secondary" type="button" [attr.title]="dismissButtonTooltip" (click)="modal.dismiss('dismiss button')">Decline</button></div>
</ng-template>
