import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EyFroalaContentWrapperComponent } from 'src/app/shared/components/ey-froala-content-wrapper/ey-froala-content-wrapper.component';
import { ViewTypes } from '../page.model';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})
export class HintComponent implements OnInit, AfterViewChecked {
  @Input() hintHtml: string;
  @Input() selectedViewType = ViewTypes.desktop;

  @ViewChild(EyFroalaContentWrapperComponent) wrapper: EyFroalaContentWrapperComponent;

  constructor(private sanitizer: DomSanitizer) { }

  ngAfterViewChecked(): void {
    this.wrapper?.resizeFroalaContent();
  }

  ngOnInit(): void {
  }

  get hintSafeHtml(): SafeHtml {
    return this.hintHtml
      ? this.sanitizer.bypassSecurityTrustHtml(this.hintHtml)
      : null;
  }
}
