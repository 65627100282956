<div class="d-flex align-items-center p-0 m-0">
  <div class="d-flex flex-grow-1 align-items-center p-0 m-0 border-top" [class.border-top]="showBorderTop" [class]="{'border-bottom': showBottomBorder}">
    <p class="flex-grow-1 fonts-col__body-copy toggle-padding m-0" [class.bold-text]="boldText">{{meta?.title}}</p>
    <ng-container *ngIf="!isDisabled; then std else disabledWithTooltip"></ng-container>
    <!--ey-toggle-template [selectedValue]="selectedValue" [slim]="slim" (selectedChanged)="onSelectedChanged($event)"></ey-toggle-template -->
  </div>
  <div *ngIf="meta?.info" class="tooltip--holder vertical-padding mt-0 mb-0">
    <ey-input-tooltip [toolTipContent]="meta?.info"></ey-input-tooltip>
  </div>
</div>
<ng-template #disabledWithTooltip>
  <div class="tooltip tooltip--light tooltip--left">
    <ey-toggle-template [class.margin-right]="displayEnableDisable" [displayEnableDisable]="displayEnableDisable" [ariaLabel]="meta?.title + (meta.info ? meta.info : '' )"  [selectedValue]="selectedValue" [slim]="slim" (selectedChanged)="onSelectedChanged($event)" [isDisabled]="isDisabled"></ey-toggle-template>
    <div *ngIf="showDisabledTooltipText" class="tooltiptext text-left" [ngClass]=""><span [innerHTML]="meta?.disabledTooltipText"></span></div>
  </div>
</ng-template>
<ng-template #std>
  <ey-toggle-template [class.margin-right]="displayEnableDisable" [displayEnableDisable]="displayEnableDisable" [ariaLabel]="meta?.title + (meta.info ? meta.info : '' )" [selectedValue]="selectedValue" [slim]="slim" (selectedChanged)="onSelectedChanged($event)"></ey-toggle-template>
  <!-- span  class="switch-label">On</span -->
</ng-template>


