<form [formGroup]="condition">
	<ey-drop-down [positioningDropdownsWithRespectToBrowserWindow]="true" [maxContent]="true" [values]="sourceValues" class="d-block w-100 mb-10" [noInfo]="true"
		formControlName="sourceControl" [meta]="meta.source"
		(ngModelChange)="sourceChange($event)">
	</ey-drop-down>
	<fieldset [disabled]="fieldsDisabled ? true : null">
		<ey-drop-down [positioningDropdownsWithRespectToBrowserWindow]="true" [maxContent]="true" class="d-block w-100 mb-10" [noInfo]="true"
			formControlName="condition" [meta]="meta.condition" [values]="conditions"
			(ngModelChange)="conditionChange($event)" [isDisabled]="fieldsDisabled"
			></ey-drop-down>
		<ey-drop-down [positioningDropdownsWithRespectToBrowserWindow]="true" [maxContent]="true" *ngIf="targetVisible"
			class="d-block w-100 mb-10" [noInfo]="true" [isDisabled]="fieldsDisabled"
			formControlName="targetControl" [meta]="targetMeta"
			[values]="target" (ngModelChange)="targetChange($event)">
		</ey-drop-down>
	</fieldset>
	<ey-input *ngIf="customValueVisible" [type]="customValueType" [isDisabled]="fieldsDisabled"
		class="d-block mb-10" extra="none" formControlName="customValue"
		[meta]="customValueMeta" maxlength="500">
	</ey-input>
	<ey-date-picker [meta]="customDateMeta" [showToolTipHolder]="false" class="d-block mb-20"
		*ngIf="customDateVisible" formControlName="customDate"
                  placeholder="dd/mm/yyyy">
	</ey-date-picker>
</form>
