import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EyBaseFormControlComponent } from '../ey-base-form-control/ey-base-form-control';
import * as _ from 'lodash';

@Component({
  selector: 'ey-drop-down',
  templateUrl: './ey-drop-down.component.html',
  styleUrls: ['./ey-drop-down.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EyDropDownComponent),
      multi: true,
    }
  ]
})
export class EyDropDownComponent extends EyBaseFormControlComponent implements AfterViewInit, ControlValueAccessor {
  @ViewChild('inputElement') inputElement: ElementRef;
  container: HTMLElement = null;
  @Input() labelName = 'name';
  // darkDropdown inputs are used for dropdowns having dark mode but are being used as input field which may change
  // and would have the ability to remove inputs which are selected.
  @Input() darkDropdown = false;
  @Input() autoWidth = false;
  @Input() autoFocus = false;
  @Input() maxContent = false;
  @Input() lightDarkDropdown = false;
  @Input() positioningDropdownsUnderModalWindow = false;
  @Input() positioningDropdownsWithRespectToBrowserWindow = false;
  @Input() enableTwoLineCutOffForDropDownItem = false;
  @Input() lightBackgroundPaginationDropdown = false;
  @Input() set values(val) {
      this._values = val;
      this.filteredValues = val;
  }

  @Input() set defaultValue(val) {
    this.hasDefaultValue = true;
    this.valSelected(val);
  }
  hasDefaultValue = false;
  inputFocussed: boolean;

  _values: any[] = [];
  _selectedValue: any = {};
  valueSelected = false;
  filteredValues: any[] = [];
  @Input() placeHolderValue = null;
  @Input() floatingLabels = false;
  @Input() showDarkPlaceHolder = false;
  // dark inputs here is just used for dark pagination panel at the bottom which selects number of rows shown
  // and this would not have the ability to remove selected input.
  @Input() dark: false;
  @Input() slim = false;
  @Input() dropUp = false;
  @Input() noInfo = false;
  @Output() onType: EventEmitter<string> = new EventEmitter<string>();
  @Input() filteringDisabled = false;
  @Output() selectionRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionAdded: EventEmitter<any> = new EventEmitter<any>();
  isExpanded = false;

  constructor(private eRef: ElementRef, private controlContainer: ControlContainer) {
  super(controlContainer);
  }

  @HostListener('document:click', ['$event'])
  clickOut(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isExpanded = false;
      if (this.positioningDropdownsWithRespectToBrowserWindow) {
        this.getDirectionOfDropdownOpeningWithRespectToWindow(this.container);
      } else if (this.positioningDropdownsUnderModalWindow) {
        this.getDirectionOfDropdownOpeningWithRespectToModal(this.container);
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      this.inputElement.nativeElement.focus();
    }
  }

  onFocusInput(): void {
    this.inputFocussed = true;
  }

  filter(val): void {
    if (val) {
      this.onType.emit(val);
      if (!this.filteringDisabled) {
        const startsWith = this._values.filter(v => v[this.labelName].search(new RegExp('^' + val, 'i')) !== -1);
        let containsList = this._values.filter(v => v[this.labelName].search(new RegExp(val, 'i')) !== -1);
        containsList = containsList.filter(e => !startsWith.includes(e));
        this.filteredValues = [...startsWith, ...containsList];
      }
      this.isExpanded = true;
    } else {
      this.filteredValues = this._values;
    }
  }

  toggleExpand(container: HTMLElement): void {
    this.container = container;
    if ((this.valueSelected && !this.dark && !this.hasDefaultValue && !this.lightBackgroundPaginationDropdown) || this.isDisabled){
      return;
    }
    this.isExpanded = !this.isExpanded;
    if (this.positioningDropdownsWithRespectToBrowserWindow) {
      this.getDirectionOfDropdownOpeningWithRespectToWindow(container);
    } else if (this.positioningDropdownsUnderModalWindow) {
      this.getDirectionOfDropdownOpeningWithRespectToModal(container);
    }

    // this.onChange(this.selectedValue);
    // this.onTouched(this.selectedValue);
  }

  getDirectionOfDropdownOpeningWithRespectToWindow(container: HTMLElement): void {
    if (!container) {
      return;
    }
    // visibility has been hidden in both the cases to make sure no dropdown appears before the operation is complete
    // though the timeout is of 0sec but still visibility is hidden till operation completes so the ser doesnot even see
    // any flickers on the screen.
    container.style.visibility = 'hidden';

    // timeout is used as we need coordinates and for that we have used getBoundingClientRect()
    // but till the time user clicks on dropdown to open it. the dropdown div is set to display none which means
    // getBoundingClientRect wont be able to get any coordinate as its not on DOM. settimeout of 0sec will make sure
    // the operation is async and so DOM gets populated before the operation starts. Hence we get the result.

    setTimeout(() => {
      // calculating height of the dropdown and the y coordinate of dropdown which determines the space from the top
      // of the window till the point where dropdown starts.
      // the formula contains adding the height of the dropdown(heightOfDropdown) + the space between top of the window and the
      // point where dropdown starts (dropdownCoordinateY) should not be more that the total height of window.
      // if it is then it means dropdown does not have space downwards and in that case we open dropdown upwards.

      const dropdownPosition = container?.getBoundingClientRect();

      // space between top of the window and the point where dropdown starts.
      const dropdownCoordinateY = dropdownPosition?.y;

      // height of the dropdown
      const heightOfDropdown = dropdownPosition?.height;

      // formula --> dropdownCoordinateY + heightOfDropdown > window.innerHeight
      if (dropdownCoordinateY + heightOfDropdown > window.innerHeight) {
        this.dropUp = true;
      } else {
        this.dropUp = false;
      }
      container.style.visibility = 'visible';
    }, 0);
  }

  getDirectionOfDropdownOpeningWithRespectToModal(container: HTMLElement): void {
    if (!container) {
      return;
    }
    container.style.visibility = 'hidden';
    setTimeout(() => {

      // modal height and coordinates
      const modalWindow = document?.getElementById('modalBody');
      const modalRect = modalWindow?.getBoundingClientRect();
      const modalY = modalRect?.y;
      const heightOfModalBody = modalRect?.height;

      // dropdown height and coordinates
      const position = container?.getBoundingClientRect();
      const dropdownY = position?.y;
      const heightOfDropDown = position?.height;

      // calculating space left from top of the modal window till start of dropdown
      const topSpacingBetweenModalAndDropdown = dropdownY - modalY;

      // calculating where should dropdown open
      if (topSpacingBetweenModalAndDropdown + heightOfDropDown > heightOfModalBody) {
        this.dropUp = true;
      } else {
        this.dropUp = false;
      }
      container.style.visibility = 'visible';
    }, 0);
  }
  onChange = (value: any) => {};
  onTouched = (value: any) => {};

  set selectedValue(val) {
    this._selectedValue = val;
    this.onChange(val);
    this.onTouched(val);
  }

  get selectedValue(): any {
    return this._selectedValue;
  }

  writeValue(initValue: any): void {
    this._selectedValue = initValue || [];
    this.valueSelected = initValue != null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  valSelected(v: any): void {
    this.isExpanded = false;
    this.selectedValue = v;
    this.valueSelected = true;
    this.selectionAdded.emit(true);

    if (this.hasDefaultValue) {
      this.onTouched(this.selectedValue);
      this.onChange(this.selectedValue);
    }
  }

  getPlaceHolderValue(): string {
    return (!this.isExpanded && !this.inputFocussed && this.floatingLabels)  ? this.placeHolderValue : '';
  }

  hidePlaceholder(): boolean {
    if (this.floatingLabels) {
      return (this.isExpanded || this.valueSelected || this.inputFocussed) ? true : false;
    } else {
      return true;
    }
  }

  clearSelected(): void {
    this.selectedValue = null;
    this.valueSelected = false;
    this.filteredValues = this._values;
    this.selectionRemoved.emit(true);
  }

  getSelectedValue(): any {
    return this.selectedValue == null ? null : this.selectedValue[this.labelName];
  }

  blur(): void {
    this.inputFocussed = false;
    this.onTouched(this.selectedValue);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}

