import {TableBuilderControlTypes} from './controls/controls.meta';
import {IToolMeta} from '../../workflow-designer/tool-menu-item/tool-meta';

export const TABLE_CELL_TYPES: IToolMeta[] = [
  {
    title: 'Text',
    icon: 'no icon',
    type: TableBuilderControlTypes.label,
  },
  {
    title: 'Map Field',
    icon: 'no icon',
    type: TableBuilderControlTypes.mapField,
    readonly: true
  },
  {
    title: 'Number',
    icon: 'no icon',
    type: TableBuilderControlTypes.number,
    formulaPlaceholder: '00.00',
    formulaInputType: 'number'
  },
  {
    title: 'Number Input',
    icon: 'icon-ico_form_inputnum',
    type: TableBuilderControlTypes.numberInput,
    readonly: true
  },
  {
    title: 'Text Input',
    icon: 'icon-ico_form_inputtxt',
    type: TableBuilderControlTypes.textInput,
    readonly: true
  },
  {
    title: 'Dropdown',
    icon: 'icon-ico_form_drop',
    type: TableBuilderControlTypes.dropdown,
    readonly: true
  },
  {
    title: 'Date',
    icon: 'no icon',
    type: TableBuilderControlTypes.date,
    formulaPlaceholder: 'dd/mm/yyyy',
    formulaInputType: 'date',
  }
];
