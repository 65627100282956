<form [formGroup]="form">
  <div class="d-flex flex-fill flex-column">
    <p class="eyfontbold">Number Formatting</p>
    <div class="d-flex flex-fill flex-column">
      <ey-drop-down formControlName="category" [meta]="meta.category" [values]="categories" [defaultValue]="defCategoryNumber" noInfo="true" class="mb-20"></ey-drop-down>
      <ey-input formControlName="decimalPlaces" type="number" [meta]="meta.decimalPlaces" extra="none" class="mb-20"></ey-input>
      <ng-container *ngIf="categoryCtrl?.value?.name === defCategoryNumber?.name">

      <ey-drop-down formControlName="decimalSeparator" [meta]="meta.decimalSeparator" [values]="decimalSeparators" [defaultValue]="defDecSeparator" noInfo="true" class="mb-20"></ey-drop-down>
      <label class="checkbox checkbox-margin">
        <input
          class="checkbox__input"
          type="checkbox"
          formControlName="useThousandsSeparator"
        />
        <span class="checkbox__checkmark checkbox-background"></span>
        <p class="checkbox__label">Use 1000 separator</p>
      </label>
      <ey-drop-down
        *ngIf="thousandsSeparatorChecked"
        formControlName="thousandsSeparator"
        [meta]="meta.thousandSeparator"
        [values]="thousandsSeparators"
        [defaultValue]="defThoSeparator"
        noInfo="true">
      </ey-drop-down>
      </ng-container>
    </div>
  </div>
</form>

