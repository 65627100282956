<fieldset class="rbcontainer" [class.d-flex]="horizontal" [ngClass]="containerClass">
  <label class="rbcontainer__wrapper" [ngClass]="{ 'w-100': !narrowOptions, 'w-unset': narrowOptions, 'm-0': narrowOptions, 'custom-spacing': thinLabels }"
         *ngFor="let val of values; index as i" [for]="'radio_' + ctrl_id + '_' + i">
    <label class="rbcontainer__label mb-10" >
      <label [class.thin-label]="thinLabels" class="fonts-col__heading-4 focusable" [for]="'radio_' + ctrl_id + '_' + i">{{val.text}}</label>
      <input aria-label="" [value]="val.id" [id]="'radio_' + ctrl_id + '_'+ i" [name]="ctrl_id" type="radio"
              [attr.disabled]="isDisabled ? true : null"
              [class]="{'error': showError}"
              [(ngModel)]="selectedValue"
              (ngModelChange)="onSelectionChange($event)"
      >
      <span class="rbcontainer__checkmark" [class]="{'radio--error': showError}"></span></label>
  </label>
</fieldset>
<label *ngIf="showError" class="textinput__errmsg">
  <i class="err_icon icon-ico_error_toast"></i>
  <span class="">{{meta.errorMsg}}</span>
</label>


