export enum ToolType {
    Statement = 1,
    YesNo,
    Decision,
    List,
    Form,
    Letter,
    Upload,
    Report,
    Mail,
    Table,
    Connector
}
