import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import {ButtonClass} from '../../../../../../shared/components/ey-button/ey-button.component';
import {saveAs as importedSaveAs} from 'file-saver';
import { EyAppSpinnerService } from 'src/app/shared/components/ey-app-spinner/ey-app-spinner.service';
import { SummaryDocumentModel } from 'src/app/designer/preview/summary-document.model';
import { map, takeUntil } from 'rxjs/operators';
import {AdminService} from '../../../../../../core/services/admin.service';
import {DownloadGeneratedDocumentsService} from '../../../../../../cross-feature-module/download-generated-documents-modal/download-generated-documents.service';

export interface File {
  fileName: string;
  fileId: string;
}

@Component({
  selector: 'app-download-documents-summary',
  templateUrl: './download-documents-summary.component.html',
  styleUrls: ['./download-documents-summary.component.scss']
})
export class DownloadDocumentsSummaryComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() docList: SummaryDocumentModel[] = [];
  btnClass = ButtonClass;
  fileSelectCount = 0;
  constructor(
    private spinnerService: EyAppSpinnerService,
    private downloadGeneratedDocumentsService: DownloadGeneratedDocumentsService,
    private adminService: AdminService) { }


  ngOnInit(): void {
  }

  downloadFile(file: SummaryDocumentModel): void {
    this.spinnerService.withLoadingIndicator(this.downloadGeneratedDocumentsService.downloadMultipleResponseTemplates(file.sessionId, file.templateType, file.id), this.destroy$)
    .subscribe(res => {
      this.saveFileData(res, file);
    });

  }

  downloadSelected(): void {
    this.spinnerService.withLoadingIndicator(this.getDownloadData(), this.destroy$)
    .subscribe(response => {
      response.forEach((res, index) => {
        this.saveFileData(res.response, res.metadata);
      });
    });
  }

  getDownloadData(): Observable<any> {
    const apiCallsArray = [];
    this.docList.filter(file => file.isSelected).forEach(file => {
        apiCallsArray.push(
          this.downloadGeneratedDocumentsService.downloadMultipleResponseTemplates(file.sessionId, file.templateType, file.id)
            .pipe(takeUntil(this.destroy$))
            .pipe(map((res : any) => {
              return { response: res, metadata: file};
            }))
          );
    });
    return forkJoin(apiCallsArray);
  }

  fileSelectionCounter(fileSelected: boolean): void {
    fileSelected ? this.fileSelectCount++ : this.fileSelectCount--;
  }

  saveFileData(fileDownloadResponse: any, file: SummaryDocumentModel): void {
    const dString = this.adminService.getCurrentDate();
    const fileName = `${file.name}_${dString}.${file.extension}`;
    importedSaveAs(fileDownloadResponse.body, fileName);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
